import {
  HOME_PATH,
  HOME_ROUTE,
  showNotFoundPageAction,
} from '../../stores/page';
import { requestCategories } from './actionCreators';
import { getCurrentLocale } from '../../stores/i18n';
import { selectCategoriesRequestState } from './selectors';
import { REQUEST_STATE } from './types';

export const routes = {
  [HOME_ROUTE]: {
    path: HOME_PATH,
    thunk: async (dispatch, getState) => {
      const state = getState();
      const locale = getCurrentLocale(state);

      if (state.location.pathname !== HOME_PATH) {
        dispatch(showNotFoundPageAction());
        return;
      }

      // If the categories are in store, do not fetch data
      if (selectCategoriesRequestState(state) !== REQUEST_STATE.SUCCESS) {
        await dispatch(requestCategories({ locale }));
      }
    },
  },
};
