import {
  BackendConversationItemType,
  ConversationItemType,
} from './types/ConversationItem';
/*
 To set the counter part participant we need to take the other
 participant information, so we can handle the conversation UI
 Component with they data. Since COMaaS API may return more than
 two participants e.g. group chat (not implemented yet in this product).
 That's why we are returning only the first item as ContactInfo.
 We should enhance this function whenever we want to include group chat.
 */

const setContactInfo = (
  conversation: BackendConversationItemType,
  currentUserId: string
) => {
  const participants = conversation.participants || [];

  return participants.filter(({ userId }) => userId !== currentUserId)[0];
};

export const formatAndSortConversationList = (
  conversationList: BackendConversationItemType[],
  userId: string
) =>
  conversationList
    .map((conversation) => ({
      ...conversation,
      contactInfo: setContactInfo(conversation, userId),
    }))
    // make sure each conversation has latestMessage
    .filter((conversation) => Boolean(conversation.latestMessage))
    // sort by latestMessage
    .sort(
      (a, b) =>
        new Date(b.latestMessage?.receivedDate || '').getTime() -
        new Date(a.latestMessage?.receivedDate || '').getTime()
    );

export const toggleConversationInList = (list, id: string) => {
  const tempList = new Set(list);

  if (tempList.has(id)) {
    tempList.delete(id);
  } else {
    tempList.add(id);
  }

  return [...tempList];
};

export const stripConversations = (
  conversationList: ConversationItemType[] = [],
  idList: string[] = []
) =>
  conversationList.filter(
    (conversation) => idList.indexOf(conversation.id) === -1
  );

export const selectIsDealerFromLatestMessage = (latestMessage) =>
  latestMessage?.conversationMetaData?.displayMetaData?.sellerType === 'DEALER';
