export const CLOSE_CHAT = 'feature/chat/CLOSE_CHAT';
export const UNMOUNT_CHAT = 'feature/chat/UNMOUNT_CHAT';

export const SEND_MESSAGE = 'feature/chat/SEND_MESSAGE';
export const HANDLE_RESPONSE_SEND_MESSAGE =
  'feature/chat/HANDLE_RESPONSE_SEND_MESSAGE';

export const HANDLE_RESPONSE_SEND_GUEST_MESSAGE =
  'feature/chat/HANDLE_RESPONSE_SEND_GUEST_MESSAGE';

export const HANDLE_RESPONSE_ERROR_SEND_MESSAGE =
  'feature/chat/HANDLE_RESPONSE_ERROR_SEND_MESSAGE';

export const RETRY_SEND_MESSAGE = 'feature/chat/RETRY_SEND_MESSAGE';

export const REQUEST_CONVERSATION = 'feature/chat/REQUEST_CONVERSATION';
export const HANDLE_RESPONSE_CONVERSATION_EMPTY =
  'feature/chat/HANDLE_RESPONSE_CONVERSATION_EMPTY';
export const HANDLE_RESPONSE_CONVERSATION =
  'feature/chat/HANDLE_RESPONSE_CONVERSATION';
export const HANDLE_RESPONSE_ERROR_CONVERSATION =
  'feature/chat/HANDLE_RESPONSE_ERROR_CONVERSATION';

export const HANDLE_RESPONSE_MESSAGE_LIST =
  'feature/chat/HANDLE_RESPONSE_MESSAGE_LIST';
export const HANDLE_RESPONSE_ERROR_MESSAGE_LIST =
  'feature/chat/HANDLE_RESPONSE_ERROR_MESSAGE_LIST';
