import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import { PageSection } from '@move-ui/layout';

import PageTracking, {
  getGoogleTagManagerPageType,
} from '../../utils/tracking/googleTagManager';
import Error from '../../shared/components/Error';
import Head from '../../../shared/components/Head';

interface ErrorPageProps {
  page: string;
}

const ErrorPage: FC<ErrorPageProps> = ({ page }) => {
  const { formatMessage } = useIntl();

  return (
    <PageTracking page={page}>
      <Head
        title={formatMessage({ id: 'headline_system_error_page' })}
        description=""
      />
      <div data-testid="errorPage">
        <PageSection>
          <Error
            trackingCategory={getGoogleTagManagerPageType(page)}
            headline={formatMessage({ id: 'headline_system_error_page' })}
            subline={formatMessage({ id: 'text_system_error_page' })}
          />
        </PageSection>
      </div>
    </PageTracking>
  );
};

export default ErrorPage;
