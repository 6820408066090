import { refresh } from '@move-auth/web-client';

import RequestHeaders, { REQUEST_HEADER } from './requestHeaders';

const reqDefaults = {
  method: 'GET',
  cache: 'default',
  credentials: 'same-origin',
};

const parseResponse = (response) => {
  // do not parse "204 - No Content"
  if (response.status === 204) {
    return Promise.resolve();
  }

  const contentTypeHeader =
    (response.headers && response.headers.get('content-type')) || '';

  // do not parse non-json responses
  if (contentTypeHeader.indexOf('json') === -1) {
    return response;
  }

  return response.json();
};

const getErrorResponseBody = async (errorResponse) => {
  try {
    return await errorResponse.json();
  } catch (e) {
    return errorResponse.body;
  }
};

const throwNodeApiClientCompatibleError = async (metricsId, errorResponse) => {
  const error = await getErrorResponseBody(errorResponse);
  const statusCode = errorResponse.status;
  const { url } = errorResponse;
  throw Object({ statusCode, error, options: { metricsId, url } });
};

const validateResponse = async (metricsId, response) => {
  if (response.ok) return response;
  return throwNodeApiClientCompatibleError(metricsId, response);
};

const fetch = async (url, fetchOptions) => {
  const { authorizationRequired } = fetchOptions;
  if (authorizationRequired) {
    await refresh();
  }
  return window.fetch(url, fetchOptions);
};

type ApiConfigProps = {
  url?: string;
  metricsId?: string;
  method?: string;
  resolveWithFullResponse?: boolean;
  authorizationRequired?: boolean;
  headers?: Record<string, string>;
  signal?: AbortSignal;
};

const browserApiClient = (apiConfig: ApiConfigProps = { headers: {} }) => {
  const {
    headers: apiConfigRequestHeaders,
    resolveWithFullResponse,
    ...apiConfigRest
  } = apiConfig;

  const defaultOptions = {
    ...reqDefaults,
    ...apiConfigRest,
  };

  return async (requestOptions: ApiConfigProps = { headers: {} }) => {
    const url = requestOptions.url || defaultOptions.url;

    // IE11 Cache all the requests even if the content changed
    const clientRequestHeaders = new RequestHeaders(apiConfigRequestHeaders);
    clientRequestHeaders.mergeHeaders(
      {
        [REQUEST_HEADER.CACHE_CONTROL]: 'no-cache',
        [REQUEST_HEADER.PRAGMA]: 'no-cache',
      },
      requestOptions.headers
    );

    const fetchOptions = {
      ...defaultOptions,
      ...requestOptions,
      headers: clientRequestHeaders.toJSON(),
    };

    const original = await fetch(url, fetchOptions);
    const response = await validateResponse(fetchOptions.metricsId, original);
    if (resolveWithFullResponse) {
      return {
        statusCode: response.status,
        headers: response.headers,
        body: await parseResponse(response),
      };
    }
    return parseResponse(response);
  };
};

export default browserApiClient;
