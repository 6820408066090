import {
  NOTIFICATIONS_ROUTE,
  NOTIFICATIONS_PATH,
  handlePrivateRoute,
} from '../../stores/page';

export const routes = {
  [NOTIFICATIONS_ROUTE]: {
    path: NOTIFICATIONS_PATH,
    thunk: (dispatch, getState, bag) =>
      handlePrivateRoute(NOTIFICATIONS_ROUTE)(dispatch, getState, bag),
  },
};
