import { init, AUTH_BASE_PATH } from '@move-auth/web-client';
import { getEnv } from '@move-web-essentials-utils/env';

import track from '../app/utils/tracking/track';
import { isBot } from '../app/utils/isBot';
import { DIMENSION_AUTO_LOGIN } from '../app/utils/tracking/dimensions';
import { MOVE_CONSUMER_API_GATEWAY_PATH } from '../shared/config';
import { getLocaleShortName } from './locale-utils';
import {
  autoLoginNotificationAction,
  checkLoginStatusSuccessfulAction,
  checkLoginStatusFailureAction,
} from '../app/stores/authentication';
import { Store } from 'redux';
import { FailurePayload } from '../app/stores/authentication/types/Actions';

export default ({
  getState,
  dispatch,
}: Pick<Store, 'getState' | 'dispatch'>) => {
  const state = getState();
  const {
    authentication: user,
    i18n: { currentLocale },
  } = state;

  init({
    basePath: `${MOVE_CONSUMER_API_GATEWAY_PATH}${AUTH_BASE_PATH}`,
    rootPath: MOVE_CONSUMER_API_GATEWAY_PATH,
    locale: getLocaleShortName(currentLocale),
    user,
    onAuth: (info: Parameters<typeof checkLoginStatusSuccessfulAction>[0]) =>
      dispatch(checkLoginStatusSuccessfulAction(info)),
    onAuthError: (err: FailurePayload) =>
      dispatch(checkLoginStatusFailureAction(err)),
    onAutoLogin: (
      info: Parameters<typeof checkLoginStatusSuccessfulAction>[0]
    ) => {
      dispatch(checkLoginStatusSuccessfulAction(info));
      dispatch(autoLoginNotificationAction(info));
      track(
        {
          eventAction: 'AutoLoginSuccess',
          getEventData: () => ({
            eventCategory: 'Login',
            [DIMENSION_AUTO_LOGIN]: '1',
            nonInteraction: true,
          }),
        },
        state
      );
    },
    onAutoLoginError: () =>
      track(
        {
          eventAction: 'AutoLoginFail',
          getEventData: () => ({
            eventCategory: 'Login',
            [DIMENSION_AUTO_LOGIN]: '0',
            nonInteraction: true,
          }),
        },
        state
      ),
    horizontalUserSigninEnabled:
      getEnv('authentication_move_auth_horizontal_autologin_enabled', true) ===
        'true' && isBot(navigator.userAgent) === false,
    horizontalTrustedTokenUrl: getEnv(
      'authentication_move_auth_horizontal_trusted_token_url'
    ),
    forceAuthentication:
      getEnv('authentication_enforce_token_refresh') === 'true',
    checkInterval:
      parseInt(
        getEnv('authentication_token_refresh_check_interval') || '',
        10
      ) || 10,
  });
};
