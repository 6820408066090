import { Dispatch } from 'redux';
import { SNACKBAR_TYPE } from '@move-ui/snackbar';
import {
  PUSH_SNACKBAR_MESSAGE,
  PUSH_SNACKBAR_TRANSLATION_KEY,
  REMOVE_FROM_SNACKBAR,
  REMOVE_FROM_SNACKBAR_BY_TEXT,
} from './actionTypes';
import { selectSnackbarMessagesSortedByTimestamp } from './selectors';
import { State } from '../types/State';
import { PrimitiveType } from './types/State';

export const pushSnackbarGeneric =
  (
    message: string,
    appearance?: SNACKBAR_TYPE,
    closeable?: boolean,
    displayDuration?: number,
    pauseOnHover = true
  ) =>
  (dispatch: Dispatch, getState: () => State) => {
    const messages = selectSnackbarMessagesSortedByTimestamp(getState());

    if (messages[0]?.text === message) {
      return;
    }

    dispatch({
      type: PUSH_SNACKBAR_MESSAGE,
      payload: {
        message,
        appearance,
        closeable,
        displayDuration,
        pauseOnHover,
      },
    });
  };

export const pushSnackbarMessage = (message: string) =>
  pushSnackbarGeneric(message);

export const pushSnackbarError = (message: string) =>
  pushSnackbarGeneric(
    message,
    SNACKBAR_TYPE.ERROR,
    true,
    // SnackBar does not support infinite duration at the moment
    60 * 60 * 1000,
    // pauseOnHover would cause the snackbar to disappear on mouseout
    false
  );

export const pushSnackbarTranslationKey =
  (
    translationKey: string,
    translationValues?: Record<string, PrimitiveType | JSX.Element>
  ) =>
  (dispatch: Dispatch, getState: () => State) => {
    const messages = selectSnackbarMessagesSortedByTimestamp(getState());

    if (
      messages[0]?.translationKey === translationKey &&
      messages[0]?.translationValues === translationValues
    ) {
      return;
    }
    dispatch({
      type: PUSH_SNACKBAR_TRANSLATION_KEY,
      translationKey,
      translationValues,
    });
  };

export const removeSnackbarItem = (id: string) => ({
  type: REMOVE_FROM_SNACKBAR,
  id,
});

export const removeSnackbarItemByText = (text: string) => ({
  type: REMOVE_FROM_SNACKBAR_BY_TEXT,
  text,
});
