import logger from '@move-web-essentials-utils/logger';
import { AnyAction } from 'redux';

import { createArea } from '../../shared/domains/geoLocation/entities';

import { HANDLE_RESPONSE_SEARCH_RESULTS } from '../../pages/srp/actionTypes';

import { createLocationFromSEOSuggestion } from '../../shared/domains/geoLocation/factories/locationFactory';

import { selectArea } from './selectors';
import { saveArea } from './actionCreators';
import { State } from '../types/State';
import { ThunkDispatch } from 'redux-thunk';

export default {
  [HANDLE_RESPONSE_SEARCH_RESULTS]: (
    action: AnyAction,
    {
      state,
      dispatch,
    }: { state: State; dispatch: ThunkDispatch<State, never, AnyAction> }
  ) => {
    try {
      const {
        payload: { url },
      } = action;
      const { locationFilter, requestParams } = url || {};

      // check response for seo url, may contain location information
      if (locationFilter) {
        const area = selectArea(state);
        const location = createLocationFromSEOSuggestion(
          locationFilter,
          requestParams
        );
        const newArea = createArea({ ...area, location });

        dispatch(saveArea(newArea));
      }
    } catch (error) {
      logger.error(error);
    }
  },
};
