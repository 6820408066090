import { REMOVE_TRADE_IN_RESULT, SAVE_TRADE_IN_INPUT } from './actionTypes';
import {
  TRADE_IN_INPUT_STORAGE_KEY,
  TRADE_IN_VIN_STORAGE_KEY,
} from './constants';
import {
  removeLocalStorageItem,
  setLocalStorageObject,
} from '../../shared/utils/localStorage';

export default {
  [SAVE_TRADE_IN_INPUT]: ({ payload }) => {
    setLocalStorageObject(TRADE_IN_INPUT_STORAGE_KEY, payload);
  },
  [REMOVE_TRADE_IN_RESULT]: () => {
    removeLocalStorageItem(TRADE_IN_INPUT_STORAGE_KEY);
    removeLocalStorageItem(TRADE_IN_VIN_STORAGE_KEY);
  },
};
