export const priceRatingTrackingMap = {
  GREAT: 'Great',
  GOOD: 'Good',
  FAIR: 'Fair',
  POOR: 'Above',
  OFF_RANGE_HIGH: 'nr_above',
  OFF_RANGE_LOW: 'nr_below',
  NO_DATA: 'no_data',
  NO_BADGE: 'no_badge',
};
