import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { subscribeToPushNotifications } from '../api';
import {
  PUSH_NOTIFICATIONS_PERMISSION_DENIED,
  PUSH_NOTIFICATIONS_PERMISSION_GRANTED,
} from '../tracking';
import { selectIsLoggedIn } from '../../../stores/authentication';
import { firebaseConfig } from '../../../../shared/config';
import { loadScript } from '../util/loadScript';
import { hasNotificationsSupport } from '../util/checkPushNotificationsService';
import { isNotificationAccepted } from '../util/pushNotificationsPreferences';
import useTracking from '../../../utils/tracking/useTracking';

const runOnIdle =
  typeof requestIdleCallback === 'function' ? requestIdleCallback : setTimeout;

const initPushNotifications = async (
  trackEvent: ReturnType<typeof useTracking>['trackEvent']
) => {
  if (!hasNotificationsSupport()) {
    return;
  }

  if (Notification.permission === 'default') {
    const permission = await Notification.requestPermission();
    trackEvent(
      permission !== 'granted'
        ? PUSH_NOTIFICATIONS_PERMISSION_DENIED
        : PUSH_NOTIFICATIONS_PERMISSION_GRANTED
    );
  }
  if (Notification.permission !== 'granted') {
    return;
  }

  runOnIdle(async () => {
    if (typeof window.firebase === 'undefined') {
      try {
        await loadScript(
          'https://www.gstatic.com/firebasejs/7.11.0/firebase-app.js'
        );

        await loadScript(
          'https://www.gstatic.com/firebasejs/7.11.0/firebase-messaging.js'
        );
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        window.firebase!.initializeApp(firebaseConfig);
      } catch {
        return;
      }
    }

    window.firebase?.messaging().onTokenRefresh(subscribeToPushNotifications);
    subscribeToPushNotifications();
  });
};

export const PushNotificationSubscriber = () => {
  const { trackEvent } = useTracking();
  const isUserLoggedIn = useSelector(selectIsLoggedIn);

  useEffect(() => {
    const enableNotifications = isNotificationAccepted();
    if (isUserLoggedIn && enableNotifications) {
      initPushNotifications(trackEvent);
    }
  }, [isUserLoggedIn, trackEvent]);

  return null;
};
