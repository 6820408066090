import { useSelector } from 'react-redux';
import { useCallback, useMemo } from 'react';
import { getPathName } from '../../../stores/location';
import { selectPageId } from '../../../stores/page';
import { getSeoUrls } from '../../../pages/srp/selectors';
import { getLinkForLocales } from '../../../../lib/locale-utils';
import {
  LOCALE_COOKIE_MAX_AGE,
  LOCALE_COOKIE_NAME,
} from '../../../../shared/config';

const useChangeLanguage = () => {
  const pathname: string = useSelector(getPathName);
  const pageId = useSelector(selectPageId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const seoUrls = {
    srp: useSelector(getSeoUrls),
  };
  const linksForLocales = useMemo(
    () => getLinkForLocales(pathname, pageId, seoUrls),
    [pathname, pageId, seoUrls]
  );

  return useCallback(
    (locale: string) => {
      const hrefForSelectedLocal = linksForLocales.find(
        (x) => locale === x.locale
      )?.href;

      if (!hrefForSelectedLocal) {
        return;
      }

      document.cookie = `${LOCALE_COOKIE_NAME}=${locale}; max-age=${LOCALE_COOKIE_MAX_AGE}; path=/`;
      window.location.assign(
        `${hrefForSelectedLocal}${window.location.search}${window.location.hash}`
      );
    },
    [linksForLocales]
  );
};

export default useChangeLanguage;
