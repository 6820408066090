import { SavedItemsAction } from './types/Actions';
import { omit } from 'lodash';

import {
  REQUEST_SAVED_ITEMS,
  HANDLE_RESPONSE_SAVED_ITEMS,
  HANDLE_RESPONSE_ERROR_SAVED_ITEMS,
  REQUEST_ADD_ITEM,
  HANDLE_RESPONSE_ADD_ITEM,
  HANDLE_RESPONSE_ERROR_ADD_ITEM,
  REQUEST_REMOVE_ITEM,
  HANDLE_RESPONSE_REMOVE_ITEM,
  HANDLE_RESPONSE_ERROR_REMOVE_ITEM,
} from './actionTypes';
import { SavedItemsState } from './SavedItemsState';

export const defaultState: SavedItemsState = {
  // last error while loading
  error: null,
  // list loading pending
  isLoading: false,
  /*
   items: id based hashmap of saved items. Buffer for marking items as "favorites"
   Example:
     items: {
       123: {
         isNew: false,          // new item, not persisted
         isPending: false,      // action pending
         isRemoved: false,      // marked as removed from saved items
         error: null            // error while changing item
       }
     }
   */
  items: {},
};

const createItem = (data = {}) => ({
  error: null,
  isNew: false,
  isPending: false,
  isRemoved: false,
  ...data,
});

const createItems = (ids: string[]) =>
  ids.reduce((accumulator, id) => {
    accumulator[id] = createItem();
    return accumulator;
  }, {});

const addItem = (items: any[], id: string, item: any) => ({
  ...items,
  [id]: item,
});

const updateItem = (items: any[], id: string, data: any) => ({
  ...items,
  [id]: {
    ...items[id],
    ...data,
  },
});

const deleteItem = (items: any[], id: string) => omit(items, id);

const savedItemsReducer = (state = defaultState, action: SavedItemsAction) => {
  switch (action.type) {
    case REQUEST_SAVED_ITEMS:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case HANDLE_RESPONSE_SAVED_ITEMS:
      const { payload } = action;
      return {
        ...state,
        isLoading: false,
        error: null,
        items: createItems(payload),
      };
    case HANDLE_RESPONSE_ERROR_SAVED_ITEMS:
      const { error } = action;
      return {
        ...state,
        isLoading: false,
        error,
      };
    // add pending item
    case REQUEST_ADD_ITEM:
      return {
        ...state,
        items: addItem(
          state.items,
          action.id,
          createItem({ isNew: true, isPending: true })
        ),
      };
    case HANDLE_RESPONSE_ADD_ITEM:
      return {
        ...state,
        items: updateItem(state.items, action.id, {
          isNew: false,
          isPending: false,
        }),
      };
    // reset to new when failed
    case HANDLE_RESPONSE_ERROR_ADD_ITEM:
      return {
        ...state,
        items: updateItem(state.items, action.id, {
          isNew: true,
          isPending: false,
          error: action.error,
        }),
      };
    // optimistic: mark as isRemoved
    case REQUEST_REMOVE_ITEM:
      return {
        ...state,
        items: updateItem(state.items, action.id, {
          isPending: true,
          isRemoved: true,
          error: null,
        }),
      };
    case HANDLE_RESPONSE_REMOVE_ITEM:
      return {
        ...state,
        items: deleteItem(state.items, action.id),
      };
    case HANDLE_RESPONSE_ERROR_REMOVE_ITEM:
      return {
        ...state,
        items: updateItem(state.items, action.id, {
          error: action.error,
          isPending: false,
          isRemoved: false,
        }),
      };
    default:
      return state;
  }
};

export default savedItemsReducer;
