import { ThunkDispatch } from 'redux-thunk';
import { GO_TO_SRP } from '../../stores/page';
import { Query } from '../../stores/searchQuery';
import { State } from '../../stores/types/State';
import { goToSRP } from './route';

type Dispatch = { dispatch: ThunkDispatch<State, void, any> };

export default {
  [GO_TO_SRP]: (
    { payload: { query } }: { payload: { query: Query } },
    { dispatch }: Dispatch
  ) => dispatch(goToSRP(query)),
};
