import * as types from './actionTypes';

import {
  formatAndSortConversationList,
  toggleConversationInList,
  stripConversations,
} from './utils';
import {
  BLOCK_USER,
  BLOCK_USER_FAIL,
  BLOCK_USER_SUCCESS,
  ERRORS_VIEWED,
  GET_BLOCKED_USERS_FAIL,
  GET_BLOCKED_USERS_SUCCESS,
} from '../../shared/components/BlockUserMenu/actionTypes';
import { ConversationItemType } from './types/ConversationItem';

const reducerTable = {
  [types.HANDLE_RESPONSE_CONVERSATION_LIST]: (
    state: State,
    { conversationList, userId, unreadMessagesCount }
  ) => ({
    ...state,
    isLoading: false,
    unreadMessagesCount,
    conversationList: formatAndSortConversationList(conversationList, userId),
  }),

  [types.HANDLE_RESPONSE_ERROR_CONVERSATION_LIST]: (state: State) => ({
    ...state,
    isLoading: false,
  }),

  [types.SELECT_CONVERSATION]: (state: State, { selectedId }) => ({
    ...state,
    selectedId,
  }),

  [types.CHECK_EDIT_CONVERSATION]: (state: State, { checkedId }) => ({
    ...state,
    checkedConversationList: toggleConversationInList(
      state.checkedConversationList,
      checkedId
    ),
  }),

  [types.DELETE_CONVERSATION_LIST]: (state: State) => ({
    ...state,
    checkedConversationList: [],
    isEditMode: false,
  }),

  [types.HANDLE_ERROR_DELETE_CONVERSATION_LIST]: (
    state: State,
    { conversationIdList }
  ) => ({
    ...state,
    checkedConversationList: [...conversationIdList],
    isEditMode: true,
  }),

  [types.HANDLE_DELETE_CONVERSATION_LIST]: (
    state: State,
    { conversationIdList }
  ) => ({
    ...state,
    conversationList: stripConversations(
      state.conversationList,
      conversationIdList
    ),
  }),

  [types.CLOSE_CONVERSATION]: (state: State) => ({
    ...state,
    selectedId: undefined,
  }),

  [types.TOGGLE_EDIT_MODE]: (state) => ({
    ...state,
    isEditMode: !state.isEditMode,
    checkedConversationList: [],
  }),

  [types.RESET_UNREAD_MESSAGES_COUNT]: (state: State, { conversationId }) => {
    const currentConversation = state.conversationList.find(
      (conversation) => conversation.id === conversationId
    );

    if (!currentConversation) {
      return state;
    }

    const currentUnreadMessagesCount =
      currentConversation.unreadMessagesCount || 0;

    currentConversation.unreadMessagesCount = 0;

    return {
      ...state,
      unreadMessagesCount:
        state.unreadMessagesCount - currentUnreadMessagesCount,
    };
  },
  [BLOCK_USER]: (state: State) => ({
    ...state,
    blockedUsers: {
      ...state.blockedUsers,
      isLoading: true,
    },
  }),
  [BLOCK_USER_SUCCESS]: (state: State) => ({
    ...state,
    blockedUsers: {
      ...state.blockedUsers,
      isLoading: false,
      error: null,
    },
  }),
  [BLOCK_USER_FAIL]: (state: State, error) => ({
    ...state,
    blockedUsers: {
      ...state.blockedUsers,
      isLoading: false,
      error,
    },
  }),
  [GET_BLOCKED_USERS_SUCCESS]: (state: State, { chat }) => ({
    ...state,
    blockedUsers: {
      ...state.blockedUsers,
      userIds: chat,
      error: null,
    },
  }),
  [GET_BLOCKED_USERS_FAIL]: (state: State, error) => ({
    ...state,
    blockedUsers: {
      ...state.blockedUsers,
      error,
    },
  }),
  [ERRORS_VIEWED]: (state: State, error) => ({
    ...state,
    error,
  }),
};

const defaultState = {
  isLoading: true,
  conversationList: [],
  unreadMessagesCount: 0,
  selectedId: undefined,
  isEditMode: false,
  checkedConversationList: [],
  blockedUsers: {
    userIds: [],
    error: null,
    isLoading: false,
  },
};

export type State = {
  isLoading: boolean;
  unreadMessagesCount: number;
  conversationList: ConversationItemType[];
  selectedId?: string;
  isEditMode: boolean;
  checkedConversationList: string[];
  blockedUsers: {
    userIds: string[];
    error: Record<string, any> | null;
    isLoading: boolean;
  };
};

type Action = {
  type: string;
  payload?: any;
};

export default (
  state: State = defaultState,
  { type, payload }: Action = { type: '' }
) => (reducerTable[type] ? reducerTable[type](state, payload) : state);
