import { getEnv } from '@move-web-essentials-utils/env';
import apiClient from '../../../api/lib/apiClient';
import { urls } from '../../../api/urls';
import { MOVE_DIGITAL_RETAIL_API_BASE_PATH } from '../../../shared/config';
import {
  DealRequest,
  DealResponse,
  Listing,
  GuestDealRequest,
} from './apiTypes';

export const isEmpty = (value: unknown) =>
  Number.isNaN(value) || value === null || value === undefined || value === '';

const getFinancingUrl = () => {
  if (IS_SERVER) {
    return `${getEnv(
      'move_consumer_api_gateway_url'
    )}${MOVE_DIGITAL_RETAIL_API_BASE_PATH}/`;
  }
  return `${MOVE_DIGITAL_RETAIL_API_BASE_PATH}/`;
};

export const getListing = (listingId: string): Promise<Listing> => {
  const doRequest = apiClient({
    metricsId: 'svc_getListing',
  });

  return doRequest({
    url: `${urls.listing}/${listingId}`,
  });
};

export const sendDeal = (fields: DealRequest): Promise<DealResponse> => {
  const doRequest = apiClient({
    metricsId: 'svc_sendDeal',
  });

  return doRequest({
    method: 'POST',
    url: `${getFinancingUrl()}v2/deals`,
    body: JSON.stringify(fields),
  });
};

// TODO:Cleanup after experiment is finished MOVE-26228
export const saveGuestDeal = (
  fields: GuestDealRequest
): Promise<{ anonymousDealApplicationId: string }> => {
  const doRequest = apiClient({
    metricsId: 'svc_sendDeal',
  });

  return doRequest({
    method: 'POST',
    url: `${getFinancingUrl()}v2/anonymousDeals`,
    body: JSON.stringify(fields),
  });
};
