export const PAGE_STATE = {
  INITIAL_LOADING: 'INITIAL_LOADING',
  EXIT_LOADING: 'EXIT_LOADING',
  LOADING: 'LOADING',
  LOADING_ERROR: 'LOADING_ERROR',
  LOADING_SUCCESS: 'LOADING_SUCCESS',
};

export const REQUEST_STATE = {
  PENDING: 'pending',
  SUCCESS: 'success',
  ERROR: 'error',
};
