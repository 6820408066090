import { values } from 'lodash';

export const CATEGORY_TYPES = {
  FULL: 'full',
  ICON: 'icon',
  ICON_SMALL: 'icon-small',
  ARTICLE: 'article',
};

export const isValidBrowseByCategoryType = (type) =>
  values(CATEGORY_TYPES).includes(type);
