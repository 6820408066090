import { CATEGORIES } from './config';
import { getCookie } from 'cookies';
import { CATEGORY_PLACEHOLDER, PATH_PLACEHOLDER } from './constants';
import { MenuItem } from './types';

// external experiment, not configured in MoVe optimizely
const TOP_NAVIGATION_TEST = {
  ticketCode: '23135',
  variations: {
    VARIATION_CONTROL: 'a',
    VARIATION_TEST: 'b',
  },
};

const COOKIE_NAME = 'mv-tn';
const COOKIE_VALUE_CONTROL = 'hc-a';
const COOKIE_VALUE_TEST = 'hc-b';

const CAMPAIGN = 'xpromo_HomepageCategoryDropdown';
const CAMPAIGN_VARIATION_B = 'web_Homepage_KCAN6062_B';

export const isTopNavigationEnabled = (query?: {
  utm_campaign?: string;
  utm_content?: string;
}) =>
  (getCookie(COOKIE_NAME) === COOKIE_VALUE_TEST ||
    (query?.utm_campaign === CAMPAIGN &&
      query?.utm_content === CAMPAIGN_VARIATION_B)) &&
  CATEGORIES.length > 0;

export const getTopNavigationGroup = () => {
  switch (getCookie(COOKIE_NAME)) {
    case COOKIE_VALUE_CONTROL:
      return `${TOP_NAVIGATION_TEST.ticketCode}-${TOP_NAVIGATION_TEST.variations.VARIATION_CONTROL}`;
    case COOKIE_VALUE_TEST:
      return `${TOP_NAVIGATION_TEST.ticketCode}-${TOP_NAVIGATION_TEST.variations.VARIATION_TEST}`;
    default:
      return null;
  }
};

export const buildMenuItems = (
  categories,
  translations: Record<string, string>,
  urlTemplate: string
): MenuItem[] =>
  categories.map(({ category, path }) => ({
    key: category,
    label: translations[category],
    url: urlTemplate
      ?.replace(PATH_PLACEHOLDER, path)
      ?.replace(CATEGORY_PLACEHOLDER, category),
  }));
