export const ERRORS_VIEWED = 'shared/BlockUserMenu/ERRORS_VIEWED';
export const BLOCK_USER = 'shared/BlockUserMenu/BLOCK_USER';
export const BLOCK_USER_SUCCESS = 'shared/BlockUserMenu/BLOCK_USER_SUCCESS';
export const BLOCK_USER_FAIL = 'shared/BlockUserMenu/BLOCK_USER_FAIL';
export const UNBLOCK_USER = 'shared/BlockUserMenu/UNBLOCK_USER';
export const UNBLOCK_USER_SUCCESS = 'shared/BlockUserMenu/UNBLOCK_USER_SUCCESS';
export const UNBLOCK_USER_FAIL = 'shared/BlockUserMenu/UNBLOCK_USER_FAIL';
export const GET_BLOCKED_USERS = 'shared/BlockUserMenu/GET_BLOCKED_USERS';
export const GET_BLOCKED_USERS_SUCCESS =
  'shared/BlockUserMenu/GET_BLOCKED_USERS_SUCCESS';
export const GET_BLOCKED_USERS_FAIL =
  'shared/BlockUserMenu/GET_BLOCKED_USERS_FAIL';
