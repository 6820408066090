import {
  DIMENSION_REPLY_TYPE,
  DIMENSION_CONVERSATION_ID,
  DIMENSION_LISTING_ID,
  DIMENSION_SELLER_ACCOUNT_TYPE,
} from '../../utils/tracking/dimensions';

export const INBOX_MESSAGE_SEND_BEGIN = {
  eventAction: 'MessageSendBegin',
  getEventData: ({
    pageType,
    listingId,
    conversationId,
    sellerType,
  }: Record<string, string | undefined>) => ({
    eventCategory: pageType,
    [DIMENSION_REPLY_TYPE]: undefined,
    [DIMENSION_CONVERSATION_ID]: conversationId,
    [DIMENSION_LISTING_ID]: listingId,
    [DIMENSION_SELLER_ACCOUNT_TYPE]: sellerType,
  }),
};

export const INBOX_MESSAGE_SEND_CANCEL = {
  eventAction: 'MessageSendCancel',
  getEventData: ({ pageType }: { pageType: string }) => ({
    eventCategory: pageType,
    [DIMENSION_REPLY_TYPE]: undefined,
    [DIMENSION_CONVERSATION_ID]: undefined,
    [DIMENSION_LISTING_ID]: undefined,
  }),
};
