import { authorizedApiClient } from '../../../api/lib/apiClient';
import { MOVE_API_BASE_PATH } from '../../../shared/config';
import { Options } from './actionCreators';

const getUrl = () => `${MOVE_API_BASE_PATH}/saved-searches`;

export const getSavedSearchList = (options: Options) => {
  const url = getUrl();

  return authorizedApiClient(options)({
    method: 'GET',
    url,
  });
};

export const postSavedSearch = (
  searchParameterString: string,
  locationName: string,
  notifications: any,
  options: Options
) => {
  const url = getUrl();
  const body = JSON.stringify({
    searchParameterString,
    locationName,
    notifications,
  });

  return authorizedApiClient(options)({
    method: 'POST',
    url,
    body,
  });
};

export const deleteSavedSearch = (ids: string[]) => {
  const url = getUrl();
  const body = JSON.stringify(ids);

  return authorizedApiClient()({
    method: 'DELETE',
    url,
    body,
  });
};

export const visitSavedSearch = (
  externalSearchId: string,
  options: Options
) => {
  const url = `${getUrl()}/visit?externalSearchId=${externalSearchId}`;

  return authorizedApiClient(options)({
    method: 'PATCH',
    url,
  });
};
