import {
  DEALER_ROUTE,
  HOME_ROUTE,
  MIP_ROUTE,
  SRP_ROUTE,
  VIP_ROUTE,
} from '../../stores/page';
import { State } from '../../stores/types/State';

const PAGES = [HOME_ROUTE, SRP_ROUTE, VIP_ROUTE, DEALER_ROUTE, MIP_ROUTE];

export const selectHidden = (state: State) => {
  const pageType = state?.location?.type || '';
  return !PAGES.some((page) => pageType.includes(page));
};
