import React, { FC, Fragment, memo } from 'react';
import { Helmet } from 'react-helmet-async';
import { getEnv } from '@move-web-essentials-utils/env';

const getOrigin = () =>
  typeof window !== 'undefined' ? window.location.origin : '';

const getPrefix = () => {
  if (process.env.NODE_ENV === 'development') {
    return 'Local | ';
  }
  const tenantType = getEnv('tenant_type');
  switch (tenantType) {
    case 'nonprod':
      return `${getOrigin().split('--dev-')[1]?.split('.')[0] || 'Nonprod'} | `;
    default:
      return '';
  }
};

const prefix = getPrefix();

export interface HeadProps {
  title?: string;
  description?: string;
  robots?: string;
}

export const Head: FC<HeadProps> = ({
  title,
  description,
  robots,
  children,
}) => (
  <Fragment>
    <Helmet>
      {title && (
        <title>
          {prefix}
          {title}
        </title>
      )}
      {description && <meta name="description" content={description} />}
      {robots && <meta name="robots" content={robots} />}
      {children}
    </Helmet>
  </Fragment>
);

export default memo(Head);
