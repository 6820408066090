import UniversalPageComponent from './UniversalPageComponent';

import usePreloadComponentOnIdle from '../shared/hooks/usePreloadComponentOnIdle';

const PreloadPage = ({ page }) => {
  usePreloadComponentOnIdle(UniversalPageComponent, undefined, undefined, {
    page,
  });

  return null;
};

export default PreloadPage;
