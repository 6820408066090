import {
  REQUEST_SIMILAR_ITEMS,
  HANDLE_RESPONSE_SIMILAR_ITEMS,
  HANDLE_RESPONSE_ERROR_SIMILAR_ITEMS,
} from './actionTypes';
import { AnyAction } from 'redux';

export const pageStates = {
  IDLE: 'IDLE',
  LOADING: 'LOADING',
  ERROR: 'ERROR',
} as const;

type Item = { id: string };
type State = {
  error: null | string;
  pageState: keyof typeof pageStates;
  items: Item[];
};
export const defaultState: State = {
  error: null,
  pageState: pageStates.IDLE,
  items: [],
};

const states = {
  [REQUEST_SIMILAR_ITEMS]: (state) => ({
    ...state,
    pageState: pageStates.LOADING,
    error: null,
    items: [],
  }),
  [HANDLE_RESPONSE_SIMILAR_ITEMS]: (state, { payload }) => ({
    ...state,
    pageState: pageStates.IDLE,
    error: null,
    items: payload,
  }),
  [HANDLE_RESPONSE_ERROR_SIMILAR_ITEMS]: (state, { error }) => ({
    ...state,
    pageState: pageStates.ERROR,
    error,
    items: [],
  }),
};

const similarItemsListReducer = (
  state: State = defaultState,
  action?: AnyAction
) => (states[action?.type] ? states[action?.type](state, action) : state);

export default similarItemsListReducer;
