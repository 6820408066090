import React from 'react';
import { func, string, node } from 'prop-types';
import cx from 'classnames';
import styles from './SkipToLocationLink.css';

export const SkipToLocationLink = ({
  onFocus,
  elementId,
  onClick,
  children,
}) => {
  const handleClick = (event) => {
    event.preventDefault();

    onClick?.();

    setTimeout(() => {
      const element = document.getElementById(elementId);
      element?.scrollIntoView?.();

      const clickable = element?.querySelector('a > button');

      const elementToUse = clickable || element;
      if (elementToUse) {
        elementToUse?.setAttribute('tabindex', '-1');
        elementToUse?.setAttribute('data-focus-visible-added', '');
        elementToUse?.focus();
      }
    }, 100);
  };

  return (
    <a
      className={cx(styles.link, styles.showOnFocus)}
      href={`#${elementId}`}
      rel="nofollow"
      onClick={handleClick}
      onFocus={onFocus}
      onKeyDown={(e) => {
        if (e.keyCode === 13) {
          handleClick(e);
        }
      }}
    >
      {children}
    </a>
  );
};

SkipToLocationLink.propTypes = {
  onFocus: func,
  onClick: func,
  elementId: string.isRequired,
  children: node,
};

export default SkipToLocationLink;
