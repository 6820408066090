import { DEFAULT_LIMITED_TO_PROVINCE } from '../config';
import { DEFAULT_LOCATION_RADIUS } from '../../../../../shared/config';
import { LocationArea } from '../../../../features/location/types/LocationArea';

export default function createArea(data: LocationArea = {}): LocationArea {
  return {
    location: data.location,
    radius: data.radius || DEFAULT_LOCATION_RADIUS,
    isLimitedToProvince:
      typeof data.isLimitedToProvince !== 'undefined'
        ? data.isLimitedToProvince
        : DEFAULT_LIMITED_TO_PROVINCE,
  };
}
