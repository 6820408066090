import { TRAILING_SLASH_URLS } from '../config';

const removeTrailingSlashes = (path: string) => path.replace(/\/+$/, '');
const removeMultiSlashes = (path: string) => path.replace(/\/+/g, '/');

/**
 * normalize url or url path, remove or add trailing slash,
 * will return '/' for any path starting with '//'
 */
const normalizeUrl = (
  originalUrl?: string,
  trailingSlash = TRAILING_SLASH_URLS
) => {
  if (!originalUrl) {
    return originalUrl;
  }

  if (originalUrl === '/') {
    return '/';
  }

  const url = new URL(originalUrl, 'http://move'); // new URL() requires a base for relative urls
  const hash = url.hash || '';
  const search = url.search || '';
  const pathname = removeMultiSlashes(url.pathname) || ''; // ensure we have only single slashes. This e.g. prevents open redirects

  if (trailingSlash) {
    if (!pathname.endsWith('/')) {
      return `${pathname}/${search}${hash}`;
    }
    return `${removeTrailingSlashes(pathname)}/${search}${hash}`;
  }

  return `${removeTrailingSlashes(pathname)}${search}${hash}`;
};

/**
 * normalize a map of url or url paths, remove or add trailing slash,
 * will return '/' for any path starting with '//'
 */
const normalizeUrlMap = (
  urlMap: Record<string, string>,
  trailingSlash = TRAILING_SLASH_URLS
) => {
  if (!urlMap) {
    return null;
  }

  return Object.entries(urlMap).reduce(
    (acc, [key, url]) => ({
      ...acc,
      [key]: normalizeUrl(url, trailingSlash),
    }),
    {}
  );
};

export default normalizeUrl;
export { normalizeUrlMap };
