import { State } from './types/State';
import { ActionType } from './types/ActionType';
import { Action } from './types/Action';

export const initialState: State = {
  listings: [],
  isLoading: false,
  error: null,
};

const reducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.RequestLatestSearchResults:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case ActionType.HandleResponseLatestSearchResults:
      return {
        ...state,
        isLoading: false,
        listings: action.payload?.items || [],
        error: null,
      };
    case ActionType.HandleResponseErrorLatestSearchResults:
      return {
        ...state,
        isLoading: false,
        listings: [],
        error: action.error,
      };
    default:
      return state;
  }
};

export default reducer;
