import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { Link, LinkSize } from '@move-ui/link';
import Text, { TextSize } from '@move-ui/text';

import useTracking from '../../../utils/tracking/useTracking';
import { INFO_BANNER_CLICK } from '../tracking';
import { HEADS_UP_ID_SECOND } from '../config';
import styles from './HeadsUpBuyFromHome.css';

const HeadsUpBuyFromHome = () => {
  const { formatMessage } = useIntl();
  const { trackEvent } = useTracking();

  const handleClick = useCallback(() => {
    trackEvent(INFO_BANNER_CLICK, { id: HEADS_UP_ID_SECOND });
  }, [trackEvent]);

  return (
    <div className={styles.component}>
      <Text
        className={styles.text}
        size={TextSize.XXS}
        color="neutral-100"
        bold
      >
        {formatMessage({ id: 'ribbon_copy' })}{' '}
        <Link
          href={formatMessage({ id: 'ribbon_link' })}
          className={styles.label}
          size={LinkSize.Size100}
          onClick={handleClick}
          target="_blank"
        >
          {formatMessage({ id: 'ribbon_label' })}
        </Link>
      </Text>
    </div>
  );
};

export default HeadsUpBuyFromHome;
