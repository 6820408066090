import React, { FC } from 'react';
import BaseFooter, { defaultNavigation } from '@move-web-page-segments/footer';
import { PageContainer } from '@move-ui/layout';
import { useSelector } from 'react-redux';
import { getCurrentLocale } from '../../../../stores/i18n';
import {
  useChangeLanguage,
  useFooterNavigation,
  useFooterWidthBehavior,
  useLabels,
} from '../../hooks';
import cx from 'classnames';
import styles from './Footer.css';
import { PUBLIC_DOMAIN_URL } from '../../../../../shared/config';

type FooterProps = {
  className?: string;
};

const Footer: FC<FooterProps> = ({ className }) => {
  const locale = useSelector(getCurrentLocale);
  const changeLocale = useChangeLanguage();
  const widthBehavior = useFooterWidthBehavior();
  const { onNavigationClick } = useFooterNavigation(locale);
  const messages = useLabels();

  return (
    <PageContainer
      className={cx(styles.section, className)}
      widthBehaviour={widthBehavior}
      data-testid="Footer"
    >
      <BaseFooter
        messages={messages}
        locale={locale}
        onLocaleChange={changeLocale}
        navigation={defaultNavigation}
        onNavigationClick={onNavigationClick}
        publicDomainUrl={PUBLIC_DOMAIN_URL}
      />
    </PageContainer>
  );
};

export default Footer;
