import { pathToAction, ReceivedAction } from 'redux-first-router';
import { SRP_BASE_PATHS } from '../../../../shared/config';
import { getCurrentLocale } from '../../../stores/i18n';
import { getRoutesMap } from '../../../stores/location';
import { State } from '../../../stores/types/State';
import { getSeoUrls } from '../selectors';

export const createRootSrpRouteAction = (
  state: State
): Omit<ReceivedAction, 'meta'> => {
  const locale = getCurrentLocale(state);
  const routesMap = getRoutesMap(state);
  const seoUrl = SRP_BASE_PATHS[locale];
  return pathToAction(seoUrl, routesMap);
};

export const createSrpRouteAction = (
  state: State
): Omit<ReceivedAction, 'meta'> => {
  const locale = getCurrentLocale(state);
  const routesMap = getRoutesMap(state);
  const seoUrl = getSeoUrls(state)[locale] || SRP_BASE_PATHS[locale];
  return pathToAction(seoUrl, routesMap);
};
