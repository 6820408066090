export const LOADING_OPTIONS_STARTED =
  'feature/financingCalculator/LOADING_OPTIONS_STARTED';
export const HANDLE_RESPONSE_OPTIONS =
  'feature/financingCalculator/HANDLE_RESPONSE_OPTIONS';
export const HANDLE_RESPONSE_OPTIONS_ERROR =
  'feature/financingCalculator/HANDLE_RESPONSE_OPTIONS_ERROR';
export const UPDATE_ALLOWANCE_OPTIONS =
  'feature/financingCalculator/UPDATE_ALLOWANCE_OPTIONS';
export const LOADING_PAYMENT_STARTED =
  'feature/financingCalculator/LOADING_PAYMENT_STARTED';
export const HANDLE_RESPONSE_PAYMENT =
  'feature/financingCalculator/HANDLE_RESPONSE_PAYMENT';
export const HANDLE_RESPONSE_PAYMENT_ERROR =
  'feature/financingCalculator/HANDLE_RESPONSE_PAYMENT_ERROR';
export const SET_VALUE = 'feature/financingCalculator/SET_VALUE';
export const SET_INCLUDE_TAX = 'feature/financingCalculator/SET_INCLUDE_TAX';
export const RESET_STATE = 'feature/financingCalculator/RESET_STATE';
