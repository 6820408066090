import {
  MakeOptions,
  ModelOptions,
  VersionOptions,
} from './components/useKBBTradeInOptions';

export const getCarName = ({
  makeId,
  modelId,
  year,
  vehicleId,
  makeOptions,
  modelOptions,
  versionOptions,
}: {
  makeId: string;
  modelId: string;
  year: string;
  vehicleId: string;
  makeOptions: MakeOptions;
  modelOptions: ModelOptions;
  versionOptions: VersionOptions;
}) => {
  if (makeOptions.length === 0) {
    return '';
  }

  const make = makeOptions.find(({ value }) => value === makeId);
  const model = modelOptions.find(({ value }) => value === modelId);
  const version = versionOptions.find(({ value }) => value === vehicleId);

  if (!make || !model || !version) {
    return '';
  }
  return `${year} ${make.label} ${model.label}`;
};
