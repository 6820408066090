import { createSelector } from 'reselect';
import { orderBy } from 'lodash';
import { State } from '../types/State';

const selectSnackbarsState = (state: State) => state?.snackbars;

const selectSnackbarMessages = (state: State) =>
  selectSnackbarsState(state)?.snackbarList ?? [];

export const selectSnackbarMessagesSortedByTimestamp = createSelector(
  selectSnackbarMessages,
  (snackbarMessages) =>
    orderBy(snackbarMessages, ['createdAt', 'text'], ['asc', 'asc'])
);
