/* eslint-disable no-console */
import useFooterTracking from './useFooterTracking';
import { MouseEvent } from 'react';
import { goToMrp } from '../../../stores/page';
import { useDispatch } from 'react-redux';

type NavigationDetails = Record<string, string | undefined>;

const useFooterNavigation = (locale: string) => {
  const {
    trackMrpClick,
    trackCpoClick,
    trackCppClick,
    trackHorizontalClick,
    trackA11yClick,
  } = useFooterTracking();
  const dispatch = useDispatch();

  const mrpClicked = () => {
    trackMrpClick();
    dispatch(goToMrp(locale));
  };

  const cpoClicked = (details: NavigationDetails) => {
    trackCpoClick();
    if (details.url) {
      location.assign(details.url);
    }
  };

  const cppClicked = (details: NavigationDetails) => {
    trackCppClick();
    if (details.url) {
      location.assign(details.url);
    }
  };

  const horizontalClicked = (details: NavigationDetails) => {
    trackHorizontalClick();
    if (details.url) {
      location.assign(details.url);
    }
  };

  const a11yClicked = (details: NavigationDetails) => {
    trackA11yClick();
    if (details.url) {
      location.assign(details.url);
    }
  };

  const navActions = {
    mrp: mrpClicked,
    cpo: cpoClicked,
    cpp: cppClicked,
    linkToHorizontal: horizontalClicked,
    a11yServices: a11yClicked,
  };

  return {
    onNavigationClick: (
      name: string,
      details?: NavigationDetails,
      event?: Event | MouseEvent
    ) => {
      if (navActions[name]) {
        event?.preventDefault?.();
        navActions[name](details);
      }
    },
  };
};

export default useFooterNavigation;
