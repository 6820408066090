import { HANDLE_RESPONSE_ADD_SAVED_SEARCH } from '../../features/savedSearches/actionTypes';
import { CLOSE_EMAIL_NOTIFICATIONS_PROMPT } from './actionTypes';

const states = {
  [HANDLE_RESPONSE_ADD_SAVED_SEARCH]: (state) => ({
    ...state,
    show: true,
  }),
  [CLOSE_EMAIL_NOTIFICATIONS_PROMPT]: (state) => ({
    ...state,
    show: false,
  }),
};

export default (state = { show: false }, action = {}) =>
  states[action.type] ? states[action.type](state, action) : state;
