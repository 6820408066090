import { createQueryObjectFromURL } from '../factories/createQueryObjectFromURL';

const query = () =>
  createQueryObjectFromURL(window.location.hash.replace('#', ''));

export const shouldLoadHashedVipFromURL = () => Boolean(query()?.vip);
export const shouldLoadSrpQueryFromURL = () => {
  const keyCount = Object.keys(query()).length;
  return Boolean(
    keyCount &&
      (!shouldLoadHashedVipFromURL() ||
        (shouldLoadHashedVipFromURL() && keyCount > 1))
  );
};
