// Moridian: https://docs.google.com/spreadsheets/d/1e_EFlnjB4EFFKqk1kAVR7G7DXRviRBWyFJwNK_qwWTQ/edit?ts=5c3efcc1#gid=1771223070
export const DIMENSION_PAGE_TYPE = 'dimension1';
export const DIMENSION_SELECTED_CATEGORY_ID = 'dimension10';
export const DIMENSION_SELECTED_CATEGORY_NAME = 'dimension11';
export const DIMENSION_SELECTED_LANGUAGE = 'dimension15';
export const DIMENSION_USER_ID = 'dimension20'; // user id of logged in user
export const DIMENSION_USER_EMAIL = 'dimension21';
export const DIMENSION_DEALER_ID = 'dimension22';
export const DIMENSION_LOGGED_IN = 'dimension23';
export const DIMENSION_EXPERIMENT_VARIATION = 'dimension25';
export const DIMENSION_LISTING_ID = 'dimension30';
export const DIMENSION_LISTING_PRICE = 'dimension31';
export const DIMENSION_LISTING_IMAGE_COUNT = 'dimension33';
export const DIMENSION_REPLY_TYPE = 'dimension37';
export const DIMENSION_SELLER_ACCOUNT_TYPE = 'dimension39';
export const DIMENSION_ONSITE_SEARCH_KEYWORD = 'dimension40';
export const DIMENSION_ONSITE_SEARCH_TOTAL_RESULTS = 'dimension43';
export const DIMENSION_ONSITE_SEARCH_DISTANCE = 'dimension44';
export const DIMENSION_LOCATION_POSTAL_CODE = 'dimension45';
export const DIMENSION_ONSITE_SEARCH_RESULTS_VIEW_TYPE = 'dimension48';
export const DIMENSION_SORT_ORDER = 'dimension47';
export const DIMENSION_ADVERT_CLICK_SOURCE = 'dimension49';
export const DIMENSION_CLICK_SOURCE_DOMAIN = 'dimension50';
export const DIMENSION_CLIENT_ID = 'dimension69'; // id generated for each client (browser, app) the user is using. The value is stored in a cookie mvcid
export const DIMENSION_NOTIFICATION_PERMISSION = 'dimension76';
export const DIMENSION_L1_CATEGORY_NAME = 'dimension90';
export const DIMENSION_L2_CATEGORY_NAME = 'dimension91';
export const DIMENSION_L3_CATEGORY_NAME = 'dimension92';
export const DIMENSION_L4_CATEGORY_NAME = 'dimension93';
export const DIMENSION_L1_LOCATION_NAME = 'dimension94';
export const DIMENSION_L2_LOCATION_NAME = 'dimension95';
export const DIMENSION_ONSITE_SEARCH_MIN_PRICE = 'dimension100';
export const DIMENSION_ONSITE_SEARCH_MAX_PRICE = 'dimension101';
export const DIMENSION_ITEM_CONDITION = 'dimension102';
export const DIMENSION_TRIM_FILTER = 'dimension114';
export const DIMENSION_DR_IN_VIP = 'dimension115';
export const DIMENSION_CONDITION_FILTER_TYPE = 'dimension116';
export const DIMENSION_CONSTRUCTION_YEAR = 'dimension117';
export const DIMENSION_COMMON_LISTING_ID = 'dimension122';
export const DIMENSION_VALUE_VIP = 'dimension124';
export const DIMENSION_VALUE_LEAD = 'dimension125';
export const DIMENSION_LIFESTYLE = 'dimension126';
export const DIMENSION_PRODUCT_NAME = 'dimension139';
export const DIMENSION_CONVERSATION_ID = 'dimension144';
export const DIMENSION_PRICE_LABEL = 'dimension157';
export const DIMENSION_HAS_CERTIFIED_PRE_OWNED = 'dimension159';
// @TODO Remove after Optimizely's GA integration test has been completed
export const DIMENSION_1_UP_EXPERIMENT_VARIATION = 'dimension171';
export const DIMENSION_A_TEAM_EXPERIMENT_VARIATION = 'dimension172';
export const DIMENSION_STAR_CARS_EXPERIMENT_VARIATION = 'dimension173';
export const DIMENSION_SEARCH_CONDITION = 'dimension178';
export const DIMENSION_GROWTH_TEAM = 'dimension180';
export const DIMENSION_SRP_FIRST_ADVERTISING_TRACK = 'dimension181';
export const DIMENSION_BUSINESS_MODEL = 'dimension184';

export const DIMENSION_AUTO_LOGIN = 'dimension192';

export const allDimensions = [
  DIMENSION_PAGE_TYPE,
  DIMENSION_SELECTED_CATEGORY_ID,
  DIMENSION_SELECTED_CATEGORY_NAME,
  DIMENSION_SELECTED_LANGUAGE,
  DIMENSION_USER_ID,
  DIMENSION_USER_EMAIL,
  DIMENSION_DEALER_ID,
  DIMENSION_LOGGED_IN,
  DIMENSION_EXPERIMENT_VARIATION,
  DIMENSION_LISTING_ID,
  DIMENSION_LISTING_PRICE,
  DIMENSION_LISTING_IMAGE_COUNT,
  DIMENSION_REPLY_TYPE,
  DIMENSION_SELLER_ACCOUNT_TYPE,
  DIMENSION_ONSITE_SEARCH_KEYWORD,
  DIMENSION_ONSITE_SEARCH_TOTAL_RESULTS,
  DIMENSION_ONSITE_SEARCH_DISTANCE,
  DIMENSION_LOCATION_POSTAL_CODE,
  DIMENSION_ONSITE_SEARCH_RESULTS_VIEW_TYPE,
  DIMENSION_SORT_ORDER,
  DIMENSION_ADVERT_CLICK_SOURCE,
  DIMENSION_CLICK_SOURCE_DOMAIN,
  DIMENSION_CLIENT_ID,
  DIMENSION_NOTIFICATION_PERMISSION,
  DIMENSION_L1_CATEGORY_NAME,
  DIMENSION_L2_CATEGORY_NAME,
  DIMENSION_L3_CATEGORY_NAME,
  DIMENSION_L4_CATEGORY_NAME,
  DIMENSION_L1_LOCATION_NAME,
  DIMENSION_L2_LOCATION_NAME,
  DIMENSION_ONSITE_SEARCH_MIN_PRICE,
  DIMENSION_ONSITE_SEARCH_MAX_PRICE,
  DIMENSION_ITEM_CONDITION,
  DIMENSION_LIFESTYLE,
  DIMENSION_TRIM_FILTER,
  DIMENSION_DR_IN_VIP,
  DIMENSION_CONDITION_FILTER_TYPE,
  DIMENSION_CONSTRUCTION_YEAR,
  DIMENSION_COMMON_LISTING_ID,
  DIMENSION_VALUE_VIP,
  DIMENSION_VALUE_LEAD,
  DIMENSION_PRODUCT_NAME,
  DIMENSION_CONVERSATION_ID,
  DIMENSION_PRICE_LABEL,
  DIMENSION_HAS_CERTIFIED_PRE_OWNED,
  DIMENSION_GROWTH_TEAM,
  DIMENSION_SRP_FIRST_ADVERTISING_TRACK,
  DIMENSION_1_UP_EXPERIMENT_VARIATION,
  DIMENSION_A_TEAM_EXPERIMENT_VARIATION,
  DIMENSION_STAR_CARS_EXPERIMENT_VARIATION,
  DIMENSION_SEARCH_CONDITION,
  DIMENSION_BUSINESS_MODEL,
  DIMENSION_AUTO_LOGIN,
];

export const resetUnusedCDs = { [DIMENSION_REPLY_TYPE]: undefined };
