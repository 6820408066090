import React from 'react';
import PropTypes from 'prop-types';
// The code and files are kept in place to simplify reuse for other ribbons in the future
// import HeadsUpConditional from './HeadsUpConditional';
import HeadsUpBuyFromHome from './HeadsUpBuyFromHome';

const HeadsUpWrapper = ({ className /* , onClose */ }) => (
  <aside className={className}>
    {/* <HeadsUpConditional onClose={onClose} /> */}
    <HeadsUpBuyFromHome />
  </aside>
);

HeadsUpWrapper.propTypes = {
  className: PropTypes.string,
  // onClose: PropTypes.func,
};

export default HeadsUpWrapper;
