export const REQUEST_LOGIN_STATUS =
  'global/authentication/REQUEST_LOGIN_STATUS';
export const HANDLE_LOGIN_STATUS_SUCCESS =
  'global/authentication/HANDLE_LOGIN_STATUS_SUCCESS';
export const HANDLE_LOGIN_STATUS_FAILURE =
  'global/authentication/HANDLE_LOGIN_STATUS_FAILURE';
export const HANDLE_AUTO_LOGIN_STATUS_SUCCESS =
  'global/authentication/HANDLE_AUTO_LOGIN_STATUS_SUCCESS';

export const REQUEST_LOGIN = 'global/authentication/REQUEST_LOGIN';
export const HANDLE_LOGIN_SUCCESS =
  'global/authentication/HANDLE_LOGIN_SUCCESS';
export const HANDLE_LOGIN_FAILURE =
  'global/authentication/HANDLE_LOGIN_FAILURE';

export const REQUEST_LOGOUT = 'global/authentication/REQUEST_LOGOUT';
export const HANDLE_LOGOUT_SUCCESS =
  'global/authentication/HANDLE_LOGOUT_SUCCESS';
export const HANDLE_LOGOUT_FAILURE =
  'global/authentication/HANDLE_LOGOUT_FAILURE';

export const REQUEST_REGISTER = 'global/authentication/REQUEST_REGISTER';
export const HANDLE_REGISTER_SUCCESS =
  'global/authentication/HANDLE_REGISTER_SUCCESS';
export const HANDLE_REGISTER_FAILURE =
  'global/authentication/HANDLE_REGISTER_FAILURE';
