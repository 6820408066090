import { createSelector } from 'reselect';
import { sellerTypeTrackingMap } from '../../constants/sellerTypes';

import { ConversationItemType } from './types/ConversationItem';
import { State as GlobalState } from '../../stores/types/State';

const getConversationId = (_, { conversationId }: { conversationId: string }) =>
  conversationId;

const getInboxFeature = ({ features }: Pick<GlobalState, 'features'>) =>
  features?.inbox || {};

// inbox selectors
export const getSelectedConversation = (
  conversationList: ConversationItemType[] = [],
  selectedId?: string
) =>
  conversationList.filter((conversation) => conversation.id === selectedId)[0];

export const getInbox = createSelector(
  getInboxFeature,
  ({ conversationList = [], selectedId, ...inbox }) => ({
    ...inbox,
    conversationList,
    selectedConversation: getSelectedConversation(conversationList, selectedId),
  })
);

// tracking selectors
export const getConversationById = createSelector(
  [getInboxFeature, getConversationId],
  ({ conversationList = [] }, conversationId) =>
    conversationList.find((item) => item.id === conversationId)
);

export const selectSellerTypeForTracking = (conversation) => {
  const sellerType =
    conversation.latestMessage?.conversationMetaData?.displayMetaData
      ?.sellerType;

  return sellerTypeTrackingMap[sellerType];
};
