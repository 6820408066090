import get from 'lodash/get';
import { redirect } from 'redux-first-router';
import {
  VIP_PAGE_ID,
  VIP_PROCESSING_ROUTE,
  VIP_ROUTE,
  VIP_ROUTE_WITH_FINANCING,
} from '../../stores/page';
import { fetchListingAction, setFinancingDefaults } from './actionCreators';
import { createParentTransactionIDHeader, getListing } from './api';
import { selectPosition } from '../../stores/geoLocation';
import { getHashedVipParamsFromUrl } from '../../pageSegments/hashedVip/shouldShowRealVip';
import normalizeUrl from '../../../shared/utils/normalizeUrl';

const vipThunk = (dispatch, getState, bag) => {
  const state = getState();
  const params = getHashedVipParamsFromUrl();
  const listingId = params.id || state?.location?.payload?.listingId;

  const { latitude, longitude } = selectPosition(state) || {};
  const query = {
    ll: latitude && longitude && `${latitude},${longitude}`,
  };

  // This variable is used to create a relation between the VIP and where
  // is coming from (e.g. where the user clicked to go to VIP).
  // This information is used by CDATA to generate a better sorting algorithm.
  // The value has to be taken from the action itself
  // because the redux-first-router hasn't updated the state yet.
  const parentTransactionId = bag.action.payload?.parentTransactionId;

  return dispatch(
    fetchListingAction(listingId, query, {
      locale: state?.i18n?.currentLocale,
      headers: createParentTransactionIDHeader(parentTransactionId),
    })
  );
};

export const routes = {
  [VIP_ROUTE]: {
    path: normalizeUrl(`/${VIP_PAGE_ID}/:listingId/`),
    thunk: vipThunk,
  },
  [VIP_ROUTE_WITH_FINANCING]: {
    path: normalizeUrl(
      `/${VIP_PAGE_ID}/:listingId/#termLoan=:termLoan?&frequencyLoan=:frequencyLoan?`
    ),
    thunk: (dispatch, getState, bag) => {
      const state = getState();
      const params = getHashedVipParamsFromUrl();
      const termLoan =
        params.termLoan ||
        state?.location?.payload?.termLoan ||
        state?.searchQuery?.query?.term;
      const frequencyLoan =
        params.frequencyLoan ||
        state?.location?.payload?.frequencyLoan ||
        state?.searchQuery?.query?.frequency;

      if (termLoan && frequencyLoan) {
        dispatch(setFinancingDefaults({ termLoan, frequencyLoan }));
      }
      vipThunk(dispatch, getState, bag);
    },
  },
  [VIP_PROCESSING_ROUTE]: {
    path: normalizeUrl(`/${VIP_PAGE_ID}/processing/:listingId/`),
    thunk: async (dispatch, getState) => {
      const state = getState();
      const listingId = get(state, 'location.payload.listingId');
      const originalQuery = get(state, 'location.query');
      const locale = get(state, 'i18n.currentLocale');

      try {
        await getListing(listingId, {}, { locale });

        // getListing() did not throw, meaning that the listing exists, meaning
        // that we redirect to the correct VIP page.
        dispatch(
          redirect({
            type: VIP_ROUTE,
            payload: { listingId },
            query: originalQuery,
          })
        );
      } catch (error) {
        // getListing() did throw, meaning it was a 404, meaning that we
        // want to display VIP_PROCESSING_ROUTE, meaning we do nothing.
      }
    },
  },
};
