import { createSelector } from 'reselect';
import { getCurrentLocale } from '../../stores/i18n';
import { removeLocalePrefixFromUrl } from '../../../lib/locale-utils';
import normalizeUrl from '../../../shared/utils/normalizeUrl';

const selectMip = ({ pages: { mip } }) => mip;

const selectPageData = createSelector(selectMip, (mip) => mip?.pageData);

const selectContent = createSelector(selectPageData, (mip) => mip?.content);

export const selectPageState = createSelector(
  selectMip,
  (mip) => mip?.pageState
);

export const selectNavigation = createSelector(
  selectMip,
  (mip) => mip?.navigation
);

export const selectUrl = createSelector(selectPageData, (mip) =>
  normalizeUrl(mip?.url)
);

export const selectTitle = createSelector(selectPageData, (mip) => mip?.title);

export const selectHighlights = createSelector(
  selectContent,
  (content) => content?.highlights ?? []
);

export const selectSliderImages = createSelector(
  selectContent,
  (content) => content?.images ?? []
);

export const selectSafety = createSelector(
  selectContent,
  (content) => content?.safetyV2
);

export const selectDescription = createSelector(
  selectContent,
  (content) => content?.description
);

export const selectSpecs = createSelector(
  selectContent,
  (content) => content?.specs
);

export const selectTrims = createSelector(
  selectContent,
  (content) => content?.trims
);

export const selectTowingCapacities = createSelector(
  selectContent,
  (content) => content?.towingCapacities
);

export const selectVehicleDimensions = createSelector(
  selectContent,
  (content) =>
    content?.dimensions?.map(
      ({ vehicleHeight, vehicleLength, vehicleWidth, ...rest }) => ({
        height: vehicleHeight,
        length: vehicleLength,
        width: vehicleWidth,
        ...rest,
      })
    )
);

export const selectFAQs = createSelector(
  selectContent,
  (content) =>
    content?.faq
      ?.filter((faq) => faq.title && faq.textJson && faq.textJson.length)
      .map((faq) => ({
        ...faq,
        fullText: faq.textJson.map((element) => element.content).join(' '),
      })) || []
);

export const selectGenerations = createSelector(
  selectPageData,
  (mip) => mip?.generations ?? []
);

export const selectSelectedGeneration = createSelector(selectPageData, (mip) =>
  mip?.generations?.find((x) => x.id === mip?.content?.generationId)
);

export const selectSelectedVersion = createSelector(selectPageData, (mip) =>
  mip?.generations
    ?.find((x) => x.id === mip?.content?.generationId)
    ?.versions?.find((x) => x.id === mip?.content?.versionId)
);

export const selectMakeId = createSelector(
  selectPageData,
  (mip) => mip?.makeId
);

export const selectModelId = createSelector(
  selectPageData,
  (mip) => mip?.modelId
);

export const selectMakeModel = createSelector(selectPageData, (mip) => ({
  makeId: mip?.makeId,
  modelId: mip?.modelId,
}));

export const selectListings = createSelector(
  selectMip,
  (mip) => mip?.listings?.items ?? []
);

export const selectTotalListingsCount = createSelector(
  selectMip,
  (mip) => mip?.listings?.numResultsTotal ?? 0
);

export const selectMetaData = createSelector(
  selectPageData,
  (mip) => mip?.meta
);

export const selectReferrer = createSelector(selectMip, (mip) => mip?.referrer);

export const selectSrpUrl = (state) => {
  const locale = getCurrentLocale(state);
  const srpUrl = selectPageData(state)?.srpUrl;
  return typeof srpUrl === 'string'
    ? removeLocalePrefixFromUrl(locale, srpUrl)
    : null;
};

export const selectAllEngines = createSelector(selectTrims, (trims) =>
  trims?.specific?.reduce((engines, trim) => [...engines, ...trim?.engines], [])
);
