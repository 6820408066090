import { NOT_FOUND } from 'redux-first-router';

import { SRP_BASE_PATHS } from '../../../shared/config';
import normalizeUrl from '../../../shared/utils/normalizeUrl';

export const HOME_ROUTE = 'HOME_ROUTE';
export const VIP_ROUTE = 'VIP_ROUTE';
export const VIP_ROUTE_WITH_FINANCING = 'VIP_ROUTE_WITH_FINANCING';
export const VIP_PROCESSING_ROUTE = 'VIP_PROCESSING_ROUTE';
export const LISTING_NOT_FOUND_ROUTE = 'LISTING_NOT_FOUND_ROUTE';
export const DRP_ROUTE = 'DRP_ROUTE';
// TODO:Cleanup after experiment is finished MOVE-26228
export const DRP_SUBMIT_ROUTE = 'DRP_SUBMIT_ROUTE';
export const INBOX_ROUTE = 'INBOX';
export const ERROR_ROUTE = 'ERROR';
export const SAVED_ITEMS_ROUTE = 'SAVED_ITEMS_PAGE_ROUTE';
export const DEALER_ROUTE = 'DEALER';
export const SYI_ROUTE = 'SYI_ROUTE';
export const SAVED_SEARCHES_ROUTE = 'SAVED_SEARCHES';
export const MY_DEALS_ROUTE = 'MY_DEALS';
export const NOTIFICATIONS_ROUTE = 'NOTIFICATIONS_ROUTE';
export const MRP_ROUTE = 'MRP_ROUTE';
export const MIP_ROUTE = 'MIP_ROUTE';
export const SRP_ROUTE = 'SRP_ROUTE';
export const GO_TO_SRP = 'stores/page/GO_TO_SRP';

export const SRP_PAGE_ID = 'srp';
export const VIP_PAGE_ID = 'vip';
export const DRP_PAGE_ID = 'shop-faster';
export const SRP_ROUTE_REGEX = /^SRP_ROUTE/;

export const HOME_PATH = '/';
export const INBOX_PATH = normalizeUrl('/inbox/');
export const SAVED_ITEMS_PATH = normalizeUrl('/saveditems/');
export const SAVED_SEARCHES_PATH = normalizeUrl('/savedsearches/');
export const NOTIFICATIONS_PATH = normalizeUrl('/notifications/');
export const USER_PROFILE_PATH = '/user/profile';
export const MY_DEALS_PATH = normalizeUrl('/mydeals/');

export const pages = {
  [HOME_ROUTE]: 'home',
  [SRP_ROUTE]: SRP_PAGE_ID,
  [MIP_ROUTE]: 'mip',
  [VIP_ROUTE]: 'vip',
  [VIP_ROUTE_WITH_FINANCING]: 'vipWithFinancing',
  [VIP_PROCESSING_ROUTE]: 'vipProcessing',
  [DRP_ROUTE]: 'drp',
  [DRP_SUBMIT_ROUTE]: 'drpSubmit',
  [INBOX_ROUTE]: 'inbox',
  [ERROR_ROUTE]: 'error',
  [LISTING_NOT_FOUND_ROUTE]: 'vipNotFound',
  [NOT_FOUND]: 'notFound',
  [SAVED_ITEMS_ROUTE]: 'savedItems',
  [DEALER_ROUTE]: 'dealer',
  [SYI_ROUTE]: 'syi',
  [SAVED_SEARCHES_ROUTE]: 'savedSearches',
  [MY_DEALS_ROUTE]: 'myDeals',
  [MRP_ROUTE]: 'mrp',
  [NOTIFICATIONS_ROUTE]: 'notifications',
};

const SRP_BASE_PATHS_KEYS = Object.keys(SRP_BASE_PATHS);

/** { 'en-CA': 'SRP_ROUTE_en-CA' } */
export const srpRouteActionsForLocale = Object.fromEntries(
  SRP_BASE_PATHS_KEYS.map((locale) => [locale, `${SRP_ROUTE}_${locale}`])
);

/** { 'en-CA': 'SRP_ROUTE_en-CA_hvip' } */
export const srpHvipRouteActionsForLocale = Object.fromEntries(
  SRP_BASE_PATHS_KEYS.map((locale) => [locale, `${SRP_ROUTE}_${locale}_hvip`])
);
