import {
  HANDLE_ERROR_PROTECTION_PRODUCTS,
  HANDLE_LOADING_PROTECTION_PRODUCTS,
  HANDLE_RESPONSE_PROTECTION_PRODUCTS,
  RESET_PROTECTION_PRODUCTS,
  SET_FORM_STATE,
} from './actionTypes';

import { ProtectionPlansAction } from './actionCreators';
import { ActionMap } from '../../stores/types/ActionMap';
import {
  ProtectionProductFormState,
  ProtectionProductPageState,
} from '../../pages/drp/stateTypes';

export const protectionProductPageDefaultState: ProtectionProductPageState = {
  sessionId: null,
  products: [],
  isLoading: false,
  errorCode: 0,
  formState: {},
};

const states: ActionMap<ProtectionPlansAction, ProtectionProductPageState> = {
  [HANDLE_LOADING_PROTECTION_PRODUCTS]: (state) => ({
    ...state,
    isLoading: true,
    errorCode: 0,
    sessionId: null,
    products: [],
    formState: {},
  }),
  [HANDLE_ERROR_PROTECTION_PRODUCTS]: (state, { payload }) => ({
    ...state,
    isLoading: false,
    errorCode: payload.statusCode || 500,
    sessionId: null,
    products: [],
    formState: {},
  }),
  [HANDLE_RESPONSE_PROTECTION_PRODUCTS]: (state, { payload }) => ({
    ...state,
    isLoading: false,
    errorCode: 0,
    sessionId: payload.sessionId,
    products: payload.products,
    formState: payload.products.reduce(
      (acc, val) => ({
        ...acc,
        [val.id]: {
          checked: false,
          optionId:
            val.options.find(({ isDefault }) => isDefault)?.id ||
            val.options[0]?.id,
        },
      }),
      {} as ProtectionProductFormState
    ),
  }),
  [SET_FORM_STATE]: (state, { payload }) => ({
    ...state,
    formState: { ...state.formState, ...payload },
  }),
  [RESET_PROTECTION_PRODUCTS]: () => protectionProductPageDefaultState,
};

export default (
  state = protectionProductPageDefaultState,
  action: ProtectionPlansAction
) => (states[action.type] ? states[action.type](state, action as any) : state);
