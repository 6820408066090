import { createSelector } from 'reselect';
import { REQUEST_STATE } from './types';

const selectHome = ({ pages: { home } }) => home;

export const selectCategories = createSelector(
  selectHome,
  (home) => home.categories || []
);

export const selectCategoriesRequestState = createSelector(
  selectHome,
  (home) => home?.requestState
);

export const selectCategoryLoadingState = createSelector(
  selectCategoriesRequestState,
  (state) => state === REQUEST_STATE.PENDING
);

export const selectCampaign = createSelector(
  selectHome,
  (home) => home.campaign
);

export const selectCampaignTheme = createSelector(
  selectCampaign,
  (campaign) => campaign?.theme
);
