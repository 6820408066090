/**
 * check if the value is empty (undefined, null, empty string, empty array, empty object)
 */
export default (value?: null | Record<string, unknown> | any[] | string) => {
  if (value === undefined || value === null) {
    return true;
  }

  if (typeof value === 'string' && value.length === 0) {
    return true;
  }

  if (Array.isArray(value) && value.length === 0) {
    return true;
  }

  if (typeof value === 'object') {
    return Object.keys(value).length === 0;
  }

  return false;
};
