export const getLocationText = (location) => {
  if (!location) {
    return '';
  }

  return [
    location.postalCode,
    location.city || location.province?.name || location.name,
  ]
    .filter(Boolean)
    .join(', ');
};
