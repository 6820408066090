let isHydratingStatus = false;

export const isHydrating = () => isHydratingStatus;

export const startHydration = () => {
  isHydratingStatus = true;
};

export const endHydration = () => {
  isHydratingStatus = false;
};
