import { getEnv } from '@move-web-essentials-utils/env';
import apiClient from '../../../api/lib/apiClient';
import { MOVE_CMS_API_BASE_PATH } from '../../../shared/config';

const getBaseUrl = () => {
  if (IS_SERVER) {
    return `${getEnv(
      'move_consumer_api_gateway_url'
    )}${MOVE_CMS_API_BASE_PATH}`;
  }
  return `${MOVE_CMS_API_BASE_PATH}`;
};

export default (options = {}) =>
  apiClient({
    metricsId: 'cmsDiscover',
    ...options,
  })({
    method: 'GET',
    url: `${getBaseUrl()}/discover`,
  });
