import {
  AnyAction,
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
  Middleware,
  Store,
} from 'redux';
import { connectRoutes, HistoryEntries } from 'redux-first-router';
import restoreScroll from 'redux-first-router-restore-scroll';
import queryString from 'query-string';
import reduxThunk, { ThunkDispatch } from 'redux-thunk';
import routesMap from '../routesMap';
import { ERROR_ROUTE } from './page';
import pageReducer from './page/reducer';
import referenceDataReducer from './referenceData/reducer';
import searchQueryReducer from './searchQuery/reducer';
import geoLocationReducer from './geoLocation/reducer';
import geoLocationActors from './geoLocation/actors';
import i18nReducer from './i18n';
import snackbarsReducer from './snackbars/reducer';
import isLegacyBrowserReducer from './isLegacyBrowser/reducer';

import featuresReducer from '../features/reducer';
import featuresActors from '../features/actors';
import pagesReducer from '../pages/reducer';
import pagesActors from '../pages/actors';
import pageSegmentsReducer from '../pageSegments/reducer';
import authenticationActors from './authentication/actors';
import authenticationReducer from './authentication/reducer';
import { combineActors, createActorRunnerMiddleware } from './actorRunner';
import { hashedVipMiddlewares } from './hashedVipMiddlewares';
import { expose } from '../utils/expose';
import { State } from './types/State';

const composeWithDevTools = (...enhancers: Parameters<typeof compose>) => {
  const composeEnhancers =
    (DEBUG &&
      typeof window === 'object' &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;

  return composeEnhancers(...enhancers);
};

export default function configureStoreFunc({
  initialState = {},
  basename,
  initialEntries,
  onError,
}: {
  initialState: Record<string, unknown>;
  initialEntries: HistoryEntries;
  basename?: string;
  onError?: (error?: string) => void;
}): {
  store: Store & { dispatch: ThunkDispatch<any, never, AnyAction> };
  runRouteThunks: () => void;
} {
  const {
    thunk,
    enhancer: reduxRouterEnhancer,
    middleware: reduxRouterMiddleware,
    reducer: reduxRouterReducer,
  } = connectRoutes(routesMap, {
    ...(basename ? { basename } : {}),
    ...(initialEntries ? { initialEntries } : {}),
    // https://github.com/faceyspacey/redux-first-router/blob/master/docs/connectRoutes.md#options
    restoreScroll: restoreScroll({ manual: true }),
    querySerializer: queryString,
  });

  const errorMiddleware: Middleware<never, State> =
    () => (next) => (action: AnyAction) => {
      if (action.type === ERROR_ROUTE) {
        onError?.({ ...action.payload });
      }
      return next(action);
    };

  const middlewares = [
    ...hashedVipMiddlewares,
    reduxRouterMiddleware,
    reduxThunk,
    errorMiddleware,
    createActorRunnerMiddleware(
      combineActors(
        authenticationActors,
        featuresActors,
        pagesActors,
        geoLocationActors
      )
    ),
  ];

  if (DEBUG && typeof window === 'object') {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { createLogger } = require('redux-logger');
    middlewares.push(createLogger({ collapsed: true, duration: true }));
  }

  const rootReducer = combineReducers({
    location: reduxRouterReducer,
    authentication: authenticationReducer,
    page: pageReducer,
    features: featuresReducer,
    pages: pagesReducer,
    pageSegments: pageSegmentsReducer,
    referenceData: referenceDataReducer,
    searchQuery: searchQueryReducer,
    geoLocation: geoLocationReducer,
    i18n: i18nReducer,
    snackbars: snackbarsReducer,
    isLegacyBrowser: isLegacyBrowserReducer,
  });

  const store = createStore(
    rootReducer,
    initialState,
    composeWithDevTools(reduxRouterEnhancer, applyMiddleware(...middlewares))
  );

  expose({ store });

  const runRouteThunks = () => thunk(store);

  return { store, runRouteThunks };
}
