import { EVENT_KEYS } from '../../../lib/optimizely';
import { SELLER_TYPES } from '../../constants/sellerTypes';
import { Role } from '../../shared/types';
import {
  DIMENSION_LISTING_ID,
  DIMENSION_CONVERSATION_ID,
  DIMENSION_REPLY_TYPE,
} from '../../utils/tracking/dimensions';
import { setAttachmentLabel } from './utils/setAttachmentLabel';

const DIRECTION_BUYER_TO_SELLER = 'CHAT_B2S';
const DIRECTION_SELLER_TO_BUYER = 'CHAT_S2B';

type MessageSuccessEventData = {
  currentUserRole: string;
  conversationId: string;
  listingId: string;
  hasImage: boolean;
  sendMessageEventLabel: string;
  isDealerListing: boolean;
};

type BaseEventData = {
  pageType: string;
  listingId: string;
  eventLabel: string;
};

const directionsByRole = {
  [Role.Buyer]: DIRECTION_BUYER_TO_SELLER,
  [Role.Seller]: DIRECTION_SELLER_TO_BUYER,
};

export const MESSAGE_SEND_BEGIN = {
  eventAction: 'MessageSendBegin',
  getEventData: ({
    pageType,
    listingId,
    eventLabel,
    ...rest
  }: BaseEventData) => ({
    ...rest,
    eventLabel,
    eventCategory: pageType,
    [DIMENSION_REPLY_TYPE]: undefined,
    [DIMENSION_CONVERSATION_ID]: undefined,
    [DIMENSION_LISTING_ID]: listingId,
  }),
  optimizelyEvents: [EVENT_KEYS.MESSAGE_SEND_BEGIN],
};

export const MESSAGE_SEND_CANCEL = {
  eventAction: 'MessageSendCancel',
  getEventData: ({ pageType }) => ({
    eventCategory: pageType,
    [DIMENSION_REPLY_TYPE]: undefined,
    [DIMENSION_CONVERSATION_ID]: undefined,
    [DIMENSION_LISTING_ID]: undefined,
  }),
};

const getMessageSuccessData = ({
  currentUserRole,
  conversationId,
  listingId,
  hasImage,
  sendMessageEventLabel,
  isDealerListing,
}: MessageSuccessEventData) => {
  const messageLabel = setAttachmentLabel(hasImage, sendMessageEventLabel);
  const sellerType = isDealerListing ? SELLER_TYPES.DEALER : SELLER_TYPES.FSBO;
  return {
    eventLabel: `${messageLabel};sellerType=${sellerType}`,
    [DIMENSION_REPLY_TYPE]: directionsByRole[currentUserRole],
    [DIMENSION_CONVERSATION_ID]: conversationId,
    [DIMENSION_LISTING_ID]: listingId,
  };
};

export const MESSAGE_SEND_SUCCESS = {
  eventAction: 'MessageSendSuccess',
  getEventData: getMessageSuccessData,
};

export const R2S_CHAT_SUCCESS = {
  eventAction: 'R2SChatSuccess',
  getEventData: getMessageSuccessData,
  optimizelyEvents: [EVENT_KEYS.R2S_CHAT_SUCCESS],
};

export const RELATED_AD_CLICK = {
  eventAction: 'RelatedAdClick',
  getEventData: ({ pageType, listingId, eventLabel }: BaseEventData) => ({
    eventLabel,
    eventCategory: pageType,
    [DIMENSION_LISTING_ID]: listingId,
  }),
  optimizelyEvents: [EVENT_KEYS.RELATED_AD_CLICK],
};
