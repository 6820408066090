import { ConvertedAdConfig } from '../../types/ConvertedAdConfig';
import { AdConfigSlot } from '../../types/GotAdConfig';
import getAmazonTamConfig from './getAmazonTamConfig';

/**
 * @name convertGotV2AdConfig
 *
 * Transforms advertising configuration in the format we receive from the GOT advertising config
 * service into the format by the react-advertising library.
 *
 * @see Documentation react-advertising config: https://github.com/eBayClassifiedsGroup/react-advertising/wiki/Configuration
 */
export default (gotAdConfig: { slots: AdConfigSlot[] }): ConvertedAdConfig => {
  const amazonConfig = getAmazonTamConfig();

  // `prebid`, `aps`, `metaData`, `targeting` are important here
  // Without these three properties in the config, `react-advertising` will add the missing properties.
  // It causes the equality check always fail and trigger undesired advertising reloading
  return {
    ...amazonConfig,
    prebid: {},
    metaData: {},
    targeting: {},
    slots: gotAdConfig.slots,
  };
};
