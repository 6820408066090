import {
  REQUEST_RECENT_SEARCHES,
  RECEIVE_RECENT_SEARCHES,
  HANDLE_RECENT_SEARCHES_ERROR,
} from './actionTypes';

export const defaultState = {
  isLoading: false,
  error: null,
  searches: [],
};

const states = {
  [REQUEST_RECENT_SEARCHES]: (state) => ({
    ...state,
    isLoading: true,
    error: null,
  }),
  [RECEIVE_RECENT_SEARCHES]: (state, { payload }) => ({
    ...state,
    isLoading: false,
    searches: payload,
  }),
  [HANDLE_RECENT_SEARCHES_ERROR]: (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  }),
};

const reducer = (state = defaultState, action = {}) =>
  states[action.type] ? states[action.type](state, action) : state;

export default reducer;
