export const REQUEST_SAVED_ITEMS = 'feature/savedItems/REQUEST_SAVED_ITEMS';
export const HANDLE_RESPONSE_SAVED_ITEMS =
  'feature/savedItems/HANDLE_RESPONSE_SAVED_ITEMS';
export const HANDLE_RESPONSE_ERROR_SAVED_ITEMS =
  'feature/savedItems/HANDLE_RESPONSE_ERROR_SAVED_ITEMS';

export const REQUEST_ADD_ITEM = 'feature/savedItems/REQUEST_ADD_SAVED_ITEMS';
export const HANDLE_RESPONSE_ADD_ITEM =
  'feature/savedItems/HANDLE_RESPONSE_ADD_ITEM';
export const HANDLE_RESPONSE_ERROR_ADD_ITEM =
  'feature/savedItems/HANDLE_RESPONSE_ERROR_ADD_ITEM';

export const REQUEST_REMOVE_ITEM = 'feature/savedItems/REQUEST_REMOVE_ITEM';
export const HANDLE_RESPONSE_REMOVE_ITEM =
  'feature/savedItems/HANDLE_RESPONSE_REMOVE_ITEM';
export const HANDLE_RESPONSE_ERROR_REMOVE_ITEM =
  'feature/savedItems/HANDLE_RESPONSE_ERROR_REMOVE_ITEM';
