import {
  DIMENSION_LISTING_ID,
  DIMENSION_ONSITE_SEARCH_TOTAL_RESULTS,
  DIMENSION_SORT_ORDER,
  DIMENSION_SRP_FIRST_ADVERTISING_TRACK,
  DIMENSION_ONSITE_SEARCH_RESULTS_VIEW_TYPE,
} from '../../../utils/tracking/dimensions';
import { EVENT_KEYS } from '../../../../lib/optimizely';
import { Sorting } from '../../../shared/domains/sorting/types/Sorting';
import { SortBy } from '../../../shared/domains/sorting/types/SortBy';
import { SortOrder } from '../../../shared/domains/sorting/types/SortOrder';
import { PromotionType } from '../../../shared/types/PromotionType';
import { getViewType } from '../utils/getView';

export const DEFAULT_ADVERT_TYPE = 'organic';
const PROMOTION_PARTNER = {
  [PromotionType.Top]: 'topad',
  [PromotionType.Inline]: 'inlinead',
  [DEFAULT_ADVERT_TYPE]: 'organic',
};

const SORT_ORDER_DIMENSIONS = {
  [SortBy.Relevance]: 'Best_Match',
  [`${SortBy.Price}_${SortOrder.Ascending}`]: 'price_ASC',
  [`${SortBy.Price}_${SortOrder.Descending}`]: 'price_DESC',
  [SortBy.DateCreated]: 'date_DESC',
  [SortBy.Mileage]: 'mileage_ASC',
  [`${SortBy.Year}_${SortOrder.Ascending}`]: 'year_ASC',
  [`${SortBy.Year}_${SortOrder.Descending}`]: 'year_DESC',
};

export const getSortingForTracking = (sorting: Sorting) =>
  SORT_ORDER_DIMENSIONS[
    [sorting.sortBy, sorting.sortOrder].filter(Boolean).join('_')
  ] ||
  SORT_ORDER_DIMENSIONS[sorting.sortBy] ||
  SORT_ORDER_DIMENSIONS[SortBy.Relevance];

export const LOAD_MORE_CLICK = {
  eventAction: 'LoadMoreClick',
  getEventData: ({ page }: { page: number }) => ({
    eventLabel: `position=${page}`,
    [DIMENSION_ONSITE_SEARCH_RESULTS_VIEW_TYPE]: getViewType(),
  }),
  optimizelyEvents: [EVENT_KEYS.LOAD_MORE_CLICK],
};

export const RESULTS_AD_CLICK = {
  eventAction: 'ResultsAdClick',
  optimizelyEvents: [EVENT_KEYS.RESULTS_AD_CLICK],
  getEventData: ({
    adId,
    position,
    promotion,
    numResultsTotal,
    firstAdvertisingTrack,
    place,
    hasDigitalRetailing,
  }: {
    adId: string;
    position: number;
    promotion?: PromotionType;
    numResultsTotal?: number;
    firstAdvertisingTrack?: number;
    place?: 'image' | 'readMore' | 'CTA';
    hasDigitalRetailing?: boolean;
  }) => ({
    eventLabel: `partner=${
      PROMOTION_PARTNER[promotion || DEFAULT_ADVERT_TYPE]
    };position=${position};dr=${hasDigitalRetailing ? 1 : 0}${
      place ? `;place=${place}` : ''
    }`,
    [DIMENSION_SRP_FIRST_ADVERTISING_TRACK]: Number.isInteger(
      firstAdvertisingTrack
    )
      ? String(firstAdvertisingTrack)
      : '0',
    [DIMENSION_LISTING_ID]: adId,
    [DIMENSION_ONSITE_SEARCH_TOTAL_RESULTS]: Number.isInteger(numResultsTotal)
      ? String(numResultsTotal)
      : undefined,
    [DIMENSION_ONSITE_SEARCH_RESULTS_VIEW_TYPE]: getViewType(),
    ecommerce: {
      promoClick: {
        promotions: [
          {
            id: adId,
            name: PROMOTION_PARTNER[promotion || DEFAULT_ADVERT_TYPE],
            position: `${position}`,
          },
        ],
      },
    },
  }),
};

export const RESULTS_AD_CLICK_DR = {
  ...RESULTS_AD_CLICK,
  optimizelyEvents: [
    ...RESULTS_AD_CLICK.optimizelyEvents,
    EVENT_KEYS.RESULTS_AD_CLICK_DR,
  ],
};

export const SORT_ATTEMPT = {
  eventAction: 'SortAttempt',
  optimizelyEvents: [EVENT_KEYS.SORT_ATTEMPT],
};

export const SORT_SELECTED = {
  eventAction: 'SortSelected',
  getEventData: ({ sorting }: { sorting: Sorting }) => ({
    [DIMENSION_SORT_ORDER]: getSortingForTracking(sorting),
    [DIMENSION_ONSITE_SEARCH_RESULTS_VIEW_TYPE]: getViewType(),
  }),
  optimizelyEvents: [EVENT_KEYS.SORT_SELECTED],
};
