import LOGO_LARGE from '@move-core/asset-catalog/images/ca/web/img-logo.svg';
import LOGO_LARGE_INVERSE from '@move-core/asset-catalog/images/ca/web/img-logo-inverse.svg';
import LOGO_SMALL from '@move-core/asset-catalog/images/ca/web/img-logo-small.svg';
import { THEME } from '../constants';

export const SHOW_POST_AD = true;
export const MARKETPLACE_THEME = {
  [THEME.DEFAULT]: THEME.DEFAULT,
  [THEME.BRAND]: THEME.BRAND,
};

export const LOGO = {
  small: {
    standard: LOGO_SMALL,
    width: 40,
    height: 32,
  },
  large: {
    standard: LOGO_LARGE,
    inverse: LOGO_LARGE_INVERSE,
    width: 128,
    height: 26,
  },
};
