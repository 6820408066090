import qs from 'query-string';
import { replace } from 'redux-first-router';
import { removeLocalePrefixFromUrl } from '../../../../../lib/locale-utils';
import { pickValidPropertiesForURL } from '../specifications/queryProperty';
import normalizeUrl from '../../../../../shared/utils/normalizeUrl';

const URL_DELIMITER = '#';

type QueryObject = Parameters<typeof pickValidPropertiesForURL>[0];

const joinURLPathWithQuery = (queryObject: QueryObject) =>
  [
    window.location.pathname,
    qs.stringify(pickValidPropertiesForURL(queryObject)),
  ]
    .filter(Boolean)
    .join(URL_DELIMITER);

export const pushHistoryWithQueryURL = (queryObject: QueryObject) => {
  if (IS_SERVER) {
    return;
  }

  window.history.pushState(null, '', joinURLPathWithQuery(queryObject));
};

export const replaceHistoryWithQueryURL = (
  queryObject: QueryObject | null,
  locale: string
) => {
  if (IS_SERVER || !queryObject) {
    return;
  }

  const url = normalizeUrl(
    removeLocalePrefixFromUrl(locale, joinURLPathWithQuery(queryObject))
  );

  replace(url || '');
};

export const decodeQueryURL = () => {
  if (IS_SERVER || !window.location.hash) {
    return;
  }
  try {
    const decodedUrl = decodeURI(window.location.href);
    window.history.replaceState(window.history.state, '', decodedUrl);
  } catch {
    // decoding uri failed
    window.history.replaceState(
      window.history.state,
      '',
      `${window.location.origin}${window.location.pathname}`
    );
  }
};

export const createScriptToAppendCSSClassOnQueryURL = () =>
  `<script>
    (function () {
      var hashQuery = window.location.hash.replace('${URL_DELIMITER}', '');
      if (!hashQuery) {
        return;
      }
      var params = hashQuery.split('&');
      var isHashedVip = Boolean(
        params.filter(function (param) {
          return param.indexOf('vip=') > -1;
        }).length > 0
      );
      var isHashVipOnly = isHashedVip && params.length === 1;
      let isHashVipRedirect = false;
      try {
        isHashVipRedirect = Boolean(window.sessionStorage.getItem('mv-VIP-id'));
      } catch (error) {}
      if (params && !isHashVipOnly || isHashVipRedirect) {
        document.body.classList.add('hasQueryOnURL');
      }
      if (isHashedVip && !isHashVipRedirect) {
        document.body.classList.add('hashedVipLoading');
      }
    })();
  </script>`.replace(/\s(?!var\s)\s/g, '');
