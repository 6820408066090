import { State } from './types/State';
import { pathToAction } from 'redux-first-router';

import isFeatureEnabled from '../../lib/env/isFeatureEnabled';
import routesMap from '../routesMap';

import {
  goToHashedVip,
  srpHvipRouteActionsForLocale,
  srpRouteActionsForLocale,
  VIP_ROUTE,
  VIP_ROUTE_WITH_FINANCING,
} from './page';
import { getCurrentLocale } from './i18n';
import { AnyAction, Middleware } from 'redux';

function getSrpSeoSegments(action: AnyAction, locale: string) {
  const seoPath = action.payload?.item?.shareUrl?.path[locale];
  if (!seoPath) {
    return undefined;
  }
  const seoAction = pathToAction(seoPath, routesMap);
  return seoAction?.payload?.srpSeoSegments;
}

const isRedirectingAfterHashedVip = (action: AnyAction, locale: string) => {
  const prev = action.meta?.location?.prev;
  const timestamp = prev?.payload?.timestamp
    ? new Date(prev?.payload?.timestamp)?.getTime()
    : 0;
  const timeIntervalMs = new Date().getTime() - timestamp;

  return (
    action.type === srpRouteActionsForLocale[locale] &&
    prev?.type === srpHvipRouteActionsForLocale[locale] &&
    timeIntervalMs < 100
  );
};

const hashedVipMiddleware: Middleware<never, State> =
  ({ getState }) =>
  (next) =>
  (action: AnyAction) => {
    const state = getState();
    const locale = getCurrentLocale(state);

    const navigatingToVip = [VIP_ROUTE, VIP_ROUTE_WITH_FINANCING].includes(
      action.type
    );
    if (navigatingToVip) {
      // Navigate to hashed VIP instead.
      // If we ever decide to go with hashed VIP everywhere we should remove that and
      // replace the action creators in the code.
      const { listingId, item, parentTransactionId } = action.payload;
      const srpHvipAction = goToHashedVip({
        listingId,
        srpSeoSegments: getSrpSeoSegments(action, locale),
        locale,
        historyState: {
          realVip: true,
        },
        item,
        parentTransactionId,
      });
      return next(srpHvipAction);
    }

    if (isRedirectingAfterHashedVip(action, locale)) {
      // propagate the history state to the new action as well
      const { payload } = action.meta.location.prev;
      action.payload.historyState = payload.historyState;
      action.payload.parentTransactionId = payload.parentTransactionId;
    }

    return next(action);
  };

const includeHashedVipMiddleware = !IS_SERVER && isFeatureEnabled('hashed_vip');

export const hashedVipMiddlewares = includeHashedVipMiddleware
  ? [hashedVipMiddleware]
  : [];
