import { createSelector } from 'reselect';

import { getActiveExperiments } from '../../../../lib/optimizely';
import { getClientId } from '../../../../lib/userSessionTracking/userSessionService';

import { omitEmpty } from '../../../shared/utils/omitEmpty';
import { createFilterSelector } from '../../../stores/referenceData';
import { selectIsLoggedIn } from '../../../stores/authentication';
import { getActiveExperimentArray } from '../../../utils/tracking/getExperimentsDimension';

import { convertGotAdConfigV2 } from '../api/converter';

import { SIZE_MAPPINGS } from './sizeMappings';
import setTargetingValues from './setTargetingValues';
import {
  normalize,
  normalizeSpecialCharacters,
  normalizeMakes,
  normalizeModels,
  normalizePriceFilter,
  normalizePrice,
  normalizeFuelType,
  normalizeDrivetrain,
  normalizeBody,
} from './normalizers';

const TARGET_FIELDS = [
  ['MAKE_MODEL', 'makeModels'],
  ['PRICE', 'price'],
  ['CONSTRUCTION_YEAR', 'year'],
  ['DRIVETRAIN', 'drivetrain'],
  ['SELLER_TYPE', 'sellerType'],
  ['FUEL_TYPE', 'fuelType'],
  ['CONDITION', 'condition'],
  ['BODY_TYPE', 'body'],
];

const selectFeatureAdvertising = (state) => state.features?.advertising;

const formatFieldValue = (value, fieldName) => {
  if (Array.isArray(value)) {
    const normalized = value.map(normalize);
    if (fieldName === 'makeModels') {
      return normalized;
    }
    return normalized.join(',');
  }
  return normalize(value);
};

export const selectConvertedAdConfig = (page) => (state) => {
  const featureAdvertising = selectFeatureAdvertising(state);
  const rawAdConfig = featureAdvertising?.configs?.[page];

  if (!rawAdConfig) {
    return undefined;
  }

  return convertGotAdConfigV2(rawAdConfig);
};

const selectIsLoggedInValues = createSelector(
  selectIsLoggedIn,
  (isLoggedIn) => ({
    '%LOGGED_IN_USER%': isLoggedIn ? 'yes' : 'no',
  })
);

export const selectSRPValues = createSelector(
  (state) => state.referenceData.makeModelTree,
  createFilterSelector,
  (_, props) => props,
  (makeModelTree, findFilter, { query, location }) => {
    const {
      makeModels,
      price,
      year,
      drivetrain,
      sellerType,
      fuelType,
      condition,
      body,
    } = TARGET_FIELDS.reduce((target, [key, fieldName]) => {
      const filter = findFilter(key);
      const value = query[`${filter?.param}`];
      if (value) {
        return {
          ...target,
          [fieldName]: formatFieldValue(value, fieldName),
        };
      }
      return target;
    }, {});

    return omitEmpty({
      '%LOCATION_CITY%': normalize(location?.city),
      '%LOCATION_PROVINCE%': normalize(location?.province?.code),
      '%MAKE%': normalizeMakes(makeModels, makeModelTree),
      '%MODEL%': normalizeModels(makeModels, makeModelTree),
      '%PRICE%': normalizePriceFilter(price),
      '%YEAR%': year?.replace(':', ','),
      '%DRIVETRAIN%': drivetrain,
      '%FOR_SALE_BY%': sellerType === 'fsbo' ? 'owner' : sellerType,
      '%FUEL_TYPE%': fuelType,
      '%CONDITION%': condition,
      '%BODY_TYPE%': body,
    });
  }
);

const findAttributeGroupValue = (groups, keys) => {
  const keyMap = keys.reduce(
    (previousState, key) => ({
      ...previousState,
      [key]: true,
    }),
    {}
  );

  let result;
  groups.forEach(({ attributes }) => {
    attributes.forEach(({ values: [key, value] }) => {
      if (keyMap[key] && result === undefined) {
        result = value;
      }
    });
  });

  return result;
};

// FIXME: improve testing MOVE-22201
export const selectVIPValues = createSelector(
  (_, props) => props,
  ({
    make,
    model,
    prices,
    year,
    attributeGroups,
    isDealer,
    isConditionNew,
    location,
    dealerName,
    adId,
  }) => {
    if (!attributeGroups) {
      return undefined;
    }

    const drivetrain = findAttributeGroupValue(attributeGroups, [
      'Drivetrain',
      'Motricité',
    ]);
    const fuelType = findAttributeGroupValue(attributeGroups, [
      'Fuel type',
      'Type de carburant',
    ]);
    const body = findAttributeGroupValue(attributeGroups, [
      'Body type',
      'Type de carrosserie',
    ]);

    return omitEmpty(
      {
        '%LOCATION_CITY%': normalizeSpecialCharacters(location?.city),
        '%LOCATION_PROVINCE%': normalize(location?.province),
        '%MAKE%': normalize(make),
        '%MODEL%': normalize(model),
        '%PRICE%': prices?.consumerPrice?.amount,
        '%RANGE%': normalizePrice(prices?.consumerPrice?.amount),
        '%YEAR%': year,
        '%DRIVETRAIN%': normalizeDrivetrain(drivetrain),
        '%FOR_SALE_BY%': isDealer ? 'dealer' : 'owner',
        '%FUEL_TYPE%': normalizeFuelType(fuelType),
        '%CONDITION%': isConditionNew ? 'new' : 'used',
        '%BODY_TYPE%': normalizeBody(body),
        '%DEALER_NAME%': normalizeSpecialCharacters(dealerName),
        '%AD_ID%': adId,
      },
      (value) => !value
    );
  }
);

// this is a common prebid config used for all pages
const userSync = {
  filterSettings: {
    iframe: {
      bidders: '*', // '*' represents all bidders
      filter: 'include',
    },
  },
};

export const createSelectAdConfig = (pageType, pageSelector, groupName) =>
  createSelector(
    selectConvertedAdConfig(pageType),
    selectIsLoggedInValues,
    pageSelector,
    (config, isLoggedInValues, pageValues) => {
      if (!config) {
        return undefined;
      }
      const activeExperiments = getActiveExperiments();

      return {
        ...setTargetingValues(
          {
            isLoggedInValues,
            ...pageValues,
            '%GROUP_NAME%': [
              config.groupName,
              groupName,
              ...getActiveExperimentArray(activeExperiments),
            ].filter(Boolean),
            '%DEVICE_ID%': getClientId(),
          },
          config
        ),
        sizeMappings: SIZE_MAPPINGS[pageType].gpt,
        prebid: {
          userSync,
          sizeConfig: SIZE_MAPPINGS[pageType].prebid,
        },
      };
    }
  );
