import { IntlFormatters } from 'react-intl';
import { Frequency } from '../../shared/types/Frequency';

export const getFrequencyLabel = (
  formatMessage: IntlFormatters['formatMessage']
): Record<Frequency, string> => ({
  [Frequency.Weekly]: formatMessage({ id: 'vip_financing_frequency_weekly' }),
  [Frequency.BiWeekly]: formatMessage({
    id: 'vip_financing_frequency_bi_weekly',
  }),
  [Frequency.SemiMonthly]: formatMessage({
    id: 'vip_financing_frequency_semi_monthly',
  }),
  [Frequency.Monthly]: formatMessage({
    id: 'vip_financing_frequency_monthly',
  }),
});

export enum FieldName {
  TradeIn = 'value',
  DownPayment = 'downpayment',
  Frequency = 'paymentfrequency', // eslint-disable-line @typescript-eslint/no-shadow
  Term = 'term',
}

export enum PaymentType {
  Lease = 'lease',
  Loan = 'loan',
}

export const FIELD_MAPPING = {
  term: {
    [PaymentType.Loan]: 'termLoan',
    [PaymentType.Lease]: 'termLease',
  },
  frequency: {
    [PaymentType.Loan]: 'frequencyLoan',
    [PaymentType.Lease]: 'frequencyLease',
  },
} as const;
