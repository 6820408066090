import { EVENT_KEYS as OPTIMIZELY_EVENTS } from '../../../lib/optimizely';

export const SAVED_SEARCH_CLICK = {
  eventAction: 'SavedSearchClick',
  optimizelyEvents: [OPTIMIZELY_EVENTS.SAVE_SEARCH],
};
export const SAVED_SEARCH_LOGGED_IN = {
  eventAction: 'SavedSearchLoggedIn',
};
export const SAVED_SEARCH_NOT_LOGGED_IN = {
  eventAction: 'SavedSearchNotLoggedIn',
};
export const SAVED_SEARCH_SUCCESS = {
  eventAction: 'SavedSearchSuccess',
  optimizelyEvents: [OPTIMIZELY_EVENTS.SAVED_SEARCH_SUCCESS],
};
export const SAVED_SEARCH_DELETE_BEGIN = {
  eventAction: 'SavedSearchDeleteBegin',
};
export const SAVED_SEARCH_DELETE_SUCCESS = {
  eventAction: 'SavedSearchDeleteSuccess',
};
