import { Filter as FullFilter } from '../../../../stores/referenceData';

type Filter = Pick<Partial<FullFilter>, 'type' | 'name' | 'tabs'>;

export const isTabbedType = (filter?: Filter) => !!filter?.tabs;
export const isRangeType = (filter?: Filter) => filter?.type === 'RANGE';
export const isMultiType = (filter?: Filter) => filter?.type === 'MULTI';
export const isSingleType = (filter?: Filter) => filter?.type === 'SINGLE';
export const isMultiSelectionWithIconType = (filter?: Filter) =>
  filter?.type === 'MULTI_SELECT_WITH_ICON';
export const isBooleanType = (filter?: Filter) => filter?.type === 'BOOLEAN';
export const isExtraType = (filter?: Filter) => filter?.name === 'EXTRAS';
export const isMakeModel = (filter?: Filter) => filter?.name === 'MAKE_MODEL';
export const isLocation = (filter?: Filter) => filter?.name === 'LOCATION';
export const isMediaType = (filter?: Filter) => filter?.name === 'MEDIA';
export const isPrice = (filter?: Filter) => filter?.name === 'PRICE';
export const isPayment = (filter?: Filter) => filter?.name === 'PAYMENT';
export const isPricePayment = (filter?: Filter) =>
  filter?.name === 'PRICE_PAYMENT';
export const isTerm = (filter?: Filter) => filter?.name === 'TERM';
export const isFrequency = (filter?: Filter) => filter?.name === 'FREQUENCY';
export const isMileage = (filter?: Filter) => filter?.name === 'MILEAGE';
export const isDigitalRetail = (filter?: Filter) =>
  filter?.name === 'DIGITAL_RETAIL';
export const isVehicleReport = (filter?: Filter) =>
  filter?.name === 'VEHICLE_REPORT';
export const isRangeDropdown = (filter?: Filter) =>
  filter?.type === 'RANGE_DROPDOWN';
export const isSidebarFilter = (filter?: Filter) => !isLocation(filter);
