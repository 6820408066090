import financing, { financingDefaultState } from './financingSpoke/reducer';
import protectionPlans, {
  protectionProductPageDefaultState,
} from '../../features/protectionPlans/reducer';
import {
  DEAL_ABORTED,
  DEAL_ERROR,
  DEAL_REQUEST,
  DEAL_SAVED,
  DEAL_SUBMITTED,
  HANDLE_RESPONSE_LISTING,
  RESET_PAGE,
  SET_IS_FINANCING_SPOKE_FILLED_OUT,
  SET_IS_PAYMENT_SPOKE_FILLED_OUT,
  SET_IS_TRADE_IN_SPOKE_FILLED_OUT,
  SET_PAYMENT_TYPE,
} from './actionTypes';
import {
  defaultState as financingCalculatorDefaultState,
  HANDLE_RESPONSE_OPTIONS,
  HANDLE_RESPONSE_PAYMENT,
  HANDLE_RESPONSE_PAYMENT_ERROR,
  LOADING_PAYMENT_STARTED,
  PaymentType,
  SET_VALUE,
} from '../../features/financingCalculator';
import { DRPState } from './stateTypes';
import { Action, MappedAction } from './actionCreators';
import { ActionMap } from '../../stores/types/ActionMap';

export const defaultState: DRPState = {
  listing: null,
  payment: financingCalculatorDefaultState,
  isPaymentSpokeFilledOut: false,
  isFinancingSpokeFilledOut: false,
  referenceId: null,
  paymentType: PaymentType.Loan,
  errorMessages: [],
  isDealLoading: false,
  financing: financingDefaultState,
  currentStep: 1,
  protectionPlans: protectionProductPageDefaultState,
  totalProtectionProductsPrice: 0,
};

const states: ActionMap<MappedAction, DRPState> = {
  [HANDLE_RESPONSE_LISTING]: (state, { payload }) => ({
    ...state,
    listing: payload,
  }),
  [SET_IS_PAYMENT_SPOKE_FILLED_OUT]: (state, { payload }) => ({
    ...state,
    isPaymentSpokeFilledOut: payload,
    currentStep: Math.max(state.currentStep, 2),
  }),
  [SET_IS_TRADE_IN_SPOKE_FILLED_OUT]: (state) => ({
    ...state,
    currentStep: Math.max(state.currentStep, 3),
  }),
  [SET_IS_FINANCING_SPOKE_FILLED_OUT]: (state, { payload }) => ({
    ...state,
    isFinancingSpokeFilledOut: payload,
    currentStep: Math.max(state.currentStep, 4),
  }),
  [SET_PAYMENT_TYPE]: (state, { payload }) => ({
    ...state,
    paymentType: payload,
  }),
  [LOADING_PAYMENT_STARTED]: (state, { payload }) => ({
    ...state,
    payment: {
      ...state.payment,
      loan:
        payload.type === PaymentType.Loan
          ? {
              ...state.payment.loan,
              loading: true,
              error: undefined,
            }
          : state.payment.loan,
      lease:
        payload.type === PaymentType.Lease
          ? {
              ...state.payment.lease,
              loading: true,
              error: undefined,
            }
          : state.payment.lease,
    },
  }),
  [HANDLE_RESPONSE_PAYMENT]: (
    state,
    {
      payload: {
        type,
        payment,
        breakdown,
        lenderId,
        apr,
        interestRate,
        totalAmountFinanced,
        totalFees,
        oemId,
        taxAmount,
        programId,
        freightPdi,
        paymentId,
        totalProtectionProductsPrice,
      },
    }
  ) => ({
    ...state,
    payment: {
      ...state.payment,
      [type]: {
        loading: false,
        error: false,
        payment,
        breakdown,
        lenderId,
        apr,
        interestRate,
        totalAmountFinanced,
        totalFees,
        oemId,
        taxAmount,
        programId: programId ?? state.payment[type].programId,
        freightPdi: freightPdi ?? state.payment[type].freightPdi,
        paymentId,
        totalProtectionProductsPrice,
      },
    },
  }),
  [HANDLE_RESPONSE_PAYMENT_ERROR]: (state, { payload }) => ({
    ...state,
    payment: {
      ...state.payment,
      [payload.type]: {
        ...state.payment[payload.type],
        loading: false,
        error: payload.reason ?? true,
      },
    },
  }),
  [SET_VALUE]: (state, { payload }) => ({
    ...state,
    payment: {
      ...state.payment,
      input: { ...state.payment.input, ...payload.field },
    },
  }),
  [HANDLE_RESPONSE_OPTIONS]: (state, { payload }) => ({
    ...state,
    payment: {
      ...state.payment,
      input: { ...state.payment.input, ...payload.input },
    },
  }),
  [DEAL_REQUEST]: (state) => ({
    ...state,
    errorMessages: [],
    isDealLoading: true,
  }),
  [DEAL_SUBMITTED]: (state, { payload }) => ({
    ...state,
    referenceId: payload.referenceId,
    isDealLoading: false,
  }),
  [DEAL_SAVED]: (state) => ({
    ...state,
    isDealLoading: false,
  }),
  [DEAL_ABORTED]: (state) => ({
    ...state,
    errorMessages: [],
    isDealLoading: false,
  }),
  [DEAL_ERROR]: (state, { payload }) => ({
    ...state,
    errorMessages: payload.errorMessages,
    isDealLoading: false,
  }),
  [RESET_PAGE]: (state) => ({
    ...defaultState,
    currentStep: state.currentStep,
  }),
};

export default (state = defaultState, action: Action) => {
  const rootState = states[action.type]
    ? states[action.type](state, action as any)
    : state;
  return {
    ...rootState,
    financing: financing(state.financing, action as any),
    protectionPlans: protectionPlans(state.protectionPlans, action as any),
  };
};
