import { createSelector } from 'reselect';

import isFeatureEnabled from '../../../../lib/env/isFeatureEnabled';
import * as selectors from './selectors';

const selectFeatureAdvertising = (state) => state.features?.advertising;

const selectForceAdvertisement = (state) =>
  selectFeatureAdvertising(state)?.forceAdvertisement;

export const selectHasContactedDealer = (state) =>
  Boolean(selectFeatureAdvertising(state).hasContactedDealer);

export const isAdvertisingEnabled = createSelector(
  selectForceAdvertisement,
  (forceAdvertisement) => {
    switch (forceAdvertisement) {
      case 'show':
        return true;
      case 'hide':
        return false;
      case 'none':
      default:
        return isFeatureEnabled('advertising');
    }
  }
);

export const selectGroupName = createSelector(
  selectFeatureAdvertising,
  (advertising) => {
    const configs = advertising?.configs;
    if (!configs) {
      return undefined;
    }

    // all configs have same group name
    // pick any loaded advertising configuration
    const firstKey = Object.keys(configs)[0];
    return configs[firstKey].groupName;
  }
);

export const {
  selectSRPValues,
  selectVIPValues,
  createSelectAdConfig,
  selectConvertedAdConfig,
} = selectors;
