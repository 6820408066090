import { defineMessages } from 'react-intl';

export default defineMessages({
  success: {
    id: 'saved_searches_success',
  },
  failure: {
    id: 'request_failed_title',
  },
});
