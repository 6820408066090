import React from 'react';
import { useIntl } from 'react-intl';

import { SkipToLocationLink } from './SkipToLocationLink';
import { SKIP_TO_CONTENT_ID } from './SkipToContentTarget';
import useTracking from '../../../utils/tracking/useTracking';

export const SkipToContentLink = () => {
  const { trackEvent } = useTracking();
  const { formatMessage } = useIntl();

  return (
    <SkipToLocationLink
      onClick={() => {
        trackEvent({
          eventAction: 'SkipMainContentClick',
        });
      }}
      onFocus={() => {
        trackEvent({
          eventAction: 'SkipMainContentShow',
        });
      }}
      elementId={SKIP_TO_CONTENT_ID}
    >
      {formatMessage({ id: 'skip_to_content' })}
    </SkipToLocationLink>
  );
};
