import { REQUEST_HEADER } from '../../../../../api/lib/apiClient';

export const createVipResponse = <T extends Record<string, unknown>>({
  body,
  headers,
}: {
  body: T;
  headers: Headers;
}) => ({
  ...body,
  transactionId: headers?.get(REQUEST_HEADER.TRANSACTION_ID),
});
