import { combineReducers, Reducer } from 'redux';
import * as types from './actionTypes';
import {
  insertSortedMessage,
  updatePersistedMessageId,
  updateNotSentMessage,
  removeMessage,
  getMessageList,
  getConsolidatedState,
  getMergedMessageList,
  mergeMessageList,
} from './utils';
import {
  BLOCK_USER,
  BLOCK_USER_FAIL,
  BLOCK_USER_SUCCESS,
  ERRORS_VIEWED,
  GET_BLOCKED_USERS_FAIL,
  GET_BLOCKED_USERS_SUCCESS,
} from '../../shared/components/BlockUserMenu/actionTypes';
import { MessageStatus } from '../../shared/types';

const chatReducerTable = {
  [types.SEND_MESSAGE]: (state, { message, listingId, conversationId }) => {
    const { mergedState, rest } = getConsolidatedState(
      state,
      listingId,
      conversationId
    );
    return {
      ...rest,
      status: '',
      [conversationId || listingId]: {
        ...mergedState,
        listingId,
        messageList: insertSortedMessage(mergedState, message),
      },
    };
  },

  [types.HANDLE_RESPONSE_SEND_MESSAGE]: (
    state,
    { id, conversationId, tempUid, listingId }
  ) => {
    const { mergedState, rest } = getConsolidatedState(
      state,
      listingId,
      conversationId
    );

    return {
      ...rest,
      status: MessageStatus.Success,
      [conversationId]: {
        ...mergedState,
        listingId,
        conversationId,
        messageList: updatePersistedMessageId(mergedState, tempUid, id),
      },
    };
  },

  [types.HANDLE_RESPONSE_SEND_GUEST_MESSAGE]: (
    state,
    { conversationId, listingId }
  ) => {
    const { rest } = getConsolidatedState(state, listingId, conversationId);

    return {
      ...rest,
      status: MessageStatus.Success,
    };
  },

  [types.HANDLE_RESPONSE_ERROR_SEND_MESSAGE]: (
    state,
    { tempUid, listingId, conversationId }
  ) => {
    const { mergedState, rest } = getConsolidatedState(
      state,
      listingId,
      conversationId
    );

    return {
      ...rest,
      status: MessageStatus.Failed,
      [conversationId || listingId]: {
        ...mergedState,
        listingId,
        messageList: updateNotSentMessage(mergedState, tempUid),
      },
    };
  },

  [types.RETRY_SEND_MESSAGE]: (
    state,
    { messageId, conversationId, listingId }
  ) => {
    const { mergedState, rest } = getConsolidatedState(
      state,
      listingId,
      conversationId
    );

    return {
      ...rest,
      [conversationId || listingId]: {
        ...mergedState,
        listingId,
        messageList: removeMessage(mergedState, messageId),
      },
    };
  },

  [types.HANDLE_RESPONSE_CONVERSATION]: (
    state,
    { conversationId, messageList, listingId }
  ) => {
    const { mergedState, rest } = getConsolidatedState(
      state,
      listingId,
      conversationId
    );

    return {
      ...rest,
      [conversationId]: {
        conversationId,
        listingId,
        messageList: mergeMessageList(messageList, getMessageList(mergedState)),
        isFetched: true,
      },
    };
  },

  [types.HANDLE_RESPONSE_CONVERSATION_EMPTY]: (state, { listingId }) => ({
    ...state,
    [listingId]: {
      listingId,
      messageList: [],
      isFetched: true,
    },
  }),

  [types.HANDLE_RESPONSE_ERROR_CONVERSATION]: (state, { listingId }) => ({
    ...state,
    [listingId]: {
      listingId,
      messageList: state?.[listingId]?.messageList ?? [],
      isFetched: true,
    },
  }),

  [types.HANDLE_RESPONSE_MESSAGE_LIST]: (
    state,
    { messageList, conversationId, listingId, unreadMessagesCount }
  ) => ({
    ...state,
    [conversationId]: {
      ...state[conversationId],
      conversationId,
      listingId,
      unreadMessagesCount,
      messageList: state[conversationId]
        ? getMergedMessageList(messageList, state[conversationId].messageList)
        : messageList,
      isFetched: true,
    },
  }),
};

const isOpenedReducerTable = {
  [types.CLOSE_CHAT]: () => false,
  [types.UNMOUNT_CHAT]: () => false,
};

const blockUserReducer = {
  [BLOCK_USER]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [BLOCK_USER_SUCCESS]: (state) => ({
    ...state,
    isLoading: false,
    error: null,
  }),
  [BLOCK_USER_FAIL]: (state, error) => ({
    ...state,
    isLoading: false,
    error,
  }),
  [GET_BLOCKED_USERS_SUCCESS]: (state, { chat }) => ({
    userIds: chat,
    error: null,
  }),
  [GET_BLOCKED_USERS_FAIL]: (state, error) => ({
    ...state,
    error,
  }),
  [ERRORS_VIEWED]: (state, error) => ({
    ...state,
    error,
  }),
};

const messageStatus = {
  [types.HANDLE_RESPONSE_ERROR_SEND_MESSAGE]: (state) => ({
    ...state,
    sentSuccess: false,
  }),
  [types.RETRY_SEND_MESSAGE]: (state) => ({
    ...state,
    sentSuccess: false,
  }),
  [types.HANDLE_RESPONSE_SEND_MESSAGE]: (state) => ({
    ...state,
    sentSuccess: true,
  }),
  [types.HANDLE_RESPONSE_SEND_GUEST_MESSAGE]: (state) => ({
    ...state,
    sentSuccess: true,
  }),
};

const getReducer =
  (reducerTable, defaultState): Reducer<any, any> =>
  (state = defaultState, { type, payload } = {}) =>
    reducerTable[type] ? reducerTable[type](state, payload) : state;

export default combineReducers({
  isOpened: getReducer(isOpenedReducerTable, false),
  conversationTable: getReducer(chatReducerTable, {}),
  blockedUsers: getReducer(blockUserReducer, {
    userIds: [],
    error: null,
    isLoading: false,
  }),
  messageStatus: getReducer(messageStatus, { sentSuccess: false }),
});
