import isFeatureEnabled from '../lib/env/isFeatureEnabled';

import { routes as homeRoutes } from './pages/home/route';
import { routes as srpRoutes } from './pages/srp/route';
import { routes as srpOrVipRoutes } from './pages/srpOrVip/route';
import { routes as vipRoutes } from './pages/vip/route';
import { routes as drpRoutes } from './pages/drp/route';
// TODO:Cleanup after experiment is finished MOVE-26228
import { routes as drpSubmitRoutes } from './pages/drpSubmit/route';
import { routes as inboxRoutes } from './pages/inbox/route';
import { routes as savedItemsRoutes } from './pages/savedItems/route';
import { routes as dealerRoutes } from './pages/dealer/route';
import { routes as mipRoutes } from './pages/mip/route';
import { routes as savedSearchesRoutes } from './pages/savedSearches/route';
import { routes as myDealsRoutes } from './pages/myDeals/route';
import { routes as mrpRoutes } from './pages/mrp/route';
import { routes as notificationsRoutes } from './pages/notifications/route';

const routesMap = {
  ...homeRoutes,
  ...(isFeatureEnabled('hashed_vip') ? srpOrVipRoutes : srpRoutes),
  ...vipRoutes,
  ...inboxRoutes,
  ...savedItemsRoutes,
  ...dealerRoutes,
  ...mrpRoutes,
  ...mipRoutes,
  ...savedSearchesRoutes,
  ...notificationsRoutes,
  ...myDealsRoutes,
  ...drpRoutes,
  ...drpSubmitRoutes,
};

export default routesMap;
