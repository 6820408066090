import React from 'react';
import { IntlProvider } from 'react-intl';
import { getTranslations, getCurrentLocale } from '../../stores/i18n';
import { CURRENCY_FORMATS } from '../../../shared/config';
import { useSelector } from 'react-redux';

const TranslationProvider = ({ children }) => {
  const translations = useSelector(getTranslations);
  const currentLocale = useSelector(getCurrentLocale);
  return (
    <IntlProvider
      locale={currentLocale}
      messages={translations}
      formats={CURRENCY_FORMATS}
    >
      {children}
    </IntlProvider>
  );
};

export default TranslationProvider;
