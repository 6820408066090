// import { Action } from 'redux';
import * as types from './actionTypes';
import { toggleCheckedItems, toggleCheckedAllItems } from './utils';

type State = {
  isLoading: boolean;
  isFetched: boolean;
  isEditMode: boolean;
  hasError: boolean;
  items: any[];
};

type Action = {
  type: string;
  payload?: any;
};

export const defaultState = {
  isLoading: true,
  isFetched: false,
  isEditMode: false,
  hasError: false,
  items: [],
};

const states = {
  [types.REQUEST_SAVED_SEARCH_LIST]: (state) => ({
    ...state,
    isLoading: true,
    isFetched: false,
    hasError: false,
  }),
  [types.HANDLE_RESPONSE_SAVED_SEARCH_LIST]: (state: State, { payload }) => ({
    ...state,
    items: Array.from(
      payload.items
        .reduce((map, val) => map.set(val.id, { ...val }), new Map())
        .values()
    ),
    isLoading: false,
    isFetched: true,
    hasError: false,
  }),
  [types.HANDLE_RESPONSE_ERROR_SAVED_SEARCH_LIST]: (state: State) => ({
    ...state,
    isLoading: false,
    hasError: true,
  }),
  [types.HANDLE_RESPONSE_ERROR_UPDATE_SAVED_SEARCH]: (state: State) => ({
    ...state,
    hasError: true,
  }),
  [types.REQUEST_ADD_SAVED_SEARCH]: (
    state,
    { payload: { id, locationName, queryString } }
  ) => ({
    ...state,
    items: [
      ...state.items,
      {
        id,
        searchParameterString: queryString,
        locationName,
        isChecked: false,
      },
    ],
  }),
  [types.HANDLE_RESPONSE_ERROR_ADD_SAVED_SEARCH]: (
    state: State,
    { payload }
  ) => ({
    ...state,
    items: state.items.filter(
      (item) => item.searchParameterString !== payload.queryString
    ),
  }),
  [types.HANDLE_RESPONSE_DELETE_SAVED_SEARCH]: (
    state,
    { payload: { list } }
  ) => ({
    ...state,
    items: state.items.filter(({ id }) => list.indexOf(id) === -1),
    isEditMode: false,
  }),
  [types.TOGGLE_EDIT_MODE]: (state: State) => ({
    ...state,
    /*
     * if isEditMode is true when this action was triggered, it means
     * I should reset the selection since now the edit mode will be closed.
     */
    items: state.isEditMode
      ? state.items.map((item) => ({
          ...item,
          isChecked: false,
        }))
      : state.items,
    isEditMode: !state.isEditMode,
  }),
  [types.CHECK_EDIT_SAVED_SEARCH]: (state: State, { payload: { id } }) => ({
    ...state,
    items: toggleCheckedItems(state.items, id),
  }),
  [types.CHECK_ALL_SAVED_SEARCHES]: (
    state: State,
    { payload: { checked } }
  ) => ({
    ...state,
    items: toggleCheckedAllItems(state.items, checked),
  }),
};

const savedSearchesReducer = (
  state: State = defaultState,
  action: Action = { type: '' }
) => (states[action.type] ? states[action.type](state, action) : state);

export default savedSearchesReducer;
