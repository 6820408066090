import {
  getLocalStorageObject,
  setLocalStorageObject,
} from '../../utils/localStorage';

const STORAGE_KEY = 'nps-performance-survey';

export const loadValues = () =>
  getLocalStorageObject(STORAGE_KEY, {
    visitedPages: [] as string[],
    hasActionPerformed: false,
    timestamp: new Date().getTime(),
  });

export const addVisitedPage = (decodedUrl: string) => {
  const values = loadValues();
  if (values.hasActionPerformed) {
    return;
  }

  const url = decodeURIComponent(decodedUrl);

  if (!values.visitedPages.includes(url)) {
    values.visitedPages = [...values.visitedPages, url];

    setLocalStorageObject(STORAGE_KEY, values);
  }
};

export const setActionPerformed = () => {
  const values = loadValues();
  if (values.hasActionPerformed) {
    return;
  }

  values.hasActionPerformed = true;

  setLocalStorageObject(STORAGE_KEY, values);
};
