import { Dispatch, Store } from 'redux';
import * as types from './actionTypes';
import * as api from './api';

import track from '../../utils/tracking/track';
import {
  SAVED_SEARCH_SUCCESS,
  SAVED_SEARCH_DELETE_BEGIN,
  SAVED_SEARCH_DELETE_SUCCESS,
} from './tracking';

export type Options = {
  locale?: string;
};

type SavedSearch = {
  queryString: string;
  locationName?: string;
  notifications?: any;
  id?: string;
};

export const fetchSavedSearchList =
  (options: Options) => async (dispatch: Dispatch) => {
    dispatch({
      type: types.REQUEST_SAVED_SEARCH_LIST,
    });

    try {
      const { savedSearchList } = await api.getSavedSearchList(options);
      dispatch({
        type: types.HANDLE_RESPONSE_SAVED_SEARCH_LIST,
        payload: {
          items: savedSearchList.map((item) => ({
            ...item,
            isChecked: false,
          })),
        },
      });
    } catch (error) {
      dispatch({
        type: types.HANDLE_RESPONSE_ERROR_SAVED_SEARCH_LIST,
        payload: error,
      });
    }
  };

export const updateSavedSearch =
  (
    { queryString, locationName, notifications }: SavedSearch,
    options: Options
  ) =>
  async (dispatch: Dispatch) => {
    try {
      return await api.postSavedSearch(
        queryString,
        locationName || '',
        notifications,
        options
      );
    } catch (error) {
      return dispatch({
        type: types.HANDLE_RESPONSE_ERROR_UPDATE_SAVED_SEARCH,
        error,
      });
    }
  };

export const addSavedSearch =
  (
    { queryString, locationName, notifications, id }: SavedSearch,
    options: Options
  ) =>
  async (dispatch: Dispatch, getState: Store['getState']) => {
    dispatch({
      type: types.REQUEST_ADD_SAVED_SEARCH,
      payload: {
        id,
        queryString,
        locationName,
        notifications,
      },
    });

    try {
      await api.postSavedSearch(
        queryString,
        locationName || '',
        notifications,
        options
      );
      dispatch({
        type: types.HANDLE_RESPONSE_ADD_SAVED_SEARCH,
      });
      track(SAVED_SEARCH_SUCCESS, getState());
    } catch (error) {
      dispatch({
        type: types.HANDLE_RESPONSE_ERROR_ADD_SAVED_SEARCH,
        payload: {
          error,
          queryString,
          notifications,
        },
      });
      throw error;
    }
  };

type Item = {
  id: string;
  isChecked?: boolean;
};

export const deleteSavedSearch =
  (items: Item[]) =>
  async (dispatch: Dispatch, getState: Store['getState']) => {
    track(SAVED_SEARCH_DELETE_BEGIN, getState());

    const list = items.filter((item) => item.isChecked).map((item) => item.id);

    try {
      await api.deleteSavedSearch(list);
      dispatch({
        type: types.HANDLE_RESPONSE_DELETE_SAVED_SEARCH,
        payload: {
          list,
        },
      });
      track(SAVED_SEARCH_DELETE_SUCCESS, getState());
    } catch (error) {
      dispatch({
        type: types.HANDLE_RESPONSE_ERROR_DELETE_SAVED_SEARCH,
        payload: {
          error,
        },
      });
    }
  };

export const visitSavedSearch =
  (externalSearchId: string, options: Options) =>
  async (dispatch: Dispatch) => {
    try {
      await api.visitSavedSearch(externalSearchId, options);
    } catch (error) {
      dispatch({
        type: types.HANDLE_RESPONSE_ERROR_VISIT_SAVED_SEARCH,
        payload: {
          error,
        },
      });
      throw error;
    }
  };

export const toggleCheckAllSavedSearches = (checked: boolean) => ({
  type: types.CHECK_ALL_SAVED_SEARCHES,
  payload: { checked },
});

export const toggleCheckSavedSearch = (id: string) => ({
  type: types.CHECK_EDIT_SAVED_SEARCH,
  payload: { id },
});

export const toggleEditMode = () => ({
  type: types.TOGGLE_EDIT_MODE,
});
