import { goToSrp } from '../../stores/page';
import { updateUserPositionFromQuery } from '../../stores/geoLocation';
import { Query, setPreparatoryQuery } from '../../stores/searchQuery';
import { pushSnackbarTranslationKey } from '../../stores/snackbars';
import { addSavedSearch } from '../../features/savedSearches';

import messages from './actionCreators.messages';
import { ThunkDispatch } from 'redux-thunk';
import { State } from '../../stores/types/State';
import { AnyAction } from 'redux';

const normalizeSortingParams = ({
  sb = 'ct',
  od = 'down',
  ...rest
}: Query) => ({ ...rest, sb, od });

export const goToSrpFromSavedSearch =
  (query: Query) =>
  async (dispatch: ThunkDispatch<State, never, AnyAction>) => {
    const normalizedQuery = normalizeSortingParams(query);
    await dispatch(updateUserPositionFromQuery(normalizedQuery));
    dispatch(setPreparatoryQuery(normalizedQuery));
    dispatch(goToSrp(normalizedQuery));
  };

export const addSavedSearchAndNotify =
  (
    queryString: string,
    locationName: string | undefined,
    notifications: any,
    id: string | undefined,
    options: {
      locale?: string;
    }
  ) =>
  async (dispatch: ThunkDispatch<State, never, AnyAction>) => {
    try {
      await dispatch(
        addSavedSearch(
          { queryString, locationName, notifications, id },
          options
        )
      );
      await dispatch(pushSnackbarTranslationKey(messages.success.id));
    } catch {
      await dispatch(pushSnackbarTranslationKey(messages.failure.id));
    }
  };
