import {
  SET_MODAL_OPEN,
  SET_MODAL_CLOSE,
  SET_REPORTED_LISTING,
  SET_REPORTED_LISTINGS,
} from './actionTypes';
import { State } from './types/State';

const defaultState: State = {
  isModalOpen: false,
  reportedListings: [],
};

const reducer = {
  [SET_MODAL_OPEN]: (state: State) => ({
    ...state,
    isModalOpen: true,
  }),
  [SET_MODAL_CLOSE]: (state: State) => ({
    ...state,
    isModalOpen: false,
  }),
  [SET_REPORTED_LISTING]: (state: State, { adId }) => ({
    ...state,
    reportedListings: [...state.reportedListings, adId],
  }),
  [SET_REPORTED_LISTINGS]: (state, { reportedListings }) => ({
    ...state,
    reportedListings,
  }),
};

type Action = {
  type: string;
  payload?: any;
};

export default (
  state = defaultState,
  { type, payload }: Action = { type: '' }
) => (reducer[type] ? reducer[type](state, payload) : state);
