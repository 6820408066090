import { handleSessionStorage } from '../../../shared/utils/handleSessionStorage';
import { STORAGE_KEY } from './constants';

/*
The hashed VIP may be shown when there is a #vip in the URL.
However, it may only be shown on the initial page load.
An exception is made when the user reloads a real VIP page.
As the user clicks around in the app the same ID can appear in the URL again,
but this time we have to show real VIP. If the user clicks back
until they reach the first page, it has to be a hashed VIP again.
The realVip flag is stored in several places.
Once in the browser history state for the code that has no access to redux.
Once copied into the route hashed VIP action, because router wipes browser history state
(https://github.com/faceyspacey/redux-first-router/issues/373).
Once copied into the route SRP action because it is triggered automatically.
*/

export const getHashedVipParamsFromUrl = () => {
  if (IS_SERVER) {
    return {};
  }
  const hash = window.location.hash.replace(/^#/, '');
  const params = new URLSearchParams(hash);
  const id = params.get('vip'); // this is returned as a number on iOS Safari
  const termLoan = params.get('termLoan');
  const frequencyLoan = params.get('frequencyLoan');
  return { id: id ? `${id}` : id, termLoan, frequencyLoan };
};

// This file is loaded directly, not lazily, and can capture the initial state of the URL
const initialIds = {
  url: getHashedVipParamsFromUrl().id,
  storage: handleSessionStorage.get(STORAGE_KEY),
};
handleSessionStorage.clear(STORAGE_KEY);

type Payload = { historyState?: { realVip?: boolean } };

export const shouldShowRealVip = (payload?: Payload) => {
  if (IS_SERVER) {
    // VIP would never be rendered on server
    return false;
  }

  const { id: idFromUrl } = getHashedVipParamsFromUrl();
  if (!idFromUrl) {
    // VIP would never be rendered without an ID
    return false;
  }

  const valueFromHistoryState = window.history?.state?.realVip;
  if (valueFromHistoryState) {
    // marked as VIP in browser history state
    return true;
  }

  const valueFromRouterHistoryState = payload?.historyState?.realVip;
  if (valueFromRouterHistoryState) {
    // marked as VIP in action's copy of browser history state
    return true;
  }

  const reloadingRealVip =
    initialIds.url && initialIds.url === initialIds.storage;
  if (reloadingRealVip) {
    // keep showing real VIP after a page reload
    return true;
  }

  const showHashedVip = idFromUrl === initialIds.url;
  if (showHashedVip) {
    // We have an ID, it's the same as on page load, but we're not reloading a page,
    // and the standard signs of VIP are not there. We will render a hashed VIP.
    return false;
  }

  return true;
};

const onPageHide = () => {
  if (shouldShowRealVip()) {
    const { id } = getHashedVipParamsFromUrl();
    if (typeof id === 'string') {
      handleSessionStorage.set(STORAGE_KEY, id);
    }
  }
};

if (!IS_SERVER) {
  window.addEventListener('pagehide', onPageHide, true);
}
