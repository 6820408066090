import { PAGE_TYPES_MAP } from './pageTypesMap';
import { isSVIP } from './isSVip';

const getGoogleTagManagerPageType = (page, state) => {
  const pageType = PAGE_TYPES_MAP[page];

  if (!pageType) {
    throw new Error(`Unknown page (${page}) for GA Tracking`);
  }

  if (isSVIP(pageType, state)) {
    return PAGE_TYPES_MAP.sVip;
  }

  return pageType;
};

export default getGoogleTagManagerPageType;
