import { getEnv } from '@move-web-essentials-utils/env';
import apiClient from '../../../api/lib/apiClient';
import { REQUEST_HEADER } from '../../../api/lib/apiClient/requestHeaders';
import { MOVE_DEALERS_API_BASE_PATH } from '../../../shared/config';
import { urls } from '../../../api/urls';
import { GetDealerParams, GetMoreListingsParams, Options } from './types/Api';

const getDealerUrl = () => {
  if (IS_SERVER) {
    return `${getEnv(
      'move_consumer_api_gateway_url'
    )}${MOVE_DEALERS_API_BASE_PATH}/`;
  }
  return `${MOVE_DEALERS_API_BASE_PATH}/`;
};

export const getDealer = (
  { dealerId }: GetDealerParams,
  options: Options = {}
) => {
  const doRequest = apiClient({
    metricsId: 'svc_getDealer',
    ...options,
  });

  return doRequest({
    url: `${getDealerUrl()}${dealerId}?st=DEALER`,
  });
};

export const getMoreListings = (
  { sellerId, nextPageToken }: GetMoreListingsParams,
  options: Options = {}
) => {
  const doRequest = apiClient({
    metricsId: 'svc_getDealer',
    ...options,
    headers: {
      ...options.headers,
      [REQUEST_HEADER.TRACK_RECENT_SEARCH]: false,
      [REQUEST_HEADER.SEARCH_SOURCE]: 'dealer-page',
    },
  });

  return doRequest({
    url: `${urls.search}?${
      nextPageToken ? `pageToken=${nextPageToken}&` : ''
    }psz=20&sid=${sellerId}&p=&promotionsDisabled=true`,
  });
};
