import React, { useEffect, useLayoutEffect, Fragment, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import getGoogleTagManagerPageType from './getGoogleTagManagerPageType';
import { trackPageView } from './trackPageView';
import { tempDataLayer } from './dataLayer';
import { selectPageId } from '../../stores/page';

// On Private browsers like Firefox private tab tag manager is not enabled
export const isGoogleTagManagerEnabled = () =>
  typeof window.google_tag_manager === 'object';

const useClientOnlyEffect = IS_SERVER ? () => null : useLayoutEffect;

type Props = {
  page?: string;
  dimensions?: Record<string, unknown>;
  data?: Record<string, unknown>;
  extendsData?: Record<string, unknown>;
};

const PageTracking: FC<Props> = ({
  page,
  dimensions,
  data,
  extendsData,
  children,
}) => {
  const dispatch = useDispatch();
  const pageId = useSelector(selectPageId);

  useClientOnlyEffect(() => {
    tempDataLayer.enable();
  }, []);

  useEffect(() => {
    if (IS_SERVER) {
      return;
    }
    // then add pageview to temporary dataLayer
    dispatch(trackPageView(page || pageId, dimensions, data, extendsData));
    // then sort pageview before events
    tempDataLayer.sortPageUp();
    // then apply temporary dataLayer to real dataLayer
    // and disable the temporary one
    tempDataLayer.applyAndDisable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Fragment>{children}</Fragment>;
};

export default PageTracking;

export { getGoogleTagManagerPageType };
