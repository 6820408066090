import { Badge } from '../../shared/types/Badge';
import { CommercialTracking } from '../../shared/types/CommercialTracking';
import { Price } from '../../shared/types/Price';
import { Contact } from '../../shared/types/Contact';
import { ImageRef } from '../../shared/types/ImageRef';
import { LatLong } from '../../shared/types/LatLong';
import { ListingStatus } from '../../shared/types/ListingStatus';
import { PriceRating } from '../../shared/types/PriceRating';
import { ShareUrl } from '../../shared/types/ShareUrl';
import { CertifiedPreOwned } from '../../shared/types/CertifiedPreOwned';
import { Frequency } from '../../shared/types/Frequency';

export enum StreetType {
  Abbey = 'AB',
  Acres = 'AS',
  Allee = 'AE',
  Alley = 'AL',
  Autoroute = 'AU',
  Avenue = 'AV',
  Bay = 'BA',
  Beach = 'BE',
  Bend = 'BN',
  Blvd = 'BV',
  Boul = 'BO',
  Bypass = 'BP',
  Campus = 'CA',
  Cape = 'CP',
  Carre = 'CR',
  Centre = 'CN',
  Cercle = 'CC',
  Chase = 'CE',
  Chemin = 'CH',
  Circle = 'CI',
  Circuit = 'CF',
  Close = 'CL',
  Common = 'CM',
  Concession = 'CQ',
  Corners = 'CB',
  Cote = 'CO',
  Cour = 'CU',
  Cours = 'CY',
  Court = 'CT',
  Cove = 'CV',
  Crescent = 'CS',
  Croissant = 'CW',
  Crossing = 'CX',
  Cul = 'CD',
  Dale = 'DA',
  Dell = 'DE',
  Diversion = 'DI',
  Downs = 'DO',
  Drive = 'DR',
  End = 'EN',
  Esplanade = 'EP',
  Estates = 'ES',
  Expressway = 'EX',
  Extension = 'ET',
  Field = 'FI',
  Freeway = 'FY',
  Front = 'FR',
  Gardens = 'GD',
  Gate = 'GT',
  Glade = 'GL',
  Glen = 'GE',
  Green = 'GN',
  Grounds = 'GA',
  Grove = 'GR',
  Harbour = 'HA',
  Heath = 'HE',
  Heights = 'HT',
  Highlands = 'HG',
  Highway = 'HW',
  Hill = 'HL',
  Hollow = 'HO',
  Ile = 'IL',
  Impasse = 'IM',
  Inlet = 'IN',
  Island = 'IS',
  Key = 'KE',
  Knoll = 'KN',
  Landng = 'LA',
  Lane = 'LN',
  Limits = 'LM',
  Line = 'LE',
  Link = 'LI',
  Lookout = 'LK',
  Loop = 'LP',
  Mall = 'MA',
  Manor = 'MR',
  Maze = 'MZ',
  Meadow = 'MW',
  Mews = 'MS',
  Montee = 'MN',
  Mount = 'MO',
  Mountain = 'MT',
  Parade = 'PR',
  Parc = 'PC',
  Park = 'PK',
  Parkway = 'PY',
  Passage = 'PS',
  Path = 'PA',
  Pathway = 'PW',
  Place = 'PL',
  Plateau = 'PP',
  Plaza = 'PZ',
  Point = 'PQ',
  Pointe = 'PT',
  Private = 'PV',
  Promenade = 'PE',
  Quai = 'QU',
  Quay = 'QY',
  Ramp = 'RM',
  Rang = 'RA',
  Range = 'RG',
  Ridge = 'RE',
  Rise = 'RI',
  Road = 'RD',
  Route = 'RT',
  Row = 'RO',
  Rue = 'RU',
  Ruelle = 'RL',
  Run = 'RN',
  Sentier = 'SN',
  Square = 'SQ',
  Street = 'ST',
  Subdivision = 'SU',
  Terrace = 'TC',
  Terrasse = 'TS',
  Townline = 'TL',
  Trail = 'TR',
  Turnabout = 'TT',
  Vale = 'VL',
  View = 'VW',
  Village = 'VI',
  Villas = 'VA',
  Vista = 'VS',
  Voie = 'VO',
  Walk = 'WK',
  Way = 'WY',
  Wyndwharf = 'WN',
  Wood = 'WO',
}

export enum Province {
  AB = 'AB',
  BC = 'BC',
  MB = 'MB',
  NB = 'NB',
  NL = 'NL',
  NS = 'NS',
  NT = 'NT',
  NU = 'NU',
  ON = 'ON',
  PE = 'PE',
  QC = 'QC',
  SK = 'SK',
  YT = 'YT',
}

export enum FeeType {
  Dealer = 'DEALER',
  Retail = 'RETAIL',
}

export enum Gender {
  Male = 'Male',
  Female = 'Female',
}

export enum Relationship {
  Brother = 'BROTHER',
  CommonLaw = 'COMMON_LAW',
  Grandfather = 'GRANDFATHER',
  Grandmother = 'GRANDMOTHER',
  InLaw = 'IN_LAW',
  Employer = 'EMPLOYER',
  Other = 'OTHER',
  Parental = 'PARENTAL',
  Spouse = 'SPOUSE',
  Sister = 'SISTER',
}

export enum HonorificPrefix {
  Dr = 'DR',
  Mr = 'MR',
  Miss = 'MISS',
  Mrs = 'MRS',
  Ms = 'MS',
}

export enum MaritalStatus {
  CommonLaw = 'COMMON_LAW',
  Divorced = 'DIVORCED',
  Married = 'MARRIED',
  Single = 'SINGLE',
  Separated = 'SEPARATED',
  WidowWidower = 'WIDOW_WIDOWER',
}

export enum HomeOwnershipType {
  OwnFreeAndClear = 'OWN_FREE_AND_CLEAR',
  OwnMobileHome = 'OWN_MOBILE_HOME',
  OwnWithMortgage = 'OWN_WITH_MORTGAGE',
  Rent = 'RENT',
  ReserveHousing = 'RESERVE_HOUSING',
  Parents = 'PARENTS',
  Other = 'OTHER',
}

export enum FinancingType {
  Lease = 'LEASE',
  Loan = 'LOAN',
}

export enum CardinalDirection {
  Ast = 'AST',
  North = 'NORTH',
  NorthEast = 'NORTH_EAST',
  NorthWest = 'NORTH_WEST',
  South = 'SOUTH',
  SouthEast = 'SOUTH_EAST',
  SouthWest = 'SOUTH_WEST',
  West = 'WEST',
  East = 'EAST',
}

export enum EmploymentType {
  AtHome = 'AT_HOME',
  Executive = 'EXECUTIVE',
  Labourer = 'LABOURER',
  Management = 'MANAGEMENT',
  OfficeStaff = 'OFFICE_STAFF',
  Other = 'OTHER',
  Production = 'PRODUCTION',
  Professional = 'PROFESSIONAL',
  Retired = 'RETIRED',
  Sales = 'SALES',
  SelfEmployed = 'SELF_EMPLOYED',
  Service = 'SERVICE',
  Student = 'STUDENT',
  Trades = 'TRADES',
  Unemployed = 'UNEMPLOYED',
}

export enum EmploymentStatus {
  FullTime = 'FULL_TIME',
  FullTimeProbation = 'FULL_TIME_PROBATION',
  PartTimeCasual = 'PART_TIME_CASUAL',
  PartTimeRegular = 'PART_TIME_REGULAR',
  Retired = 'RETIRED',
  SelfEmployed = 'SELF_EMPLOYED',
  SeasonalSummer = 'SEASONAL_SUMMER',
  SeasonalWinter = 'SEASONAL_WINTER',
}

export enum Country {
  AA = 'AA',
  AC = 'AC',
  AD = 'AD',
  AE = 'AE',
  AF = 'AF',
  AG = 'AG',
  AI = 'AI',
  AL = 'AL',
  AM = 'AM',
  AN = 'AN',
  AO = 'AO',
  AP = 'AP',
  AQ = 'AQ',
  AR = 'AR',
  AS = 'AS',
  AT = 'AT',
  AU = 'AU',
  AX = 'AX',
  AW = 'AW',
  AZ = 'AZ',
  BA = 'BA',
  BB = 'BB',
  BD = 'BD',
  BE = 'BE',
  BF = 'BF',
  BG = 'BG',
  BH = 'BH',
  BI = 'BI',
  BJ = 'BJ',
  BM = 'BM',
  BN = 'BN',
  BO = 'BO',
  BR = 'BR',
  BS = 'BS',
  BT = 'BT',
  BV = 'BV',
  BW = 'BW',
  BY = 'BY',
  BZ = 'BZ',
  CA = 'CA',
  CC = 'CC',
  CD = 'CD',
  CF = 'CF',
  CG = 'CG',
  CH = 'CH',
  CI = 'CI',
  CK = 'CK',
  CL = 'CL',
  CM = 'CM',
  CN = 'CN',
  CO = 'CO',
  CR = 'CR',
  CS = 'CS',
  CU = 'CU',
  CV = 'CV',
  CX = 'CX',
  CY = 'CY',
  CZ = 'CZ',
  DE = 'DE',
  DJ = 'DJ',
  DK = 'DK',
  DM = 'DM',
  DO = 'DO',
  DZ = 'DZ',
  EC = 'EC',
  EE = 'EE',
  EG = 'EG',
  EH = 'EH',
  ER = 'ER',
  ES = 'ES',
  ET = 'ET',
  EU = 'EU',
  FI = 'FI',
  FJ = 'FJ',
  FK = 'FK',
  FL = 'FL',
  FM = 'FM',
  FO = 'FO',
  FR = 'FR',
  GA = 'GA',
  GB = 'GB',
  GD = 'GD',
  GE = 'GE',
  GF = 'GF',
  GH = 'GH',
  GI = 'GI',
  GL = 'GL',
  GM = 'GM',
  GN = 'GN',
  GP = 'GP',
  GQ = 'GQ',
  GR = 'GR',
  GS = 'GS',
  GT = 'GT',
  GU = 'GU',
  GW = 'GW',
  GY = 'GY',
  HK = 'HK',
  HM = 'HM',
  HN = 'HN',
  HR = 'HR',
  HT = 'HT',
  HU = 'HU',
  ID = 'ID',
  IE = 'IE',
  IL = 'IL',
  IN = 'IN',
  IO = 'IO',
  IQ = 'IQ',
  IR = 'IR',
  IS = 'IS',
  IT = 'IT',
  JM = 'JM',
  JO = 'JO',
  JE = 'JE',
  JP = 'JP',
  KE = 'KE',
  KG = 'KG',
  KH = 'KH',
  KI = 'KI',
  KM = 'KM',
  KN = 'KN',
  KP = 'KP',
  KR = 'KR',
  KW = 'KW',
  KY = 'KY',
  KZ = 'KZ',
  LA = 'LA',
  LB = 'LB',
  LC = 'LC',
  LI = 'LI',
  LK = 'LK',
  LR = 'LR',
  LS = 'LS',
  LT = 'LT',
  LU = 'LU',
  LV = 'LV',
  LY = 'LY',
  MA = 'MA',
  MC = 'MC',
  MD = 'MD',
  ME = 'ME',
  MG = 'MG',
  MH = 'MH',
  MK = 'MK',
  ML = 'ML',
  MM = 'MM',
  MN = 'MN',
  MO = 'MO',
  MP = 'MP',
  MQ = 'MQ',
  MR = 'MR',
  MS = 'MS',
  MT = 'MT',
  MU = 'MU',
  MV = 'MV',
  MW = 'MW',
  MX = 'MX',
  MY = 'MY',
  MZ = 'MZ',
  NA = 'NA',
  NC = 'NC',
  NE = 'NE',
  NF = 'NF',
  NG = 'NG',
  NI = 'NI',
  NL = 'NL',
  NO = 'NO',
  NP = 'NP',
  NR = 'NR',
  NU = 'NU',
  NZ = 'NZ',
  OM = 'OM',
  PA = 'PA',
  PE = 'PE',
  PF = 'PF',
  PG = 'PG',
  PH = 'PH',
  PK = 'PK',
  PL = 'PL',
  PM = 'PM',
  PN = 'PN',
  PR = 'PR',
  PS = 'PS',
  PT = 'PT',
  PW = 'PW',
  PY = 'PY',
  QA = 'QA',
  QM = 'QM',
  QN = 'QN',
  QO = 'QO',
  QP = 'QP',
  RE = 'RE',
  RO = 'RO',
  RS = 'RS',
  RU = 'RU',
  RW = 'RW',
  SA = 'SA',
  SB = 'SB',
  SC = 'SC',
  SD = 'SD',
  SE = 'SE',
  SG = 'SG',
  SH = 'SH',
  SI = 'SI',
  SJ = 'SJ',
  SK = 'SK',
  SL = 'SL',
  SM = 'SM',
  SN = 'SN',
  SO = 'SO',
  SR = 'SR',
  ST = 'ST',
  SV = 'SV',
  SY = 'SY',
  SZ = 'SZ',
  TC = 'TC',
  TD = 'TD',
  TF = 'TF',
  TG = 'TG',
  TH = 'TH',
  TJ = 'TJ',
  TK = 'TK',
  TM = 'TM',
  TN = 'TN',
  TO = 'TO',
  TP = 'TP',
  TR = 'TR',
  TT = 'TT',
  TV = 'TV',
  TW = 'TW',
  TZ = 'TZ',
  UA = 'UA',
  UG = 'UG',
  UM = 'UM',
  US = 'US',
  UY = 'UY',
  UZ = 'UZ',
  VA = 'VA',
  VC = 'VC',
  VE = 'VE',
  VG = 'VG',
  VI = 'VI',
  VN = 'VN',
  VU = 'VU',
  WF = 'WF',
  WS = 'WS',
  YE = 'YE',
  YT = 'YT',
  YU = 'YU',
  ZA = 'ZA',
  ZM = 'ZM',
  ZW = 'ZW',
  ZZ = 'ZZ',
}

export enum AddressType {
  PostalBox = 'POSTAL_BOX',
  RuralRoute = 'RURAL_ROUTE',
  Street = 'STREET',
}

export enum VehicleUsage {
  Low = 'low',
  Average = 'average',
  High = 'high',
}

export enum RecommendationType {
  Similar = 'SIMILAR',
  Dealer = 'DEALER',
  None = 'NONE',
}

export enum SubscriptionBundle {
  Business = 'BUSINESS',
  BusinessPro = 'BUSINESS_PRO',
  BusinessElite = 'BUSINESS_ELITE',
  Basic = 'BASIC',
  Plus = 'PLUS',
  Premium = 'PREMIUM',
  Irrelevant = 'IRRELEVANT',
}

export enum SubscriptionType {
  UsedVehicles = 'USED_VEHICLES',
  NewVehicles = 'NEW_VEHICLES',
  NewAndUsed = 'NEW_AND_USED',
  Irrelevant = 'IRRELEVANT',
}

// Listing

export type Location = {
  country?: Country;
  province: string; // differs from spec
  zipCode: string;
  city: string;
  address1?: string;
  latLong?: LatLong;
};

export type Prices = {
  consumerPrice: Price;
  msrp?: Price;
  driveAwayPrice?: Price;
  strikeThroughPrice?: Price;
};

export type VehicleDetail = {
  tag: string;
  label: string;
  attributes: {
    values: string[];
  }[];
  dimensions: number;
};

export type AttributeGroup = {
  tag: string;
  label: string;
  attributes: {
    values: string[];
  }[];
  dimensions: number;
};

export type VehicleReport = {
  reports?: {
    language: string;
    url: string;
  }[];
  badges?: string[];
  badgeKeys?: string[];
  providerName: string;
  providerLogoUrl: string;
  providerDarkLogoUrl: string;
  buyReportUrl?: string;
  requestReportUrl?: string;
  reportUrl?: string;
};

export type FinancingData = {
  loan: {
    terms: {
      term: number;
      rate: number;
      isDefault: boolean;
    }[];
    frequencies: {
      frequency: Frequency;
      isDefault: boolean;
    }[];
  };
  remoteCalculation: boolean;
};

export type Subscription = {
  bundle: SubscriptionBundle;
  type: SubscriptionType;
};

export type Review = {
  authorName: string;
  authorUrl: string;
  language: string;
  profilePhotoUrl: string;
  rating: number;
  text: string;
  time: number;
  timeDescription: string;
};

export type OpeningHours = {
  weekdayText?: {
    day: string;
    time: string;
  }[];
};

export type Listing = {
  sellerId: string;
  id: string;
  version: number;
  stockNumber?: string;
  foreignId: string;
  partnerId: string;
  customerId: string;
  deprecated?: true;
  sellerForeignId: string;
  title: string;
  make: string;
  model: string;
  trim: string;
  year: string;
  vin?: string;
  exteriorColor: string;
  transmission?: string;
  bodyType: string;
  fuelType: string;
  driveTrain: string;
  location: Location;
  sku?: string;
  images: ImageRef[];
  prices: Prices;
  priceRating?: PriceRating;
  contact: Contact;
  isPreRegistration?: boolean;
  isDemoVehicle: boolean;
  quickFacts: {
    attributes: {
      tag: string;
      key: string;
      value?: string;
    }[];
  };
  vehicleDetails: VehicleDetail[];
  advertisingDetails: VehicleDetail[];
  mediaRefs: {
    type: string;
    description: string;
    reference: string;
  }[];
  vehicleUsage?: {
    usage: VehicleUsage;
    currentMileage: string;
    averageMileagePerYear: string;
    buckets: {
      low: string;
      average: string;
      high: string;
    };
  };
  attributeGroups: AttributeGroup[];
  isDamageCase?: boolean;
  readyToDrive?: boolean;
  htmlDescription: string;
  vc: 'Car'; // just 'Car'
  vehicleReport?: VehicleReport;
  created: number;
  modified: number;
  status?: string;
  url: string;
  youtubeVideos: {
    id: string;
    previewImage: string;
  }[];
  showDealerPage: boolean;
  recommendationType: RecommendationType;
  amountOfDealerListings: number;
  certifiedPreOwned?: CertifiedPreOwned & {
    textJson: {
      type: string;
      content: string;
    }[];
  };
  listingStatus: ListingStatus;
  financingData?: FinancingData;
  hasDigitalRetailing: boolean;
  protectionProductsOptIn: boolean;
  commercialTrackings: CommercialTracking[];
  shareUrl: ShareUrl;
  licenseNumber?: string;
  subscriptions: Subscription[];
  badges: Badge[];
  isNew: boolean;
  isConditionNew: boolean;

  // not in spec
  segment?: string;
  showSimilarItems?: boolean;
  digitalRetail?: any;
  mapsUrl?: string;
  directionsUrl?: string;
  dealerRating?: {
    name: string;
    rating: number;
    url: string;
    directionsUrl: string;
    locale: string;
    latLong: LatLong;
    reviews: Review[];
    totalReviews: number;
    openingHours: OpeningHours;
  };
  covid19?: {
    textJson: { type: string; content: string }[];
    dealerCovid19Url?: string;
  };
};

// Deals

export type Fee = {
  type: FeeType;
  description: string;
  amount: number;
};

export type Incentive = {
  type: string;
  description: string;
  amount: number;
};

export type Personal = {
  honorificPrefix: HonorificPrefix;
  firstName: string;
  middleName?: string;
  lastName: string;
  phone: string;
  mobilePhone: string;
  email: string;
  birthDate: string; // Expects MM/DD/YYYY format
  gender: Gender;
  maritalStatus: MaritalStatus;
};

export type Payments = {
  term: number;
  totalAmountFinanced: number;
  cashDownPayment: number;
  interestRate: number;
  isTradeInIncluded: boolean;
  totalFees?: number;
  installmentPayment: number;
  taxAmount?: number;
  paymentFrequency: Frequency;
  annualKilometers: number;
  fees?: Fee[];
  incentives?: Incentive[];
};

export type Address = {
  streetDirection?: CardinalDirection;
  addressType: AddressType;
  city: string;
  province: Province;
  postalCode?: string;
  streetNumber?: string;
  postBoxNumber?: number;
  streetType?: StreetType;
  civicAddress?: string;
  ruralRouteNumber?: number;
  concession?: string;
  lotNumber?: number;

  streetName: string;
  suiteNumber: string;
};

export type Residence = {
  address: Address;
  yearsAtAddress: number;
  monthsAtAddress: number;
  homeMonthlyPayment: number;
  landlordName?: string;
  homeOwnershipType: HomeOwnershipType;
  homeMortgageAmount?: number;
  homeMarketValue?: number;
  homeMortgageOwnership?: string;
  additionalInfo?: string;
};

export type PreviousResidence = {
  address: Address;
  yearsAtAddress: number;
  monthsAtAddress: number;
};

export type PreviousEmployment = {
  employmentType: EmploymentType;
  employmentStatus?: EmploymentStatus;
  address: Address;
  employerName?: string;
  occupation?: string;
  employerPhone?: string;
  employerDurationYears: number;
  employerDurationMonths: number;
  annualGrossIncome: number;
};

export type CurrentEmployment = PreviousEmployment;

export type TradeIn = {
  year: number;
  make: string;
  model: string;
  vin?: string;
  mileage: number;
  trim?: string;
  bodyStyle: string;
  tradeInAllowanceAmount: number;
  lienAmount?: number;
  lienHolderName?: string;
};

type ApplicantData = {
  email: Personal['email'];
  relation?: Relationship;
  personal: Omit<Personal, 'email'>;
  residence: Residence;
  previousResidence?: PreviousResidence;
  employment: CurrentEmployment;
  previousEmployment?: PreviousEmployment;
};
type GuestApplicantData = Omit<ApplicantData, 'email'>;

type FinancingApplicationData = Payments & {
  type: FinancingType;
  tradeIn?: TradeIn;

  apr: number;
  interestRate: number;
  oemId?: string;

  lenderId: string;
  programId: number;
  freightPdi: number;

  installmentPayment?: number;
  rateType?: string;
  paymentId: string;
};

export type DealRequest = {
  listingId: string;
  financing: FinancingApplicationData;
  applicant: ApplicantData;
  coApplicant?: GuestApplicantData;
  consent: boolean;
  protectionSessionId?: string;
  protectionProducts?: { productId: string; optionId?: string }[];
};

// TODO:Cleanup after experiment is finished MOVE-26228
export type GuestDealRequest = {
  listingId: string;
  financing: FinancingApplicationData;
  applicant: GuestApplicantData;
  coApplicant?: GuestApplicantData;
  consent: boolean;
};

export type DealResponse = {
  dealId: string;
  referenceId: string;
};
