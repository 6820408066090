export const HANDLE_RESPONSE_LISTING = 'page/vip/HANDLE_RESPONSE_LISTING';
export const SET_PROVISIONAL_BASE64 = 'page/vip/SET_PROVISIONAL_BASE64';
export const DISPLAY_CONTACT_FORM_MODAL = 'page/vip/DISPLAY_CONTACT_FORM_MODAL';
export const CLOSE_CONTACT_FORM_MODAL = 'page/vip/CLOSE_CONTACT_FORM_MODAL';
export const DISPLAY_REQUEST_IMAGES_MODAL =
  'page/vip/DISPLAY_REQUEST_IMAGES_MODAL';
export const CLOSE_REQUEST_IMAGES_MODAL = 'page/vip/CLOSE_REQUEST_IMAGES_MODAL';
export const SET_CLICK_SCROLL_SECTION = 'page/vip/SET_CLICK_SCROLL_SECTION';
export const RESET_CLICK_SCROLL_SECTION = 'page/vip/RESET_CLICK_SCROLL_SECTION';
export const DISPLAY_DEALER_UPDATES_MODAL =
  'page/vip/DISPLAY_DEALER_UPDATES_MODAL';
export const CLOSE_DEALER_UPDATES_MODAL = 'page/vip/CLOSE_DEALER_UPDATES_MODAL';
export const DISPLAY_DESCRIPTION_MODAL = 'page/vip/DISPLAY_DESCRIPTION_MODAL';
export const CLOSE_DESCRIPTION_MODAL = 'page/vip/CLOSE_DESCRIPTION_MODAL';
export const DISPLAY_VEHICLE_FEATURES_MODAL =
  'page/vip/DISPLAY_VEHICLE_FEATURES_MODAL';
export const CLOSE_VEHICLE_FEATURES_MODAL =
  'page/vip/CLOSE_VEHICLE_FEATURES_MODAL';
export const SET_FINANCING_DEFAULTS = 'page/vip/SET_FINANCING_DEFAULTS';
