import { mapValues, isEqual } from 'lodash';
import { Query } from '../../../stores/searchQuery';

const cleanQuery = (query: Query) =>
  mapValues(query, (item) =>
    Array.isArray(item) && item.length === 1 ? item[0] : item
  ) as Query;

const extractUserInputQueryParams = ({ psz, vc, ...rest }: Query) => ({
  ...rest,
});

const getSearchActionReturnCondition = (
  searchState: { items?: unknown[] } = {},
  currentQuery: Query = {},
  previousQuery: Query = {}
) => {
  const { items = [] } = searchState;

  const previousQueryClean = cleanQuery(previousQuery);
  const currentQueryClean = cleanQuery(currentQuery);

  const areEqualQueries = isEqual(
    extractUserInputQueryParams(previousQueryClean),
    extractUserInputQueryParams(currentQueryClean)
  );

  const areExistingListings = Array.isArray(items) && items.length >= 1;

  return Boolean(areEqualQueries && areExistingListings);
};

export default getSearchActionReturnCondition;
