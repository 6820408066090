import React, { FC, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import useDropdownMenu from 'react-accessible-dropdown-menu-hook';
import { getQuery } from '../../../stores/location';
import { getCurrentLocale } from '../../../stores/i18n';
import { BASE_URL_TEMPLATE, CATEGORIES } from '../config';
import { buildMenuItems, isTopNavigationEnabled } from '../utils';
import useTopNavigationTranslations from '../hooks/useTopNavigationTranslations';
import AppBar from './AppBar';
import TopMenu from './TopMenu';
import useTopNavigationTracking from '../hooks/useTopNavigationTracking';

type Props = {
  className?: string;
};

const LABEL_ID = 'top-navigation-label';

const TopNavigation: FC<Props> = ({ className }) => {
  const { buttonProps, itemProps, isOpen, setIsOpen } = useDropdownMenu(
    CATEGORIES.length
  );
  const query = useSelector(getQuery);
  const currentLocale = useSelector(getCurrentLocale);
  const translations = useTopNavigationTranslations();
  const { trackLogoClick, trackCategorySelected, trackView } =
    useTopNavigationTracking();
  const isNavigationEnabled = isTopNavigationEnabled(query);

  useEffect(() => {
    if (isNavigationEnabled) {
      trackView();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNavigationEnabled]);

  const items = useMemo(
    () =>
      buildMenuItems(
        CATEGORIES,
        translations,
        BASE_URL_TEMPLATE[currentLocale]
      ),
    [currentLocale, translations]
  );

  const handleClose = () => setIsOpen(false);

  const handleLogoClick = () => {
    trackLogoClick();
    setIsOpen(false);
  };

  const handleMenuItemClick = (category: string) => {
    trackCategorySelected(category);
    setIsOpen(false);
  };

  if (!isNavigationEnabled) {
    return null;
  }

  return (
    <nav className={className} aria-labelledby={LABEL_ID}>
      <AppBar buttonProps={buttonProps} labelId={LABEL_ID} />
      {isOpen && (
        <TopMenu
          isOpen={isOpen}
          itemProps={itemProps}
          items={items}
          onClose={handleClose}
          onLogoClick={handleLogoClick}
          onMenuItemClick={handleMenuItemClick}
        />
      )}
    </nav>
  );
};

export default TopNavigation;
