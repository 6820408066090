import { REQUEST_HEADER } from '../../../../../api/lib/apiClient';
import { addTransactionIDToItems } from '../services/listingItems';

export const createSRPResponseWithContent = <
  T extends { listings?: { items: I[] } },
  I
>({
  body,
  headers,
}: {
  body: T;
  headers: Headers;
}) => ({
  ...(body as Omit<T, 'listings'>),
  listings: {
    ...body?.listings,
    items: addTransactionIDToItems<{ items: I[] }, I>(
      body?.listings,
      headers?.get(REQUEST_HEADER.TRANSACTION_ID)
    ),
  },
});

export const createSRPResponseWithoutContent = <
  T extends Record<string, unknown>
>({
  body,
  headers,
}: {
  body: T;
  headers: Headers;
}) => ({
  ...body,
  items: addTransactionIDToItems(
    body,
    headers?.get(REQUEST_HEADER.TRANSACTION_ID)
  ),
});
