import { mapValues } from 'lodash';
import { ERROR_ROUTE } from '../../stores/page';
import {
  CLEAR_CHAT,
  FIRST_ADVERTISING_TRACK,
  HANDLE_RESPONSE_ERROR_MORE_SEARCH_RESULTS,
  HANDLE_RESPONSE_ERROR_SORTED_SEARCH_RESULTS,
  HANDLE_RESPONSE_HASHED_VIP,
  HANDLE_RESPONSE_MISSING_HASHED_VIP,
  HANDLE_RESPONSE_MORE_SEARCH_RESULTS,
  HANDLE_RESPONSE_SEARCH_RESULTS,
  HANDLE_RESPONSE_SORTED_SEARCH_RESULTS,
  LAZY_LOADING_FINISHED,
  LAZY_LOADING_STARTED,
  LOADING_FINISHED,
  LOADING_STARTED,
  PAGE_UNMOUNTED,
  REQUEST_MORE_SEARCH_RESULTS,
  REQUEST_SEARCH_RESULTS,
  REQUEST_SORTED_SEARCH_RESULTS,
  RESET_CONTENT,
  RESET_SORTING,
  RESTORE_CHAT,
  SELECT_ITEM,
  SET_IS_INFINITE_SCROLL,
  SET_SKIP_BY_URL_REQUEST,
  SET_SKIP_PAGE_REQUEST,
  SET_SORTING,
} from './actionTypes';
import { LAZY_LOADING_TYPE, PAGE_STATE } from './types';
import { SRP_BASE_PATHS } from '../../../shared/config';
import { addLocalePrefixToUrl } from '../../../lib/locale-utils';
import { addPageToItems } from './domain/services/listingItems';
import normalizeUrl from '../../../shared/utils/normalizeUrl';
import { State } from './types/State';
import { SRPAction } from './actionCreators';

const defaultSeoUrls = mapValues(SRP_BASE_PATHS, (path, locale) =>
  normalizeUrl(addLocalePrefixToUrl(locale, `${path}/`))
);

const defaultState: State = {
  pageCount: 0,
  pageState: PAGE_STATE.LOADING,
  items: [],
  content: {},
  url: {},
  skipByUrlRequest: false,
  skipPageRequest: false,
  lazyLoading: LAZY_LOADING_TYPE.NONE,
  isInfiniteScroll: true,
};

export default function srpReducer(
  state = defaultState,
  action: SRPAction | { type: typeof ERROR_ROUTE }
): State {
  switch (action.type) {
    case LOADING_STARTED:
      return {
        ...state,
        pageState: PAGE_STATE.LOADING,
      };

    case LOADING_FINISHED:
      return {
        ...state,
        pageState: PAGE_STATE.LOADING_SUCCESS,
        skipByUrlRequest: false,
        skipPageRequest: false,
      };

    case LAZY_LOADING_STARTED:
      return {
        ...state,
        lazyLoading: action.payload,
      };

    case LAZY_LOADING_FINISHED:
      return {
        ...state,
        lazyLoading: LAZY_LOADING_TYPE.NONE,
      };

    case PAGE_UNMOUNTED:
      return {
        ...state,
        pageState: PAGE_STATE.LOADING,
      };

    case ERROR_ROUTE:
      return {
        ...state,
        pageState: PAGE_STATE.LOADING_ERROR,
      };

    case REQUEST_SEARCH_RESULTS:
      return {
        ...state,
        items: [],
        url: {},
        selectedItem: undefined,
        related: undefined,
        firstAdvertisingTrack: undefined,
      };

    case HANDLE_RESPONSE_SEARCH_RESULTS:
      const { payload } = action;
      return {
        ...state,
        content: payload.content,
        pageCount: 1,
        nextPageToken: payload.listings?.nextPageToken,
        numResultsTotal: payload.listings?.numResultsTotal ?? 0,
        items: addPageToItems(payload.listings, 1),
        priceRange: payload.listings?.priceRange,
        url: {
          seoUrls: defaultSeoUrls,
          ...payload.url,
        },
        related: payload.related,
        queryParamsHash: payload.listings?.queryParamsHash,
      };

    case HANDLE_RESPONSE_HASHED_VIP:
      return {
        ...state,
        hashedVip: action.payload,
      };

    case HANDLE_RESPONSE_MISSING_HASHED_VIP:
      return {
        ...state,
        hashedVip: {
          ...action.payload,
          missing: true,
        },
      };

    case SET_SKIP_BY_URL_REQUEST:
      return {
        ...state,
        items: [...(state.items || [])],
        skipByUrlRequest: action.payload,
      };

    case SET_SKIP_PAGE_REQUEST:
      return {
        ...state,
        items: [...(state.items || [])],
        skipPageRequest: action.payload,
      };

    case SET_SORTING:
      return {
        ...state,
        sorting: action.sorting,
        firstAdvertisingTrack: undefined,
      };

    case RESET_SORTING:
      return {
        ...state,
        sorting: undefined,
        firstAdvertisingTrack: undefined,
      };

    case RESET_CONTENT:
      return { ...state, content: defaultState.content };

    case REQUEST_MORE_SEARCH_RESULTS:
      return {
        ...state,
        pageState: PAGE_STATE.LOADING_MORE,
      };

    case HANDLE_RESPONSE_MORE_SEARCH_RESULTS:
      return {
        ...state,
        nextPageToken: action.payload.nextPageToken,
        items: state.items.concat(
          addPageToItems(action.payload, state.pageCount + 1)
        ),
        pageCount: state.pageCount + 1,
        pageState: PAGE_STATE.LOADING_SUCCESS,
      };

    case HANDLE_RESPONSE_ERROR_MORE_SEARCH_RESULTS:
      return {
        ...state,
        pageState: PAGE_STATE.LOADING_MORE_ERROR,
      };

    case REQUEST_SORTED_SEARCH_RESULTS:
      return {
        ...state,
        sorting: action.sorting,
        selectedItem: undefined,
        pageState: PAGE_STATE.LOADING,
        firstAdvertisingTrack: undefined,
      };

    case HANDLE_RESPONSE_SORTED_SEARCH_RESULTS:
      return {
        ...state,
        nextPageToken: action.payload.nextPageToken,
        items: addPageToItems(action.payload, 1),
        pageCount: 1,
        pageState: PAGE_STATE.LOADING_SUCCESS,
        numResultsTotal: action.payload?.numResultsTotal ?? 0,
      };

    case HANDLE_RESPONSE_ERROR_SORTED_SEARCH_RESULTS:
      return {
        ...state,
        pageState: PAGE_STATE.LOADING_ERROR,
      };

    case SELECT_ITEM:
      return {
        ...state,
        selectedItem: action.item,
      };

    case FIRST_ADVERTISING_TRACK:
      return {
        ...state,
        firstAdvertisingTrack: action.firstAdvertisingTrack,
      };

    case SET_IS_INFINITE_SCROLL:
      return {
        ...state,
        isInfiniteScroll: action.isInfiniteScroll,
      };

    case RESTORE_CHAT:
      return { ...state, chatId: action.payload };

    case CLEAR_CHAT:
      // eslint-disable-next-line no-case-declarations, no-unused-vars
      const { chatId, ...rest } = state;
      return rest;

    default:
      return state;
  }
}
