import { createFiltersWithSettings } from '../../filters/factories/filterSettingFactory';
import {
  isBooleanType,
  isExtraType,
  isLocation,
  isMakeModel,
  isMultiType,
  isSingleType,
  isRangeDropdown,
  isRangeType,
  isMultiSelectionWithIconType,
} from '../../filters/specifications/filters';
import { findFilterValue } from '../../filters/specifications/filterValues';
import { splitRangeValue } from '../../filters/services/filterSetting';
import { DEFAULT_LOCATION_RADIUS } from '../../../../../shared/config';
import { IntlFormatters } from 'react-intl';
import { Filter } from '../../../../stores/referenceData';
import { Query } from '../../../../stores/searchQuery';
import { FilterSetting } from '../../filters/types/FilterSetting';

const getRangeValueMessages = (
  formatMessage: IntlFormatters['formatMessage'],
  { from, to }: { from?: string; to?: string }
) => ({
  PRICE: {
    from: formatMessage({ id: 'filterchip_price_from_value' }, { value: from }),
    to: formatMessage({ id: 'filterchip_price_to_value' }, { value: to }),
  },
  PAYMENT: {
    from: formatMessage(
      { id: 'filterchip_payment_from_value' },
      { value: from }
    ),
    to: formatMessage({ id: 'filterchip_payment_to_value' }, { value: to }),
  },
  MILEAGE: {
    from: formatMessage(
      { id: 'filterchip_mileage_from_value' },
      { value: from }
    ),
    to: formatMessage({ id: 'filterchip_mileage_to_value' }, { value: to }),
  },
  SEATS: {
    from: formatMessage({ id: 'filterchip_seats_from_value' }, { value: from }),
    to: formatMessage({ id: 'filterchip_seats_to_value' }, { value: to }),
  },
  CONSTRUCTION_YEAR: {
    from: formatMessage({ id: 'filterchip_year_from_value' }, { value: from }),
    to: formatMessage({ id: 'filterchip_year_to_value' }, { value: to }),
  },
  ENGINE_SIZE: {
    from: formatMessage(
      { id: 'filterchip_engine_from_value' },
      { value: from }
    ),
    to: formatMessage({ id: 'filterchip_engine_to_value' }, { value: to }),
  },
  POWER_IN_KW: {
    from: formatMessage({ id: 'filterchip_power_from_value' }, { value: from }),
    to: formatMessage({ id: 'filterchip_power_to_value' }, { value: to }),
  },
  CYLINDERS: {
    from: formatMessage(
      { id: 'filterchip_cylinders_from_value' },
      { value: from }
    ),
    to: formatMessage({ id: 'filterchip_cylinders_to_value' }, { value: to }),
  },
});

const multiValueTitle = ({ filter, value }: FilterSetting<string>) =>
  (Array.isArray(value) ? value : [value]).map(
    (filterValue) => findFilterValue(filter.values || [], filterValue)?.title
  );

const extraValueTitle = ({
  filter,
  value,
}: FilterSetting<Record<string, string>>) =>
  Object.values(value).reduce<(string | undefined)[]>((array, values) => {
    const valuesAsArray = Array.isArray(values) ? values : [values];
    return [
      ...array,
      ...valuesAsArray
        .map(
          (filterValue: string) =>
            findFilterValue(filter.values || [], filterValue)?.title
        )
        .filter(Boolean),
    ];
  }, []);

const rangeValueTitle = (
  { filter, value }: FilterSetting<string>,
  formatMessage: IntlFormatters['formatMessage']
) => {
  const [from, to] = splitRangeValue(value);
  const rangeValueMessages = getRangeValueMessages(formatMessage, { from, to });
  const range = rangeValueMessages[filter.name];

  return [from && range.from, to && range.to].filter(Boolean);
};

const singleValueTitle = ({ filter, value }: FilterSetting<string>) =>
  findFilterValue(filter.values || [], value)?.title || value;

const booleanValueTitle = ({ filter, value }: FilterSetting<string>) =>
  findFilterValue(filter.values || [], value)?.title;

const toSavedSearchValue =
  (formatMessage: IntlFormatters['formatMessage']) =>
  (filterSetting: FilterSetting<any>) => {
    const { filter } = filterSetting;
    let { value } = filterSetting;
    let separator = ', ';

    if (filter.tabs) {
      value = filter.tabs.flatMap((tab) =>
        tab.filters
          .flatMap((filterFromTab) => {
            const filterValue = filterSetting.value[filterFromTab.param || ''];
            if (!filterValue) {
              return;
            }
            const formatted = toSavedSearchValue(formatMessage)({
              value: filterValue,
              filter: filterFromTab,
            });
            return Array.isArray(formatted.value)
              ? formatted.value.join(formatted.separator).toLowerCase()
              : formatted.value;
          })
          .filter(Boolean)
      );
      const selectedTab = filter.tabs.find((tab) =>
        tab.filters.some((filtr) => filterSetting.value[filtr.param || ''])
      );
      return {
        value,
        filter: { ...filter, title: selectedTab?.title || filter.title },
        separator,
      };
    }
    if (isMultiType(filter) || isMultiSelectionWithIconType(filter)) {
      value = multiValueTitle(filterSetting);
    } else if (isExtraType(filter)) {
      value = extraValueTitle(filterSetting);
    } else if (isRangeType(filter) || isRangeDropdown(filter)) {
      value = rangeValueTitle(filterSetting, formatMessage);
      separator = ' ';
    } else if (isSingleType(filter)) {
      value = singleValueTitle(filterSetting);
    } else if (isBooleanType(filter)) {
      if (filter.param) {
        value = booleanValueTitle(filterSetting);
      } else {
        value = extraValueTitle(filterSetting);
      }
    }

    value = Array.isArray(value) ? value : [value];

    return { ...filterSetting, value, separator };
  };

type TranslatedFilters = {
  filterConfiguration: Filter[];
  searchQuery: Query;
  locationName?: string;
  formatMessage: IntlFormatters['formatMessage'];
};

const buildTranslatedFiltersForQuery = ({
  filterConfiguration,
  searchQuery,
  locationName,
  formatMessage,
}: TranslatedFilters) => {
  const filters = createFiltersWithSettings(filterConfiguration, searchQuery)
    .filter(({ filter }) => !isMakeModel(filter))
    .map(toSavedSearchValue(formatMessage));

  if (locationName) {
    filters.unshift({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      filter: filterConfiguration.find(isLocation)!,
      value: [
        formatMessage(
          { id: 'location_label' },
          {
            location: locationName,
            radius: searchQuery?.rd || DEFAULT_LOCATION_RADIUS,
          }
        ),
      ],
      separator: '',
    });
  }

  return filters;
};

export default buildTranslatedFiltersForQuery;
