import { selectIsPaymentSpokeFilledOut, selectPaymentType } from '../selectors';
import {
  selectFinancing,
  selectInput,
  PaymentType,
  selectIsOptionError,
  selectIsError,
  selectIsLoading,
  selectError,
} from '../../../features/financingCalculator';
import { selectHasTradeInResult } from '../../../features/tradeIn';
import { State } from '../../../stores/types/State';
import { DealRequest } from '../apiTypes';
import { Frequency } from '../../../shared/types/Frequency';

type PaymentForDeal = Omit<DealRequest['financing'], 'tradeIn' | 'type'>;

export const selectPaymentForDeal = (state: State): PaymentForDeal => {
  const paymentType = selectPaymentType(state);
  const financing = selectFinancing(paymentType)(state);

  const {
    termLoan,
    frequencyLoan,
    termLease,
    frequencyLease,
    downPayment,
    allowance,
  } = selectInput(state);

  const {
    apr,
    interestRate,
    lenderId,
    programId,
    oemId,
    freightPdi,
    totalAmountFinanced,
    totalFees,
    taxAmount,
    payment,
    paymentId,
  } = financing;

  let term: number;
  let paymentFrequency: Frequency;
  if (paymentType === PaymentType.Loan) {
    term = parseInt(termLoan);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    paymentFrequency = frequencyLoan!;
  } else {
    term = parseInt(termLease);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    paymentFrequency = frequencyLease!;
  }

  return {
    apr,
    interestRate,
    lenderId,
    programId,
    oemId,
    freightPdi,
    term,
    totalAmountFinanced,
    cashDownPayment: parseFloat(downPayment || '0'),
    isTradeInIncluded: selectHasTradeInResult(state),
    totalFees,
    taxAmount,
    installmentPayment: payment,
    paymentFrequency,
    annualKilometers:
      paymentType === PaymentType.Loan ? 0 : parseFloat(allowance),
    paymentId,
  };
};

export const selectIsPaymentSpokeReady = (state: State) => {
  const paymentType = selectPaymentType(state);
  const isOptionsError = selectIsOptionError(state);
  const isError = selectIsError(paymentType)(state);
  const isLoading = selectIsLoading(paymentType)(state);
  return (
    state.pages.drp.isPaymentSpokeFilledOut &&
    !isOptionsError &&
    !isError &&
    !isLoading
  );
};

export const selectIsBreakdownLinkShown = (state: State) => {
  const paymentType = selectPaymentType(state);
  const isSpokeFilledOut = selectIsPaymentSpokeFilledOut(state);
  const hasOptionsError = selectIsOptionError(state);
  const isPaymentLoading = selectIsLoading(paymentType)(state);
  const paymentError = selectError(paymentType)(state);
  const hasError = Boolean(hasOptionsError || paymentError);

  return !isPaymentLoading && !hasError && isSpokeFilledOut;
};
