import { shouldShowRealVip } from '../../pageSegments/hashedVip/shouldShowRealVip';
import { VIP_ROUTE_WITH_FINANCING } from '../../stores/page';
import { routes as vipRoutes } from '../vip/route';
import { routes as srpRoutes, srpThunk } from '../srp/route';

const srpOrVipThunk = async (dispatch, getState, bag) => {
  const isVip = shouldShowRealVip(bag.action.payload);

  if (isVip) {
    return vipRoutes[VIP_ROUTE_WITH_FINANCING].thunk(dispatch, getState, bag);
  }

  return srpThunk(dispatch, getState, bag);
};

/** A copy of srpRoutes with thunk replaced */
export const routes = Object.assign(
  {},
  ...Object.entries(srpRoutes).map(([action, configuration]) => ({
    [action]: {
      ...configuration,
      ...(configuration.thunk && {
        thunk: srpOrVipThunk,
      }),
    },
  }))
);
