import {
  RECEIVE_SEARCH_HIT,
  ERROR_SEARCH_HIT,
  REQUEST_SEARCH_HIT,
} from './actionTypes';
import { SearchHitCounterState as State } from './types/State';

const defaultState = { isLoading: false };

type Payload = Partial<State>;

const states = {
  [REQUEST_SEARCH_HIT]: (state: State) => ({
    ...state,
    isLoading: true,
  }),
  [RECEIVE_SEARCH_HIT]: (_state: State, payload: Payload) => ({
    isLoading: false,
    ...payload,
  }),
  [ERROR_SEARCH_HIT]: () => defaultState,
};

export default (
  state: State = defaultState,
  { type, payload }: { type: string; payload?: Payload } = { type: '' }
) => (states[type] ? states[type](state, payload) : state);
