import { PAGE_TYPES } from '../constants';

const SRP = {
  gpt: {
    leaderBoard: [
      {
        viewPortSize: [0, 0],
        sizes: [[320, 50]],
      },
      {
        viewPortSize: [808, 0],
        sizes: [[728, 90]],
      },
    ],
    mediumRectangle: [
      {
        viewPortSize: [0, 0],
        sizes: [[300, 250]],
      },
      {
        viewPortSize: [808, 0],
        sizes: [[728, 90]],
      },
    ],
    nativeAdRectangle: [
      {
        viewPortSize: [0, 0],
        sizes: ['fluid'],
      },
    ],
    lateralAd: [
      {
        viewPortSize: [1366, 0],
        sizes: [[160, 600]],
      },
      {
        viewPortSize: [1680, 0],
        sizes: [
          [300, 250],
          [300, 600],
        ],
      },
    ],
  },
  prebid: [
    {
      mediaQuery: '(max-width: 807.99px)',
      sizesSupported: [
        [320, 50],
        [300, 250],
      ],
    },
    {
      mediaQuery: '(min-width: 808px)',
      sizesSupported: [[728, 90]],
    },
  ],
};

const VIP = {
  gpt: {
    leaderBoard: [
      {
        viewPortSize: [0, 0],
        sizes: [[320, 50]],
      },
      {
        viewPortSize: [808, 0],
        sizes: [[728, 90]],
      },
    ],
    mediumRectangleOrBanner: [
      {
        viewPortSize: [0, 0],
        sizes: [
          [320, 50],
          [300, 250],
        ],
      },
      {
        viewPortSize: [808, 0],
        sizes: [[728, 90]],
      },
    ],
    mediumRectangle: [
      {
        viewPortSize: [0, 0],
        sizes: [
          [320, 50],
          [300, 50],
        ],
      },
      {
        viewPortSize: [808, 0],
        sizes: [[728, 90]],
      },
    ],
  },
  prebid: [
    {
      mediaQuery: '(max-width: 807.99px)',
      sizesSupported: [
        [320, 50],
        [300, 250],
      ],
    },
    {
      mediaQuery: '(min-width: 808px)',
      sizesSupported: [[728, 90]],
    },
  ],
};

const HOME = {
  gpt: {
    homepageTakeover: [
      {
        viewPortSize: [0, 0],
        sizes: [],
      },
      {
        viewPortSize: [1024, 0],
        sizes: [[1, 1]],
      },
    ],
    mediumRectangle: [
      {
        viewPortSize: [0, 0],
        sizes: [],
      },
      {
        viewPortSize: [1024, 0],
        sizes: [[300, 250]],
      },
    ],
    midLandscapeBanner: [
      {
        viewPortSize: [0, 0],
        sizes: [
          [300, 250],
          [300, 50],
          [320, 100],
          [320, 50],
        ],
      },
      {
        viewPortSize: [808, 0],
        sizes: [[728, 90]],
      },
    ],
  },
  prebid: [
    {
      mediaQuery: '(min-width: 1440px)',
      sizesSupported: [
        [300, 600],
        [300, 250],
      ],
    },
    {
      mediaQuery: '(max-width: 807.99px)',
      sizesSupported: [
        [300, 250],
        [320, 100],
        [320, 50],
      ],
    },
    {
      mediaQuery: '(min-width: 808px)',
      sizesSupported: [[728, 90]],
    },
  ],
};

export const SIZE_MAPPINGS = {
  [PAGE_TYPES.HOME]: HOME,
  [PAGE_TYPES.VIP]: VIP,
  [PAGE_TYPES.VIP_AFTER_MESSAGE]: VIP,
  [PAGE_TYPES.SRP_WITH_CATEGORY]: SRP,
  [PAGE_TYPES.SRP_WITH_SEARCH_RESULT]: SRP,
};
