import React, { FC } from 'react';
import cx from 'classnames';

import LoadingIndicatorIcon from '@move-ui/icons/progress/LoadingIndicator';

import styles from './LoadingState.css';

type Props = {
  className?: string;
};

export const LoadingState: FC<Props> = ({ className }) => (
  <div className={cx(styles.container, className)} data-testid="LoadingState">
    <div className={styles.loading}>
      <LoadingIndicatorIcon />
    </div>
  </div>
);
