export const PAGE_NAMES = {
  SRP: 'srp',
  LIFE_STYLE_SRP: 'lifeStyle',
  BODY_TYPE_SRP: 'bodyType',
  DEALER_PAGE: 'dealerPage',
  OTHER: 'other',
};

export const VIP_SECTIONS = {
  DESCRIPTION: 'description',
  FEATURES: 'features',
  CERTIFIED_PREOWNED: 'certified_preowned',
  PRICE_ANALYSIS: 'price_analysis',
  PAYMENT_ESTIMATOR: 'payment_estimator',
  MILEAGE_ANALYSIS: 'mileage_analysis',
  CAR_HISTORY: 'car_history',
  ABOUT_SELLER: 'about_seller',
};

export const DEALER = 'dealer';
export const GALLERY_TRACKING_LABEL = 'gallery';
