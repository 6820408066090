import { flatMap, isArray, isNaN, uniq } from 'lodash';
import { FilterValue } from '../../../../stores/referenceData';
import { Query } from '../../../../stores/searchQuery';

export const sanitizeEngineSize = ({
  filter: { values = [], selectedValues },
}: {
  filter: {
    values: Pick<FilterValue, 'value'>[];
    selectedValues: NonNullable<Query[keyof Query]>;
  };
}) => {
  const uniqValues = isArray(selectedValues)
    ? uniq(flatMap(selectedValues))
    : [selectedValues];

  if (uniqValues.length > 1) {
    return '';
  }

  const [min, max] = uniqValues?.[0]?.split(':');
  const minValue = min ? parseFloat(min) : undefined;
  const maxValue = max ? parseFloat(max) : undefined;

  if (!min && !max) {
    return '';
  }

  const defaultValues = values.map(({ value }) => value);

  if (
    min &&
    max &&
    (isNaN(minValue) || isNaN(maxValue) || (minValue || 0) > (maxValue || 0))
  ) {
    return '';
  }

  const hasMinDefined = minValue !== undefined && defaultValues.includes(min);
  const hasMaxDefined = maxValue !== undefined && defaultValues.includes(max);

  return hasMinDefined || hasMaxDefined
    ? `${minValue && hasMinDefined ? minValue.toFixed(1) : ''}:${
        maxValue && hasMaxDefined ? maxValue.toFixed(1) : ''
      }`
    : '';
};
