import { Action } from './types/Action';
import { omitEmpty } from '../../shared/utils/omitEmpty';
import { transformMakeModelToQueryString } from '../../utils/filters/makeModel';
import { selectQuery } from './selectors';
import { Dispatch } from 'redux';
import { State } from '../types/State';
import { Query } from './types/Query';

export const updatePreparatoryQuery = (query: Query) =>
  ({
    type: Action.UpdatePreparatoryQuery,
    query,
  } as const);

export const setPreparatoryQuery = (query: Query) =>
  ({
    type: Action.SetPreparatoryQuery,
    query,
  } as const);

export const applyQuery = (query: Query) =>
  ({
    type: Action.ApplyQuery,
    query: omitEmpty(query),
  } as const);

export const resetPreparatoryQuery = () =>
  ({
    type: Action.ResetPreparatoryQuery,
  } as const);

export const setMakeModel =
  (makeModelSelection: Parameters<typeof transformMakeModelToQueryString>[0]) =>
  (dispatch: Dispatch, getState: () => State) => {
    const query = {
      ...selectQuery(getState()),
      ms: transformMakeModelToQueryString(makeModelSelection),
    };
    dispatch(updatePreparatoryQuery(query));

    return query;
  };
