export const setCookie = (
  name: string,
  value: string | number | boolean,
  days: number
) => {
  if (typeof document === 'undefined') {
    return;
  }

  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);

  document.cookie = `${name}=${encodeURIComponent(
    value
  )}; expires=${date.toUTCString()}; path=/`;
};

export const getCookie = (name: string) => {
  if (typeof document === 'undefined') {
    return '';
  }

  const cookies = document.cookie.split(';');

  const result = cookies
    .map((cookie) => cookie.trim().split('='))
    .filter((cookie) => cookie[0] === name)
    .map((cookie) => decodeURIComponent(cookie[1]))[0];

  return result || '';
};

export const hasCookie = (name: string) => getCookie(name) !== '';

export const deleteCookie = (name: string) => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
};
