import { getCurrentLocale } from '../../stores/i18n';
import { addLocalePrefixToUrl } from '../../../lib/locale-utils';
import {
  getPathName,
  getMipSeoSegments,
  getPayload,
} from '../../stores/location';
import {
  MIP_ROUTE,
  showErrorPageAction,
  showNotFoundPageAction,
} from '../../stores/page';
import { MIP_BASE_PATHS } from '../../../shared/config';
import { getMipByUrl, loaded, setReferrer } from './actionCreators';
import { selectUrl } from './selectors';
import normalizeUrl from '../../../shared/utils/normalizeUrl';

// if route is not matching locale, or
// if there is no seo path
const is404 = ({ locale, basePath, seoPath }) =>
  basePath !== MIP_BASE_PATHS[locale] || !seoPath;

const mipThunk = async (dispatch, getState) => {
  const state = getState();
  const pathname = getPathName(state);
  const payload = getPayload(state);
  const seoSegments = getMipSeoSegments(state);
  const locale = getCurrentLocale(state);
  const seoPath = seoSegments ? `/${seoSegments}` : '/';
  const basePath = pathname.replace(new RegExp(`${seoPath}/?$`), '');

  if (is404({ locale, basePath, seoPath })) {
    return dispatch(showNotFoundPageAction());
  }

  dispatch(setReferrer(payload?.referrer));

  const requestUrl = normalizeUrl(
    addLocalePrefixToUrl(locale, `${basePath}${seoPath}`)
  );
  const currentStoredUrl = selectUrl(state)?.find(
    (x) => x.localeStr === locale
  )?.url;

  if (currentStoredUrl === requestUrl) {
    return dispatch(loaded());
  }

  try {
    return await getMipByUrl(requestUrl, { locale })(dispatch);
  } catch (error) {
    if (error?.statusCode === 404) {
      return dispatch(showNotFoundPageAction());
    }
    return dispatch(showErrorPageAction(error));
  }
};

const localizedRoutes = Object.keys(MIP_BASE_PATHS || {}).reduce(
  (paths, key) => ({
    ...paths,
    [`${MIP_ROUTE}_${key}`]: {
      path: normalizeUrl(`${MIP_BASE_PATHS[key]}/:mipSeoSegments*/`),
      thunk: mipThunk,
    },
  }),
  {}
);

export const routes = {
  ...localizedRoutes,
};
