import { rIC, cIC } from './idleCallback';

export type IdleValue<T = unknown> = {
  value?: T;
  init: () => T;
  idleHandle: number;
};

export function createIdleValue<T = unknown>(init: () => T): IdleValue<T> {
  const result: Pick<IdleValue<T>, 'value'> = {};
  const idleHandle = rIC(() => {
    result.value = init();
  }) as number;
  return { ...result, idleHandle, init };
}

export const cancelIdle = <T>(idleValue: IdleValue<T>) => {
  if (idleValue.idleHandle) {
    cIC(idleValue.idleHandle);
    Object.assign(idleValue, {
      idleHandle: null,
    });
  }
};

export const getUrgentValue = <T>(idleValue: IdleValue<T>) => {
  if (idleValue.value === undefined) {
    cancelIdle(idleValue);
    Object.assign(idleValue, {
      value: idleValue.init(),
    });
  }

  return idleValue.value as T;
};

export const isExecuted = <T>(idleValue: IdleValue<T>) =>
  idleValue.value !== undefined;
