import { isNaN } from 'lodash';
import { MakeModel } from '../../shared/types/MakeModel';

export const findMake = (makeId: number) => (makeModel: MakeModel) =>
  makeModel.parentId === undefined && makeModel.i === makeId;

export const findModel =
  (makeId: number, modelId: number) => (makeModel: MakeModel) =>
    makeModel.parentId === makeId && makeModel.i === modelId;

export const createMakeModelLabel = (
  makeModel: { make?: MakeModel; model?: MakeModel } | undefined,
  defaultMessage: string
) => {
  if (!makeModel) {
    return defaultMessage;
  }
  if (makeModel.make && makeModel.model) {
    return `${makeModel.make.n} ${makeModel.model.n}`;
  }
  if (makeModel.make) {
    return makeModel.make.n;
  }
  if (makeModel.model) {
    return makeModel.model.n;
  }

  return defaultMessage;
};

export const createMakeOptions = (
  makes: MakeModel[],
  hitCount: Record<string, number> | null,
  formatNumber: (number: number) => string
) =>
  makes.map(({ n, i }) => ({
    label: n,
    value: `${i}`,
    description:
      i !== undefined && hitCount?.[i] ? formatNumber(hitCount[i]) : '0',
  }));

export const createModelOptions = (
  make: MakeModel,
  models: Record<number, MakeModel[]>,
  hitCount: Record<string, number> | null,
  formatNumber: (number: number) => string
) => {
  const modelKey = make?.parentId || make?.i;
  if (modelKey === undefined) {
    return [];
  }

  return models[modelKey].map(({ n, i }) => ({
    label: n,
    value: `${modelKey};${i}`,
    description: hitCount?.[`${modelKey}:${i}`]
      ? formatNumber(hitCount[`${modelKey}:${i}`])
      : '0',
  }));
};

export const transformMakeModelToQueryString = (
  values: Partial<MakeModel>[] = []
) =>
  values
    .filter((value) => Object.keys(value).length > 0)
    .map(({ i, parentId, trm }) => {
      let ms = '';

      if (i) {
        ms = parentId ? `${parentId};${i}` : `${i}`;
      }

      return trm ? `${ms};${trm}` : ms;
    })
    .filter(Boolean);

export const createMakeModelFromQueryString = (
  queryString: string | undefined,
  makes: MakeModel[],
  models: Record<number, MakeModel[]>
) => {
  if (!queryString) {
    return null;
  }

  const [makeId, modelId] = queryString.split(';').map(Number);
  const make = makes.find(({ i }) => i === makeId);

  if (modelId && makeId && models[makeId]) {
    const model = models[makeId].find(({ i }) => i === modelId);

    if (model) {
      return {
        i: model.i,
        parentId: makeId,
        n: `${make?.n || ''} ${model.n}`,
      };
    }

    return null;
  }

  return make || null;
};

const getMakeModelTrim = ({
  makeModelQuery,
  makeModelTree,
}: {
  makeModelQuery: string[];
  makeModelTree: MakeModel[];
}) =>
  makeModelQuery
    .map((ms) => {
      const [makeIdPart, modelIdPart, trimPart] = ms.split(';');
      const makeId = Number(makeIdPart);

      if (!makeId) {
        return null;
      }

      const modelId = Number(modelIdPart);
      const findMakeModel =
        modelIdPart === '' || isNaN(modelId) ? findMake : findModel;

      return {
        ...makeModelTree.find(findMakeModel(makeId, modelId)),
        ...(trimPart ? { trm: trimPart } : {}),
      };
    })
    .filter((ms) => ms && Object.keys(ms).length > 0) as MakeModel[];

export const getMakeModelTrimTrackingCategory = ({
  makeModelQuery,
  makeModelTree,
}: {
  makeModelQuery: string[];
  makeModelTree: MakeModel[];
}) => {
  const makeModelTrims = getMakeModelTrim({
    makeModelQuery,
    makeModelTree,
  });
  const result = makeModelTrims
    .map(({ makeName, modelName, trm }) =>
      [makeName, modelName, trm].filter(Boolean).join('/')
    )
    .join(';');
  return result.length > 0 ? result : undefined;
};
