import { getEnv } from '@move-web-essentials-utils/env';
import querystring from 'querystring';
import apiClient from '../../../api/lib/apiClient';
import { MOVE_MIP_API_BASE_PATH } from '../../../shared/config';

const getBaseUrl = () => {
  if (IS_SERVER) {
    return `${getEnv(
      'move_consumer_api_gateway_url'
    )}${MOVE_MIP_API_BASE_PATH}/`;
  }
  return `${MOVE_MIP_API_BASE_PATH}/`;
};

export const fetchMipByUrl = (url, options = {}) => {
  const doRequest = apiClient({
    metricsId: 'svc_getMipData',
    ...options,
  });

  return doRequest({
    url: `${getBaseUrl()}?url=${encodeURIComponent(url)}`,
  });
};

export const fetchMipById = (
  { make, model, generation, version },
  options = {}
) => {
  const doRequest = apiClient({
    metricsId: 'svc_getMipData',
    ...options,
  });

  return doRequest({
    url: `${getBaseUrl()}?${querystring.stringify({
      makeId: make,
      modelId: model,
      generationId: generation,
      versionId: version,
    })}`,
  });
};
