import { SRP_ROUTE } from '../../../app/stores/page';
import { LocationState } from '../../../app/stores/types/LocationState';
import { State } from '../../../app/stores/types/State';

const DrSrpSeoSegmentEn = 'shop-faster';
const DrSrpSeoSegmentFr = 'magasinez-plus-vite';

const DrSrpRedirectSeoSegments = [DrSrpSeoSegmentEn, DrSrpSeoSegmentFr];

enum LocalizedDrSrpRedirectUrls {
  'en-CA' = 'https://www.kijijiautos.ca/pages/online-financing-no-longer-available/',
  'fr-CA' = 'https://www.kijijiautos.ca/fr/pages/le-financement-en-ligne-n-est-plus-disponible/',
}

// this function checks if the route is a DR SRP route
export const isDrSrpRoute = ({ payload, type }: LocationState): boolean => {
  // skip if, it is not an SRP route
  if (!type.includes(SRP_ROUTE)) return false;

  // skip if route does not contain any srpSeoSegments
  if (!payload.srpSeoSegments) return false;

  // only redirect if srpSeoSegments matches any of the DR SRP seo segments
  if (DrSrpRedirectSeoSegments.includes(payload.srpSeoSegments)) return true;

  return false;
};

export const getLocalizedDrSrpRedirect = (i18n: State['i18n']): string =>
  LocalizedDrSrpRedirectUrls[i18n.currentLocale];
