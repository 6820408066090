import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import cx from 'classnames';

import { GhostButton } from '@move-ui/button';
import { PageSection } from '@move-ui/layout';
import Text, { TextSize } from '@move-ui/text';

import styles from './OutdatedBrowserBanner.css';
import { selectIsLegacyBrowser } from '../../stores/isLegacyBrowser';

type Props = {
  className?: string;
};

export const OutdatedBrowserBanner: FC<Props> = ({ className }) => {
  const { formatMessage } = useIntl();

  const isLegacyBrowser = useSelector(selectIsLegacyBrowser);

  if (!isLegacyBrowser) {
    return null;
  }

  return (
    <PageSection className={cx(styles.bg, className)}>
      <div className={styles.component}>
        <span className={styles.text}>
          <Text size={TextSize.XXS} color="neutral-100" bold>
            {formatMessage({ id: 'disclaimer_ie_copy_1' })}
          </Text>
          <Text
            size={TextSize.XXS}
            color="neutral-100"
            className={styles.secondLine}
          >
            {formatMessage({ id: 'disclaimer_ie_copy_2' })}
          </Text>
        </span>
        <GhostButton
          href={formatMessage({ id: 'disclaimer_ie_cta_url' })}
          className={styles.button}
          rel="nofollow noindex noopener"
          target="_blank"
        >
          {formatMessage({ id: 'disclaimer_ie_cta_copy' })}
        </GhostButton>
      </div>
    </PageSection>
  );
};
