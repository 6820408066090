export type CarDetailsByVin = {
  brandId: number;
  brandName: string;
  modelId: number;
  modelName: string;
  year: number;
  versions: {
    vehicleId: number;
    name: string;
    bodyType: string;
  }[];
  vin: string;
};

export type TradeInColorOptions = {
  colorId: number;
  name: string;
  hexColor: string;
}[];

export enum CarDetailsInputMode {
  Manual = 'MANUAL',
  VIN = 'VIN',
}

export type BreakdownContext = {
  vehicleName: string;
  vehicleId: string;
  year: string;
  colorId: string;
  gradeId: string;
  mileage: string;
  province: string;
};
