import {
  SET_MIP_REFERRER,
  REQUEST_MIP,
  INITIAL_REQUEST_MIP,
  HANDLE_RESPONSE_MIP,
  HANDLE_RESPONSE_ERROR_MIP,
  UPDATE_NAVIGATION,
  MIP_LOADED,
  EXIT_MIP,
  REQUEST_VIP_LISTINGS,
  RECEIVE_VIP_LISTINGS,
  ERROR_VIP_LISTINGS,
} from './actionTypes';
import { PAGE_STATE, REQUEST_STATE } from './types';
import { PAGE_NAMES } from './constants';

const initialState = {
  pageData: {
    title: '',
    generations: [],
    content: {},
  },
  listings: {
    items: [],
    status: REQUEST_STATE.PENDING,
    numResultsTotal: 0,
    error: null,
  },
  pageState: PAGE_STATE.INITIAL_LOADING,
  referrer: PAGE_NAMES.OTHER,
};

const reducer = (state = initialState, action) => {
  const { payload, error, items, numResultsTotal } = action;
  switch (action.type) {
    case UPDATE_NAVIGATION:
      return {
        ...state,
        navigation: {
          generationId: payload.generationId,
          versionId: payload.versionId,
        },
      };
    case INITIAL_REQUEST_MIP:
      return { ...initialState, referrer: state.referrer };
    case SET_MIP_REFERRER:
      return {
        ...state,
        referrer: Object.values(PAGE_NAMES).includes(payload)
          ? payload
          : PAGE_NAMES.OTHER,
      };
    case REQUEST_MIP:
      return { ...state, pageState: PAGE_STATE.LOADING };
    case MIP_LOADED:
      return { ...state, pageState: PAGE_STATE.LOADING_SUCCESS };
    case EXIT_MIP:
      return { ...state, pageState: PAGE_STATE.EXIT_LOADING };
    case HANDLE_RESPONSE_MIP:
      return {
        ...state,
        pageData: payload,
        pageState: PAGE_STATE.LOADING_SUCCESS,
        navigation: {
          generationId: payload?.content?.generationId,
          versionId: payload?.content?.versionId,
        },
      };
    case HANDLE_RESPONSE_ERROR_MIP: {
      return {
        ...state,
        pageState: PAGE_STATE.LOADING_ERROR,
        navigation: {
          generationId: state?.pageData?.content?.generationId,
          versionId: state?.pageData?.content?.versionId,
        },
      };
    }
    case REQUEST_VIP_LISTINGS: {
      return {
        ...state,
        listings: {
          ...state.listings,
          status: REQUEST_STATE.PENDING,
          error: null,
        },
      };
    }
    case RECEIVE_VIP_LISTINGS: {
      return {
        ...state,
        listings: {
          status: REQUEST_STATE.SUCCESS,
          items,
          numResultsTotal,
          error: null,
        },
      };
    }
    case ERROR_VIP_LISTINGS: {
      return {
        ...state,
        listings: {
          status: REQUEST_STATE.ERROR,
          items: [],
          numResultsTotal: 0,
          error,
        },
      };
    }
    default:
      return state;
  }
};

export default reducer;
