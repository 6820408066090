import { getEnv } from '@move-web-essentials-utils/env';

const FEATURE_FLAG_PREFIX = 'feature_';
const FEATURE_FLAG_SUFFIX = '_enabled';
const FEATURE_VALUE_ENABLED = 'true';

const isFeatureEnabled = (featureName: string): boolean =>
  getEnv(`${FEATURE_FLAG_PREFIX}${featureName}${FEATURE_FLAG_SUFFIX}`) ===
  FEATURE_VALUE_ENABLED;

export default isFeatureEnabled;
