import {
  DIMENSION_L3_CATEGORY_NAME,
  DIMENSION_L4_CATEGORY_NAME,
  DIMENSION_TRIM_FILTER,
} from '../../app/utils/tracking/dimensions';

export const getMakeModelTrimDimensions = (
  makeModelTrims: { makeName?: string; modelName?: string; trm?: string }[]
) => ({
  [DIMENSION_L3_CATEGORY_NAME]:
    makeModelTrims
      .map(({ makeName }) => makeName)
      .filter(Boolean)
      .filter((elem, index, array) => array.indexOf(elem) === index)
      .join(';') || undefined,

  [DIMENSION_L4_CATEGORY_NAME]:
    makeModelTrims
      .map(({ modelName }) => modelName)
      .filter(Boolean)
      .filter((elem, index, array) => array.indexOf(elem) === index)
      .join(';') || undefined,

  [DIMENSION_TRIM_FILTER]:
    makeModelTrims
      .map(({ trm }) => trm)
      .filter(Boolean)
      .filter((elem, index, array) => array.indexOf(elem) === index)
      .join(';') || undefined,
});
