import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import BaseHeader, {
  defaultNavigation,
  User,
} from '@move-web-page-segments/header';
import { PageContainer } from '@move-ui/layout';

import { getCurrentLocale } from '../../stores/i18n';
import ChatPolling from '../../shared/components/ChatPolling';
import { FEATURE_DIGITAL_RETAIL } from '../../../shared/featureFlags';

import { setHasUnreadMessages } from './actionCreators';
import { SHOW_POST_AD } from './config';
import { THEME } from './constants';
import { useLabels } from './labels';
import { useNavigation } from './navigation';
import { useUser } from './user';

import styles from './Header.css';
import { useDisplay } from './display';

type HeaderProps = {
  className: string;
};

export const Header: FC<HeaderProps> = ({ className }) => {
  const dispatch = useDispatch();
  const labels = useLabels();
  const locale = useSelector(getCurrentLocale);
  const {
    isLoggedIn,
    avatar,
    username,
    hasUnreadMessages,
    hasUnreadNotifications,
  } = useUser();
  const { logoClicked, onNavigationClick } = useNavigation();
  const { theme, widthBehaviour, logoOnlyHeader } = useDisplay();

  return (
    <PageContainer
      className={cx(styles.headerSection, className, {
        [styles.themeBrand]: theme === THEME.BRAND,
      })}
      widthBehaviour={widthBehaviour}
    >
      {isLoggedIn && (
        <ChatPolling
          onNewMessage={({ unreadMessages }) => {
            dispatch(setHasUnreadMessages(unreadMessages > 0));
          }}
        />
      )}
      <BaseHeader
        classNames={{ appbar: styles.appBar }}
        homeUrl="/"
        locale={locale}
        messages={labels}
        navigation={logoOnlyHeader ? undefined : defaultNavigation}
        theme={theme}
        featureFlags={{
          show_post_ad: SHOW_POST_AD,
          digital_retail: FEATURE_DIGITAL_RETAIL,
        }}
        onLogoClick={logoClicked}
        onNavigationClick={onNavigationClick}
        currentUser={isLoggedIn ? ({ username, avatar } as User) : undefined}
        hasUnreadMessages={hasUnreadMessages}
        hasUnreadNotifications={hasUnreadNotifications}
      />
    </PageContainer>
  );
};
