import React, { FC } from 'react';
import cx from 'classnames';
import { useIntl } from 'react-intl';
import { Link, LinkSize, Target } from '@move-ui/link';
import { CloseIcon } from '@move-ui/icons-asset-catalog';
import { GhostInverseButton } from '@move-ui/button';
import Text, { TextSize } from '@move-ui/text';
import RatingStars from '@move-ui/rating';
import { APP_DOWNLOAD_BEGIN } from './SmartBanner.tracking';
import { PLAY_STORE_ID } from '../../../../shared/config';
import useTracking from '../../../utils/tracking/useTracking';

import styles from './SmartBanner.css';

const APP_RATING = 4.6;
const APP_NUMBER_OF_RATINGS = 4112;

type Props = {
  className?: string;
  close?: () => void;
};

export const AndroidSmartBanner: FC<Props> = ({ close, className }) => {
  const { formatMessage } = useIntl();
  const { trackEvent } = useTracking();

  const onClickDownlad = () => {
    trackEvent(APP_DOWNLOAD_BEGIN);
  };

  return (
    <div className={cx(styles.component, className)}>
      <GhostInverseButton
        className={styles.closeButton}
        onClick={close}
        data-testid="smartBannerClose"
        iconOnly
        icon={<CloseIcon className={cx(styles.closeButtonIcon)} />}
      />
      <img
        className={styles.appLogo}
        alt={formatMessage({ id: 'smartbanner_app_name' })}
        src="/static/ca/appicon_120.png"
        width="50"
        height="50"
      />
      <div className={styles.message}>
        <Text color="neutral-100" size={TextSize.XS}>
          {formatMessage({ id: 'smartbanner_app_name' })}
        </Text>
        <div>
          <RatingStars
            value={APP_RATING}
            iconClassName={styles.starIcon}
            activeClassName={styles.activeStar}
          />
          <Text color="neutral-200" size={TextSize.XXS}>
            {APP_RATING} ({APP_NUMBER_OF_RATINGS})
          </Text>
        </div>
        <Text color="neutral-200" size={TextSize.XXS}>
          {formatMessage({ id: 'smartbanner_free_google_play' })}
        </Text>
      </div>
      <Link
        className={styles.appStoreLink}
        onClick={onClickDownlad}
        href={`https://play.google.com/store/apps/details?id=${PLAY_STORE_ID}&referrer=utm_source%3DWebsite%26utm_medium%3DSmartBanner%26utm_campaign%3DSmartBanner`}
        label={formatMessage({ id: 'smartbanner_get_app' })}
        size={LinkSize.Size200}
        target={Target.Blank}
      />
    </div>
  );
};
