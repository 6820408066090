/**
 * @name setTargetingValues
 *
 * Given a react-advertising ad configuration, replaces placeholders (e.g. %LOGGED_IN%)
 * used by the ad configuration service with the actual values.
 */
export default (replacements, config) => {
  const replace = (value) => {
    let isAnyFail = false;
    const result = value.replace(/%[A-Z_]+%/g, (match) => {
      if (replacements[match] === undefined) {
        isAnyFail = true;
        return match;
      }
      return replacements[match];
    });

    return isAnyFail && value.match(/(%[A-Z_]+%)+/) ? undefined : result;
  };
  return {
    ...config,
    slots: config.slots.map((slot) => {
      if (!slot.targeting) {
        return slot;
      }
      return {
        ...slot,
        targeting: Object.entries(slot.targeting).reduce(
          (targeting, [key, value]) => {
            const finalValue =
              typeof value === 'string' ? replace(value) : value;

            return finalValue === undefined
              ? targeting
              : {
                  ...targeting,
                  [key]: finalValue,
                };
          },
          {}
        ),
      };
    }),
  };
};
