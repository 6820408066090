import { MakeModel } from '../../shared/types/MakeModel';
import { MakeModelTree } from './../referenceData';

export const getAutocompleteSource = (tree?: MakeModelTree): MakeModel[] => {
  if (!tree) {
    return [];
  }
  const { makes, models } = tree;

  return makes.all.reduce((acc: MakeModelTree['makes']['all'], make_) => {
    const make = { ...make_, makeName: make_.n };
    const list = [...acc, make];

    if (!models[make.i]) {
      return list;
    }
    return [
      ...list,
      ...models[make.i].map((model) => ({
        ...model,
        n: `${make.n} ${model.n}`,
        makeName: make.n,
        modelName: model.n,
        parentId: make.i,
      })),
    ];
  }, []);
};
