import { isEmpty } from 'lodash';
import logger from '@move-web-essentials-utils/logger';

import {
  createArea,
  createGeoPosition,
} from '../../shared/domains/geoLocation/entities';

import { get, remove, set } from '../../shared/domains/geoLocation/services';

import { resolvePosition } from './api';
import { selectLocation } from './selectors';
import {
  areGeoPositionsEqual,
  isGeoPositionValid,
} from '../../shared/utils/geoLocation';
import {
  HANDLE_REMOVE_AREA_ERROR,
  HANDLE_REMOVED_AREA_SUCCESS,
  HANDLE_RESTORE_AREA_ERROR,
  HANDLE_SAVE_AREA_ERROR,
  HANDLE_SAVE_AREA_SUCCESS,
  RECEIVE_RESTORED_AREA,
  REQUEST_REMOVE_AREA,
  REQUEST_RESTORE_AREA,
  REQUEST_SAVE_AREA,
} from './actionTypes';

export const requestSaveAreaAction = (area: string) =>
  ({
    type: REQUEST_SAVE_AREA,
    area,
  } as const);

export const handleSaveAreaSuccessAction = (payload: string) =>
  ({
    type: HANDLE_SAVE_AREA_SUCCESS,
    payload,
  } as const);

const handleSaveAreaErrorAction = () => ({
  type: HANDLE_SAVE_AREA_ERROR,
});

const requestRemoveAreaAction = () => ({
  type: REQUEST_REMOVE_AREA,
});

export const handleRemoveAreaSuccessAction = () =>
  ({
    type: HANDLE_REMOVED_AREA_SUCCESS,
  } as const);

const handleRemoveAreaErrorAction = () => ({
  type: HANDLE_REMOVE_AREA_ERROR,
});

const requestRestoreAreaAction = () => ({
  type: REQUEST_RESTORE_AREA,
});

export const receiveRestoredAreaAction = (payload) =>
  ({
    type: RECEIVE_RESTORED_AREA,
    area: payload,
  } as const);

const handleRestoreErrorAction = () => ({
  type: HANDLE_RESTORE_AREA_ERROR,
});

export const saveArea = (area) => (dispatch) => {
  dispatch(requestSaveAreaAction(area));

  try {
    set(area);

    dispatch(handleSaveAreaSuccessAction(area));
  } catch (ex) {
    logger.error(ex);
    dispatch(handleSaveAreaErrorAction());
  }
};

export const restoreArea = () => (dispatch) => {
  dispatch(requestRestoreAreaAction());

  try {
    const area = get();

    if (!area) {
      dispatch(handleRestoreErrorAction());
    } else {
      dispatch(receiveRestoredAreaAction(area));
    }
  } catch (ex) {
    logger.error(ex);
    dispatch(handleRestoreErrorAction());
  }
};

export const removeArea = () => (dispatch) => {
  dispatch(requestRemoveAreaAction());

  try {
    remove();

    dispatch(handleRemoveAreaSuccessAction());
  } catch (ex) {
    logger.error(ex);
    dispatch(handleRemoveAreaErrorAction());
  }
};

export const updateUserPositionFromQuery =
  (query) => async (dispatch, getState) => {
    const state = getState();
    const location = selectLocation(state);

    const { ll = '', rd, aa } = query;
    const [latitude, longitude] = ll.split(',');
    const radius = rd ? parseInt(rd, 10) : undefined;
    const isLimitedToProvince = Boolean(aa);

    const userPosition = location?.position ?? {};
    const newPosition = createGeoPosition({
      latitude,
      longitude,
    });

    const shouldUpdatePosition =
      isGeoPositionValid(newPosition) &&
      !areGeoPositionsEqual(newPosition, userPosition);

    if (shouldUpdatePosition) {
      const resolvedPosition = await resolvePosition({ latitude, longitude });
      dispatch(
        saveArea(
          createArea({
            location: resolvedPosition,
            radius,
            isLimitedToProvince,
          })
        )
      );
    }

    const shouldRemovePosition =
      !isGeoPositionValid(newPosition) && !isEmpty(userPosition);

    if (shouldRemovePosition) {
      dispatch(removeArea());
    }
  };
