import {
  REQUEST_MRP,
  HANDLE_RESPONSE_SUCCESS_MRP,
  HANDLE_RESPONSE_ERROR_MRP,
  SET_MAKE_FILTER,
  RESET_MAKE_FILTER,
} from './actionTypes';
import { PAGE_STATE } from './types';

const initialState = {
  title: '',
  mips: [],
  meta: {
    title: '',
    description: '',
  },
  pageState: PAGE_STATE.INITIAL_LOADING,
  filters: { make: '' },
};

const reducer = (state = initialState, action) => {
  const { payload, make } = action;
  switch (action.type) {
    case REQUEST_MRP: {
      return {
        ...state,
        pageState: PAGE_STATE.LOADING,
      };
    }
    case HANDLE_RESPONSE_ERROR_MRP: {
      return {
        ...state,
        pageState: PAGE_STATE.LOADING_ERROR,
      };
    }
    case HANDLE_RESPONSE_SUCCESS_MRP: {
      return {
        ...state,
        ...payload,
        pageState: PAGE_STATE.LOADING_SUCCESS,
      };
    }
    case SET_MAKE_FILTER: {
      return {
        ...state,
        filters: {
          ...state.filters,
          make,
        },
      };
    }
    case RESET_MAKE_FILTER: {
      return {
        ...state,
        filters: {
          ...state.filters,
          make: '',
        },
      };
    }
    default:
      return state;
  }
};

export default reducer;
