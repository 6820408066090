import { Action } from 'redux';
import {
  MIP_ROUTE,
  pages,
  SRP_ROUTE,
  MRP_ROUTE,
  HOME_ROUTE,
} from './constants';

const reducer = (
  page = pages[HOME_ROUTE],
  { type }: Action = { type: HOME_ROUTE }
) => {
  if (type.includes(SRP_ROUTE)) {
    return pages[SRP_ROUTE];
  }
  if (type.includes(MIP_ROUTE)) {
    return pages[MIP_ROUTE];
  }
  if (type.includes(MRP_ROUTE)) {
    return pages[MRP_ROUTE];
  }
  return pages[type] || page;
};

export default reducer;
