import { Query } from '../../../stores/searchQuery';

export enum NotificationType {
  SavedSearch = 'saved_search',
  PriceAlert = 'price_drop',
  Marketing = 'marketing',
}

export type NotificationItemType = {
  headline?: string;
  title?: string;
  body?: string;
  listingId?: string;
  vipUrl?: string;
  url?: string;
  isRead?: boolean;
  isRecent?: boolean;
  timestamp?: string;
  notificationId?: string;
  notificationType?: NotificationType;
  searchQuery?: Query;
};

export type BackendNotificationItemType = {
  read: boolean;
  timestamp: string;
  notificationId: string;
  notificationType: NotificationType;
  data: {
    listingTitle?: string;
    vipUrl?: string;
    listingId?: string;
    title?: string;
    description?: string;
    url?: string;
    searchParameterString?: string;
    locationName?: string;
    userId?: string;
    oldPrice?: string;
    newPrice?: string;
    notificationType?: NotificationType;
    notificationId?: string;
  };
};
