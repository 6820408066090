import React, { FC, useState, useEffect } from 'react';
import { isAndroidDevice, isIOSDevice } from '@move-webapp/device';
import { useSelector } from 'react-redux';

import { getCookie, setCookie } from 'cookies';
import { AndroidSmartBanner, IOSSmartBanner } from './components';
import { selectHidden } from './selectors';
import selectTrackingPageName from '../../utils/tracking/selectTrackingPageName';
import {
  FEATURE_SMART_BANNER_ANDROID,
  FEATURE_SMART_BANNER_IOS,
} from '../../../shared/featureFlags';
import { SMART_BANNER_LOADED } from './components/SmartBanner.tracking';
import useTracking from '../../utils/tracking/useTracking';

type Props = {
  className?: string;
  close?: () => void;
};

const SmartBanner: FC<Props> = (props) => {
  const SMART_BANNER_COOKIE_NAME = 'disableSmartBanner';
  const SMART_BANNER_COOKIE_VALUE = 'true';
  const [closed, setClosed] = useState(
    getCookie(SMART_BANNER_COOKIE_NAME) === SMART_BANNER_COOKIE_VALUE
  );
  const category = useSelector(selectTrackingPageName);
  const hidden = useSelector(selectHidden);
  const { trackEvent } = useTracking();

  useEffect(() => {
    if (!closed && !hidden && (isAndroidDevice() || isIOSDevice())) {
      trackEvent(SMART_BANNER_LOADED);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    setCookie(SMART_BANNER_COOKIE_NAME, SMART_BANNER_COOKIE_VALUE, 90);
    setClosed(true);
  };

  if (!closed && !hidden && isAndroidDevice() && FEATURE_SMART_BANNER_ANDROID) {
    return <AndroidSmartBanner {...props} close={handleClose} />;
  }
  if (!closed && !hidden && isIOSDevice() && FEATURE_SMART_BANNER_IOS) {
    return (
      <IOSSmartBanner {...props} category={category} close={handleClose} />
    );
  }
  return null;
};

export default SmartBanner;
