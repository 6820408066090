import { WidthBehaviour } from '@move-ui/layout';
import { Theme } from '@move-web-page-segments/header';
import { isMobileDevice, isTabletDevice } from '@move-webapp/device';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import isFeatureEnabled from '../../../lib/env/isFeatureEnabled';
import { selectCampaignTheme } from '../../pages/home/selectors';
import { selectIsDRP } from '../../stores/page';
import { THEME } from './constants';
import { usePage } from './navigation';

export const useDisplay = () => {
  const homepageThemeEnabled = isFeatureEnabled('homepage_theme');
  const campaignTheme = useSelector(selectCampaignTheme);
  const { isHome, isSearchResult, isVehicleItem } = usePage();
  const isDesktop = useMemo(() => !isMobileDevice() && !isTabletDevice(), []);
  const logoOnlyHeader = useSelector(selectIsDRP);

  const getTheme = () => {
    if (!isHome) {
      return Theme.DEFAULT;
    }
    if (homepageThemeEnabled && Object.values(THEME).includes(campaignTheme)) {
      return campaignTheme as Theme;
    }
    return Theme.BRAND;
  };

  const getWidthBehaviour = (): WidthBehaviour => {
    if (
      isHome ||
      (isSearchResult && isDesktop) ||
      (isVehicleItem && isDesktop)
    ) {
      return WidthBehaviour.FullContent;
    }
    return WidthBehaviour.LimitedContent;
  };

  return {
    theme: getTheme(),
    widthBehaviour: getWidthBehaviour(),
    logoOnlyHeader,
  };
};
