export const SAVE_LEAD_TYPES = 'feature/contactForm/SAVE_LEAD_TYPES';
export const TOGGLE_CHAT = 'feature/contactForm/TOGGLE_CHAT';
export const CLOSE_CHAT = 'feature/contactForm/CLOSE_CHAT';

export const SET_RETAIL_MODAL_VISIBILITY =
  'feature/contactForm/SET_RETAIL_MODAL_VISIBILITY';

export const SEND_MESSAGE = 'feature/contactForm/SEND_MESSAGE';
export const HANDLE_RESPONSE_SEND_MESSAGE =
  'feature/contactForm/HANDLE_RESPONSE_SEND_MESSAGE';

export const HANDLE_RESPONSE_SEND_GUEST_MESSAGE =
  'feature/contactForm/HANDLE_RESPONSE_SEND_GUEST_MESSAGE';

export const HANDLE_RESPONSE_ERROR_SEND_MESSAGE =
  'feature/contactForm/HANDLE_RESPONSE_ERROR_SEND_MESSAGE';
export const REQUEST_CONVERSATION = 'feature/contactForm/REQUEST_CONVERSATION';
export const HANDLE_RESPONSE_CONVERSATION_EMPTY =
  'feature/contactForm/HANDLE_RESPONSE_CONVERSATION_EMPTY';
export const HANDLE_RESPONSE_CONVERSATION =
  'feature/contactForm/HANDLE_RESPONSE_CONVERSATION';
export const HANDLE_RESPONSE_ERROR_CONVERSATION =
  'feature/contactForm/HANDLE_RESPONSE_ERROR_CONVERSATION';

export const CLEAR_STATUS = 'feature/contactForm/CLEAR_STATUS';

export const RETRY_SEND_MESSAGE = 'feature/contactForm/RETRY_SEND_MESSAGE';

export const SET_CONTACT_FORM_INQUIRY = 'page/vip/SET_CONTACT_FORM_INQUIRY';

export const SET_FOCUS_MESSAGE_TEXTFIELD =
  'feature/contactForm/SET_FOCUS_MESSAGE_TEXTFIELD';
export const CLEAR_FOCUS_MESSAGE_TEXTFIELD =
  'feature/contactForm/CLEAR_FOCUS_MESSAGE_TEXTFIELD';
