import * as types from './actionTypes';

export const toggleChat = () => ({
  type: types.TOGGLE_CHAT,
});

const getChatParams = ({
  messageList = [],
  conversationId,
  unreadMessagesCount,
  isFetched = false,
} = {}) => ({
  messageList,
  conversationId,
  unreadMessagesCount,
  isFetched,
});

export const getChatInfo = (conversationTable = {}, id) => {
  const conversation =
    conversationTable[id] ||
    conversationTable[
      Object.keys(conversationTable).find(
        (key) => conversationTable[key].listingId === id
      )
    ];
  return getChatParams(conversation);
};

export const getChatFeature = (state) => state.features?.contactForm ?? {};

export const selectGeneralInquiryDefaults = (state) =>
  state.features?.contactForm?.generalInquiryDefaults ?? {};

export const selectGeneralInquiryDefaultsLeadTypes = (state) =>
  state.features?.contactForm?.generalInquiryDefaults?.leadTypes ?? {};

export const selectMessageStatus = (state) =>
  state.features.contactForm.conversationTable.status;

export const getRetailModalVisibility = (state) =>
  state.features?.contactForm?.retailModalVisibility;

export const selectFocusMessageTextArea = (state) =>
  state.features.contactForm.focusMessageTextArea;
