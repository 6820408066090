import sortedIndexBy from 'lodash/sortedIndexBy';

import { ApiMessageStatus } from '../../../shared/types';

const SORTED_FIELD = 'receivedDate';

export const getMessageList = ({ messageList = [] } = {}) => messageList;

export const insertSortedMessage = (
  { messageList = [] } = {},
  latestMessage
) => {
  if (latestMessage) {
    const index = sortedIndexBy(messageList, latestMessage, SORTED_FIELD);

    return [
      ...messageList.slice(0, index),
      latestMessage,
      ...messageList.slice(index),
    ];
  }

  return messageList;
};

const getMessageIndexById = (messageList, id) =>
  messageList.findIndex((message) => message.id === id);

const spliceMessageList = (messageList, id, callback) => {
  const index = getMessageIndexById(messageList, id);

  if (index > -1) {
    const newValue = callback(messageList[index]);

    return [
      ...messageList.slice(0, index),
      ...(newValue ? [newValue] : []),
      ...messageList.slice(index + 1),
    ];
  }

  return messageList;
};

export const updatePersistedMessageId = ({ messageList = [] }, oldId, newId) =>
  spliceMessageList(messageList, oldId, (oldMessage) => ({
    ...oldMessage,
    id: newId,
    status: ApiMessageStatus.Sent,
  }));

export const updateNotSentMessage = ({ messageList = [] }, id) =>
  spliceMessageList(messageList, id, (oldMessage) => ({
    ...oldMessage,
    status: ApiMessageStatus.Error,
  }));

export const removeMessage = ({ messageList = [] }, id) =>
  spliceMessageList(messageList, id, () => false);

export const mergeMessageList = (head, tail) => {
  const mergedMessages = [...head, ...tail].reduce(
    (map, val) => map.set(val.id, { ...val, ...(map.get(val.id) || {}) }),
    new Map()
  );

  return [...mergedMessages.values()];
};

export const getMergedMessageList = (head, tail) => {
  if (tail && head) {
    return mergeMessageList(head, tail);
  }

  return head || tail;
};

export const getConsolidatedState = (state = {}, listingId, conversationId) => {
  const {
    [listingId]: byListingId = {},
    [conversationId]: byConversationId = {},
    ...rest
  } = state;

  const mergedMessageList = getMergedMessageList(
    byConversationId.messageList,
    byListingId.messageList
  );

  return {
    mergedState: {
      ...byListingId,
      ...byConversationId,
      messageList: mergedMessageList,
    },
    rest,
  };
};
