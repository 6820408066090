import { authorizedApiClient } from '../../../../api/lib/apiClient';
import { MOVE_CHAT_API_BASE_PATH } from '../../../../shared/config';

const getChatUrl = (path: string) => `${MOVE_CHAT_API_BASE_PATH}${path}`;

export const getUnreadCount = (options: any) => {
  const url = getChatUrl('/unread-counts');

  return authorizedApiClient(options)({
    method: 'GET',
    metricsId: 'chat_getUnreadCount',
    url,
  });
};
