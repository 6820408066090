import { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { loadValues, setActionPerformed, addVisitedPage } from './storage';
import { getPathName } from '../../../stores/location';
import { selectQuery } from '../../../stores/searchQuery';
import selectTrackingPageName from '../../../utils/tracking/selectTrackingPageName';
import { PAGE_STATE } from '../../../pages/srp/types';
import { getSearch } from '../../../pages/srp/selectors';
import { getCurrentLocale } from '../../../stores/i18n';
import { PAGE_TYPES_MAP } from '../../../utils/tracking/pageTypesMap';
import { setCustomDataWithDefaults } from '../../components/GetFeedback';

const MIN_NUMBER_OF_PAGES = 2;
const MINIMUM_PAGE_VISIT_TIME_MS = 75000;

const ALLOWED_PAGES_SURVEY_DISPLAY = [
  PAGE_TYPES_MAP.srp,
  PAGE_TYPES_MAP.vip,
  PAGE_TYPES_MAP.sVip,
];

const displaySurveyOnSpecificPages = (pageName: string, locale: string) => {
  if (ALLOWED_PAGES_SURVEY_DISPLAY.includes(pageName)) {
    setCustomDataWithDefaults({ locale, campaign: 'nps_performance_survey' });
    setActionPerformed();
  }
};

export const useNPSPerformanceSurvey = () => {
  const timer = useRef<NodeJS.Timeout>();
  const query = useSelector(selectQuery);
  const pathName = useSelector(getPathName);
  const { pageState } = useSelector(getSearch);
  const locale = useSelector(getCurrentLocale);
  const pageName = useSelector(selectTrackingPageName);

  const checkAndPerformAction = () => {
    const { hasActionPerformed, visitedPages, timestamp } = loadValues();

    if (hasActionPerformed || visitedPages.length <= MIN_NUMBER_OF_PAGES) {
      return;
    }
    const hasMinPageVisitCompleted =
      new Date(timestamp).getTime() + MINIMUM_PAGE_VISIT_TIME_MS <
      new Date().getTime();

    if (hasMinPageVisitCompleted) {
      displaySurveyOnSpecificPages(pageName, locale);
      return;
    }
    const timePassed = new Date().getTime() - new Date(timestamp).getTime();
    const timeRemaining = MINIMUM_PAGE_VISIT_TIME_MS - timePassed;

    if (timer.current) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(checkAndPerformAction, timeRemaining);
  };

  useEffect(() => {
    if (
      pageName !== PAGE_TYPES_MAP.srp ||
      pageState === PAGE_STATE.LOADING_SUCCESS
    ) {
      addVisitedPage(window.location.href);
      checkAndPerformAction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageName, pathName, query, pageState]);

  useEffect(() => {
    checkAndPerformAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
