import React, { ComponentClass, FC, PureComponent } from 'react';
import universal, { Options } from 'react-universal-component';
import logger from '@move-web-essentials-utils/logger';
import { EventListener } from './EventListener';

const MAX_RETRY_COUNT = 3;
const RETRY_TIMEOUT = 2000;
const REQUEST_TIMEOUT_LIMIT = 30000;

type State<T> = { RetriedComponent?: FC<T> };

function lazyComponent<T = any>(
  fetchComponent: (args: T) => Promise<{ default: FC<any> }>,
  options: Omit<Options<T, unknown, unknown>, 'loading'> & { loading?: FC } = {}
) {
  let retryCount = 0;
  const eventListener = new EventListener();

  const UniversalComponent = universal<T>(fetchComponent, {
    timeout: REQUEST_TIMEOUT_LIMIT,
    loading: () => null,
    error: () => null,
    onError: () => eventListener.dispatch('error'),
    ...options,
  });

  const retryPreload = (props: T) => {
    retryCount += 1;

    try {
      fetchComponent(props);
    } catch (error) {
      if (retryCount < MAX_RETRY_COUNT) {
        return new Promise((resolve) => {
          setTimeout(() => resolve(retryPreload(props)), RETRY_TIMEOUT);
        });
      }

      logger.error(error);
      return null;
    }
  };

  class LazyComponent extends PureComponent<T, State<T>> {
    state: State<T> = {};

    constructor(props: T) {
      super(props);

      eventListener.on('error', () => this.retry());
    }

    static async preload(props: T) {
      try {
        return await (UniversalComponent.preload(
          props
        ) as unknown as Promise<void>);
      } catch {
        return await retryPreload(props);
      }
    }

    private async retry() {
      if (retryCount >= MAX_RETRY_COUNT) {
        return;
      }

      try {
        const component = await fetchComponent(this.props);
        this.setState({
          RetriedComponent: component.default,
        });
      } catch (error) {
        if (retryCount < MAX_RETRY_COUNT) {
          retryCount += 1;
          this.retry();
          return;
        }

        logger.error(error);
      }
    }

    render() {
      const { RetriedComponent } = this.state;
      const Component = RetriedComponent || UniversalComponent;
      return <Component {...this.props} />;
    }
  }

  return LazyComponent as unknown as ComponentClass<T, State<T>> & {
    preload: (props?: T) => Promise<void>;
  };
}

/*
 * lazyComponent() is a function that returns a wrapper of UniversalComponent.
 * It implements retry operations on preload() or when the component was not
 * fetched correctly when rendered.
 * The retry operations is executed every RETRY_TIMEOUT for a maximum of
 * MAX_RETRY_COUNT. When the request is loaded, it will update the component
 * with the one that the retry was successful.
 * */
export default lazyComponent;
