import { DIMENSION_NOTIFICATION_PERMISSION } from '../../utils/tracking/dimensions';

export const PUSH_NOTIFICATIONS_PERMISSION_GRANTED = {
  eventAction: 'NotificationAllowed',
  getEventData: () => ({
    eventCategory: 'Notification',
    [DIMENSION_NOTIFICATION_PERMISSION]: '1',
  }),
};

export const PUSH_NOTIFICATIONS_PERMISSION_DENIED = {
  eventAction: 'NotificationDenied',
  getEventData: () => ({
    eventCategory: 'Notification',
    [DIMENSION_NOTIFICATION_PERMISSION]: '0',
  }),
};
