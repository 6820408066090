import {
  REQUEST_DEALER,
  HANDLE_RESPONSE_DEALER,
  REQUEST_MORE_LISTINGS,
  HANDLE_RESPONSE_MORE_LISTINGS,
  HANDLE_RESPONSE_ERROR_MORE_SEARCH_RESULTS,
} from './actionTypes';

import { State } from './types/State';
import { DealerAction } from './actionCreators';
import { PageState } from './types/PageState';

export const defaultState: State = {
  pageState: PageState.Loading,
  listings: {
    items: [],
  },
};

const reducer = (state = defaultState, action: DealerAction): State => {
  switch (action.type) {
    case REQUEST_DEALER: {
      return {
        ...state,
        pageState: PageState.Loading,
      };
    }
    case HANDLE_RESPONSE_DEALER: {
      return {
        ...state,
        pageState: PageState.LoadingSuccess,
        listings: action.payload.listings,
        dealerRating: action.payload.dealerRating,
        dealerDetails: action.payload.dealerDetails,
        mapsUrl: action.payload.mapsUrl,
      };
    }
    case REQUEST_MORE_LISTINGS: {
      return {
        ...state,
        pageState: PageState.LoadingMore,
      };
    }
    case HANDLE_RESPONSE_MORE_LISTINGS: {
      return {
        ...state,
        pageState: PageState.LoadingSuccess,
        listings: {
          ...action.payload,
          items: state.listings.items.concat(action.payload.items),
        },
      };
    }
    case HANDLE_RESPONSE_ERROR_MORE_SEARCH_RESULTS: {
      return {
        ...state,
        pageState: PageState.LoadingMoreError,
      };
    }
    default:
      return state;
  }
};

export default reducer;
