export const REQUEST_SAVED_SEARCH_LIST =
  'feature/savedSearches/REQUEST_SAVED_SEARCH_LIST';
export const HANDLE_RESPONSE_SAVED_SEARCH_LIST =
  'feature/savedSearches/HANDLE_RESPONSE_SAVED_SEARCH_LIST';
export const HANDLE_RESPONSE_ERROR_SAVED_SEARCH_LIST =
  'feature/savedSearches/HANDLE_RESPONSE_ERROR_SAVED_SEARCH_LIST';

export const REQUEST_ADD_SAVED_SEARCH =
  'feature/savedSearches/REQUEST_ADD_SAVED_SEARCH';
export const HANDLE_RESPONSE_ADD_SAVED_SEARCH =
  'feature/savedSearches/HANDLE_RESPONSE_ADD_SAVED_SEARCH';
export const HANDLE_RESPONSE_ERROR_ADD_SAVED_SEARCH =
  'feature/savedSearches/HANDLE_RESPONSE_ERROR_ADD_SAVED_SEARCH';

export const HANDLE_RESPONSE_UPDATE_SAVED_SEARCH =
  'feature/savedSearches/HANDLE_RESPONSE_UPDATE_SAVED_SEARCH';
export const HANDLE_RESPONSE_ERROR_UPDATE_SAVED_SEARCH =
  'feature/savedSearches/HANDLE_RESPONSE_ERROR_UPDATE_SAVED_SEARCH';

export const HANDLE_RESPONSE_DELETE_SAVED_SEARCH =
  'feature/savedSearches/HANDLE_RESPONSE_DELETE_SAVED_SEARCH';
export const HANDLE_RESPONSE_ERROR_DELETE_SAVED_SEARCH =
  'feature/savedSearches/HANDLE_RESPONSE_ERROR_DELETE_SAVED_SEARCH';

export const HANDLE_RESPONSE_ERROR_VISIT_SAVED_SEARCH =
  'feature/savedSearches/HANDLE_RESPONSE_ERROR_VISIT_SAVED_SEARCH';

export const CHECK_EDIT_SAVED_SEARCH =
  'feature/savedSearches/CHECK_EDIT_SAVED_SEARCH';

export const CHECK_ALL_SAVED_SEARCHES =
  'feature/savedSearches/CHECK_ALL_SAVED_SEARCHES';

export const TOGGLE_EDIT_MODE = 'feature/savedSearches/TOGGLE_EDIT_MODE';
