import qs from 'query-string';
import {
  authorizedApiClient,
  externalApiClient,
} from '../../../../api/lib/apiClient';
import {
  MOVE_CHAT_API_BASE_PATH,
  MOVE_EPS_API_BASE_PATH,
  MOVE_API_CLIENT_ID,
} from '../../../../shared/config';
import normalizeConversationResponse from '../../utils/normalizeConversationResponse';

const getChatUrl = (path) => `${MOVE_CHAT_API_BASE_PATH}${path}`;

export const postGuestMessage = (
  message,
  listingId,
  email,
  name = '',
  phone,
  leadType,
  conversationId = '-1',
  options
) => {
  const body = JSON.stringify({
    adId: listingId,
    conversationId,
    email,
    name,
    phone,
    leadType,
    message,
  });
  const url = getChatUrl('/guest-messaging');
  return externalApiClient({ ...options })({
    method: 'POST',
    metricsId: 'chat_postChatMessage',
    url,
    body,
  });
};

export const postChatMessage = (
  message,
  listingId,
  conversationId = '-1',
  name = '',
  phone = '',
  leadType = [],
  options
) => {
  const body = JSON.stringify({
    adId: listingId,
    conversationId,
    name,
    phone,
    leadType,
    message,
  });
  const url = getChatUrl('/conversations');

  return authorizedApiClient({
    ...options,
  })({
    method: 'POST',
    metricsId: 'chat_postChatMessage',
    url,
    body,
  });
};

export const getConversation = async (listingId, options) => {
  const url = getChatUrl('/conversations');
  const queryParams = `?${qs.stringify({ adId: listingId })}`;

  const response = await authorizedApiClient(options)({
    method: 'GET',
    metricsId: 'chat_getConversation',
    url: `${url}${queryParams}`,
  });
  const { conversations = [] } = response || {};

  return {
    ...response,
    conversations: conversations.map(normalizeConversationResponse),
  };
};

export const getMessages = async ({
  conversationId,
  latestMessageId,
  options,
}) => {
  const url = getChatUrl(`/conversations/${conversationId}`);
  const queryParams = latestMessageId
    ? `?${qs.stringify({ cursor: latestMessageId })}`
    : '';

  const result = await authorizedApiClient(options)({
    method: 'GET',
    metricsId: 'chat_getMessages',
    url: `${url}${queryParams}`,
  });
  return normalizeConversationResponse(result);
};

export const putConversationAsRead = (conversationId, options) => {
  const url = getChatUrl(`/conversations/${conversationId}/read-status`);
  const body = JSON.stringify({
    readStatus: 'read',
  });

  return authorizedApiClient(options)({
    method: 'PUT',
    metricsId: 'chat_markConversationAsRead',
    url,
    body,
  });
};

export const uploadImage = async ({ blob }) => {
  const formData = new FormData();
  // After 30 days of inactivity chat messages are deleted.
  // Chat conversations usually exist for 2-3 weeks on average.
  // So a lifetime of 90 days should work for most cases.
  formData.append('objectLifetimeInDays', '90');
  formData.append('image', blob);

  const options = {
    method: 'POST',
    body: formData,
    headers: {
      'x-client': MOVE_API_CLIENT_ID,
      'cache-control': 'no-cache',
      Pragma: 'no-cache',
    },
  };

  const response = await fetch(MOVE_EPS_API_BASE_PATH, options);
  if (response.ok) {
    return response.json();
  }
  throw new Error('Image upload request fail');
};
