import { getEnv } from '@move-web-essentials-utils/env';
import { appendQueryString } from '@move-web-essentials-utils/url';
import { isEmpty, omitBy } from 'lodash';
import querystring from 'querystring';
import { getListingsByParams as getListingsApi } from '../../../api/getListings';
import apiClient from '../../../api/lib/apiClient';
import { REQUEST_HEADER } from '../../../api/lib/apiClient/requestHeaders';
import { urls } from '../../../api/urls';
import getApiVersion from '../../../lib/env/getApiVersion';
import { MOVE_SRP_API_BASE_PATH } from '../../../shared/config';
import { Listing } from '../../shared/types/Listing';
import getDefaultSearchQueryParams from '../../shared/utils/getDefaultSearchQueryParams';
import { Query } from '../../stores/searchQuery';
import {
  createSRPResponseWithContent,
  createSRPResponseWithoutContent,
} from './domain/factories/createSRPResponse';
import { createVipResponse } from './domain/factories/createVipResponse';
import { State } from './types/State';

const getSrpBaseUrl = () =>
  IS_SERVER
    ? `${getEnv('move_consumer_api_gateway_url')}${MOVE_SRP_API_BASE_PATH}`
    : MOVE_SRP_API_BASE_PATH;

export const getSrpByUrl = async (
  url: string,
  options: {
    queryParams?: Record<string, any>;
    additionalParam?: string;
    locale?: string;
  } = {}
) => {
  const { additionalParam, ...restOptions } = options;
  const urlAdditionalParam = additionalParam ? `&${additionalParam}` : '';

  let requestUrl = `${getSrpBaseUrl()}/by-url?url=${encodeURIComponent(
    url
  )}${urlAdditionalParam}&${querystring.stringify(
    getDefaultSearchQueryParams()
  )}`;

  if (options.queryParams) {
    requestUrl = `${requestUrl}&${querystring.stringify(
      omitBy(options.queryParams, isEmpty)
    )}`;
  }

  const result = await apiClient({
    metricsId: 'srp_byUrl',
    version: getApiVersion(),
    resolveWithFullResponse: true,
    ...restOptions,
    headers: {
      [REQUEST_HEADER.TRACK_RECENT_SEARCH]: true,
      [REQUEST_HEADER.SEARCH_SOURCE]: 'srp',
    },
  })({
    url: requestUrl,
  });
  return createSRPResponseWithContent<
    Pick<State, 'content' | 'related' | 'url'> & {
      listings: { items: State['items'] };
    },
    State['items'][0]
  >(result);
};

export const getSrpByParams = async (
  query: Query,
  options: { locale?: string; additionalParam?: string } = {}
) => {
  const supportedQueryParams = omitBy(query, isEmpty);
  const { additionalParam, locale } = options;

  const result = await apiClient({
    metricsId: 'srp_byParams',
    version: getApiVersion(),
    resolveWithFullResponse: true,
    locale,
    headers: {
      [REQUEST_HEADER.TRACK_RECENT_SEARCH]: true,
      [REQUEST_HEADER.SEARCH_SOURCE]: 'srp',
    },
  })({
    method: 'GET',
    url: `${getSrpBaseUrl()}/by-params?${querystring.stringify(
      supportedQueryParams
    )}${additionalParam ? `&${additionalParam}` : ''}`,
  });
  return createSRPResponseWithContent(result) as Pick<
    State,
    'content' | 'related' | 'url'
  > & {
    listings?: Pick<
      State,
      | 'items'
      | 'queryParamsHash'
      | 'priceRange'
      | 'numResultsTotal'
      | 'nextPageToken'
    >;
  };
};

export const getListingsByParams = async (
  query: Query & { pageToken?: string },
  options: { locale?: string; additionalParam?: string } = {}
): Promise<{ nextPageToken?: string; items: Listing[] }> => {
  const { body, headers } = await getListingsApi(query, {
    ...options,
    resolveWithFullResponse: true,
  });
  return createSRPResponseWithoutContent({
    body: body?.listings,
    headers,
  });
};

export const getListing = async (listingId: string) => {
  const doRequest = apiClient({
    metricsId: 'svc_getListing',
    resolveWithFullResponse: true,
  });

  const result = await doRequest({
    url: appendQueryString(`${urls.listing}/${listingId}`, undefined),
  });
  return createVipResponse<Listing>(result);
};
