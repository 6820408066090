const map = {};

const set = <T = string>(key: string, value: T) => {
  if (!key) {
    // eslint-disable-next-line no-console
    console.warn(
      'you have to provide a key, when you want to put data to the store'
    );
    return;
  }
  if (typeof key !== 'string') {
    // eslint-disable-next-line no-console
    console.warn('the key should be of type string');
    return;
  }

  map[key] = value;
};

const get = <T = string>(key: string): T => map[key];

export default map;
export { get, set };
