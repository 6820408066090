import {
  HOME_ROUTE,
  VIP_ROUTE,
  DEALER_ROUTE,
  SAVED_ITEMS_ROUTE,
  pages,
  SRP_PAGE_ID,
} from '../stores/page';

const HOME = pages[HOME_ROUTE];
const VIP = pages[VIP_ROUTE];
const DEALER_PAGE = pages[DEALER_ROUTE];
const SAVED_ITEMS_PAGE = pages[SAVED_ITEMS_ROUTE];

export default {
  [HOME]: [SRP_PAGE_ID],
  [SRP_PAGE_ID]: [VIP],
  [VIP]: [SRP_PAGE_ID, DEALER_PAGE],
  [SAVED_ITEMS_PAGE]: [VIP],
};
