import {
  HOME_ROUTE,
  SRP_ROUTE,
  srpHvipRouteActionsForLocale,
  VIP_ROUTE,
} from '../../stores/page';
import { getPrevLocationType } from '../../stores/location';
import { HANDLE_LOGOUT_SUCCESS } from '../../stores/authentication';
import { getSelectedItem } from '../srp/selectors';
import {
  clearAdvertSourceDimension,
  setAdvertSourceDimension,
} from './tracking';
import { State } from '../../stores/types/State';

const handleNavigateToVip = (_: unknown, { state }: { state: State }) => {
  const prevLocationType = getPrevLocationType(state);

  // if user navigates from SRP to VIP we set the promotion type of the ad
  if (prevLocationType?.indexOf(SRP_ROUTE) === 0) {
    const selectedItem = getSelectedItem(state);

    setAdvertSourceDimension(selectedItem?.promotion);
  } else if (prevLocationType) {
    clearAdvertSourceDimension();
  }
};

const srpHvipActors = Object.assign(
  {},
  ...Object.values(srpHvipRouteActionsForLocale).map((action) => ({
    [action as string]: handleNavigateToVip,
  }))
);

export default {
  [VIP_ROUTE]: handleNavigateToVip,
  ...srpHvipActors,
  [HOME_ROUTE]: () => {
    clearAdvertSourceDimension();
  },
  [HANDLE_LOGOUT_SUCCESS]: () => {},
};
