import {
  LOADING_OPTIONS_STARTED,
  HANDLE_RESPONSE_OPTIONS,
  HANDLE_RESPONSE_OPTIONS_ERROR,
  LOADING_PAYMENT_STARTED,
  HANDLE_RESPONSE_PAYMENT,
  HANDLE_RESPONSE_PAYMENT_ERROR,
  SET_VALUE,
  RESET_STATE,
  UPDATE_ALLOWANCE_OPTIONS,
} from './actionTypes';
import { PaymentType } from './constants';
import { FinancingCalculatorState } from './storeTypes';
import { Action } from './actionCreators';
import { ActionMap } from '../../stores/types/ActionMap';

export const defaultState: FinancingCalculatorState = {
  listingId: null,
  options: {
    loading: false,
    error: false,
    data: null,
  },
  input: {
    price: '0',
    termLoan: '',
    termLease: '',
    frequencyLoan: undefined,
    frequencyLease: undefined,
    allowance: '',
    tradeIn: '',
    tradeInOwing: '',
    downPayment: '',
    vehicle: null,
    mileage: '',
    isTaxIncluded: false,
  },
  loan: {
    loading: false,
    payment: 0,
    breakdown: [],
    apr: 0,
    interestRate: 0,
    taxIncluded: true,
    term: 0,
    availableTerms: [],
    frequency: undefined,
    availableFrequencies: [],
    lenderId: '',
    incentives: [],
    dealerFees: [],
    taxAmount: 0,
    totalAmountFinanced: 0,
    totalFees: 0,
    programId: 0,
    rateType: '',
    oemId: '',
    freightPdi: 0,
    paymentId: '',
    totalProtectionProductsPrice: 0,
  },
  lease: {
    loading: false,
    payment: 0,
    breakdown: [],
    apr: 0,
    interestRate: 0,
    taxIncluded: false,
    term: 0,
    availableTerms: [],
    frequency: undefined,
    availableFrequencies: [],
    annualKilometers: 0,
    availableKilometers: [],
    lenderId: '',
    incentives: [],
    dealerFees: [],
    taxAmount: 0,
    totalAmountFinanced: 0,
    totalFees: 0,
    programId: 0,
    rateType: '',
    oemId: '',
    freightPdi: 0,
    paymentId: '',
    totalProtectionProductsPrice: 0,
  },
};

const states: ActionMap<Action, FinancingCalculatorState> = {
  [LOADING_OPTIONS_STARTED]: (state, {}) => ({
    ...state,
    options: {
      error: false,
      loading: true,
      data: null,
    },
  }),
  [HANDLE_RESPONSE_OPTIONS]: (state, { payload }) => ({
    ...state,
    options: {
      loading: false,
      error: false,
      data: payload.options,
    },
    listingId: payload.listingId,
    input: {
      ...state.input,
      ...payload.input,
    },
  }),
  [HANDLE_RESPONSE_OPTIONS_ERROR]: (state) => ({
    ...state,
    options: {
      loading: false,
      error: true,
      data: null,
    },
  }),
  [UPDATE_ALLOWANCE_OPTIONS]: (state, { payload }) => {
    const nextTerms = (state.options.data?.lease?.terms || []).map((term) =>
      term.term === payload.term
        ? {
            ...term,
            allowance: { defaultValue: payload.value, values: payload.options },
          }
        : term
    );

    return {
      ...state,
      input: {
        ...state.input,
        allowance: String(payload.value),
      },
      options: {
        ...state.options,
        data: {
          ...state.options.data,
          lease: state.options.data?.lease
            ? {
                ...state.options.data.lease,
                terms: nextTerms,
              }
            : undefined,
        },
      },
    };
  },
  [LOADING_PAYMENT_STARTED]: (state, { payload }) => ({
    ...state,
    loan:
      payload.type === PaymentType.Loan
        ? { ...state.loan, loading: true, error: undefined }
        : state.loan,
    lease:
      payload.type === PaymentType.Lease
        ? { ...state.lease, loading: true, error: undefined }
        : state.lease,
  }),
  [HANDLE_RESPONSE_PAYMENT]: (state, { payload }) => ({
    ...state,
    [payload.type]: {
      error: false,
      loading: false,
      payment: payload.payment,
      oemId: payload.oemId,
      breakdown: payload.breakdown,
      apr: payload.apr,
      totalProtectionProductsPrice: payload.totalProtectionProductsPrice,
    },
  }),
  [HANDLE_RESPONSE_PAYMENT_ERROR]: (state, { payload }) => ({
    ...state,
    [payload.type]: {
      ...state[payload.type],
      loading: false,
      error: payload.reason ?? true,
    },
  }),
  [SET_VALUE]: (state, { payload }) => ({
    ...state,
    input: { ...state.input, ...payload.field },
  }),
  [RESET_STATE]: () => ({
    ...defaultState,
  }),
};

const financingCalculatorStateReducer = (
  state: FinancingCalculatorState = defaultState,
  action?: Action
) => {
  if (!action) return state;
  // 'payload' does not necessarily exist on 'Action'
  const isDigitalRetailPage = Boolean(
    (action as any)?.payload?.isDigitalRetailPage
  );
  return states[action.type] && !isDigitalRetailPage
    ? states[action.type](state, action as any)
    : state;
};

export default financingCalculatorStateReducer;
