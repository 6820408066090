import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';

import {
  ConversationCallback,
  registerConversationPollingCallback,
  registerErrorCallback,
  registerUnreadPollingCallback,
  setPollingLocale,
  UnreadCountCallback,
  unregisterPollingCallback,
} from './pollingTicker';
import { getCurrentLocale } from '../../../stores/i18n';
import { pushSnackbarMessage } from '../../../stores/snackbars';

type Props = {
  onConversation?: ConversationCallback;
  onNewMessage?: UnreadCountCallback;
};

const ChatPolling: FC<Props> = ({ onConversation, onNewMessage }) => {
  const [uid] = useState(Math.random());
  const { formatMessage } = useIntl();
  const locale = useSelector(getCurrentLocale);
  const dispatch = useDispatch();

  useEffect(() => {
    setPollingLocale(locale);
    return () => {
      unregisterPollingCallback(uid);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (onConversation) {
      registerConversationPollingCallback(uid, onConversation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onConversation]);

  useEffect(() => {
    if (onNewMessage) {
      registerUnreadPollingCallback(uid, onNewMessage);
      const error = formatMessage({
        id: 'error_message_internet_connection_lost_text',
      });
      registerErrorCallback(() => dispatch(pushSnackbarMessage(error)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onNewMessage]);

  return null;
};

export default ChatPolling;
