import apiClient from '../../api/lib/apiClient';
import { Coordinates } from '../../app/shared/types/Coordinates';
import { MOVE_GEOCODE_API_BASE_PATH } from '../../shared/config';
import { Address, ApiResponse, PlaceType } from './geoLocationTypes';

// Place types, see https://developers.google.com/places/web-service/supported_types
const PLACES_TYPE_COUNTRY: PlaceType = 'country';

export const isGeolocationAvailable = () =>
  typeof window !== 'undefined' && 'geolocation' in navigator;

const getCurrentPosition = (): Promise<GeolocationPosition> =>
  new Promise((resolve, reject) => {
    if (isGeolocationAvailable()) {
      navigator.geolocation.getCurrentPosition((position) => {
        resolve(position);
      }, reject);
    } else {
      reject(Error('NotSupported'));
    }
  });

const getBestMatch = ({ results }: ApiResponse) => results[0]; // take the first entry because we assume that this is the best match

const getFormattedAddress = (address?: Address) => address?.formatted_address;

export const getPlaceTypes = (address?: Address) => address?.types || [];

export const findAddressProperty = (
  address: Address | undefined,
  propertyName: PlaceType
) => {
  const addressComponents = address?.address_components || [];
  return addressComponents.find((addressComponent) =>
    (addressComponent?.types || []).includes(propertyName)
  );
};

export const getCity = (address?: Address) => {
  const city = findAddressProperty(address, 'locality');
  return city?.short_name;
};

export const getPostalCode = (address?: Address) => {
  const postalCode = findAddressProperty(address, 'postal_code');
  return postalCode?.short_name;
};

const getLongitude = (address?: Address) => address?.geometry?.location.lng;

const getLatitude = (address?: Address) => address?.geometry?.location.lat;

export const getProvince = (address?: Address) => {
  const province = findAddressProperty(address, 'administrative_area_level_1');
  return province?.short_name;
};

/**
 * parse data returned by places api
 * @param data    the data from places api
 * @returns {*}   the address components
 */
const parseData = (data: ApiResponse) => {
  const bestMatch = getBestMatch(data);
  const types = getPlaceTypes(bestMatch);
  const address = getFormattedAddress(bestMatch);
  const city = getCity(bestMatch);
  const postalCode = getPostalCode(bestMatch);
  const latitude = getLatitude(bestMatch);
  const longitude = getLongitude(bestMatch);
  const province = getProvince(bestMatch);

  // country not allowed
  if (types.includes(PLACES_TYPE_COUNTRY)) {
    throw new Error('Could not parse country to address data');
  }

  return { address, city, postalCode, latitude, longitude, province };
};

// exported for unit test
export const resolveCurrentPosition =
  ({ signal }: { signal?: AbortSignal } = {}) =>
  async () => {
    try {
      const {
        coords: { latitude, longitude },
      } = await getCurrentPosition();
      try {
        const data = await apiClient({ signal })({
          method: 'GET',
          metricsId: 'geocode',
          url: `${MOVE_GEOCODE_API_BASE_PATH}?latlng=${latitude},${longitude}`,
        });

        return {
          ...parseData(data),
          latitude,
          longitude,
          payload: getBestMatch(data),
        };
      } catch {
        throw new Error('Could not resolve position to address');
      }
    } catch {
      const errorMessage = 'User location access denied';
      throw new Error(errorMessage);
    }
  };

// exported for unit test
export const resolvePosition =
  () =>
  async ({ latitude, longitude }: Coordinates) => {
    try {
      const data: ApiResponse = await apiClient()({
        method: 'GET',
        metricsId: 'geocode',
        url: `${MOVE_GEOCODE_API_BASE_PATH}?latlng=${latitude},${longitude}`,
      });
      return {
        ...parseData(data),
        latitude,
        longitude,
        payload: getBestMatch(data),
      };
    } catch {
      throw new Error('Could not resolve position to address');
    }
  };

// exported for unit test
export const resolveUserInput =
  ({ country }: { country: string }) =>
  async (userInput: string) => {
    try {
      const data = await apiClient()({
        method: 'GET',
        metricsId: 'geocode',
        url: `${MOVE_GEOCODE_API_BASE_PATH}?components=country:${country}&address=${encodeURIComponent(
          userInput
        )}`,
      });
      return {
        userInput,
        ...parseData(data),
        payload: getBestMatch(data),
      };
    } catch {
      throw new Error('Could not resolve user input to address');
    }
  };

export default ({ country = 'CA' }) => ({
  resolveCurrentPosition,
  resolveUserInput: resolveUserInput({ country }),
  resolvePosition: resolvePosition(),
});
