import useTracking from '../../../utils/tracking/useTracking';

type TrackingOptions = { nonInteraction: boolean };

const useLinkItemTracking = (
  action: string,
  label?: string,
  options: TrackingOptions = { nonInteraction: false }
): (() => void) => {
  const { trackEvent } = useTracking();

  return () =>
    trackEvent({
      eventAction: action,
      getEventData: () => ({ ...(label && { eventLabel: label }), ...options }),
    });
};

const useFooterTracking = () => ({
  trackMrpClick: useLinkItemTracking('FooterClick', 'MRP'),
  trackCpoClick: useLinkItemTracking('FooterClick', 'CPO'),
  trackCppClick: useLinkItemTracking('FooterClick', 'CPP'),
  trackHorizontalClick: useLinkItemTracking(
    'HorizontalClick',
    'Explore footer'
  ),
  trackA11yClick: useLinkItemTracking('FooterClick', 'a11y'),
});

export default useFooterTracking;
