import { MakeModelTrim } from '../../../../shared/types/MakeModelTrim';
import { Query } from '../../../../stores/searchQuery';

const isValidTrim = (
  makeModels: MakeModelTrim[],
  { make, model, trim }: { make?: string; model?: string; trim?: string }
) =>
  make &&
  model &&
  trim &&
  makeModels.some(
    (makeModel) =>
      makeModel.parentId === Number(make) &&
      makeModel.i === Number(model) &&
      makeModel.trims?.includes(trim)
  );

export const sanitizeMakeModelTrim = ({
  optionsQueryParam,
  preparatoryQuery,
  makeModelTree,
}: {
  optionsQueryParam: Query;
  preparatoryQuery: Query;
  makeModelTree: MakeModelTrim[];
}) => {
  const { ms: msFromPreparatoryQuery } = preparatoryQuery;
  const { ms = [] } = optionsQueryParam;

  const makeModels = ms.flatMap((value) => {
    let makeModel = value;

    const [localMake, localModel, localTrim] = value?.split(';') || [];
    const trim = localMake && localModel ? localTrim : undefined;

    if (localMake && localModel && localTrim) {
      if (
        !isValidTrim(makeModelTree, {
          make: localMake,
          model: localModel,
          trim,
        })
      ) {
        // Trim is invalid either on ms param or trm param. In those cases, we ignore trim filter and set ms to have only make/model
        makeModel = `${localMake};${localModel}`;
      }
    }

    // both query ms and url ms should match. If they don't, user changed it manually and we should consider the url ms top priority
    return ms &&
      msFromPreparatoryQuery &&
      !msFromPreparatoryQuery.includes(`${localMake};${localModel}`)
      ? msFromPreparatoryQuery
      : makeModel;
  });

  return makeModels;
};
