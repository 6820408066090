import { createSelector } from 'reselect';
import { getLocationText } from './utils/getLocationText';
import { State } from '../types/State';

const selectLocationState = (state: Pick<State, 'geoLocation'>) =>
  state.geoLocation;

export const selectArea = createSelector(
  selectLocationState,
  (state) => state.area
);

export const selectLocation = createSelector(
  selectArea,
  (state) => state?.location
);

export const selectLocationText = createSelector(
  selectLocation,
  getLocationText
);

export const selectCity = createSelector(
  selectLocation,
  (state) => state?.city
);

export const selectProvinceCode = createSelector(
  selectLocation,
  (state) => state?.province?.code
);

export const selectPostalCode = createSelector(
  selectLocation,
  (state) => state?.postalCode
);

export const selectRadius = createSelector(
  selectArea,
  (state) => state?.radius
);

export const selectPosition = createSelector(
  selectLocation,
  (state) => state?.position
);
