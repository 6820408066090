export enum SellerType {
  Dealer = 'dealer',
  Private = 'private',
}

export const CONTACT_TYPE = {
  DEALER: 'DEALER',
  PRIVATE: 'FSBO',
};

export const SELLER_TYPES = {
  DEALER: 'PV_DEALER',
  FSBO: 'PV_FSBO',
};

export const sellerTypeTrackingMap = {
  DEALER: 'PV_DEALER',
  FSBO: 'FSBO',
};

export const FILTER_SELLER_TYPE = {
  DEALER: 'dealer',
  FSBO: 'fsbo',
};
