import { IntlFormatters } from 'react-intl';
import {
  selectMakeModelForQueryString,
  selectMakeModelTree,
  Filter,
} from '../../stores/referenceData';
import buildTranslatedFiltersForQuery from '../../shared/domains/savedSearches/services/buildTranslatedFiltersForQuery';
import { getQueryAsObject } from '../../../shared/utils/getQueryAsObject';

import {
  BackendNotificationItemType,
  NotificationType,
  NotificationItemType,
} from './types/NotificationItem';

const createTitle = ({ makeModelTree, searchQuery: { ms }, formatMessage }) => {
  const makeModels = selectMakeModelForQueryString(makeModelTree, ms);
  return makeModels?.length > 0
    ? makeModels
        .map((makeModel) => makeModel?.n)
        .filter(Boolean)
        .join(', ')
    : formatMessage({ id: 'all_makes' });
};

const createBody = ({
  filterConfiguration,
  searchQuery,
  locationName,
  formatMessage,
}) => {
  const filters = buildTranslatedFiltersForQuery({
    filterConfiguration,
    searchQuery,
    locationName,
    formatMessage,
  });

  return filters
    .reduce<string[]>((acc, { value, separator }) => {
      acc.push((value as string[]).join(separator));
      return acc;
    }, [])
    .join(', ');
};

type FilterConfig = Pick<IntlFormatters, 'formatMessage'> & {
  makeModelTree: ReturnType<typeof selectMakeModelTree>;
  filterConfiguration: Filter[];
};

const saveSearchNotificationPresenter = (
  notification: BackendNotificationItemType,
  { makeModelTree, filterConfiguration, formatMessage }: FilterConfig
): Partial<NotificationItemType> => {
  const searchQuery = getQueryAsObject(
    notification.data?.searchParameterString || ''
  );
  const { locationName } = notification.data;

  return {
    headline: formatMessage({ id: 'notification_saved_search_title' }),
    title: createTitle({ makeModelTree, searchQuery, formatMessage }),
    body: createBody({
      filterConfiguration,
      searchQuery,
      locationName,
      formatMessage,
    }),
    searchQuery,
  };
};

const priceDropNotificationPresenter = (
  notification: BackendNotificationItemType,
  formatMessage: IntlFormatters['formatMessage']
) => {
  const { listingTitle, vipUrl, listingId } = notification?.data;
  return {
    headline: formatMessage({ id: 'push_price_alert_title' }),
    title: listingTitle,
    listingId,
    vipUrl,
  };
};

const marketingNotificationPresenter = (
  notification: BackendNotificationItemType
) => {
  const { title, description, url } = notification?.data;
  return {
    headline: `📉 ${title}`,
    title: description,
    url,
  };
};

export const getPresenterForNotificationType = (
  notification: BackendNotificationItemType,
  { makeModelTree, filterConfiguration, formatMessage }: FilterConfig
) => {
  switch (notification.notificationType) {
    case NotificationType.SavedSearch:
      return saveSearchNotificationPresenter(notification, {
        makeModelTree,
        filterConfiguration,
        formatMessage,
      });
    case NotificationType.PriceAlert:
      return priceDropNotificationPresenter(notification, formatMessage);
    case NotificationType.Marketing:
      return marketingNotificationPresenter(notification);
    default:
      return () => ({});
  }
};

export const defaultNotificationPresenter = (
  notification: BackendNotificationItemType
): Partial<NotificationItemType> => ({
  isRead: notification.read,
  timestamp: notification.timestamp,
  notificationId: notification.notificationId,
  notificationType: notification.notificationType,
});

const ONE_DAY_MILLISECONDS = 24 * 60 * 60 * 1000;

export const isRecentNotification = (
  notification: Partial<NotificationItemType>
) => {
  const twentyFourHoursAgo = new Date(
    new Date().getTime() - ONE_DAY_MILLISECONDS
  );

  return (
    !!notification.timestamp &&
    new Date(notification.timestamp) > twentyFourHoursAgo
  );
};
