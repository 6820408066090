import { handleSessionStorage } from '../../../../../shared/utils/handleSessionStorage';

/**
 * Returns url params that need to be appended to all SRP requests
 */
export const getForwardedUrlParams = (queryString: string) => {
  if (typeof window === 'undefined') {
    return queryString;
  }
  const urlParams = handleSessionStorage.get('urlParams')?.split('&') || [];
  const combinedParams = [
    ...new Set([...urlParams, ...queryString.split('&')].filter(Boolean)),
  ].join('&');

  handleSessionStorage.set('urlParams', combinedParams);
  return combinedParams;
};
