import { createSelector } from 'reselect';
import {
  selectMakeModelTree,
  selectFilterConfiguration,
} from '../../stores/referenceData';
import {
  NotificationType,
  NotificationItemType,
} from './types/NotificationItem';
import {
  defaultNotificationPresenter,
  getPresenterForNotificationType,
  isRecentNotification,
} from './utils';
import { State as GlobalState } from '../../stores/types/State';

const selectNotifications = ({ features }: Pick<GlobalState, 'features'>) =>
  features?.notifications || {};

export const selectIsLoading = createSelector(
  selectNotifications,
  (state) => state.isLoading
);

export const selectNewCount = createSelector(
  selectNotifications,
  (state) => state.newCount
);

export const selectHasUnreadNotifications = (
  state: Pick<GlobalState, 'features'>
) => Boolean(selectNewCount(state));

const getIntlFromProps = (_, props) => props.intl;

export const selectNotificationList = createSelector(
  [
    selectNotifications,
    selectMakeModelTree,
    selectFilterConfiguration,
    getIntlFromProps,
  ],
  ({ items }, makeModelTree, filterConfiguration, intl) => {
    if (!items.length) {
      return [];
    }

    return (
      items
        // remove unsupported notification types
        .filter(({ notificationType }) =>
          Object.values(NotificationType).includes(notificationType)
        )
        .map<Partial<NotificationItemType>>((notification) => ({
          ...defaultNotificationPresenter(notification),
          ...getPresenterForNotificationType(notification, {
            makeModelTree,
            filterConfiguration,
            formatMessage: intl.formatMessage,
          }),
        }))
        .map((notification) => ({
          ...notification,
          isRecent: isRecentNotification(notification),
        }))
    );
  }
);
