import initGeoLocation from '../../../lib/geoLocation';

import apiClient from '../../../api/lib/apiClient';
import {
  DEFAULT_COUNTRY,
  MOVE_PLACE_SUGGESTIONS,
} from '../../../shared/config';

import { createLocationFromGooglePlaces } from '../../shared/domains/geoLocation/factories/locationFactory';

const geoLocationService = () => initGeoLocation({ country: DEFAULT_COUNTRY });

export const resolveCurrentPosition = async ({
  signal,
}: {
  signal?: AbortSignal;
}) => {
  try {
    const { payload } = await geoLocationService().resolveCurrentPosition({
      signal,
    })();
    return createLocationFromGooglePlaces(payload);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn('[geolocation] locateMe error:', error);
    return undefined;
  }
};

export const resolvePosition = async ({ longitude, latitude }) => {
  const { payload } = await geoLocationService().resolvePosition({
    longitude,
    latitude,
  });
  return createLocationFromGooglePlaces(payload);
};

export const resolveLocationSuggestions = ({ signal, address, sessionToken }) =>
  apiClient({ metricsId: 'svc_placeSuggestions', signal })({
    url: `${MOVE_PLACE_SUGGESTIONS}?type=suggestions&components=country:ca&input=${address}&sessionToken=${sessionToken}`,
    headers: {
      'accept-version': 'v2',
    },
  });

export const resolveSuggestionDetails = ({ placeId, sessionToken }) =>
  apiClient({ metricsId: 'svc_placeSuggestions_details' })({
    url: `${MOVE_PLACE_SUGGESTIONS}?type=details&placeId=${placeId}&sessionToken=${sessionToken}`,
    headers: {
      'accept-version': 'v2',
    },
  });
