type NonFalsy<T> = Exclude<T, Exclude<Falsy, 0>>;

type NonFalsyValues<T> = {
  [K in keyof T as NonFalsy<T[K]> extends never ? never : K]: T[K];
};

/**
 * Omits falsy values from an object except for 0
 */
export const omitFalsyValues = <T extends Record<string, any>>(object: T) =>
  Object.fromEntries(
    Object.entries(object).filter(([, param]) =>
      Array.isArray(param) ? param.length > 0 : param || param === 0
    )
  ) as NonFalsyValues<T>;
