import * as api from '../../../shared/api/message';
import * as types from '../actionTypes';
import { getImageUrl } from '@move-web-essentials-utils/images';

const IMAGE_PREFIX = 'Here is a picture: ';

export const pipeApiCall = (...calls) => {
  const firstAPICall = calls.shift();
  return calls.reduce((prev, curr) => prev.then(curr), firstAPICall());
};

export const postMessageApiCall = async ({
  message,
  listingId,
  conversationId,
  options,
  dispatch,
  tempUid,
  imgUrl,
  sendMessageEventLabel,
  email,
  name,
  phone,
  leadType,
}) => {
  const builtMessage = imgUrl ? `${IMAGE_PREFIX}${imgUrl}` : message;
  const postMessage = () => {
    if (email) {
      return api.postGuestMessage(
        builtMessage,
        listingId,
        email,
        name,
        phone,
        leadType,
        options
      );
    }
    return api.postChatMessage(
      builtMessage,
      listingId,
      conversationId,
      name,
      phone,
      leadType,
      options
    );
  };
  const { lastMessageCreatedId: id, conversation = {} } = await postMessage();

  dispatch({
    type: email
      ? types.HANDLE_RESPONSE_SEND_GUEST_MESSAGE
      : types.HANDLE_RESPONSE_SEND_MESSAGE,
    payload: {
      id,
      tempUid,
      conversationId: conversation.id,
      listingId,
      participants: conversation.participants,
      sendMessageEventLabel,
    },
  });
};

export const postImageApiCall = async (image) => {
  const { pictureBaseUrl, baseUrl } = await api.uploadImage(image);
  // pictureBaseUrl is deprecated and can be removed once we switch to YAMS
  return getImageUrl(pictureBaseUrl || baseUrl, { size: 200 });
};

const getImageBlob = (image) => {
  const [metadata, imageData] = image.split(';base64,');
  const type = metadata.split(':').pop();
  /*
   * Workaround for Getting bytes
   * from base64 of a binary format in JS
   * since atob has some issues when converting
   * to non string formats
   */
  const raw = atob(imageData);
  const rawLength = raw.length;
  const arrayBuffer = new Uint8Array(new ArrayBuffer(rawLength));

  // fixing atob issue for binary base64 formats
  for (let i = 0; i < rawLength; i += 1) {
    arrayBuffer[i] = raw.charCodeAt(i);
  }

  return new Blob([arrayBuffer], { type });
};

export const buildNewImage = (image) =>
  !image || {
    src: image,
    blob: getImageBlob(image),
  };
