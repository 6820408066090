/**
 * Universal API client.
 *
 * The `apiClient` import will resolve to `nodeApiClient` on the server and `browserApiClient`
 * in the browser. This is done via alias in webpack config.
 *
 */
import apiClient from 'apiClient';

import RequestHeaders from './requestHeaders';

import {
  getClientId,
  getClientIp,
  getClientLocale,
} from '../../../lib/userSessionTracking/userSessionService';

export type ApiConfigProps = {
  url?: string;
  metricsId?: string;
  method?: string;
  resolveWithFullResponse?: boolean;
  authorizationRequired?: boolean;
  externalRequest?: boolean;
  headers?: Record<string, string | string[] | boolean | number | undefined>;
  signal?: AbortSignal;
  version?: number | string;
  token?: string;
  locale?: string;
  local?: string;
  body?: string;
};

/**
 *
 * @param {*} options
 * @param {*} isUserInteraction - If it's set to true then the information like locale, clientId and clientIp are coming out of the user session service which is initialized by every user request. If it's false, the user session service will not be used at all.
 */
const universalApiClient = (
  options: ApiConfigProps = {},
  isUserInteraction = true
) => {
  const doRequest = apiClient({ ...options });

  return (requestArgs?: ApiConfigProps) => {
    const {
      headers: optionHeaders,
      token,
      version,
      locale,
      ...restOptions
    } = options;
    const { headers: requestHeaders, ...restRequestArgs } = requestArgs || {};

    // In some cases the locale explicitly overrides the default behaviour, for example odin tracking
    const clientLocale =
      locale || (isUserInteraction ? getClientLocale() : locale);
    const clientId = isUserInteraction ? getClientId() : undefined;
    const clientIp = isUserInteraction ? getClientIp() : undefined;

    const requestOptions = {
      ...restOptions,
      ...restRequestArgs,
    };

    if (!requestOptions.url) {
      return Promise.reject(new TypeError('ApiClient needs url as option.'));
    }

    const headers = new RequestHeaders(optionHeaders, {
      authToken: token,
      clientId,
      clientIP: clientIp,
      clientLocale,
      version,
    });
    headers.mergeHeaders(requestHeaders);

    return doRequest({
      headers: headers.toJSON(),
      ...requestOptions,
    });
  };
};

export default universalApiClient;

export const authorizedApiClient = (options?: ApiConfigProps) =>
  universalApiClient({ ...options, authorizationRequired: true });

export const externalApiClient = (
  options: ApiConfigProps,
  isUserInteraction?: boolean
) =>
  universalApiClient({ ...options, externalRequest: true }, isUserInteraction);
