import { combineReducers } from 'redux';
import * as types from './actionTypes';
import {
  insertSortedMessage,
  updatePersistedMessageId,
  updateNotSentMessage,
  removeMessage,
  getConsolidatedState,
  getMessageList,
  mergeMessageList,
} from './utils';
import { MessageStatus } from '../../shared/types';

const chatReducerTable = {
  [types.SEND_MESSAGE]: (state, { message, listingId, conversationId }) => {
    const { mergedState, rest } = getConsolidatedState(
      state,
      listingId,
      conversationId
    );
    return {
      ...rest,
      status: '',
      [conversationId || listingId]: {
        ...mergedState,
        listingId,
        messageList: insertSortedMessage(mergedState, message),
      },
    };
  },

  [types.HANDLE_RESPONSE_SEND_MESSAGE]: (
    state,
    { id, conversationId, tempUid, listingId }
  ) => {
    const { mergedState, rest } = getConsolidatedState(
      state,
      listingId,
      conversationId
    );

    return {
      ...rest,
      status: MessageStatus.Success,
      [conversationId]: {
        ...mergedState,
        listingId,
        conversationId,
        messageList: updatePersistedMessageId(mergedState, tempUid, id),
      },
    };
  },

  [types.HANDLE_RESPONSE_SEND_GUEST_MESSAGE]: (
    state,
    { conversationId, listingId }
  ) => {
    const { rest } = getConsolidatedState(state, listingId, conversationId);

    return {
      ...rest,
      status: MessageStatus.Success,
    };
  },

  [types.CLEAR_STATUS]: (state) => ({
    ...state,
    status: '',
  }),

  [types.HANDLE_RESPONSE_ERROR_SEND_MESSAGE]: (
    state,
    { tempUid, listingId, conversationId }
  ) => {
    const { mergedState, rest } = getConsolidatedState(
      state,
      listingId,
      conversationId
    );

    return {
      ...rest,
      status: MessageStatus.Failed,
      [conversationId || listingId]: {
        ...mergedState,
        listingId,
        messageList: updateNotSentMessage(mergedState, tempUid),
      },
    };
  },

  [types.RETRY_SEND_MESSAGE]: (
    state,
    { messageId, conversationId, listingId }
  ) => {
    const { mergedState, rest } = getConsolidatedState(
      state,
      listingId,
      conversationId
    );

    return {
      ...rest,
      [conversationId || listingId]: {
        ...mergedState,
        listingId,
        messageList: removeMessage(mergedState, messageId),
      },
    };
  },

  [types.HANDLE_RESPONSE_CONVERSATION]: (
    state,
    { conversationId, messageList, listingId }
  ) => {
    const { mergedState, rest } = getConsolidatedState(
      state,
      listingId,
      conversationId
    );

    return {
      ...rest,
      [conversationId]: {
        conversationId,
        listingId,
        messageList: mergeMessageList(messageList, getMessageList(mergedState)),
        isFetched: true,
      },
    };
  },

  [types.HANDLE_RESPONSE_CONVERSATION_EMPTY]: (state, { listingId }) => ({
    ...state,
    [listingId]: {
      listingId,
      messageList: [],
      isFetched: true,
    },
  }),

  [types.HANDLE_RESPONSE_ERROR_CONVERSATION]: (state, { listingId }) => ({
    ...state,
    [listingId]: {
      listingId,
      messageList: state?.[listingId]?.messageList ?? [],
      isFetched: true,
    },
  }),
};

const isOpenedReducerTable = {
  [types.TOGGLE_CHAT]: (state) => !state,
  [types.CLOSE_CHAT]: () => false,
};

const contactFormModalVisibility = {
  [types.SET_RETAIL_MODAL_VISIBILITY]: (state, payload) => payload,
};

const contactFormModal = {
  [types.SET_CONTACT_FORM_INQUIRY]: (state, payload) => ({
    ...state,
    ...payload,
  }),
  [types.SAVE_LEAD_TYPES]: (state, { leadTypes }) => ({
    ...state,
    leadTypes,
  }),
};

const messageStatus = {
  [types.HANDLE_RESPONSE_ERROR_SEND_MESSAGE]: (state) => ({
    ...state,
    sentSuccess: false,
  }),
  [types.RETRY_SEND_MESSAGE]: (state) => ({
    ...state,
    sentSuccess: false,
  }),
  [types.HANDLE_RESPONSE_SEND_MESSAGE]: (state) => ({
    ...state,
    sentSuccess: true,
  }),
  [types.HANDLE_RESPONSE_SEND_GUEST_MESSAGE]: (state) => ({
    ...state,
    sentSuccess: true,
  }),
};

const focusMessageTextArea = {
  [types.SET_FOCUS_MESSAGE_TEXTFIELD]: () => true,
  [types.CLEAR_FOCUS_MESSAGE_TEXTFIELD]: () => false,
};

const getReducer =
  (reducerTable, defaultState) =>
  (state = defaultState, { type, payload } = {}) =>
    reducerTable[type] ? reducerTable[type](state, payload) : state;

export default combineReducers({
  isOpened: getReducer(isOpenedReducerTable, false),
  conversationTable: getReducer(chatReducerTable, {}),
  generalInquiryDefaults: getReducer(contactFormModal, {}),
  messageStatus: getReducer(messageStatus, { sentSuccess: false }),
  retailModalVisibility: getReducer(contactFormModalVisibility, false),
  focusMessageTextArea: getReducer(focusMessageTextArea, false),
});
