import React from 'react';
import { defineMessages } from 'react-intl';

import {
  REQUEST_SAVED_ITEMS,
  REQUEST_ADD_ITEM,
  REQUEST_REMOVE_ITEM,
  HANDLE_RESPONSE_ADD_ITEM,
  HANDLE_RESPONSE_REMOVE_ITEM,
  HANDLE_RESPONSE_ERROR_ADD_ITEM,
  HANDLE_RESPONSE_ERROR_REMOVE_ITEM,
  HANDLE_RESPONSE_ERROR_SAVED_ITEMS,
  HANDLE_RESPONSE_SAVED_ITEMS,
} from './actionTypes';

import { ADD_CLICK_RELATED_AD, ADD_CLICK } from './tracking';
import { fetchSavedItems, postSavedItem, deleteSavedItem } from './api';
import { trackOptimizelyEvent, EVENT_KEYS } from '../../../lib/optimizely';
import {
  EVENT_NAME_TRACKING_SAVE_ITEMS,
  FAVORITE_SRP_EVENT_SOURCE,
} from './constants';
import { pushSnackbarTranslationKey } from '../../stores/snackbars';
import track from '../../utils/tracking/track';
import { Action, AnyAction } from 'redux';
import { State } from '../../stores/types/State';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { SellerType } from '../sellerInformation/types';
import { SavedItemsState } from './SavedItemsState';

const messages = defineMessages({
  savedSuccess: {
    id: 'saved_items_success_message_listing_saved_web',
  },
  saveError: {
    id: 'saved_item_error_message_saving_failed',
  },
  unsaveError: {
    id: 'saved_item_error_message_unsaving_failed',
  },
});

/*
 * action creators
 */

export const requestSavedItemsAction = () =>
  ({
    type: REQUEST_SAVED_ITEMS,
  } as const);

export const receiveSavedItemsAction = (payload: string[]) =>
  ({
    type: HANDLE_RESPONSE_SAVED_ITEMS,
    payload,
  } as const);

export const handleSavedItemsErrorAction = (error: SavedItemsState['error']) =>
  ({
    type: HANDLE_RESPONSE_ERROR_SAVED_ITEMS,
    error,
  } as const);

export const addItemAction = (id: string) =>
  ({
    type: REQUEST_ADD_ITEM,
    id,
  } as const);

export const handleAddItemSuccessAction = (id: string) =>
  ({
    type: HANDLE_RESPONSE_ADD_ITEM,
    id,
  } as const);

export const handleAddItemErrorAction = (
  id: string,
  error: SavedItemsState['error']
) =>
  ({
    type: HANDLE_RESPONSE_ERROR_ADD_ITEM,
    id,
    error,
  } as const);

export const removeItemAction = (id: string) =>
  ({
    type: REQUEST_REMOVE_ITEM,
    id,
  } as const);

export const handleRemoveItemSuccessAction = (id: string) =>
  ({
    type: HANDLE_RESPONSE_REMOVE_ITEM,
    id,
  } as const);

export const handleRemoveItemErrorAction = (
  id: string,
  error: SavedItemsState['error']
) =>
  ({
    type: HANDLE_RESPONSE_ERROR_REMOVE_ITEM,
    id,
    error,
  } as const);

/*
 * actions
 */

export const loadSavedItems =
  () => async (dispatch: ThunkDispatch<State, never, Action>) => {
    dispatch(requestSavedItemsAction());
    try {
      const data = await fetchSavedItems();
      dispatch(receiveSavedItemsAction(data));
    } catch (error) {
      dispatch(handleSavedItemsErrorAction(error));
    }
  };

export const addSavedItem =
  (id: string) => async (dispatch: ThunkDispatch<State, never, Action>) => {
    dispatch(addItemAction(id));

    try {
      await postSavedItem(id);
      dispatch(handleAddItemSuccessAction(id));
      dispatch(
        pushSnackbarTranslationKey(messages.savedSuccess.id, {
          br: <br />,
        })
      );
    } catch (error) {
      dispatch(handleAddItemErrorAction(id, error));
      dispatch(pushSnackbarTranslationKey(messages.saveError.id));
    }
  };

export const addSavedItemAndTrack =
  (
    id: string,
    eventName: string,
    source: string,
    sellerType: SellerType
  ): ThunkAction<void, State, never, AnyAction> =>
  async (dispatch, getState) => {
    dispatch(addItemAction(id));
    const state = getState();

    try {
      await postSavedItem(id);
      dispatch(handleAddItemSuccessAction(id));
      dispatch(pushSnackbarTranslationKey(messages.savedSuccess.id));
      if (eventName === EVENT_NAME_TRACKING_SAVE_ITEMS.relatedAd) {
        track(ADD_CLICK_RELATED_AD, state, { adId: id, sellerType });
      } else {
        track(ADD_CLICK, state, {
          adId: id,
          sellerType,
        });

        if (source === FAVORITE_SRP_EVENT_SOURCE) {
          trackOptimizelyEvent(EVENT_KEYS.WATCHLIST_ADD_AD_SRP);
        }
      }
    } catch (error) {
      dispatch(handleAddItemErrorAction(id, error));
      dispatch(pushSnackbarTranslationKey(messages.saveError.id));
    }
  };

export const removedSavedItem =
  (id: string) => async (dispatch: ThunkDispatch<State, never, Action>) => {
    dispatch(removeItemAction(id));

    try {
      await deleteSavedItem(id);
      dispatch(handleRemoveItemSuccessAction(id));
    } catch (error) {
      dispatch(handleRemoveItemErrorAction(id, error));
      dispatch(pushSnackbarTranslationKey(messages.unsaveError.id));
    }
  };

export const toggleSavedItem = (id: string, isSaved: boolean) =>
  isSaved ? removedSavedItem(id) : addSavedItem(id);
