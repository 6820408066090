import { authorizedApiClient } from '../../../api/lib/apiClient';
import { MOVE_CONSUMER_API_GATEWAY_PATH } from '../../../shared/config';

const getNotificationsUrl = () =>
  `${MOVE_CONSUMER_API_GATEWAY_PATH}/nc/notifications`;

export const getNotifications = () => {
  const url = getNotificationsUrl();

  return authorizedApiClient()({
    method: 'GET',
    headers: { accept: 'application/json;version=2' },
    metricsId: 'notifications_getNotifications',
    url,
  });
};

export const putNotificationMarkAsRead = (notificationId) => {
  const url = `${getNotificationsUrl()}/${notificationId}/read`;

  return authorizedApiClient()({
    method: 'PUT',
    metricsId: 'notifications_markAsRead',
    url,
  });
};

export const getNotificationsNewCount = () => {
  const url = `${MOVE_CONSUMER_API_GATEWAY_PATH}/nc/new-notifications-count`;

  return authorizedApiClient()({
    method: 'GET',
    metricsId: 'notifications_getNewCount',
    url,
  });
};

export const deleteNotificationsNewCount = () => {
  const url = `${MOVE_CONSUMER_API_GATEWAY_PATH}/nc/mark-all-as-old`;

  return authorizedApiClient()({
    method: 'PUT',
    metricsId: 'notifications_deleteNewCount',
    url,
  });
};
