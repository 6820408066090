import {
  selectFinancingCoApplicantForDeal,
  selectIsCoApplicantAddressAvailable,
} from './financingSpoke/selectors';
import { NOT_FOUND } from 'redux-first-router';
import { ThunkAction } from 'redux-thunk';
import {
  handleResponsePayment,
  handleResponsePaymentError,
  loadingPaymentStarted,
} from '../../features/financingCalculator/actionCreators/changeValue';
import { handleResponseOptions } from '../../features/financingCalculator/actionCreators/fetchFinancingOptions';
import {
  goToDigitalRetailPage,
  goToVip,
  showErrorPageAction,
  showListingNotFoundPageAction,
} from '../../stores/page';
import { getListing, sendDeal as sendDealToAPI, saveGuestDeal } from './api';
import {
  HANDLE_RESPONSE_LISTING,
  SET_PAYMENT_TYPE,
  DEAL_SUBMITTED,
  DEAL_ERROR,
  DEAL_REQUEST,
  DEAL_ABORTED,
  RESET_PAGE,
  SET_IS_PAYMENT_SPOKE_FILLED_OUT,
  SET_IS_FINANCING_SPOKE_FILLED_OUT,
  SET_IS_TRADE_IN_SPOKE_FILLED_OUT,
  DEAL_SAVED,
} from './actionTypes';
import { selectListing, selectPaymentType } from './selectors';
import { selectFinancingForDeal } from './financingSpoke';
import { selectTradeInForDeal } from './tradeInSpoke';
import { selectPaymentForDeal } from './paymentSpoke/selectors';
import track from '../../utils/tracking/track';
import {
  removeTradeInResult,
  saveTradeInInput,
  saveTradeInResult,
  selectTradeInValue,
} from '../../features/tradeIn';
import { PaymentType, setValue } from '../../features/financingCalculator';
import { State } from '../../stores/types/State';
import {
  DealRequest,
  FinancingType,
  Listing,
  GuestDealRequest,
} from './apiTypes';
import { ErrorMessage } from './stateTypes';
import { EVENT_KEYS } from '../../../lib/optimizely';
import { selectProtectionPlansState } from '../../features/protectionPlans/selectors';
import { resetProtectionProducts } from '../../features/protectionPlans/actionCreators';

export const setPaymentType = (payload: PaymentType) =>
  ({
    type: SET_PAYMENT_TYPE,
    payload,
  } as const);

export const setIsPaymentSpokeFilledOut = (payload: boolean) =>
  ({
    type: SET_IS_PAYMENT_SPOKE_FILLED_OUT,
    payload,
  } as const);

export const setIsTradeInSpokeFilledOut = () =>
  ({
    type: SET_IS_TRADE_IN_SPOKE_FILLED_OUT,
  } as const);

export const setIsFinancingSpokeFilledOut = (payload: boolean) =>
  ({
    type: SET_IS_FINANCING_SPOKE_FILLED_OUT,
    payload,
  } as const);

export const resetPage = () =>
  ({
    type: RESET_PAGE,
  } as const);

export const handleResponse = (listing: Listing) =>
  ({
    type: HANDLE_RESPONSE_LISTING,
    payload: listing,
  } as const);

export const fetchListingAction =
  (listingId: string): ThunkAction<Promise<void>, State, undefined, Action> =>
  async (dispatch, getState) => {
    if (selectListing(getState())?.id === listingId) {
      return;
    }

    try {
      const listing = await getListing(listingId);

      // handle listing does not support digital retail
      if (!listing.digitalRetail) {
        dispatch({ type: NOT_FOUND });
        return;
      }

      // TODO: handle vip cache here
      dispatch(handleResponse(listing));
    } catch (err: any) {
      dispatch(
        err.statusCode === 404
          ? showListingNotFoundPageAction()
          : showErrorPageAction(err)
      );
    }
  };

export const dealRequest = () =>
  ({
    type: DEAL_REQUEST,
  } as const);

export const dealSubmitted = (referenceId: string) =>
  ({
    type: DEAL_SUBMITTED,
    payload: { referenceId },
  } as const);

export const dealSaved = () =>
  ({
    type: DEAL_SAVED,
  } as const);

export const dealError = (errorMessages: ErrorMessage[]) =>
  ({
    type: DEAL_ERROR,
    payload: { errorMessages },
  } as const);

export const dealAborted = () =>
  ({
    type: DEAL_ABORTED,
  } as const);

const mapError = ({ localizedErrorProperty, localizedErrorMessage }) => ({
  fieldName: localizedErrorProperty,
  message: localizedErrorMessage,
});

// the thunk will return `true` in case of an unhandled error
export const sendDeal =
  (): ThunkAction<Promise<boolean>, State, undefined, Action> =>
  async (dispatch, getState) => {
    const state = getState();

    let showError = false;
    try {
      track({ eventAction: 'DigitalRetailRequestAttempt' }, state);
      dispatch(dealRequest());

      const listing = selectListing(state);
      const paymentType = selectPaymentType(state);

      const tradeIn = selectTradeInForDeal(state);
      const paymentForDeal = selectPaymentForDeal(state);
      const applicant = selectFinancingForDeal(state);
      const hasCoApplicantData = selectIsCoApplicantAddressAvailable(state);
      const coApplicant = hasCoApplicantData
        ? selectFinancingCoApplicantForDeal(state)
        : undefined;
      const { formState, sessionId } = selectProtectionPlansState(state);

      const selectedProtectionProducts = Object.keys(formState).reduce(
        (acc, productId) =>
          formState[productId].checked
            ? [
                ...acc,
                {
                  productId,
                  ...(formState[productId].optionId
                    ? { optionId: formState[productId].optionId }
                    : {}),
                },
              ]
            : acc,
        [] as { productId: string; optionId?: string }[]
      );

      const protectionData = !selectedProtectionProducts.length
        ? undefined
        : {
            protectionSessionId: String(sessionId),
            protectionProducts: selectedProtectionProducts,
          };

      const fields: DealRequest = {
        listingId: listing.id,
        financing: {
          type:
            paymentType === PaymentType.Loan
              ? FinancingType.Loan
              : FinancingType.Lease,
          ...paymentForDeal,
          tradeIn,
        },
        applicant,
        coApplicant,
        consent: true,
        ...(protectionData ? protectionData : {}),
      };

      const dealResult = await sendDealToAPI(fields);

      if (dealResult.referenceId) {
        const hasTradeIn = Boolean(selectTradeInValue(state));
        dispatch(dealSubmitted(dealResult.referenceId));
        dispatch(resetProtectionProducts());
        const eventLabel = [
          hasTradeIn && 'trade_in',
          paymentType === PaymentType.Loan && 'financing',
          `dealId=${dealResult.dealId}`,
        ]
          .filter(Boolean)
          .join(';');
        track(
          {
            eventAction: 'DigitalRetailRequestSuccess',
            getEventData: () => ({ eventLabel }),
            optimizelyEvents: [EVENT_KEYS.CONTACT_FORM_REQUEST_SUCCESS],
          },
          state
        );
      } else {
        // The response from the server was erroneous
        dispatch(dealAborted());
        showError = true;
      }
    } catch (error: any) {
      // Handle request errors
      if (error?.statusCode >= 400 && error?.statusCode < 500) {
        const errorMessages: ErrorMessage[] = (error?.error?.errors || []).map(
          mapError
        );
        dispatch(dealError(errorMessages));
        // Tracking the input errors is done in useErrorTracking.ts due to the reliance on react-intl
        // unless the API did, for some reason, not return any error messages.
        if (errorMessages.length === 0) {
          track(
            {
              eventAction: 'DigitalRetailRequestFail',
              getEventData: () => ({ eventLabel: 'error=unknown' }),
            },
            state
          );
        }
      } else {
        track(
          {
            eventAction: 'DigitalRetailRequestFail',
            getEventData: () => ({ eventLabel: 'error=500' }),
          },
          state
        );
        // Another error occurred that cannot be handled specifically
        dispatch(dealAborted());
        showError = true;
      }
    }
    return showError;
  };

// TODO:Cleanup after experiment is finished MOVE-26228
export const saveGuestApplication =
  (): ThunkAction<Promise<string>, State, undefined, Action> =>
  async (dispatch, getState) => {
    const state = getState();

    try {
      track({ eventAction: 'DigitalRetailRequestAttempt' }, state);
      dispatch(dealRequest());

      const listing = selectListing(state);
      const paymentType = selectPaymentType(state);

      const tradeIn = selectTradeInForDeal(state);
      const paymentForDeal = selectPaymentForDeal(state);
      const { email, ...applicant } = selectFinancingForDeal(state);
      const hasCoApplicantData = selectIsCoApplicantAddressAvailable(state);
      const coApplicant = hasCoApplicantData
        ? selectFinancingCoApplicantForDeal(state)
        : undefined;

      const fields: GuestDealRequest = {
        listingId: listing.id,
        financing: {
          type:
            paymentType === PaymentType.Loan
              ? FinancingType.Loan
              : FinancingType.Lease,
          ...paymentForDeal,
          tradeIn,
        },
        applicant,
        coApplicant,
        consent: true,
      };

      const dealResult = await saveGuestDeal(fields);

      if (dealResult.anonymousDealApplicationId) {
        const hasTradeIn = Boolean(selectTradeInValue(state));
        dispatch(dealSaved());
        const eventLabel = [
          hasTradeIn && 'trade_in',
          paymentType === PaymentType.Loan && 'financing',
        ]
          .filter(Boolean)
          .join(';');
        track(
          {
            eventAction: 'DigitalRetailRequestSuccess',
            getEventData: () => ({ eventLabel }),
            optimizelyEvents: [EVENT_KEYS.CONTACT_FORM_REQUEST_SUCCESS],
          },
          state
        );
        return dealResult.anonymousDealApplicationId;
      } else {
        // The response from the server was erroneous
        dispatch(dealAborted());
      }
    } catch (error: any) {
      // Handle request errors
      if (error?.statusCode >= 400 && error?.statusCode < 500) {
        const errorMessages: ErrorMessage[] = (error?.error?.errors || []).map(
          mapError
        );
        dispatch(dealError(errorMessages));
        // Tracking the input errors is done in useErrorTracking.ts due to the reliance on react-intl
        // unless the API did, for some reason, not return any error messages.
        if (errorMessages.length === 0) {
          track(
            {
              eventAction: 'DigitalRetailRequestFail',
              getEventData: () => ({ eventLabel: 'error=unknown' }),
            },
            state
          );
        }
      } else {
        track(
          {
            eventAction: 'DigitalRetailRequestFail',
            getEventData: () => ({ eventLabel: 'error=500' }),
          },
          state
        );
        // Another error occurred that cannot be handled specifically
        dispatch(dealAborted());
      }
    }
    return '';
  };

// all actions that are handled in the reducer
export type MappedAction =
  | ReturnType<typeof setPaymentType>
  | ReturnType<typeof setIsPaymentSpokeFilledOut>
  | ReturnType<typeof setIsTradeInSpokeFilledOut>
  | ReturnType<typeof setIsFinancingSpokeFilledOut>
  | ReturnType<typeof resetPage>
  | ReturnType<typeof handleResponse>
  | ReturnType<typeof dealRequest>
  | ReturnType<typeof dealSubmitted>
  | ReturnType<typeof dealError>
  | ReturnType<typeof dealAborted>
  | ReturnType<typeof dealSaved>
  // handled actions from financing calculator feature
  | ReturnType<typeof loadingPaymentStarted>
  | ReturnType<typeof handleResponsePayment>
  | ReturnType<typeof handleResponsePaymentError>
  | ReturnType<typeof setValue>
  | ReturnType<typeof handleResponseOptions>;

// all actions that are fired within the page, but not necessarily handled by the reducer
export type Action =
  | MappedAction
  // dispatched here, but not handled in the reducer
  | ReturnType<typeof goToVip>
  | ReturnType<typeof removeTradeInResult>
  | ReturnType<typeof showListingNotFoundPageAction>
  | ReturnType<typeof showErrorPageAction>
  | ReturnType<typeof saveTradeInInput>
  | ReturnType<typeof saveTradeInResult>
  | ReturnType<typeof goToDigitalRetailPage>
  | ReturnType<typeof resetProtectionProducts>
  | { type: typeof NOT_FOUND };
