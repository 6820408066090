import {
  HANDLE_RESPONSE_DEALS,
  OPEN_MODAL,
  CLOSE_MODAL,
  SET_FORM_VALUES,
  CLEAR_FORM,
  SET_DEAL_IS_SCHEDULING,
} from './actionTypes';
import { DealDetails } from './components/Deal';

interface State {
  deals: DealDetails[] | null;
  isOpenModal: boolean;
  currentDeal: any;
  form: any;
}

const defaultState: State = {
  deals: null,
  isOpenModal: false,
  currentDeal: null,
  form: {},
};

export default function dealsReducer(state = defaultState, { type, payload }) {
  switch (type) {
    case HANDLE_RESPONSE_DEALS:
      return {
        ...state,
        deals: payload,
      };
    case OPEN_MODAL:
      return {
        ...state,
        currentDeal: payload,
        isOpenModal: true,
      };
    case CLOSE_MODAL:
      return {
        ...state,
        isOpenModal: false,
        currentDeal: null,
      };
    case SET_FORM_VALUES:
      return {
        ...state,
        form: {
          ...state.form,
          [payload.key]: payload.value,
        },
      };
    case CLEAR_FORM:
      return {
        ...state,
        form: {},
      };
    case SET_DEAL_IS_SCHEDULING:
      return {
        ...state,
        deals: state.deals?.map((deal) =>
          deal.referenceId === payload.referenceId
            ? { ...deal, isScheduling: payload.isScheduling }
            : deal
        ),
      };
    default:
      return state;
  }
}
