import { MY_DEALS_ROUTE, MY_DEALS_PATH } from '../../stores/page';
import { FEATURE_DIGITAL_RETAIL } from '../../../shared/featureFlags';
import { ThunkDispatch } from 'redux-thunk';
import { State } from '../../stores/types/State';
import { Action } from './actionCreators';
import { NOT_FOUND } from 'redux-first-router';

export const routes = {
  [MY_DEALS_ROUTE]: {
    path: MY_DEALS_PATH,
    thunk: (dispatch: ThunkDispatch<State, undefined, Action>) => {
      if (!FEATURE_DIGITAL_RETAIL) {
        dispatch({ type: NOT_FOUND });
      }
    },
  },
};
