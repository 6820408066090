import { VEHICLE_CATEGORY } from '../../../shared/config';

const getDefaultSearchQueryParams = () =>
  ({
    // page size
    psz: '20',
    // vehicle category
    vc: VEHICLE_CATEGORY,
  } as const);

export default getDefaultSearchQueryParams;
