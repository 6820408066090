import { getEnv } from '@move-web-essentials-utils/env';
import {
  MOVE_API_BASE_PATH,
  MOVE_DIGITAL_RETAIL_API_BASE_PATH,
  MOVE_SRP_API_BASE_PATH,
} from '../shared/config';

const getSrpBaseUrl = () =>
  IS_SERVER
    ? `${getEnv('move_consumer_api_gateway_url')}${MOVE_SRP_API_BASE_PATH}`
    : MOVE_SRP_API_BASE_PATH;

const getSvcBaseUrl = () =>
  IS_SERVER
    ? `${getEnv('move_consumer_api_gateway_url')}${MOVE_API_BASE_PATH}`
    : MOVE_API_BASE_PATH;

const getDigitalRetailBaseUrl = () =>
  IS_SERVER
    ? `${getEnv(
        'move_consumer_api_gateway_url'
      )}${MOVE_DIGITAL_RETAIL_API_BASE_PATH}`
    : MOVE_DIGITAL_RETAIL_API_BASE_PATH;

const svcBaseUrl = getSvcBaseUrl();

export const urls = {
  search: `${svcBaseUrl}/s`,
  listing: `${svcBaseUrl}/a`,
  srp: `${getSrpBaseUrl()}/by-params`,
  carMakes: `${svcBaseUrl}/r/makes/Car`,
  models: `${svcBaseUrl}/r/models`,
  hitCount: `${svcBaseUrl}/hit-count`,
  deals: getDigitalRetailBaseUrl(),
};
