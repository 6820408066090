import { getEnv } from '@move-web-essentials-utils/env';
import { runTaskOnIdleOrOnTabClosed } from './runTaskOnIdle';

const PAGE_VIEW = 'pageView';

let useTemporaryDataLayer = false;
let temporaryDataLayer = [];

export const push = (data) => {
  if (useTemporaryDataLayer) {
    temporaryDataLayer.push(data);
    return;
  }

  if (typeof dataLayer !== 'undefined') {
    runTaskOnIdleOrOnTabClosed(() => dataLayer?.push(data));
  }
};

// This is used to so set dimensions, pageview and events in the right order:
// - set dimensions
// - trigger pageview
// - trigger events
export const tempDataLayer = {
  enable: () => {
    useTemporaryDataLayer = true;
  },
  sortPageUp: () => {
    const withoutPageview = temporaryDataLayer.filter(
      ({ event }) => event !== PAGE_VIEW
    );
    const pageview = temporaryDataLayer.find(
      ({ event }) => event === PAGE_VIEW
    );
    temporaryDataLayer = pageview
      ? [pageview, ...withoutPageview]
      : temporaryDataLayer;
  },
  applyAndDisable: () => {
    useTemporaryDataLayer = false;
    temporaryDataLayer.forEach(push);
    temporaryDataLayer = [];
  },
};

export const get = (key) => {
  const gtmId = getEnv('google_tag_manager_id');
  if (
    gtmId &&
    typeof google_tag_manager !== 'undefined' &&
    google_tag_manager[gtmId]
  ) {
    return google_tag_manager[gtmId].dataLayer.get(key);
  }
  return undefined;
};
