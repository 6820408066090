import { getCurrentLocale } from '../../stores/i18n';
import { getMrp } from './actionCreators';
import { selectPageState } from './selectors';
import { PAGE_STATE } from './types';
import { MRP_ROUTE } from '../../stores/page';
import { MRP_PATHS } from './config';

const mrpThunk = (dispatch, getState) => {
  const state = getState();
  const locale = getCurrentLocale(state);
  const isLoaded = selectPageState(state) === PAGE_STATE.LOADING_SUCCESS;
  return !isLoaded ? dispatch(getMrp({ locale })) : null;
};

const localizedRoutes = Object.keys(MRP_PATHS || {}).reduce(
  (paths, key) => ({
    ...paths,
    [`${MRP_ROUTE}_${key}`]: {
      path: MRP_PATHS[key],
      thunk: mrpThunk,
    },
  }),
  {}
);

export const routes = {
  ...localizedRoutes,
};
