import * as normalizers from './normalizers';

export const normalize = (value) =>
  value?.trim().toLowerCase().replace(/ /g, '_');

export const normalizeMakes = (makeModels = [], makeModelTree) => {
  const values = makeModels
    .map((makeModel) => {
      const [makeId] = makeModel.split(';');
      if (!makeId) {
        return undefined;
      }

      const makeRow = makeModelTree.makes.all.find(
        ({ i }) => `${i}` === makeId
      );

      return makeRow ? normalize(makeRow.n) : undefined;
    })
    .filter(Boolean);

  return values.length === 0 ? undefined : values;
};

export const normalizeModels = (makeModels = [], makeModelTree) => {
  const values = makeModels
    .map((makeModel) => {
      const [makeId, modelId] = makeModel.split(';');
      if (!modelId) {
        return undefined;
      }

      const modelRow = makeModelTree.models[makeId].find(
        ({ i }) => `${i}` === modelId
      );

      return modelRow ? normalize(modelRow.n) : undefined;
    })
    .filter(Boolean);

  return values.length === 0 ? undefined : values;
};

export const normalizeVariant = (makeModels = [], makeModelTree) => {
  const values = makeModels
    .map((makeModel) => {
      const [makeId, modelId, trim] = makeModel.split(';');
      if (!trim || !makeId || !modelId) {
        return undefined;
      }

      const modelRow = makeModelTree.models[makeId].find(
        ({ i }) => `${i}` === modelId
      );

      if (!modelRow) {
        return undefined;
      }

      const variant = modelRow.trims.find(
        (trimValue) => normalize(trimValue) === trim
      );

      return variant ? normalize(variant) : undefined;
    })
    .filter(Boolean);

  return values.length === 0 ? undefined : values;
};

export default {
  normalize,
  normalizeMakes,
  normalizeModels,
  normalizeVariant,
};

export const {
  normalizeBody,
  normalizeSpecialCharacters,
  normalizePrice,
  normalizeRange,
  normalizeSellType,
  normalizeFuelType,
  normalizeDrivetrain,
  normalizePriceFilter,
  normalizePriceThreshold,
  normalizeTransmission,
} = normalizers;
