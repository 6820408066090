import logger from '@move-web-essentials-utils/logger';
import { isPlainObject } from 'lodash';
import { getCookie } from 'cookies';
import { setForcedVariation, getForcedVariation } from '../api';
import { FORCE_VARIATION_KEY, FORCE_VARIATION_RESET } from '../config';

const log = (key, instance) => {
  logger.info(
    [
      'OPTIMIZELY: forced experiment variation',
      `  experiment: "${key}"`,
      `  variation: "${getForcedVariation(key, instance)}"`,
    ].join('\n')
  );
};

const resetForcedVariations = (instance) => {
  const allExperimentKeys = Object.keys(
    instance?.projectConfigManager?.getConfig()?.experimentKeyMap ?? {}
  );
  allExperimentKeys.forEach((key) => {
    setForcedVariation(key, null, instance);
  });
};

export default function initForcedVariations(instance) {
  const cookieValue = getCookie(FORCE_VARIATION_KEY);
  let forcedVariationMap;
  if (typeof cookieValue !== 'string' || !cookieValue.length) {
    return;
  }

  if (cookieValue === FORCE_VARIATION_RESET) {
    resetForcedVariations(instance);
    return;
  }

  try {
    forcedVariationMap = JSON.parse(cookieValue);
  } catch (error) {
    logger.error(
      'OPTIMIZELY: something is wrong with your force variation cookie.'
    );
    return;
  }

  if (isPlainObject(forcedVariationMap)) {
    Object.keys(forcedVariationMap).forEach((key) => {
      const isValid = setForcedVariation(
        key,
        forcedVariationMap[key],
        instance
      );
      if (isValid) {
        log(key, instance);
      }
    }, {});
  }
}
