import { find } from 'lodash';

import { getCurrentLocale } from '../../../stores/i18n';
import { getLinkForLocales } from '../../../../lib/locale-utils';
import { HOME_ROUTE } from '../../../stores/page';
import { State } from '../../../stores/types/State';

export const getLocalizedHomePath = (state: State): string => {
  const currentLocale = getCurrentLocale(state);
  const linksForLocales = getLinkForLocales('/', HOME_ROUTE);

  const hrefForSelectedLocal = find(linksForLocales, {
    locale: currentLocale,
  });

  return hrefForSelectedLocal?.href || '/';
};
