import { selectArea } from '../../stores/geoLocation';
import {
  get as getStoredArea,
  convertRadiusToString,
} from '../domains/geoLocation/services';

const getArea = (state?: Parameters<typeof selectArea>[0]) => {
  try {
    // try to get stored area from cookie
    return getStoredArea();
  } catch {
    // otherwise try to get area from state
    return state ? selectArea(state) : null;
  }
};

/**
 * get location search params for the current user
 */
const getLocationSearchQueryParams = (
  state?: Parameters<typeof getArea>[0]
) => {
  const area = getArea(state);

  if (!area?.location) {
    return null;
  }

  const { location, isLimitedToProvince, radius } = area;
  const { position, province } = location;

  const result = {
    ll: position
      ? [position.latitude, position.longitude].join(',')
      : undefined,
    rd: radius ? convertRadiusToString(radius) : undefined,
    aa: isLimitedToProvince ? province?.code : undefined,
  };

  Object.keys(result).forEach(
    (key) => result[key] == null && delete result[key]
  );

  return result;
};

export default getLocationSearchQueryParams;
