import { isInternalImageUrl } from '@move-web-essentials-utils/images';
import { ApiMessageStatus } from '../types';

// image messages have a special format:
// "Here is a picture: {uploadedImageUrl}"
const extractImageUriFromText = (text: string) => {
  const [, , url] = text.match(/^([\w ]+: )?(https?:\/\/[^\s]+)$/) || [];
  return isInternalImageUrl(url) ? url : undefined;
};

type Message = {
  text: string;
  isRead?: boolean;
};

const parseImage = (message: Message) => {
  const { text } = message;
  const image = extractImageUriFromText(text);

  return {
    ...message,
    image,
    text: image ? undefined : text,
  };
};

const getStatus = (isRead?: boolean) =>
  isRead ? ApiMessageStatus.Read : ApiMessageStatus.Done;

const setStatus = (message: Partial<Message>) => {
  const { isRead, ...rest } = message;

  return {
    ...rest,
    status: getStatus(isRead),
  };
};

const normalizeConversationResponse = (
  conversation: { messages?: Message[] } = {}
) => {
  const { messages = [] } = conversation;

  return {
    ...conversation,
    messages: messages.map(parseImage).map(setStatus),
  };
};

export default normalizeConversationResponse;
