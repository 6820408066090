import { getCookie } from 'cookies';
import memoizeOne from 'memoize-one';
import { TRAFFIC_TYPE_COOKIE_KEY, TRAFFIC_TYPE_INTERNAL } from '../config';

const getTrafficType = () =>
  getCookie(TRAFFIC_TYPE_COOKIE_KEY) === TRAFFIC_TYPE_INTERNAL
    ? 'internal'
    : 'external';

export default memoizeOne(getTrafficType);
