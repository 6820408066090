import { selectMakeModelForQueryString } from '../../stores/referenceData';
import {
  isExtraType,
  isBooleanType,
} from '../../shared/domains/filters/specifications/filters';
import buildTranslatedFiltersForQuery from '../../shared/domains/savedSearches/services/buildTranslatedFiltersForQuery';
import { getQueryAsObject } from '../../../shared/utils/getQueryAsObject';

const createTitle = ({ makeModelTree, query: { ms }, formatMessage }) => {
  const makeModels = selectMakeModelForQueryString(makeModelTree, ms);
  return makeModels.length > 0
    ? makeModels
        .map(({ n, trm }) => (trm ? `${n} ${trm}` : n))
        .sort()
        .join(', ')
    : formatMessage({ id: 'all_makes' });
};

const getFilterCount = (filterSettings) =>
  filterSettings.reduce((total, filterSetting) => {
    if (
      isExtraType(filterSetting.filter) ||
      (isBooleanType(filterSetting.filter) && !filterSetting.filter.param)
    ) {
      return total + filterSetting.value.length;
    }
    return total + 1;
  }, 0);

export const savedSearchItemPresenter =
  ({ filterConfiguration, makeModelTree, intl }) =>
  (savedSearch) => {
    const { formatMessage } = intl;
    const query = getQueryAsObject(savedSearch.searchParameterString);
    const { ms } = query;
    const trm = ms?.[0]?.split(';')?.[2];

    const filters = buildTranslatedFiltersForQuery({
      filterConfiguration,
      searchQuery: { ...query, trm },
      locationName: savedSearch.locationName,
      formatMessage,
    });

    return {
      ...savedSearch,
      query,
      title: createTitle({
        makeModelTree,
        query,
        formatMessage,
      }),
      filters,
      filterCount: getFilterCount(filters),
    };
  };

export const toggleCheckedItems = (items, id) => {
  const copiedList = [...items];
  const mutatedItem = copiedList.find((item) => item.id === id);

  mutatedItem.isChecked = !mutatedItem.isChecked;

  return copiedList;
};

export const toggleCheckedAllItems = (items, value) =>
  items.map((item) => ({ ...item, isChecked: value }));

export const uniqueMergeList = (head = []) => {
  const merged = head.reduce(
    (map, val) => map.set(val.id, { ...val }),
    new Map()
  );

  return [...merged.values()];
};
