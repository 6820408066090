import { setCookie, getCookie } from 'cookies';

import { LOCATION_COOKIE_NAME, LOCATION_COOKIE_MAX_AGE_DAYS } from '../config';

import createArea from '../entities/createArea';

export const set = (area) => {
  setCookie(
    LOCATION_COOKIE_NAME,
    JSON.stringify(area),
    LOCATION_COOKIE_MAX_AGE_DAYS
  );
};

export const get = () => {
  const savedData = getCookie(LOCATION_COOKIE_NAME);

  if (!savedData) {
    return null;
  }

  return createArea(JSON.parse(savedData));
};

export const remove = () => {
  set({});
};
