import {
  PUBLIC_DOMAIN_URL,
  DEFAULT_LOCALE,
  LOCALES,
} from '../../shared/config';

export const getLocaleShortName = (locale: string) =>
  locale.split('-')[0].toLowerCase();

export const getUrlWithoutLanguage = (path: string, locale: string) => {
  const shortLocale = getLocaleShortName(locale);
  const isInSupportedPath =
    path.startsWith(`/${shortLocale}/`) || path === `/${shortLocale}`;

  if (isInSupportedPath) {
    const pathWithoutLanguage = path
      .split('/') // path /fr/abc/def/ becomes ['', 'fr', 'abc', 'def', '']
      .slice(2)
      .join('/');
    return `/${pathWithoutLanguage}`;
  }
  return path;
};

export const getUrlWithLanguage = (languageShortName: string, path: string) =>
  `/${languageShortName}${path}`;

export const getLocaleFromPath = (path: string, supportedLocales = LOCALES) =>
  supportedLocales.find((locale) =>
    path.startsWith(`/${getLocaleShortName(locale)}/`)
  );

export const withTrailingSlash = (originalUrl: string) => {
  const { pathname, search } = new URL(originalUrl, PUBLIC_DOMAIN_URL);

  if (pathname.length > 0 && !pathname.endsWith('/')) {
    return `${pathname}/${search || ''}`;
  }

  return originalUrl;
};

export const getBasename = ({
  currentLocale,
  defaultLocale = DEFAULT_LOCALE,
  defaultIsImplicit = true,
}: {
  currentLocale: string;
  defaultLocale?: string;
  defaultIsImplicit?: boolean;
}) => {
  const defaultLocaleShort = getLocaleShortName(defaultLocale);
  const currentLocaleShort = getLocaleShortName(currentLocale);

  return defaultIsImplicit === true && defaultLocaleShort === currentLocaleShort
    ? undefined
    : `/${currentLocaleShort}`;
};

export const addLocalePrefixToUrl = (currentLocale: string, url: string) => {
  const basename = getBasename({ currentLocale }) || '';

  return `${basename}${url}`;
};

export const removeLocalePrefixFromUrl = (
  currentLocale: string,
  url: string
) => {
  const basename = getBasename({ currentLocale }) || '';

  return url.replace(basename, '');
};

export const getLinkForLocales = (
  pathname: string,
  pageId: string,
  seoUrlsMap: Record<string, Record<string, string | undefined>> = {}
) =>
  LOCALES.map((locale) => {
    const pathMap = seoUrlsMap[pageId] || {};
    const localePrefixedPathname = addLocalePrefixToUrl(locale, pathname);
    const path = pathMap[locale] ? pathMap[locale] : localePrefixedPathname;
    const href = `${PUBLIC_DOMAIN_URL}${path}`;

    return {
      href,
      locale,
    };
  });
