import { compact } from 'lodash';
import { createSelector } from 'reselect';

import { selectCategories } from '../../stores/referenceData';
import {
  CONTACT_TYPE,
  sellerTypeTrackingMap,
} from '../../constants/sellerTypes';
import { PAGE_NAMES } from './constants';
import { selectQuery, selectIsBodyTypeSrp } from '../../stores/searchQuery';
import {
  isReferredBySrp,
  isLifeStyleSrp,
  isReferredByDealerPage,
} from './referrerCheckers';
import { DATA_LOADING_STATE } from './types';
import createListingImages from './utils/createListingImages';
import { isAdvertisingEnabled as isAdvertisingFeatureEnable } from '../../features/advertising';
import { normalizeUrlMap } from '../../../shared/utils/normalizeUrl';
import { getVideo } from '../../utils/getVideo';
export const selectIsExpiredVIP = (state) => {
  const status = state.pages?.vip?.listing?.listingStatus?.statusId;
  return status === 'deleted' || status === 'paused';
};

export const selectIsDeletedVIP = (state) => {
  const status = state.pages?.vip?.listing?.listingStatus?.statusId;
  return status === 'deleted';
};

export const selectIsPausedVIP = (state) => {
  const status = state.pages?.vip?.listing?.listingStatus?.statusId;
  return status === 'paused';
};

export const selectPreviousSrpBodyType = (state) => {
  const { c } = selectQuery(state);
  if (c === undefined) {
    return undefined;
  }

  let rawBodyType;
  if (!Array.isArray(c)) {
    rawBodyType = c;
  } else if (c.length === 1) {
    [rawBodyType] = c;
  }

  if (rawBodyType === undefined) {
    return undefined;
  }

  const category = selectCategories(state).find(({ i }) => rawBodyType === i);
  return category?.n;
};

export const selectPreviousSrpLifeStyleTitle = (state) =>
  state.pages.srp.content?.title;

export const selectReferrer = (state) => {
  if (isReferredBySrp(state)) {
    if (isLifeStyleSrp(state)) {
      return PAGE_NAMES.LIFE_STYLE_SRP;
    }
    if (selectIsBodyTypeSrp(state)) {
      return PAGE_NAMES.BODY_TYPE_SRP;
    }
    return PAGE_NAMES.SRP;
  }
  if (isReferredByDealerPage(state)) {
    return PAGE_NAMES.DEALER_PAGE;
  }
  return PAGE_NAMES.OTHER;
};

export const selectShowDealerPage = (state) =>
  state.pages?.vip?.listing?.showDealerPage;

export const selectCustomerId = (state) =>
  state.pages?.vip?.listing?.customerId;

export const selectPartnerId = (state) => state.pages?.vip?.listing?.partnerId;

export const selectDealerPageCount = (state) =>
  state.pages?.vip?.listing?.amountOfDealerListings;

export const selectDataLoadingState = (state) => {
  const listing = state.pages?.vip?.listing;

  if (!listing?.title) {
    return DATA_LOADING_STATE.NONE;
  }

  if (listing?.provisional) {
    return DATA_LOADING_STATE.PROVISIONAL;
  }

  return DATA_LOADING_STATE.FULL;
};

export const selectTitle = (state) => state.pages?.vip?.listing?.title;

export const selectPrices = (state) => state.pages?.vip?.listing?.prices ?? {};

export const selectImages = (state) => {
  const images = state.pages?.vip?.listing?.images ?? [];
  const provisionalBase64Image = state.pages?.vip?.provisionalBase64Image;
  return createListingImages(images, provisionalBase64Image);
};

export const selectContactName = (state) =>
  state.pages?.vip?.listing?.contact?.name;

export const selectContactType = (state) =>
  state.pages?.vip?.listing?.contact?.enumType === CONTACT_TYPE.PRIVATE
    ? 'private'
    : 'dealer';

export const selectContactPhone = (state) => {
  if (selectIsExpiredVIP(state) && selectContactType(state) === 'private') {
    return null;
  }

  return (
    state.pages?.vip?.listing?.contact?.phones?.filter(
      ({ type }) => type.indexOf('PHONE') > -1
    )[0] ?? null
  );
};

export const selectContactLogo = (state) =>
  state.pages?.vip?.listing?.contact?.logo;

export const selectDescription = (state) =>
  state.pages?.vip?.listing?.htmlDescription;

export const selectAttributeGroups = (state) =>
  state.pages?.vip?.listing?.attributeGroups;

export const selectLocalizedContactDistance = (state) =>
  state.pages?.vip?.listing?.contact?.distanceToSeller?.localized;

export const selectContactTypeForTracking = (state) =>
  sellerTypeTrackingMap[state.pages?.vip?.listing?.contact?.enumType];

export const selectContactAddress = (state) =>
  compact([
    state.pages?.vip?.listing?.contact?.address1,
    state.pages?.vip?.listing?.contact?.address2,
  ]).join(', ');

export const selectContactLatLong = (state) =>
  state.pages?.vip?.listing?.contact?.latLong;

export const selectVideo = function selectVideo(state) {
  return getVideo(state.pages?.vip?.listing);
};

export const selectId = (state) => state.pages?.vip?.listing?.id;

export const selectSellerForeignId = (state) =>
  state.pages?.vip?.listing?.sellerForeignId;

export const selectDealerRating = (state) =>
  state.pages?.vip?.listing?.dealerRating;

export const selectPriceRating = (state) => {
  const listing = state.pages?.vip?.listing ?? {};
  const { priceRating } = listing;

  return (
    priceRating && {
      ...priceRating,
      province: listing.location?.province,
    }
  );
};

export const selectMake = (state) => state.pages?.vip?.listing?.make;
export const selectModel = (state) => state.pages?.vip?.listing?.model;
export const selectTrim = (state) => state.pages?.vip?.listing?.trim;
export const selectTransmission = (state) =>
  state.pages?.vip?.listing?.transmission;
export const selectExteriorColor = (state) =>
  state.pages?.vip?.listing?.exteriorColor;
export const selectFuelType = (state) => state.pages?.vip?.listing?.fuelType;
export const selectDriveTrain = (state) =>
  state.pages?.vip?.listing?.driveTrain;

export const selectIsConditionNew = (state) =>
  state.pages?.vip?.listing?.isConditionNew;

export const selectNumberOfImages = (state) =>
  state.pages?.vip?.listing?.images?.length ?? 0;

export const selectVehicleReport = (state) => {
  const vehicleReport = state.pages?.vip?.listing?.vehicleReport;
  if (!vehicleReport) {
    return null;
  }
  return vehicleReport;
};

export const selectYear = (state) => state.pages?.vip?.listing?.year;

export const selectLocation = (state) => state.pages?.vip?.listing?.location;

export const selectMapUrl = (state) => state.pages?.vip?.listing?.mapsUrl;

export const selectDirectionsUrl = (state) =>
  state.pages?.vip?.listing?.directionsUrl;

export const selectCertifiedPreOwned = (state) =>
  state.pages?.vip?.listing?.certifiedPreOwned;

export const selectShareUrl = (state) => {
  const normalizedPaths = normalizeUrlMap(
    state.pages?.vip?.listing?.shareUrl?.path
  );

  return normalizedPaths
    ? {
        ...state.pages?.vip?.listing?.shareUrl,
        path: normalizedPaths,
      }
    : state.pages?.vip?.listing?.shareUrl;
};

export const selectIsContactFormModalOpen = (state) =>
  state.pages.vip.isContactFormModalOpen;

export const selectIsDealerUpdatesModalOpen = (state) =>
  state.pages.vip.isDealerUpdatesModalOpen;

export const selectIsDescriptionModalOpen = (state) =>
  state.pages.vip.isDescriptionModalOpen;

export const selectIsVehicleFeaturesModalOpen = (state) =>
  state.pages.vip.isVehicleFeaturesModalOpen;

export const selectIsRequestImagesModalOpen = (state) =>
  state.pages.vip.isRequestImagesModalOpen;

export const selectListing = (state) => state.pages?.vip?.listing;

export const selectForeignId = (state) => state.pages?.vip?.listing?.foreignId;

export const isAdvertisingEnabled = (state) =>
  isAdvertisingFeatureEnable(state) && !selectIsExpiredVIP(state);

export const selectQuickFacts = (state) =>
  state.pages?.vip?.listing?.quickFacts?.attributes;

export const selectVehicleDetails = (state) =>
  state.pages?.vip?.listing?.vehicleDetails;

export const selectStockNumber = (state) =>
  state.pages?.vip?.listing?.stockNumber;

export const selectVehicleUsage = (state) =>
  state.pages?.vip?.listing?.vehicleUsage;

export const selectSellerId = (state) => state.pages?.vip?.listing?.sellerId;

export const selectRecommendationType = (state) =>
  state.pages?.vip?.listing?.recommendationType;

export const selectCovid19 = (state) => state.pages?.vip?.listing?.covid19;

export const selectIsDealer = createSelector(
  selectContactType,
  (contactType) => contactType === 'dealer'
);

export const selectVin = (state) => state.pages?.vip?.listing?.vin;

export const selectClickScrollSection = (state) =>
  state.pages?.vip?.clickScrollSection;

export const selectHasDigitalRetailing = (state) =>
  Boolean(state.pages?.vip?.listing?.hasDigitalRetailing);

export const selectBadges = (state) => state.pages?.vip?.listing?.badges;

export const selectFinancingDefaults = (state) =>
  state.pages?.vip?.financingDefaults;
