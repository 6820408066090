import { MOVE_API_CLIENT_ID } from '../../../shared/config';
import doNotTrack from '../../../lib/doNotTrack/isDoNotTrack';

const DEFAULT_CONTENT_TYPE = 'application/json';
const DEFAULT_ACCEPT_TYPE = 'application/json';

export const REQUEST_HEADER = {
  ACCEPT: 'accept',
  ACCEPT_LANGUAGE: 'accept-language',
  AUTHORIZATION: 'authorization',
  CACHE_CONTROL: 'cache-control',
  CLIENT: 'x-client',
  CLIENT_ID: 'x-client-id',
  CONTENT_TYPE: 'content-type',
  FORWARD_FOR: 'x-forwarded-for',
  PRAGMA: 'pragma',
  SEARCH_SOURCE: 'x-search-source',
  TRANSACTION_ID: 'x-transaction-id',
  PARENT_TRANSACTION_ID: 'x-parent-transaction-id',
  DO_NOT_TRACK: 'x-dnt',
  TRACK_RECENT_SEARCH: 'x-track-recent-search',
};

type RequestHeadersOptions = Record<
  string,
  string | string[] | boolean | number | undefined
>;

type RequestHeadersParamsProps = {
  authToken?: string;
  clientId?: string;
  clientIP?: string;
  clientLocale?: string;
  version?: number | string;
};

/**
 * Http Request headers
 *
 * request headers with lowercase header field names
 */
class RequestHeaders {
  headers: RequestHeadersOptions = {};

  /**
   *
   * @param options       the default request header settings
   * @param authToken     auth token
   * @param clientId      client id
   * @param clientIP      client IP address
   * @param clientLocale  client locale setting
   * @param version       api version
   */
  constructor(
    options: RequestHeadersOptions = {},
    {
      authToken,
      clientId,
      clientIP,
      clientLocale,
      version,
    }: RequestHeadersParamsProps = {}
  ) {
    this.setHeaders(options);

    this.setHeader(REQUEST_HEADER.CLIENT, MOVE_API_CLIENT_ID);

    if (!this.getHeader(REQUEST_HEADER.ACCEPT)) {
      this.setHeader(REQUEST_HEADER.ACCEPT, DEFAULT_ACCEPT_TYPE);
    }
    if (!this.getHeader(REQUEST_HEADER.CONTENT_TYPE)) {
      this.setHeader(REQUEST_HEADER.CONTENT_TYPE, DEFAULT_CONTENT_TYPE);
    }

    if (version) {
      this.setHeader(
        REQUEST_HEADER.ACCEPT,
        this.buildAccept(this.getHeader(REQUEST_HEADER.ACCEPT), version)
      );
    }
    if (authToken) {
      this.setHeader(
        REQUEST_HEADER.AUTHORIZATION,
        this.buildAuthorization(authToken)
      );
    }
    if (clientLocale) {
      this.setHeader(REQUEST_HEADER.ACCEPT_LANGUAGE, clientLocale);
    }
    if (clientId) {
      this.setHeader(REQUEST_HEADER.CLIENT_ID, `${clientId}`);
    }
    if (clientIP) {
      this.setHeader(REQUEST_HEADER.FORWARD_FOR, `${clientIP}`);
    }
    if (doNotTrack()) {
      this.setHeader(REQUEST_HEADER.DO_NOT_TRACK, '1');
    }
  }

  normalizeHeaderName = (name) => name.toLowerCase();

  buildAccept = (accept, version) => {
    if (!version) {
      return accept;
    }
    const [mimeType] = accept.split(';');

    return `${mimeType};version=${version}`;
  };

  buildAuthorization = (token) => `Bearer ${token}`;

  /**
   * get request header
   * @param name
   * @returns {*}
   */
  getHeader = (name) => {
    const key = this.normalizeHeaderName(name);

    return this.headers[key];
  };

  /**
   * set request header
   * @param name
   * @param value
   */
  setHeader = (name, value) => {
    const key = this.normalizeHeaderName(name);

    this.headers[key] = value;
  };

  /**
   * get request headers
   * @returns {*}
   */
  getHeaders = () => ({ ...this.headers });

  /**
   * set new values for request headers
   * @param values
   */
  setHeaders = (values) => {
    this.headers = {};

    Object.keys(values).forEach((key) => {
      this.setHeader(key, values[key]);
    });
  };

  /**
   * merge values into existing request headers
   * @param values
   */
  mergeHeaders = (...values) => {
    const mergedValues = Object.assign({}, ...values);
    Object.keys(mergedValues).forEach((key) => {
      this.setHeader(key, mergedValues[key]);
    });
  };

  toJSON = () => this.getHeaders();
}

export default RequestHeaders;
