import runOnTabBlur from '../onTabBlur';

const runOnIdle =
  typeof requestIdleCallback === 'function' ? requestIdleCallback : setTimeout;

export const runTaskOnIdleOrOnTabClosed = (task) => {
  let isExecuted = false;

  const runTaskOnce = () => {
    if (!isExecuted) {
      task();
      isExecuted = true;
    }
  };

  // We don't call runOnIdle directly (`runOnIdle(runTaskOnce)`)
  // because IE11 loses the correct `this` when running in dev mode (`npm start`).
  // We don't know why, but this fixes it.
  runOnIdle.call(window, runTaskOnce);
  runOnTabBlur(runTaskOnce);
};
