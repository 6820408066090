import { Filter } from './types/Filter';
import { createSelector } from 'reselect';
import { isSidebarFilter } from '../../shared/domains/filters/specifications/filters';
import { getAutocompleteSource } from '../utils/autocompleteSource';
import { State } from '../types/State';

const selectReferenceDataState = (state: Pick<State, 'referenceData'>) =>
  state.referenceData || {};

export const selectFilterConfiguration = createSelector(
  selectReferenceDataState,
  (referenceData) => referenceData.filterData || []
);

const selectUnformattedMakeModelTree = createSelector(
  selectReferenceDataState,
  (referenceData) => referenceData.makeModelTree
);

export const selectMakeModelTree = createSelector(
  selectUnformattedMakeModelTree,
  getAutocompleteSource
);

export const selectSidebarFilterConfiguration = createSelector(
  selectFilterConfiguration,
  (filters) => filters.filter(isSidebarFilter)
);

export const createFilterSelector = createSelector(
  selectFilterConfiguration,
  (filters) => (filterName: string) =>
    filters.find(({ name }) => name === filterName)
);

export const selectMakeTitle = createSelector(
  selectFilterConfiguration,
  (filters) => filters.map(({ makeTitle }) => makeTitle).filter(Boolean)[0]
);

export const selectModelTitle = createSelector(
  selectFilterConfiguration,
  (filters) => filters.map(({ modelTitle }) => modelTitle).filter(Boolean)[0]
);

export const selectTrimTitle = createSelector(
  selectFilterConfiguration,
  (filters) => filters.map(({ trimTitle }) => trimTitle).filter(Boolean)[0]
);

export const selectTopMakes = createSelector(
  selectUnformattedMakeModelTree,
  (makeModelTree) => makeModelTree?.makes?.top ?? []
);

export const selectMakes = createSelector(
  selectUnformattedMakeModelTree,
  (makeModelTree) => makeModelTree?.makes?.all ?? []
);

export const selectModels = createSelector(
  selectUnformattedMakeModelTree,
  (makeModelTree) => makeModelTree?.models ?? {}
);

export const selectAllFilters = createSelector(
  selectFilterConfiguration,
  (filters): Filter[] =>
    filters.flatMap((filter) => [
      ...(filter.tabs?.flatMap((tab) => tab?.filters) || []),
      filter,
    ])
);

const buildReferenceData = (filterName: string) => (filters: Filter[]) =>
  filters
    .find(({ name }) => name === filterName)
    ?.values?.map(({ value, title }) => ({ i: value, n: title })) ?? [];

export const selectGears = createSelector(
  selectFilterConfiguration,
  buildReferenceData('TRANSMISSION')
);

export const selectCategories = createSelector(
  selectFilterConfiguration,
  buildReferenceData('BODY_TYPE')
);

export const selectFuelTypes = createSelector(
  selectFilterConfiguration,
  buildReferenceData('FUEL_TYPE')
);

const selectSingleMakeModelTrimForQueryString = (
  makeModelTree: ReturnType<typeof getAutocompleteSource>,
  query?: string
) => {
  if (!query) {
    return null;
  }

  const [makeIdPart, modelIdPart, trm] = query.split(';');
  const makeId = Number(makeIdPart);

  if (!makeId) {
    return null;
  }

  const modelId = Number(modelIdPart);

  const value = makeModelTree.find(({ parentId, i }) =>
    Number.isNaN(modelId)
      ? parentId === undefined && i === makeId
      : parentId === makeId && i === modelId
  );
  if (!trm) {
    return value;
  }
  return { trm, ...value };
};

// queryString makeModel query string, e.g. 20229;12
export const selectMakeModelForQueryString = (
  makeModelTree: Parameters<typeof selectSingleMakeModelTrimForQueryString>[0],
  queryString: string | string[] = []
) =>
  (Array.isArray(queryString) ? queryString : [queryString])
    .map((query) =>
      selectSingleMakeModelTrimForQueryString(makeModelTree, query)
    )
    .filter(Boolean);
