import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import AlternateLanguages from '../../shared/components/Head/AlternateLanguages/AlternateLanguages';
import SelfReferencingCanonicalLink from '../../shared/components/Head/SelfReferencingCanonicalLink/SelfReferencingCanonicalLink';
import { getPathName } from '../stores/location';
import { getSeoUrls as getSrpSeoUrls } from './srp/selectors';
import UniversalPageComponent from './UniversalPageComponent';
import PreloadPage from './PreloadPage';
import mapPagesToPreload from './mapPagesToPreload';

import { getCurrentLocale } from '../stores/i18n';
import { selectPageId } from '../stores/page';

type Props = {
  className?: string;
};

const Page: FC<Props> = ({ className }) => {
  const pageId = useSelector(selectPageId);
  const pathname = useSelector(getPathName);
  const currentLocale = useSelector(getCurrentLocale);
  const srpSeoUrls = useSelector(getSrpSeoUrls);

  const page = pageId || 'home';
  return (
    <div className={className}>
      <AlternateLanguages
        pathname={pathname}
        pageId={page}
        seoUrls={{ srp: srpSeoUrls }}
      />
      <SelfReferencingCanonicalLink
        pathname={pathname}
        pageId={page}
        seoUrls={{ srp: srpSeoUrls }}
        currentLocale={currentLocale}
      />
      <UniversalPageComponent page={page} error={page === 'error'} />
      {mapPagesToPreload[page]?.map((pageToPreload) => (
        <PreloadPage page={pageToPreload} key={pageToPreload} />
      ))}
    </div>
  );
};

export default Page;
