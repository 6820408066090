import { FinancingType } from './constants';

export const getTradeInValue = ({
  tradeInValueFromAPI,
  isMakingPayment,
  financingType,
  remainingPayment,
}: {
  tradeInValueFromAPI: number | null;
  isMakingPayment?: boolean;
  financingType?: FinancingType;
  remainingPayment: string;
}) => {
  // calculate trade-in value
  let remainingPaymentNumber = 0;
  if (
    isMakingPayment &&
    financingType === FinancingType.Financing &&
    remainingPayment !== ''
  ) {
    remainingPaymentNumber = parseInt(remainingPayment, 10);
  }

  return (tradeInValueFromAPI ?? 0) - remainingPaymentNumber;
};
