import { EVENT_KEYS, trackOptimizelyEvent } from '../../../lib/optimizely';
import { selectUserId } from '../../stores/authentication';
import { selectId, selectIsDealer } from '../../pages/vip/selectors';
import { GALLERY_TRACKING_LABEL } from '../../pages/vip/constants';
import selectTrackingPageName from '../../utils/tracking/selectTrackingPageName';
import { PAGE_TYPES_MAP } from '../../utils/tracking/pageTypesMap';
import { isFirstGuestMessage } from './utils/conversationHistory';
import track from '../../utils/tracking/track';

import * as types from './actionTypes';
import {
  MESSAGE_SEND_BEGIN,
  MESSAGE_SEND_CANCEL,
  MESSAGE_SEND_SUCCESS,
  R2S_CHAT_SUCCESS,
} from './tracking';
import { getChatFeature, getChatInfo } from './selectors';

const getRoleFromParticipants = (participants = [], userId) => {
  const participant = participants.find((item) => item.userId === userId);

  return participant ? participant.role : false;
};

export default {
  [types.HANDLE_RESPONSE_SEND_GUEST_MESSAGE]: (
    {
      payload: {
        conversationId,
        listingId,
        participants,
        sendMessageEventLabel,
      },
    },
    { state }
  ) => {
    const { role } = participants?.[0];
    if (!role) {
      return;
    }

    const isFirstMessage = isFirstGuestMessage(listingId);
    const isDealerListing = selectIsDealer(state);

    const trackPayload = {
      currentUserRole: 'buyer',
      conversationId,
      listingId,
      sendMessageEventLabel,
      isDealerListing,
    };
    if (isFirstMessage) {
      track(R2S_CHAT_SUCCESS, state, trackPayload);
      if (isDealerListing) {
        trackOptimizelyEvent(EVENT_KEYS.R2S_CHAT_SUCCESS_DEALER);
      }

      if (sendMessageEventLabel?.includes(GALLERY_TRACKING_LABEL)) {
        trackOptimizelyEvent(EVENT_KEYS.R2S_CHAT_SUCCESS_GALLERY);
      }
    } else {
      track(MESSAGE_SEND_SUCCESS, state, trackPayload);
    }
  },

  [types.HANDLE_RESPONSE_SEND_MESSAGE]: (
    {
      payload: {
        conversationId,
        listingId,
        id,
        participants,
        sendMessageEventLabel,
      },
    },
    { state }
  ) => {
    const userId = selectUserId(state);
    const currentUserRole = getRoleFromParticipants(participants, userId);

    if (!currentUserRole) {
      return;
    }

    const { conversationTable } = getChatFeature(state);
    const { messageList } = getChatInfo(conversationTable, listingId);
    const isFirstMessage = messageList.length === 1;
    const hasImage = messageList.some(
      (message) => message.id === id && message.image
    );
    const isDealerListing = selectIsDealer(state);

    const trackPayload = {
      currentUserRole,
      conversationId,
      listingId,
      hasImage,
      sendMessageEventLabel,
      isDealerListing,
    };

    if (isFirstMessage) {
      track(R2S_CHAT_SUCCESS, state, trackPayload);
      if (isDealerListing) {
        trackOptimizelyEvent(EVENT_KEYS.R2S_CHAT_SUCCESS_DEALER);
      }

      if (sendMessageEventLabel?.includes(GALLERY_TRACKING_LABEL)) {
        trackOptimizelyEvent(EVENT_KEYS.R2S_CHAT_SUCCESS_GALLERY);
      }
    } else {
      track(MESSAGE_SEND_SUCCESS, state, trackPayload);
    }
  },

  [types.TOGGLE_CHAT]: (_, { state }) => {
    const { isOpened } = getChatFeature(state);
    const pageType = selectTrackingPageName(state);
    const listingId = selectId(state);

    if (!isOpened) {
      track(MESSAGE_SEND_CANCEL, state, { pageType });
    } else if (pageType === PAGE_TYPES_MAP.inbox) {
      track(MESSAGE_SEND_BEGIN, state, {
        pageType,
        listingId,
      });
    }
  },

  [types.CLOSE_CHAT]: (_, { prevState }) => {
    track(MESSAGE_SEND_CANCEL, prevState);
  },
};
