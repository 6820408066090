export const HANDLE_RESPONSE_FETCH_NOTIFICATIONS =
  'feature/notifications/HANDLE_RESPONSE_FETCH_NOTIFICATIONS';
export const HANDLE_RESPONSE_ERROR_FETCH_NOTIFICATIONS =
  'feature/notifications/HANDLE_RESPONSE_ERROR_FETCH_NOTIFICATIONS';

export const HANDLE_RESPONSE_FETCH_NOTIFICATIONS_NEW_COUNT =
  'feature/notifications/HANDLE_RESPONSE_FETCH_NOTIFICATIONS_NEW_COUNT';

export const HANDLE_RESPONSE_DELETE_NOTIFICATIONS_NEW_COUNT =
  'feature/notifications/HANDLE_RESPONSE_DELETE_NOTIFICATIONS_NEW_COUNT';
