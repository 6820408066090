import selectTrackingPageName from '../../utils/tracking/selectTrackingPageName';
import track from '../../utils/tracking/track';
import * as types from './actionTypes';
import { getConversationById, selectSellerTypeForTracking } from './selectors';
import {
  INBOX_MESSAGE_SEND_BEGIN,
  INBOX_MESSAGE_SEND_CANCEL,
} from './tracking';

export default {
  [types.SELECT_CONVERSATION]: ({ payload: { selectedId } }, { prevState }) => {
    const conversation = getConversationById(prevState, {
      conversationId: selectedId,
    });
    const pageType = selectTrackingPageName(prevState);
    track(INBOX_MESSAGE_SEND_BEGIN, prevState, {
      pageType,
      conversationId: selectedId,
      listingId: conversation?.adId,
      sellerType: selectSellerTypeForTracking(conversation),
    });
  },
  [types.CLOSE_CONVERSATION]: (_, { prevState }) => {
    const pageType = selectTrackingPageName(prevState);
    track(INBOX_MESSAGE_SEND_CANCEL, prevState, { pageType });
  },
};
