import {
  getLocalStorageObject,
  setLocalStorageObject,
} from '../../shared/utils/localStorage';

import onTabBlur from '../onTabBlur';
import joinValues from './joinValues';
import { GA_SESSION_LENGTH, TIME_ZONE_OFFSET } from './config';

const SESSION_EXPERIMENTS = 'sessionExperiments';
let initialized = false;
let sessionExperiments;

const initSessionExperiments = () => {
  if (initialized) {
    return;
  }

  sessionExperiments = getLocalStorageObject(SESSION_EXPERIMENTS, {});

  onTabBlur(() => {
    setLocalStorageObject(SESSION_EXPERIMENTS, sessionExperiments);
  });

  initialized = true;
};

const uniqByTicketCode = (items) =>
  [...new Map(items.map((item) => [item.ticketCode, item]))].map(
    ([, item]) => item
  );

const getTodayMidnightMs = (now) => {
  const todayDate = new Date(now + TIME_ZONE_OFFSET).toISOString();
  const [year, month, day] = todayDate.split('T')[0].split('-');
  const todayMidnightMs = Date.UTC(year, month - 1, day) - TIME_ZONE_OFFSET;
  return todayMidnightMs;
};

// a Google Analytics session expires when:
//  * GA_SESSION_LENGTH exceeds without user activity
//  * day changes (in specific timezone) between last activity and now
const hasSessionExpired = () => {
  const now = Date.now();
  return (
    !sessionExperiments?.timestamp ||
    now > sessionExperiments.timestamp + GA_SESSION_LENGTH ||
    sessionExperiments.timestamp < getTodayMidnightMs(now)
  );
};

const getSessionExperiments = () =>
  hasSessionExpired() || !sessionExperiments?.experiments
    ? []
    : sessionExperiments.experiments;

const setSessionExperiments = (experiments) => {
  sessionExperiments = {
    experiments,
    timestamp: Date.now(),
  };
};

const mergeWithSessionExperiments = (activeExperiments) =>
  uniqByTicketCode(
    [...getSessionExperiments(), ...activeExperiments].filter(
      (x) => x && x.ticketCode && x.activeVariation
    )
  );

export const getActiveExperimentArray = (activeExperiments) => {
  initSessionExperiments();

  const experiments = mergeWithSessionExperiments(activeExperiments).map(
    ({ ticketCode, activeVariation }) => ({
      ticketCode,
      activeVariation,
    })
  );
  setSessionExperiments(experiments);
  return experiments.map(
    ({ ticketCode, activeVariation }) => `${ticketCode}-${activeVariation}`
  );
};

export default (activeExperiments) =>
  joinValues(getActiveExperimentArray(activeExperiments));
