import { State } from './types/State';
import { filter, union, without } from 'lodash';
import {
  REQUEST_SAVED_ADS,
  REQUEST_MORE_SAVED_ADS,
  HANDLE_RESPONSE_ERROR_SAVED_ADS,
  HANDLE_RESPONSE_SAVED_ADS,
  HANDLE_DISABLE_EDIT_MODE,
  HANDLE_UNSELECT_ALL,
  HANDLE_ENABLE_EDIT_MODE,
  HANDLE_UNSELECT_ITEM,
  HANDLE_SELECT_ITEM,
  REQUEST_REMOVE_ITEMS,
  HANDLE_RESPONSE_ERROR_REMOVE_ITEMS,
  HANDLE_RESPONSE_REMOVE_ITEMS,
} from './actionTypes';
import { Listing } from '../../shared/types/Listing';
import {
  requestSavedAdsAction,
  requestMoreSavedAdsAction,
  receiveSavedAdsAction,
  handleSavedAdsErrorAction,
  enableEditModeAction,
  disableEditModeAction,
  selectItemAction,
  unselectItemAction,
  unselectAllAction,
  removeItemsAction,
  handleRemoveItemsSuccessAction,
  handleRemoveItemsErrorAction,
} from './actionCreators';

export const defaultState: State = {
  isLoading: true,
  isPending: false,
  editMode: false,
  hasMore: true,
  offset: 0,
  selectedIds: [],
  ads: [],
};

export type SavedItemsListAction =
  | ReturnType<typeof requestSavedAdsAction>
  | ReturnType<typeof requestMoreSavedAdsAction>
  | ReturnType<typeof receiveSavedAdsAction>
  | ReturnType<typeof handleSavedAdsErrorAction>
  | ReturnType<typeof enableEditModeAction>
  | ReturnType<typeof disableEditModeAction>
  | ReturnType<typeof selectItemAction>
  | ReturnType<typeof unselectItemAction>
  | ReturnType<typeof unselectAllAction>
  | ReturnType<typeof handleRemoveItemsSuccessAction>
  | ReturnType<typeof handleRemoveItemsErrorAction>
  | ReturnType<typeof removeItemsAction>;

/*
 * helper
 */
/**
 * mark ads in a list as "removed"
 */
const removeAds = (ads: Listing[], ids: string[]) =>
  ads.map((ad) => (ids.includes(ad.id) ? { ...ad, isRemoved: true } : ad));
/**
 * restore marked ads in a list
 */
const restoreAds = (ads: Listing[], ids: string[]) =>
  ads.map((ad) => (ids.includes(ad.id) ? { ...ad, isRemoved: false } : ad));
/**
 * deletes ads from a list
 */
const deleteAds = (ads: Listing[], ids: string[]) =>
  filter(ads, (ad) => !ids.includes(ad.id));

export default (state = defaultState, action: SavedItemsListAction) => {
  switch (action.type) {
    case REQUEST_REMOVE_ITEMS:
      return {
        ...state,
        isPending: true,
        error: null,
        ads: removeAds(state.ads, action.ids),
      };
    case HANDLE_RESPONSE_ERROR_REMOVE_ITEMS:
      return {
        ...state,
        isPending: false,
        error: action.error,
        ads: restoreAds(state.ads, action.ids),
      };
    case HANDLE_RESPONSE_REMOVE_ITEMS:
      return {
        ...state,
        isPending: false,
        error: null,
        ads: deleteAds(state.ads, action.ids),
      };
    case HANDLE_ENABLE_EDIT_MODE:
      return {
        ...state,
        editMode: true,
      };
    case HANDLE_DISABLE_EDIT_MODE:
      return {
        ...state,
        editMode: false,
      };
    case HANDLE_UNSELECT_ALL:
      return {
        ...state,
        selectedIds: [],
      };
    case HANDLE_SELECT_ITEM:
      return {
        ...state,
        selectedIds: union(state.selectedIds, [action.id]),
      };
    case HANDLE_UNSELECT_ITEM:
      return {
        ...state,
        selectedIds: without(state.selectedIds, action.id),
      };
    case REQUEST_SAVED_ADS:
      return {
        ...state,
        ads: [],
        error: null,
        hasMore: false,
        isLoading: true,
        offset: 0,
      };
    case HANDLE_RESPONSE_ERROR_SAVED_ADS:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case HANDLE_RESPONSE_SAVED_ADS:
      return {
        ...state,
        ads: [...state.ads, ...action.payload],
        error: null,
        hasMore: action.hasMore,
        isLoading: false,
        offset: state.offset + action.payload.length,
      };
    case REQUEST_MORE_SAVED_ADS:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    default:
      return state;
  }
};
