import { AnyAction } from 'redux';
import {
  HANDLE_REQUEST_REPORT_REQUEST,
  HANDLE_REQUEST_REPORT_REQUEST_FAILURE,
  HANDLE_REQUEST_REPORT_SUCCESS,
} from './actionTypes';
import { REQUEST_REPORT_STATES } from './types';

const requestReportModal = {
  [HANDLE_REQUEST_REPORT_REQUEST_FAILURE]: () => ({
    requestReportState: REQUEST_REPORT_STATES.PENDING,
  }),
  [HANDLE_REQUEST_REPORT_REQUEST]: () => ({
    requestReportState: REQUEST_REPORT_STATES.REQUESTED,
  }),
  [HANDLE_REQUEST_REPORT_SUCCESS]: () => ({
    requestReportState: REQUEST_REPORT_STATES.SUCCESS,
  }),
};

const defaultState = {
  requestReportState: REQUEST_REPORT_STATES.PENDING,
};

export default (state = defaultState, action?: AnyAction) =>
  requestReportModal[action?.type]
    ? requestReportModal[action?.type](state, action?.payload)
    : state;
