import { useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentLocale } from '../../../../stores/i18n';
import { getChatFeature } from '../../../../features/contactForm';
import { setCustomDataWithDefaults } from '../../GetFeedback';

export const useNPSAfterMessageSurvey = () => {
  const locale = useSelector(getCurrentLocale);
  const { messageStatus } = useSelector(getChatFeature);
  const { sentSuccess } = messageStatus;

  const startSurvey = useCallback(
    () =>
      sentSuccess &&
      setCustomDataWithDefaults({
        locale,
        campaign: 'nps_after_message_survey',
      }),
    [locale, sentSuccess]
  );

  useEffect(() => {
    startSurvey();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sentSuccess]);
};
