import { MakeModel } from '../../../../shared/types/MakeModel';
import { Filter } from '../../../../stores/referenceData';
import { Query } from '../../../../stores/searchQuery';
import { sanitizeBodyType } from './bodyType';
import { sanitizeEngineSize } from './engineSize';
import { sanitizeMakeModelTrim } from './makeModelTrim';

const FILTER_SANITIZER_PER_TYPE = {
  c: sanitizeBodyType,
  ms: sanitizeMakeModelTrim,
  engineSize: sanitizeEngineSize,
};

export const sanitizeQueryParams = ({
  filterConfiguration,
  queryParams,
  optionsQueryParam,
  makeModelTree,
  preparatoryQuery,
}: {
  filterConfiguration: Filter[];
  queryParams: Query;
  optionsQueryParam: Query;
  makeModelTree: MakeModel[];
  preparatoryQuery: Query;
}) =>
  filterConfiguration
    .filter((filter) => filter?.param)
    .filter((filter) => queryParams[filter.param || ''])
    .map(({ param, values }) => ({
      param,
      values,
      selectedValues: queryParams[param || ''],
    }))
    .reduce(
      (result, filter) => ({
        ...result,
        [filter.param || '']: FILTER_SANITIZER_PER_TYPE[filter.param || '']
          ? FILTER_SANITIZER_PER_TYPE[filter.param || '']({
              optionsQueryParam,
              filter,
              makeModelTree,
              preparatoryQuery,
            })
          : queryParams[filter.param || ''],
      }),
      {}
    );
