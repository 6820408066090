const LABEL_HAS_ATTACHMENT = 'attach';
const LABEL_NO_ATTACHMENT = 'noattach';

export const setAttachmentLabel = (hasImage, sendMessageEventLabel) => {
  let attachNoAttach = '';
  const messageLabel =
    sendMessageEventLabel && sendMessageEventLabel.endsWith(';')
      ? sendMessageEventLabel.slice(0, -1)
      : sendMessageEventLabel;

  if (!messageLabel || !messageLabel.includes('quick_message_text')) {
    attachNoAttach = hasImage ? LABEL_HAS_ATTACHMENT : LABEL_NO_ATTACHMENT;
  }

  return messageLabel ? `${messageLabel};${attachNoAttach}` : attachNoAttach;
};
