import React from 'react';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';
import { getLinkForLocales } from '../../../../lib/locale-utils';
import { DEFAULT_LOCALE } from '../../../config';

type LanguageLinkProps = {
  hrefLang?: string;
  href?: string | undefined;
  locale?: string;
};

const getLanguageLink = ({
  href,
  locale,
  hrefLang = locale?.toLowerCase(),
}: LanguageLinkProps) => (
  <link key={hrefLang} rel="alternate" hrefLang={hrefLang} href={href} />
);

type AlternateLanguagesProps = {
  pageId?: string;
  pathname?: string;
  seoUrls: Record<string, Record<string, string | undefined>>;
};

const AlternateLanguages = ({
  pathname = '',
  seoUrls,
  pageId = '',
}: AlternateLanguagesProps) => {
  if (['error', 'notFound'].includes(pageId)) {
    return null;
  }

  const linksForAllLocales = getLinkForLocales(pathname, pageId, seoUrls);
  const linkForDefaultLocale = linksForAllLocales.find(
    (link) => link.locale.toLowerCase() === DEFAULT_LOCALE.toLowerCase()
  );

  const linksForAllLocalesWithDefault = [
    ...linksForAllLocales,
    { ...linkForDefaultLocale, hrefLang: 'x-default' },
  ];

  return <Helmet>{linksForAllLocalesWithDefault.map(getLanguageLink)}</Helmet>;
};

AlternateLanguages.propTypes = {
  pathname: PropTypes.string,
  pageId: PropTypes.string,
  seoUrls: PropTypes.objectOf(PropTypes.objectOf(PropTypes.string)),
};

export default AlternateLanguages;
