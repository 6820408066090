import { uniqBy } from 'lodash';
import {
  addRecentlyChangedFilter,
  closeDSP,
  incrementChangesCount,
  openDSP,
  resetChangesCount,
  resetFilterModal,
  resolveLocation,
  setFilterModal,
} from './actionCreators';
import { Action } from './types/Action';
import { State } from './types/State';

export const defaultState: State = {
  recentlyChangedFilters: [],
  resolvedLocation: {},
  filterModal: null,
  dspIsOpen: false,
  changesCount: 0,
};

type ActionType = ReturnType<
  | typeof addRecentlyChangedFilter
  | typeof resolveLocation
  | typeof setFilterModal
  | typeof resetFilterModal
  | typeof openDSP
  | typeof closeDSP
  | typeof incrementChangesCount
  | typeof resetChangesCount
>;

export default (state = defaultState, action?: ActionType) => {
  switch (action?.type) {
    case Action.RecentlyChangedFilter:
      const { payload } = action as ReturnType<typeof addRecentlyChangedFilter>;
      return {
        ...state,
        recentlyChangedFilters: uniqBy(
          [payload, ...state.recentlyChangedFilters],
          'paramName'
        ),
        changesCount: state.changesCount + 1,
      };
    case Action.ResolveLocation:
      const { payload: resolvedLocation } = action as ReturnType<
        typeof resolveLocation
      >;
      return { ...state, resolvedLocation };
    case Action.SetFilterModal:
      const { payload: filterModal } = action as ReturnType<
        typeof setFilterModal
      >;
      return { ...state, filterModal };
    case Action.ResetFilterModal:
      return { ...state, filterModal: defaultState.filterModal };
    case Action.OpenDSP:
      return { ...state, dspIsOpen: true };
    case Action.CloseDSP:
      return { ...state, dspIsOpen: false };
    case Action.IncrementChangesCount:
      return { ...state, changesCount: state.changesCount + 1 };
    case Action.ResetChangesCount:
      return { ...state, changesCount: 0 };
    default:
      return state;
  }
};
