import RequestController from '../../utils/RequestController';
import {
  HANDLE_RESPONSE_MIP,
  HANDLE_RESPONSE_ERROR_MIP,
  SET_MIP_REFERRER,
  REQUEST_MIP,
  INITIAL_REQUEST_MIP,
  UPDATE_NAVIGATION,
  MIP_LOADED,
  EXIT_MIP,
  REQUEST_VIP_LISTINGS,
  RECEIVE_VIP_LISTINGS,
  ERROR_VIP_LISTINGS,
} from './actionTypes';
import { MAX_NUMBER_OF_LISTINGS } from './config';
import { fetchMipByUrl, fetchMipById } from './api';
import { selectMakeModel } from './selectors';
import { getListings } from '../../../api/getListings';
import { REQUEST_HEADER } from '../../../api/lib/apiClient/requestHeaders';
import { goToSrp as goToSrpRoute } from '../../stores/page';
import { transformMakeModelToQueryString } from '../../utils/filters/makeModel';
import getLocationSearchQueryParams from '../../shared/utils/getLocationSearchQueryParams';

const requestController = new RequestController();

const handleResponseErrorMip = () => ({
  type: HANDLE_RESPONSE_ERROR_MIP,
});

const handleInitialRequestMip = () => ({
  type: INITIAL_REQUEST_MIP,
});

const handleRequestMip = () => ({
  type: REQUEST_MIP,
});

const exitMip = () => (dispatch) => {
  if (!IS_SERVER) {
    window.scrollTo(0, 0);
  }
  dispatch({
    type: EXIT_MIP,
  });
};

export const handleResponseMip = (payload) => ({
  type: HANDLE_RESPONSE_MIP,
  payload,
});

export const setReferrer = (referrer) => ({
  type: SET_MIP_REFERRER,
  payload: referrer,
});

export const loaded = () => ({
  type: MIP_LOADED,
});

export const updateNavigation = ({ generationId, versionId }) => ({
  type: UPDATE_NAVIGATION,
  payload: { generationId, versionId },
});

export const getMipByUrl =
  (url, options = {}) =>
  async (dispatch) => {
    dispatch(handleInitialRequestMip());
    const data = await fetchMipByUrl(url, options);
    dispatch(handleResponseMip(data));
  };

export const getMipById =
  ({ make, model, generation, version }, options = {}) =>
  async (dispatch) => {
    dispatch(handleRequestMip());

    try {
      const data = await requestController.resolveWithLatest(
        fetchMipById({ make, model, generation, version }, options)
      );
      dispatch(handleResponseMip(data));
    } catch (err) {
      if (requestController.isCanceledError(err)) {
        throw err;
      }
      dispatch(handleResponseErrorMip());
    }
  };

export const requestMatchingListings =
  ({ makeModel }) =>
  async (dispatch, getState) => {
    const locationSearchQueryParams = getLocationSearchQueryParams(getState());

    dispatch({
      type: REQUEST_VIP_LISTINGS,
    });

    const query = {
      ms: transformMakeModelToQueryString([
        {
          parentId: makeModel.makeId,
          i: makeModel.modelId,
        },
      ]),
      psz: MAX_NUMBER_OF_LISTINGS,
      ...locationSearchQueryParams,
    };

    try {
      const { items, numResultsTotal } = await getListings(query, {
        headers: {
          [REQUEST_HEADER.TRACK_RECENT_SEARCH]: false,
          [REQUEST_HEADER.SEARCH_SOURCE]: 'mip',
        },
      });
      dispatch({
        type: RECEIVE_VIP_LISTINGS,
        items,
        numResultsTotal,
      });
    } catch (err) {
      dispatch({
        type: ERROR_VIP_LISTINGS,
        error: err,
      });
    }
  };

export const goToSrp = () => (dispatch, getState) => {
  const makeModel = selectMakeModel(getState());
  const query = {
    ms: transformMakeModelToQueryString([
      {
        parentId: makeModel.makeId,
        i: makeModel.modelId,
      },
    ]),
  };

  dispatch(exitMip());
  dispatch(goToSrpRoute(query));
};
