export const PAGE_TYPES = {
  HOME: 'home',
  SRP_WITH_SEARCH_RESULT: 'srpWithSearchResult',
  SRP_WITH_CATEGORY: 'srpWithCategory',
  VIP: 'vip',
  VIP_AFTER_MESSAGE: 'vipAfterMessage',
};

/**
 * @deprecated Please use the Page enum from src/app/feeatures/advertising instead
 */
export const PAGE = {
  HOME: 'home',
  SRP: 'srp',
  VIP: 'vip',
};
