export const REQUEST_SAVED_ADS = 'feature/savedItemsList/REQUEST_SAVED_ADS';
export const HANDLE_RESPONSE_SAVED_ADS =
  'feature/savedItemsList/HANDLE_RESPONSE_SAVED_ADS';
export const HANDLE_RESPONSE_ERROR_SAVED_ADS =
  'feature/savedItemsList/HANDLE_RESPONSE_ERROR_SAVED_ADS';

export const REQUEST_MORE_SAVED_ADS =
  'feature/savedItemsList/REQUEST_MORE_SAVED_ADS';

export const REQUEST_REMOVE_ITEMS =
  'feature/savedItemsList/REQUEST_REMOVE_ITEMS';
export const HANDLE_RESPONSE_REMOVE_ITEMS =
  'feature/savedItems/HANDLE_RESPONSE_REMOVE_ITEMS';
export const HANDLE_RESPONSE_ERROR_REMOVE_ITEMS =
  'feature/savedItems/HANDLE_RESPONSE_ERROR_REMOVE_ITEMS';

export const HANDLE_ENABLE_EDIT_MODE =
  'feature/savedItemsList/HANDLE_ENABLE_EDIT_MODE';
export const HANDLE_DISABLE_EDIT_MODE =
  'feature/savedItemsList/HANDLE_DISABLE_EDIT_MODE';
export const HANDLE_SELECT_ITEM = 'feature/savedItemsList/HANDLE_SELECT_ITEM';
export const HANDLE_UNSELECT_ITEM =
  'feature/savedItemsList/HANDLE_UNSELECT_ITEM';
export const HANDLE_UNSELECT_ALL = 'feature/savedItemsList/HANDLE_UNSELECT_ALL';
