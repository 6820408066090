import querystring from 'querystring';
import { get } from 'lodash';
import { addLocalePrefixToUrl } from '../../../lib/locale-utils';
import { SRP_BASE_PATHS, TRAILING_SLASH_URLS } from '../../../shared/config';
import {
  createSortingParams,
  mapSortParamToNameAndDirection,
} from './utils/sorting';
import { getCurrentLocale } from '../../stores/i18n';
import {
  getListing,
  getListingsByParams,
  getSrpByParams as getSrpByParamsApi,
  getSrpByUrl as getSrpByUrlApi,
} from './api';
import { SEARCH_PERFORMED } from './tracking';
import getDefaultSearchQueryParams from '../../shared/utils/getDefaultSearchQueryParams';
import getLocationSearchQueryParams from '../../shared/utils/getLocationSearchQueryParams';
import { getQueryWithDefaults } from './utils/getQueryWithDefaults';
import { pushHistoryWithQueryURL } from './domain/services/queryURL';
import {
  shouldLoadHashedVipFromURL,
  shouldLoadSrpQueryFromURL,
} from './domain/specifications/queryURL';
import {
  selectIsSRP,
  showErrorPageAction,
  showNotFoundPageAction,
} from '../../stores/page';
import { getSearch, getSorting } from './selectors';
import { LAZY_LOADING_TYPE } from './types';
import {
  applyQuery,
  Query,
  selectPreparatoryQuery,
  selectQuery,
} from '../../stores/searchQuery';

import {
  CLEAR_CHAT,
  FIRST_ADVERTISING_TRACK,
  HANDLE_RESPONSE_ERROR_MORE_SEARCH_RESULTS,
  HANDLE_RESPONSE_ERROR_SORTED_SEARCH_RESULTS,
  HANDLE_RESPONSE_HASHED_VIP,
  HANDLE_RESPONSE_MISSING_HASHED_VIP,
  HANDLE_RESPONSE_MORE_SEARCH_RESULTS,
  HANDLE_RESPONSE_SEARCH_RESULTS,
  HANDLE_RESPONSE_SORTED_SEARCH_RESULTS,
  LAZY_LOADING_FINISHED,
  LAZY_LOADING_STARTED,
  LOADING_FINISHED,
  LOADING_STARTED,
  PAGE_UNMOUNTED,
  REQUEST_MORE_SEARCH_RESULTS,
  REQUEST_SEARCH_RESULTS,
  REQUEST_SORTED_SEARCH_RESULTS,
  RESET_CONTENT,
  RESET_SORTING,
  RESTORE_CHAT,
  SELECT_ITEM,
  SET_IS_INFINITE_SCROLL,
  SET_SKIP_BY_URL_REQUEST,
  SET_SKIP_PAGE_REQUEST,
  SET_SORTING,
} from './actionTypes';

import RequestController from '../../utils/RequestController';

import { getDefaultSortParams } from './utils/getDefaultSortParams';
import track from '../../utils/tracking/track';
import { getQueryAsObject } from '../../../shared/utils/getQueryAsObject';
import { sanitizeQueryParams } from './domain/querySanitizers';
import {
  selectFilterConfiguration,
  selectMakeModelTree,
} from '../../stores/referenceData';
import { getQueryString } from '../../stores/location';
import { getForwardedUrlParams } from '../../shared/domains/filters/utils/getForwardedUrlParams';
import { State } from './types/State';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { State as GlobalState } from '../../stores/types/State';
import { Sorting } from '../../shared/domains/sorting/types/Sorting';

const requestController = new RequestController();

const is404 = ({
  locale,
  url,
  payload,
}: {
  locale: string;
  url: string;
  payload: Pick<State, 'url'>;
}) => {
  const isSrpBasePath =
    addLocalePrefixToUrl(
      locale,
      `${SRP_BASE_PATHS[locale]}${TRAILING_SLASH_URLS ? '/' : ''}`
    ) === url;

  const isSeoUrlServiceNotReachable = [502, 503, 504].includes(
    get(payload, 'url.error.statusCode')
  );

  return (
    !isSrpBasePath &&
    !get(payload, `url.seoUrls.${locale}`) &&
    !isSeoUrlServiceNotReachable
  );
};

export const loadingStarted = () => ({ type: LOADING_STARTED } as const);

export const loadingFinished = () => ({ type: LOADING_FINISHED } as const);

export const lazyLoadFinished = () =>
  ({ type: LAZY_LOADING_FINISHED } as const);

export const signalizePageUnmounted = () => ({ type: PAGE_UNMOUNTED } as const);

export const requestSearchResults = () =>
  ({ type: REQUEST_SEARCH_RESULTS } as const);

export const handleResponseSearchResults = (
  payload: Pick<State, 'content' | 'related' | 'url'> & {
    listings?: Pick<
      State,
      | 'items'
      | 'queryParamsHash'
      | 'priceRange'
      | 'numResultsTotal'
      | 'nextPageToken'
    >;
  }
) =>
  ({
    type: HANDLE_RESPONSE_SEARCH_RESULTS,
    payload,
  } as const);

export const handleResponseHashedVip = (
  payload: NonNullable<State['hashedVip']>
) =>
  ({
    type: HANDLE_RESPONSE_HASHED_VIP,
    payload,
  } as const);

export const handleResponseMissingHashedVip = (
  payload: NonNullable<State['hashedVip']>
) =>
  ({
    type: HANDLE_RESPONSE_MISSING_HASHED_VIP,
    payload,
  } as const);

export const requestMoreSearchResults = () =>
  ({
    type: REQUEST_MORE_SEARCH_RESULTS,
  } as const);

export const handleResponseMoreSearchResults = (
  payload: Pick<State, 'nextPageToken' | 'items'>
) =>
  ({
    type: HANDLE_RESPONSE_MORE_SEARCH_RESULTS,
    payload,
  } as const);

export const handleResponseErrorMoreSearchResults = () =>
  ({
    type: HANDLE_RESPONSE_ERROR_MORE_SEARCH_RESULTS,
  } as const);

export const setSkipByUrlRequest = (
  skipByUrlRequest: State['skipByUrlRequest']
) =>
  ({
    type: SET_SKIP_BY_URL_REQUEST,
    payload: skipByUrlRequest,
  } as const);

export const setSkipPageRequest = (skipPageRequest: State['skipPageRequest']) =>
  ({
    type: SET_SKIP_PAGE_REQUEST,
    payload: skipPageRequest,
  } as const);

export const setSortingAction = (sorting: Sorting) =>
  ({
    type: SET_SORTING,
    sorting,
  } as const);

export const getSrpByUrl =
  (
    url: string,
    options: {
      locale?: string;
      queryParams?: Query;
      additionalParam?: string;
    } = {}
  ) =>
  async (
    dispatch: ThunkDispatch<GlobalState, unknown, AnyAction>,
    getState: () => GlobalState
  ): Promise<AnyAction> => {
    const state = getState();
    const locationSearchQueryParams = getLocationSearchQueryParams(state);
    let queryParams = {
      ...getDefaultSortParams(),
      ...options.queryParams,
      ...locationSearchQueryParams,
    };

    if (options.queryParams) {
      const filterConfiguration = selectFilterConfiguration(state);
      const preparatoryQuery = selectPreparatoryQuery(state);
      const makeModelTree = selectMakeModelTree(state);

      const sanitizedQueryParams = sanitizeQueryParams({
        filterConfiguration,
        queryParams,
        optionsQueryParam: options.queryParams,
        preparatoryQuery,
        makeModelTree,
      });

      queryParams = {
        ...queryParams,
        ...sanitizedQueryParams,
      };
    }

    const urlParams = getForwardedUrlParams(getQueryString(state));
    const payload = await getSrpByUrlApi(url, {
      ...options,
      additionalParam: [options.additionalParam, urlParams]
        .filter(Boolean)
        .join(''),
      queryParams,
    });

    const locale = getCurrentLocale(state);
    if (is404({ locale, url, payload })) {
      return dispatch(showNotFoundPageAction());
    }

    const parsedQuery = getQueryAsObject(payload.url?.requestParams || '');
    const requestParamsQuery = {
      ...parsedQuery,
      ms: parsedQuery.ms,
    };

    const defaultSorting = getDefaultSortParams();
    const query = {
      ...defaultSorting,
      ...options.queryParams,
      ...queryParams,
      ...requestParamsQuery,
      ...locationSearchQueryParams,
      ...getDefaultSearchQueryParams(),
      // On SSR queryParams returns false so we just check for trim inside make/model on client-side
      ...((queryParams.ms || []).length > 0 ? { ms: queryParams.ms } : {}),
    };

    const sorting = mapSortParamToNameAndDirection(query);
    if (sorting) {
      dispatch(setSortingAction(sorting));
    }

    dispatch(applyQuery(query));

    return dispatch(handleResponseSearchResults(payload));
  };

export const getSrpByParams =
  (query: Query, options: Parameters<typeof getSrpByParamsApi>[1] = {}) =>
  async (
    dispatch: ThunkDispatch<GlobalState, never, AnyAction>,
    getState: () => GlobalState
  ) => {
    try {
      const state = getState();
      const urlParams = getForwardedUrlParams(getQueryString(state));
      const payload = await requestController.resolveWithLatest(
        getSrpByParamsApi(query, {
          ...options,
          additionalParam: [options.additionalParam, urlParams]
            .filter(Boolean)
            .join(''),
        })
      );
      const url = {
        ...payload.url,
        requestParams: querystring.stringify(query),
      };
      dispatch(handleResponseSearchResults({ ...payload, url }));
    } catch (err) {
      if (!requestController.isCanceledError(err as Error)) {
        throw err;
      }
    }
  };

export const searchMoreResults =
  (
    sorting?: Partial<Sorting>
  ): ThunkAction<void, GlobalState, never, AnyAction> =>
  async (dispatch, getState) => {
    const state = getState();
    const query = selectQuery(state);
    const locale = getCurrentLocale(state);
    const { nextPageToken } = getSearch(state);
    const urlParams = getForwardedUrlParams(getQueryString(state));

    const newQuery = getQueryWithDefaults(query);
    const sortedQuery = {
      ...newQuery,
      ...createSortingParams(sorting),
    };

    dispatch(applyQuery(sortedQuery));
    dispatch(requestMoreSearchResults());

    try {
      const data = await getListingsByParams(
        nextPageToken
          ? { ...sortedQuery, pageToken: nextPageToken }
          : sortedQuery,
        { locale, additionalParam: urlParams }
      );
      dispatch(handleResponseMoreSearchResults(data));
    } catch {
      dispatch(handleResponseErrorMoreSearchResults());
    }
  };

export const resetSortingAction = () => ({ type: RESET_SORTING } as const);

export const resetContentAction = () => ({ type: RESET_CONTENT } as const);

export const requestSortedSearchResultsAction = (sorting?: Sorting) =>
  ({
    type: REQUEST_SORTED_SEARCH_RESULTS,
    sorting,
  } as const);

export const handleSortedSearchResultsSuccessAction = (
  payload: Pick<State, 'nextPageToken' | 'items' | 'numResultsTotal'>
) =>
  ({
    type: HANDLE_RESPONSE_SORTED_SEARCH_RESULTS,
    payload,
  } as const);

export const handleSortedSearchResultFailureAction = () =>
  ({
    type: HANDLE_RESPONSE_ERROR_SORTED_SEARCH_RESULTS,
  } as const);

export const sortResults =
  (query: Query, sorting: Sorting) =>
  async (
    dispatch: ThunkDispatch<GlobalState, never, AnyAction>,
    getState: () => GlobalState
  ) => {
    const state = getState();
    const locale = getCurrentLocale(state);
    const sortedQuery = {
      ...query,
      ...createSortingParams(sorting),
    };

    dispatch(requestSortedSearchResultsAction(sorting));

    try {
      const data = await getListingsByParams(sortedQuery, { locale });
      dispatch(applyQuery(sortedQuery));
      dispatch(handleSortedSearchResultsSuccessAction(data));

      pushHistoryWithQueryURL(sortedQuery);
    } catch {
      dispatch(handleSortedSearchResultFailureAction());
    }
  };

export const searchInPage =
  (query: Query) =>
  async (
    dispatch: ThunkDispatch<GlobalState, never, AnyAction>,
    getState: () => GlobalState
  ) => {
    const state = getState();
    const sorting = getSorting(state);
    dispatch(sortResults(query, sorting));
    /* Track after the request is resolved to get the correct dimensions */
    track(SEARCH_PERFORMED, state);
  };

export const selectItem = (item: State['selectedItem']) =>
  ({
    type: SELECT_ITEM,
    item,
  } as const);

type NetworkError = Error & { statusCode?: number };

export const fetchListing =
  (id: string) =>
  async (dispatch: ThunkDispatch<GlobalState, unknown, AnyAction>) => {
    try {
      const data = await getListing(id);
      if (!data?.listingStatus?.visibleToConsumer) {
        const error: NetworkError = new Error();
        error.statusCode = 404;
        throw error;
      }
      dispatch(handleResponseHashedVip(data));
    } catch (err) {
      if ((err as NetworkError).statusCode === 404) {
        dispatch(handleResponseMissingHashedVip({ id }));
      } else {
        dispatch(showErrorPageAction(err as Error));
      }
    }
  };

const startLazyLoad = (payload: State['lazyLoading']) =>
  ({ type: LAZY_LOADING_STARTED, payload } as const);

export const triggerLazyLoad =
  () =>
  (
    dispatch: ThunkDispatch<GlobalState, never, AnyAction>,
    getState: () => GlobalState
  ) => {
    if (!selectIsSRP(getState())) {
      return false;
    }

    const shouldLazyLoadHashedSrp = shouldLoadSrpQueryFromURL();
    const shouldLazyLoadHashedVip = shouldLoadHashedVipFromURL();

    let payload: State['lazyLoading'] | undefined;

    if (shouldLazyLoadHashedSrp) {
      payload = LAZY_LOADING_TYPE.HASHED_SRP;
    }
    if (shouldLazyLoadHashedVip) {
      payload = LAZY_LOADING_TYPE.HASHED_VIP;
    }
    if (shouldLazyLoadHashedSrp && shouldLazyLoadHashedVip) {
      payload = LAZY_LOADING_TYPE.HASHED_SRP_VIP;
    }

    if (payload) {
      dispatch(startLazyLoad(payload));
      return true;
    }
    return false;
  };

export const setFirstAdvertisingTrack = (
  firstAdvertisingTrack: State['firstAdvertisingTrack']
) =>
  ({
    type: FIRST_ADVERTISING_TRACK,
    firstAdvertisingTrack,
  } as const);

export const setIsInfiniteScroll = (
  isInfiniteScroll: State['isInfiniteScroll']
) =>
  ({
    type: SET_IS_INFINITE_SCROLL,
    isInfiniteScroll,
  } as const);

export const restoreChat = (id: State['chatId']) =>
  ({
    type: RESTORE_CHAT,
    payload: id,
  } as const);

export const clearChat = () => ({ type: CLEAR_CHAT } as const);

export type SRPAction =
  | ReturnType<typeof loadingStarted>
  | ReturnType<typeof loadingFinished>
  | ReturnType<typeof lazyLoadFinished>
  | ReturnType<typeof signalizePageUnmounted>
  | ReturnType<typeof requestSearchResults>
  | ReturnType<typeof handleResponseSearchResults>
  | ReturnType<typeof handleResponseHashedVip>
  | ReturnType<typeof handleResponseMissingHashedVip>
  | ReturnType<typeof requestMoreSearchResults>
  | ReturnType<typeof handleResponseMoreSearchResults>
  | ReturnType<typeof handleResponseErrorMoreSearchResults>
  | ReturnType<typeof setSkipByUrlRequest>
  | ReturnType<typeof setSkipPageRequest>
  | ReturnType<typeof setSortingAction>
  | ReturnType<typeof resetSortingAction>
  | ReturnType<typeof resetContentAction>
  | ReturnType<typeof requestSortedSearchResultsAction>
  | ReturnType<typeof handleSortedSearchResultsSuccessAction>
  | ReturnType<typeof handleSortedSearchResultFailureAction>
  | ReturnType<typeof selectItem>
  | ReturnType<typeof startLazyLoad>
  | ReturnType<typeof setFirstAdvertisingTrack>
  | ReturnType<typeof setIsInfiniteScroll>
  | ReturnType<typeof restoreChat>
  | ReturnType<typeof clearChat>;
