import LOGO_SOURCE from '@move-core/asset-catalog/images/ca/web/img-horizontal.svg';
import { Locale } from '../../../shared/types/Locale';
import { CATEGORY_PLACEHOLDER, PATH_PLACEHOLDER } from '../constants';
import { LogoVariants } from '../types';

export const LOGO: LogoVariants = {
  small: {
    source: LOGO_SOURCE,
    width: 35,
    height: 18,
  },
  medium: {
    source: LOGO_SOURCE,
    width: 42,
    height: 22,
  },
};

export const BASE_URL_TEMPLATE = {
  [Locale.En]: `https://www.kijiji.ca/${PATH_PLACEHOLDER}?utm_campaign=rxpromo_CategMenu&utm_medium=referral&utm_source=kijijiautos&utm_content=web_${CATEGORY_PLACEHOLDER}`,
  [Locale.Fr]: `https://www.kijiji.ca/${PATH_PLACEHOLDER}?utm_campaign=rxpromo_CategMenu&utm_medium=referral&utm_source=kijijiautos&utm_content=web_${CATEGORY_PLACEHOLDER}&siteLocale=fr_CA`,
};

export const CATEGORIES = [
  { category: 'Homepage', path: '' },
  { category: 'BuySell', path: 'b-buy-sell/c10' },
  { category: 'CarsVehicles', path: 'b-cars-vehicles/c27' },
  { category: 'RealEstate', path: 'b-real-estate/c34' },
  { category: 'Jobs', path: 'b-jobs/c45' },
  { category: 'Services', path: 'b-services/c72' },
  { category: 'Pets', path: 'b-pets/c112' },
  { category: 'Community', path: 'b-community/c1' },
  { category: 'VacationRentals', path: 'b-vacation-rentals/c800' },
];
