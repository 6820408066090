export const PAGE_STATE = {
  LOADING: 'LOADING',
  LOADING_ERROR: 'LOADING_ERROR',
  LOADING_SUCCESS: 'LOADING_SUCCESS',
  LOADING_MORE: 'LOADING_MORE',
  LOADING_MORE_ERROR: 'LOADING_MORE_ERROR',
} as const;

export const LAZY_LOADING_TYPE = {
  NONE: 'NONE',
  HASHED_SRP: 'HASHED_SRP',
  HASHED_VIP: 'HASHED_VIP',
  HASHED_SRP_VIP: 'HASHED_SRP_VIP',
} as const;
