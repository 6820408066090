export const DATAFILE_POLLING_INTERVAL_NODE = 60000;

const ONE_YEAR_MILLISECONDS = 365 * 24 * 60 * 60 * 1000;
export const TRAFFIC_TYPE_COOKIE_MAX_AGE = ONE_YEAR_MILLISECONDS;
export const TRAFFIC_TYPE_COOKIE_KEY = 'trty';
export const TRAFFIC_TYPE_EXTERNAL = 'e';
export const TRAFFIC_TYPE_INTERNAL = 'i';

export const FORCE_VARIATION_KEY = 'optfv';
export const FORCE_VARIATION_PARAM = 'optimizely-force';
export const FORCE_VARIATION_RESET = 'reset';
export const FORCE_VARIATION_COOKIE_MAX_AGE = ONE_YEAR_MILLISECONDS;
