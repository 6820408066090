import {
  REQUEST_DISCOVER,
  HANDLE_RESPONSE_DISCOVER,
  HANDLE_RESPONSE_ERROR_DISCOVER,
} from './actionTypes';
import fetchDiscover from './api';

export const requestCategories =
  (options = {}) =>
  async (dispatch) => {
    dispatch({ type: REQUEST_DISCOVER });
    try {
      const { categories, campaign } = await fetchDiscover(options);

      dispatch({
        type: HANDLE_RESPONSE_DISCOVER,
        payload: {
          categories,
          campaign,
        },
      });
    } catch (error) {
      dispatch({ type: HANDLE_RESPONSE_ERROR_DISCOVER, payload: error });
    }
  };
