import { combineReducers } from 'redux';
import chat from './chat/reducer';
import contactForm from './contactForm/reducer';
import filter from './filter/reducer';
import inbox from './inbox/reducer';
import latestSearch from './latestSearchCarousel/reducer';
import recentSearches from './recentSearches/reducer';
import reportListing from './reportListing/reducer';
import savedItems from './savedItems/reducer';
import savedItemsList from './savedItemsList/reducer';
import savedSearches from './savedSearches/reducer';
import searchHitCounter from './searchHitCounter/reducer';
import advertising from './advertising/reducer';
import notifications from './notifications/reducer';
import { reducers as listingReducers } from './listing';
import financingCalculator from './financingCalculator/reducer';
import vehicleItemOverlay from './vehicleItemOverlay/reducer';
import tradeIn from './tradeIn/reducer';

export default combineReducers({
  chat,
  contactForm,
  filter,
  inbox,
  latestSearch,
  recentSearches,
  reportListing,
  savedItems,
  savedItemsList,
  savedSearches,
  searchHitCounter,
  listing: listingReducers,
  advertising,
  notifications,
  financingCalculator,
  vehicleItemOverlay,
  tradeIn,
});
