import {
  DIMENSION_LISTING_ID,
  DIMENSION_SELLER_ACCOUNT_TYPE,
  DIMENSION_ITEM_CONDITION,
  DIMENSION_LISTING_IMAGE_COUNT,
  DIMENSION_PRICE_LABEL,
  DIMENSION_LISTING_PRICE,
  DIMENSION_HAS_CERTIFIED_PRE_OWNED,
} from '../../../utils/tracking/dimensions';
import { sellerTypeTrackingMap } from '../../../constants/sellerTypes';
import { priceRatingTrackingMap } from '../../../constants/priceRating';

const selectId = (hashedVip) => hashedVip?.id;
const selectContactTypeForTracking = (hashedVip) =>
  sellerTypeTrackingMap[hashedVip?.contact?.enumType];
const selectIsConditionNew = (hashedVip) =>
  hashedVip?.isConditionNew ? 'New' : 'Used';
const selectNumberOfImages = (hashedVip) => {
  const numberOfImages = hashedVip?.images?.length ?? 0;
  return Number.isInteger(numberOfImages) ? String(numberOfImages) : undefined;
};
const selectRatingLabel = (hashedVip) =>
  hashedVip?.priceRating?.rating ?? 'NO_BADGE';
const selectPrice = (hashedVip) => {
  const price = hashedVip?.prices?.consumerPrice?.amount;
  return (price && Math.round(price)) ?? 'not_provided';
};
const selectCertifiedPreOwned = (hashedVip) =>
  hashedVip?.certifiedPreOwned ? 'CPO' : '';

const getHashedVipTrackingDimensions = (hashedVip) => ({
  [DIMENSION_LISTING_ID]: selectId(hashedVip),
  [DIMENSION_SELLER_ACCOUNT_TYPE]: selectContactTypeForTracking(hashedVip),
  [DIMENSION_ITEM_CONDITION]: selectIsConditionNew(hashedVip),
  [DIMENSION_LISTING_IMAGE_COUNT]: selectNumberOfImages(hashedVip),
  [DIMENSION_PRICE_LABEL]: priceRatingTrackingMap[selectRatingLabel(hashedVip)],
  [DIMENSION_LISTING_PRICE]: selectPrice(hashedVip),
  [DIMENSION_HAS_CERTIFIED_PRE_OWNED]: selectCertifiedPreOwned(hashedVip),
});

export default getHashedVipTrackingDimensions;
