import qs from 'query-string';

export const getQueryAsObject = (queryString: string) => {
  const queryObject = qs.parse(queryString);

  if (typeof queryObject.ms === 'string') {
    queryObject.ms = [queryObject.ms];
  }

  return queryObject;
};
