import {
  showErrorPageAction,
  showListingNotFoundPageAction,
} from '../../stores/page';
import { getListing } from './api';
import {
  HANDLE_RESPONSE_LISTING,
  SET_PROVISIONAL_BASE64,
  DISPLAY_CONTACT_FORM_MODAL,
  CLOSE_CONTACT_FORM_MODAL,
  SET_CLICK_SCROLL_SECTION,
  RESET_CLICK_SCROLL_SECTION,
  DISPLAY_REQUEST_IMAGES_MODAL,
  CLOSE_REQUEST_IMAGES_MODAL,
  DISPLAY_DEALER_UPDATES_MODAL,
  CLOSE_DEALER_UPDATES_MODAL,
  DISPLAY_DESCRIPTION_MODAL,
  CLOSE_DESCRIPTION_MODAL,
  DISPLAY_VEHICLE_FEATURES_MODAL,
  CLOSE_VEHICLE_FEATURES_MODAL,
  SET_FINANCING_DEFAULTS,
} from './actionTypes';

const getBase64Image = (img) => {
  if (!img) {
    return null;
  }

  try {
    const canvas = document.createElement('canvas');
    canvas.width = img.naturalWidth;
    canvas.height = img.naturalHeight;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0);

    return canvas.toDataURL('image/png');
  } catch (error) {
    return null;
  }
};

export const fetchListingAction =
  (listingId, query, options = {}) =>
  async (dispatch) => {
    try {
      const data = await getListing(listingId, query, options);
      dispatch({
        type: HANDLE_RESPONSE_LISTING,
        payload: data,
      });
    } catch (err) {
      dispatch(
        err.statusCode === 404
          ? showListingNotFoundPageAction(err)
          : showErrorPageAction(err)
      );
    }
  };

export const setVIPProvisionalBase64Image = (imgDOMReference) => ({
  type: SET_PROVISIONAL_BASE64,
  payload: getBase64Image(imgDOMReference),
});

export const displayContactFormModal = () => ({
  type: DISPLAY_CONTACT_FORM_MODAL,
});

export const closeContactFormModal = () => ({
  type: CLOSE_CONTACT_FORM_MODAL,
});

export const displayRequestImagesModal = () => ({
  type: DISPLAY_REQUEST_IMAGES_MODAL,
});

export const closeRequestImagesModal = () => ({
  type: CLOSE_REQUEST_IMAGES_MODAL,
});

export const setClickScrollSection = (payload) => ({
  type: SET_CLICK_SCROLL_SECTION,
  payload,
});

export const resetClickScrollSection = () => ({
  type: RESET_CLICK_SCROLL_SECTION,
});

export const displayDealerUpdatesModal = () => ({
  type: DISPLAY_DEALER_UPDATES_MODAL,
});

export const closeDealerUpdatesModal = () => ({
  type: CLOSE_DEALER_UPDATES_MODAL,
});

export const displayDescriptionModal = () => ({
  type: DISPLAY_DESCRIPTION_MODAL,
});

export const closeDescriptionModal = () => ({
  type: CLOSE_DESCRIPTION_MODAL,
});

export const displayVehicleFeaturesModal = () => ({
  type: DISPLAY_VEHICLE_FEATURES_MODAL,
});

export const closeVehicleFeaturesModal = () => ({
  type: CLOSE_VEHICLE_FEATURES_MODAL,
});

export const setFinancingDefaults = (payload) => ({
  type: SET_FINANCING_DEFAULTS,
  payload,
});
