import { Location } from '../../../../features/location/types/LocationArea';

// @TODO: add types for data
export default function createLocation(data: any = {}): Location {
  // entered address or resolved name

  return {
    name: data.name,
    address: data.name,
    position:
      data.latitude && data.longitude
        ? {
            longitude: parseFloat(data.longitude) || undefined,
            latitude: parseFloat(data.latitude) || undefined,
          }
        : data.position,
    street: data.street,
    streetNumber: data.streetNumber,
    city: data.city,
    postalCode: data.postalCode,
    province:
      data.provinceCode || data.provinceName
        ? {
            code: data.provinceCode,
            name: data.provinceName,
          }
        : data.province,
    country:
      data.countryCode || data.countryName
        ? {
            code: data.countryCode,
            name: data.countryName,
          }
        : data.country,
  };
}
