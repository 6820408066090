import { Filter } from '../../../../stores/referenceData';

/**
 * Extract all params from a filter
 */
export const extractFilterParams = (filter: Filter) => {
  const { param, values, tabs } = filter;

  const params = param ? [param] : [];

  if (tabs) {
    return tabs
      .flatMap(({ filters }) => filters.map((subFilter) => subFilter.param))
      .filter(Boolean) as string[];
  }

  return values && values?.length > 0
    ? [...params, ...(values.map((v) => v.param).filter(Boolean) as string[])]
    : params;
};
