import { combineActors } from '../stores/actorRunner';

import homePageActors from './home/actors';
import vehicleItemPageActors from './vip/actors';
import srpPageActors from './srp/actors';

export default combineActors(
  homePageActors,
  vehicleItemPageActors,
  srpPageActors
);
