export const VIDEO_TYPE = {
  YOUTUBE: 'YOUTUBE',
};

/**
 * Extract from the listing the video source.
 * {listing.youtubeVideos} is a legacy source that will be removed eventually and being
 * replaced by {listing.mediaRefs}, but while it becomes the unique source,
 *  both sources must mixed.
 *  "mediaRefs": [
 *     {
 *       "type": "xxxxx_VIDEO",
 *       "reference": "xxxxxxxx"
 *     }
 *  ],
 * @param {*} listing
 */
export const getVideo = (listing?: {
  youtubeVideos: {
    id: string;
    previewImage: string;
    type?: string;
  }[];
}) => {
  // FUTURE: youtubeVideos should be eventually deprecated and replaced by mediaRefs
  // that supports different types of media
  if (listing && listing?.youtubeVideos?.length > 0) {
    return {
      type: VIDEO_TYPE.YOUTUBE,
      ...listing?.youtubeVideos[0],
    };
  }

  return undefined;
};
