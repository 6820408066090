import { createSelector } from 'reselect';

import { getCarName } from './getCarName';
import { getTradeInValue } from './getTradeInValue';
import { State } from '../../stores/types/State';

export const selectIsTradeInDataLoaded = (state: State) =>
  state.features.tradeIn.isTradeInDataLoaded;

export const selectMileage = (state: State) =>
  state.features.tradeIn.input.mileage;

export const selectIsLoadingTradeInResult = (state: State) =>
  state.features.tradeIn.isLoadingTradeInResult;

export const selectIsTradeInError = (state: State) =>
  state.features.tradeIn.isTradeInError;

export const selectTradeInValue = (state: State) =>
  state.features.tradeIn.tradeInValue;

export const selectHasTradeInResult = (state: State) =>
  typeof selectTradeInValue(state) === 'number';

export const selectTradeInInput = (state: State) =>
  state.features.tradeIn.input;

export const selectCarDetailsByVinErrorCode = (state: State) =>
  state.features.tradeIn.carDetailsByVinErrorCode;

export const selectCarDetailsByVinResult = (state: State) =>
  state.features.tradeIn.carDetailsByVin;

export const selectColorOptionsByVinErrorCode = (state: State) =>
  state.features.tradeIn.colorOptionsByVinErrorCode;

export const selectColorOptionsByVinResult = (state: State) =>
  state.features.tradeIn.colorOptionsByVin;

export const selectTradeInValueFromAPI = (state: State) =>
  state.features.tradeIn.tradeInValueFromAPI;

export const selectTradeInCarName = createSelector(
  selectTradeInInput,
  ({
    makeId,
    modelId,
    year,
    vehicleId,
    makeOptions,
    modelOptions,
    versionOptions,
  }) =>
    getCarName({
      makeId,
      modelId,
      year,
      vehicleId,
      makeOptions,
      modelOptions,
      versionOptions,
    })
);

// TODO: rename to `selectTradeInValue`, when VIP trade-in estimator migration is finished
export const selectTradeInFinalValue = createSelector(
  selectTradeInInput,
  selectTradeInValue,
  ({ isMakingPayment, financingType, remainingPayment }, tradeInValueFromAPI) =>
    getTradeInValue({
      tradeInValueFromAPI,
      isMakingPayment,
      financingType,
      remainingPayment,
    })
);
