import { createIntl } from 'react-intl';
import { ThunkDispatch } from 'redux-thunk';
import { Bag, NOT_FOUND, StateGetter } from 'redux-first-router';

import { selectIsFinancingSpokeStarted, selectReferenceId } from './selectors';
import {
  Action,
  fetchListingAction,
  setIsPaymentSpokeFilledOut,
  setPaymentType,
} from './actionCreators';
import { DRP_PAGE_ID, DRP_ROUTE } from '../../stores/page';
import { FEATURE_DIGITAL_RETAIL } from '../../../shared/featureFlags';
import { DRPState } from './stateTypes';
import { State } from '../../stores/types/State';
import { selectIsLoggedIn } from '../../stores/authentication';
import { getCurrentLocale, getTranslations } from '../../stores/i18n';

export const routes = {
  [DRP_ROUTE]: {
    path: `/${DRP_PAGE_ID}/:listingId/:spoke?`,
    thunk: (
      dispatch: ThunkDispatch<State, undefined, Action>,
      _: StateGetter<DRPState>,
      bag: Bag
    ) => {
      if (!FEATURE_DIGITAL_RETAIL) {
        dispatch({ type: NOT_FOUND });
      }

      const { isPaymentSpokeFilledOut, financingType } =
        bag.action.payload || {};

      // TODO: validate spoke and show 404 if spoke type is not supported
      if (isPaymentSpokeFilledOut !== undefined) {
        dispatch(setIsPaymentSpokeFilledOut(isPaymentSpokeFilledOut));
      }

      if (financingType) {
        dispatch(setPaymentType(financingType));
      }

      return dispatch(fetchListingAction(bag.action.payload?.listingId));
    },
    confirmLeave: (state, action) => {
      // do not show exit prompt when users send a deal successfully
      const referenceId = selectReferenceId(state);
      const isLoggedIn = selectIsLoggedIn(state);
      const financingSpokeStarted = selectIsFinancingSpokeStarted(state);

      if (
        !isLoggedIn ||
        referenceId ||
        !financingSpokeStarted ||
        action.type === DRP_ROUTE
      ) {
        // do not show exit prompt when users enter spoke page
        return;
      }

      const intl = createIntl({
        locale: getCurrentLocale(state),
        messages: getTranslations(state),
      });

      return intl.formatMessage({
        id: 'digital_retail_trade_in_exit_alert_body',
      });
    },
  },
};
