import useTracking from '../../../utils/tracking/useTracking';

const HORIZONTAL_VIEW = {
  eventAction: 'KijijiRibbonDisplay',
  getEventData: () => ({
    nonInteraction: true,
  }),
};

const HORIZONTAL_LOGO_CLICKED = {
  eventAction: 'KijijiLogoClicked',
  getEventData: () => ({
    eventLabel: 'btn=rxpromoCategMenu',
    nonInteraction: false,
  }),
};

const HORIZONTAL_CATEGORY_SELECTED = {
  eventAction: 'CategorySelected',
  getEventData: ({ category }) => ({
    eventLabel: `btn=rxpromoCategMenu;cat=${category}`,
    nonInteraction: false,
  }),
};

const useClickTracking = () => {
  const { trackEvent } = useTracking();

  const trackView = () => trackEvent(HORIZONTAL_VIEW);

  const trackLogoClick = () => trackEvent(HORIZONTAL_LOGO_CLICKED);

  const trackCategorySelected = (category: string) =>
    trackEvent(HORIZONTAL_CATEGORY_SELECTED, { category });

  return {
    trackView,
    trackLogoClick,
    trackCategorySelected,
  };
};

export default useClickTracking;
