import uuid from 'uuid/v4';

import * as types from './actionTypes';
import * as api from '../../shared/api/message';
import { ApiMessageStatus } from '../../shared/types';
import { trackOptimizelyEvent, EVENT_KEYS } from '../../../lib/optimizely';
import {
  pipeApiCall,
  postMessageApiCall,
  postImageApiCall,
  buildNewImage,
} from './utils/actionCreatorsUtils';

export const toggleChat = () => ({
  type: types.TOGGLE_CHAT,
});

export const setRetailModalVisibility = (isVisible) => ({
  type: types.SET_RETAIL_MODAL_VISIBILITY,
  payload: isVisible,
});

export const showRetailModal = () => setRetailModalVisibility(true);

export const clearStatus = () => ({
  type: types.CLEAR_STATUS,
});

export const sendMessage = (params, options) => (dispatch) => {
  /**
   The send message flow has three phases:

   1. The message is sent to COMaaS API
   2. COMaaS API creates a uuid of that message
   as an acknowledge that the message reached COMaaS API
   3. We received the persisted message in the next poll from
   COMaaS API response

   This action creator tries to address the use case 1 and 2
   in the following manner.

   We fingerprint the submitted message with a temporary id BEFORE
   sending the request to the COMaaS API, so we can opportunistically
   render the message without relying to the response of COMaaS API.

   Once we receive the success response from ComaaS API, we send
   the temporary uuid and the COMaaS uuid, so we can replace it
   on the state.

   This state handling is done in the reducer.
   */

  const {
    listingId,
    message,
    image,
    image: { src } = {},
    conversationId,
    userId,
    sendMessageEventLabel,
    email,
    name,
    phone,
    leadType,
  } = params;

  const tempUid = `temp-${uuid()}`;
  const isoDate = new Date().toISOString();
  const messageApiCall = (imgUrl) =>
    postMessageApiCall({
      message,
      listingId,
      conversationId,
      options,
      dispatch,
      tempUid,
      imgUrl,
      sendMessageEventLabel,
      email,
      name,
      phone,
      leadType,
    });
  const imageUploadApiCall = () => postImageApiCall(image);
  dispatch({
    type: types.SEND_MESSAGE,
    payload: {
      message: {
        text: message,
        image: src,
        id: tempUid,
        receivedDate: isoDate,
        status: ApiMessageStatus.Pending,
        senderUserId: userId,
      },
      listingId,
      conversationId,
    },
  });
  const apiCall = image
    ? pipeApiCall(imageUploadApiCall, messageApiCall)
    : pipeApiCall(messageApiCall);

  return apiCall.catch(() => {
    dispatch({
      type: types.HANDLE_RESPONSE_ERROR_SEND_MESSAGE,
      payload: {
        tempUid,
        listingId,
        conversationId,
      },
    });
    trackOptimizelyEvent(EVENT_KEYS.R2S_CHAT_FAILURE);
  });
};

export const resendMessage =
  ({ listingId, message, messageId, conversationId, image } = {}, options) =>
  (dispatch) => {
    dispatch({
      type: types.RETRY_SEND_MESSAGE,
      payload: {
        messageId,
        listingId,
        conversationId,
      },
    });

    return dispatch(
      sendMessage(
        {
          listingId,
          message,
          conversationId,
          image: image && buildNewImage(image),
        },
        options
      )
    );
  };

export const fetchConversation = (listingId, options) => async (dispatch) => {
  dispatch({
    type: types.REQUEST_CONVERSATION,
  });

  try {
    const { conversations } = await api.getConversation(listingId, options);
    if (conversations.length === 0) {
      dispatch({
        type: types.HANDLE_RESPONSE_CONVERSATION_EMPTY,
        payload: {
          listingId,
        },
      });
      return 0;
    }

    const { id, messages } = conversations[0];

    const messageList = messages || [];
    dispatch({
      type: types.HANDLE_RESPONSE_CONVERSATION,
      payload: {
        messageList,
        conversationId: id,
        listingId,
      },
    });

    return messageList.length;
  } catch (error) {
    dispatch({
      type: types.HANDLE_RESPONSE_ERROR_CONVERSATION,
      payload: {
        listingId,
        error,
      },
    });
    return undefined;
  }
};

export const sendMessageAndToggleChatWindow =
  ({ listingId, message } = {}, options) =>
  (dispatch) => {
    dispatch(
      sendMessage(
        {
          listingId,
          message,
        },
        options
      )
    );
    dispatch(toggleChat());
  };

export const setGeneralInquiryDefaults = (values) => ({
  type: types.SET_CONTACT_FORM_INQUIRY,
  payload: { ...values },
});

export const saveLeadTypes = (leadTypes) => ({
  type: types.SAVE_LEAD_TYPES,
  payload: { leadTypes },
});

export const sendOpenFormMessage = (params, options) => (dispatch) => {
  dispatch(sendMessage(params, options));
};

export const setFocusMessageTextArea = () => ({
  type: types.SET_FOCUS_MESSAGE_TEXTFIELD,
});

export const clearFocusMessageTextArea = () => ({
  type: types.CLEAR_FOCUS_MESSAGE_TEXTFIELD,
});
