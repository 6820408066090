export default class RequestController {
  private latestRequest:
    | { promise: Promise<unknown>; timestamp: number }
    | undefined;

  static REQUEST_CANCELED_ERROR = 'REQUEST_CANCELED';

  async resolveWithLatest<T>(promise: Promise<T>) {
    const timestamp = Date.now();
    this.latestRequest = { promise, timestamp };
    await promise;

    if (this.latestRequest.timestamp !== timestamp) {
      const error = new Error();
      error.name = RequestController.REQUEST_CANCELED_ERROR;
      throw error;
    }
    return this.latestRequest.promise as Promise<T>;
  }

  isCanceledError(error: Error) {
    return error.name === RequestController.REQUEST_CANCELED_ERROR;
  }
}
