import { zipObject } from 'lodash';
import { Filter } from '../../../../stores/referenceData';
import { Query } from '../../../../stores/searchQuery';

import isEmpty from '../../../../utils/isEmpty';
import { extractFilterParams } from '../specifications/filter';
import {
  isBooleanType,
  isMultiType,
  isTabbedType,
} from '../specifications/filters';
import { FilterSetting } from '../types/FilterSetting';

const getValue = (
  currentValues: ReturnType<typeof getCurrentValuesForFilter>,
  filter: Filter
) => {
  if (!isEmpty(currentValues) || isTabbedType(filter)) {
    return currentValues;
  }
  if (isMultiType(filter)) {
    return [];
  }
  if (isBooleanType(filter)) {
    return {};
  }
  return '';
};

const getCurrentValuesForFilter = (filter: Filter, values: Query) => {
  const params = filter.values?.map(({ param }) => param) ?? [];

  if (filter.tabs) {
    return Object.fromEntries(
      filter.tabs
        .flatMap(({ filters }) => filters)
        .map((filtr) => {
          const value = getCurrentValuesForFilter(filtr, values);
          return [filtr.param, getValue(value, filtr)];
        })
    );
  }

  // setting: either there is a value for the filter param, or we need to check used params in the filter values
  return (
    values[`${filter.param}`] ||
    Object.keys(values)
      .filter((key) => params.includes(key))
      .reduce((acc, curr) => {
        acc[`${curr}`] = values[`${curr}`];
        return acc;
      }, {})
  );
};

/**
 * merge a filter and the value for this filter
 */
export const mergeFilterWithSettings = (
  filter: Filter,
  values: Query
): FilterSetting => {
  const currentValues = getCurrentValuesForFilter(filter, values);
  return { filter, value: getValue(currentValues, filter) };
};

/**
 * merges filters and values to filters with values set
 */
export const mergeFiltersWithSettings = (filters: Filter[], values: Query) =>
  filters.map((filter) => mergeFilterWithSettings(filter, values));

/**
 * creates a list of filter settings
 */
export const createFiltersWithSettings = (filters: Filter[], values: Query) =>
  mergeFiltersWithSettings(filters, values).filter((filterSetting) => {
    if (isTabbedType(filterSetting.filter)) {
      return (
        Object.values(
          filterSetting.value as Record<string, string | string[]>
        ).filter(Boolean).length > 0
      );
    }
    return !isEmpty(
      typeof filterSetting.value === 'string'
        ? filterSetting.value.trim()
        : filterSetting.value
    );
  }) as FilterSetting<string | string[] | Record<string, string | string[]>>[];

/**
 * create a map for filter values (filter param(s) => value) and set initial values
 */
export const createFilterParamValueMap = (
  filter: Filter,
  values: (string | undefined)[] = []
) =>
  filter.param
    ? { [filter.param]: values[0] }
    : zipObject(extractFilterParams(filter), values);
