import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  loginAction,
  logoutAction,
  registerAction,
} from '../../stores/authentication';
import { getCurrentLocale } from '../../stores/i18n';
import {
  goToSrp as goToSrpAction,
  goToMrp as goToMrpAction,
  selectIsHome,
  goToSavedItems,
  goToSavedSearches,
  goToInbox,
  goToHome,
  goToNotifications,
  goToMyDeals,
} from '../../stores/page';
import { useLabels } from './labels';
import { selectIsSearchResultPage, selectIsVehicleItemPage } from './selectors';
import { useHeaderTracking } from './tracking';
import { getSyiAppMyAdsPath, getSyiAppLandingPageListingPath } from './utils';

export const usePage = () => {
  const isHome = useSelector(selectIsHome);
  const isSearchResult = useSelector(selectIsSearchResultPage);
  const isVehicleItem = useSelector(selectIsVehicleItemPage);

  return {
    isHome,
    isSearchResult,
    isVehicleItem,
  };
};

export const useNavigation = () => {
  const {
    trackLogo,
    trackBuyAll,
    trackBuyNew,
    trackBuyUsed,
    trackAdvice,
    trackMrp,
    trackFinance,
    trackSavedItems,
    trackSavedSearches,
    trackMyDeals,
    trackSellYourItem,
  } = useHeaderTracking();

  const dispatch = useDispatch();
  const locale = useSelector(getCurrentLocale);
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { header_research_advice_url, header_research_financing_url } =
    useLabels();

  const logoClicked = useCallback(() => {
    trackLogo();
    dispatch(goToHome());
  }, [trackLogo, dispatch]);
  const buyAllCarsClicked = useCallback(() => {
    trackBuyAll();
    dispatch(goToSrpAction());
  }, [trackBuyAll, dispatch]);
  const buyUsedCarsClicked = useCallback(() => {
    trackBuyUsed();
    dispatch(goToSrpAction({ con: ['USED'] }));
  }, [trackBuyUsed, dispatch]);
  const buyNewCarsClicked = useCallback(() => {
    trackBuyNew();
    dispatch(goToSrpAction({ con: ['NEW'] }));
  }, [trackBuyNew, dispatch]);
  const adviceClicked = useCallback(() => {
    trackAdvice();
    location.assign(header_research_advice_url);
  }, [trackAdvice, header_research_advice_url]);
  const modelResearchClicked = useCallback(() => {
    trackMrp();
    dispatch(goToMrpAction(locale));
  }, [trackMrp, dispatch, locale]);
  const financingClicked = useCallback(() => {
    trackFinance();
    location.assign(header_research_financing_url);
  }, [trackFinance, header_research_financing_url]);
  const savedItemsClicked = useCallback(() => {
    trackSavedItems();
    dispatch(goToSavedItems());
  }, [trackSavedItems, dispatch]);
  const savedSearchesClicked = useCallback(() => {
    trackSavedSearches();
    dispatch(goToSavedSearches());
  }, [trackSavedSearches, dispatch]);
  const myDealsClicked = useCallback(() => {
    trackMyDeals();
    dispatch(goToMyDeals());
  }, [trackMyDeals, dispatch]);
  const manageAdsClicked = useCallback(() => {
    trackSellYourItem('btn=account');
    location.assign(getSyiAppMyAdsPath(locale));
  }, [trackSellYourItem, locale]);
  const postAdButtonClicked = () => {
    trackSellYourItem('btn=header');
    location.assign(getSyiAppLandingPageListingPath(locale));
  };

  const navActions = {
    allCars: buyAllCarsClicked,
    usedCars: buyUsedCarsClicked,
    newCars: buyNewCarsClicked,
    advice: adviceClicked,
    modelResearch: modelResearchClicked,
    financing: financingClicked,
    cars: savedItemsClicked,
    searches: savedSearchesClicked,
    postad: postAdButtonClicked,
    ['postad-mobile']: postAdButtonClicked,
    messages: () => dispatch(goToInbox()),
    notifications: () => dispatch(goToNotifications()),
    retail: myDealsClicked,
    inventory: manageAdsClicked,
    signIn: () => dispatch(loginAction('TopBar')),
    signUp: () => dispatch(registerAction()),
    signout: () => dispatch(logoutAction()),
  };

  return {
    logoClicked,
    onNavigationClick: (name: string, _?: any, event?: Event) => {
      event?.preventDefault?.();
      navActions[name]?.();
    },
  };
};
