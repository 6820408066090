import { combineReducers } from 'redux';
import header from './header/reducer';
import loginDialog from './loginDialog/reducer';
import emailNotifications from './emailNotifications/reducer';

export default combineReducers({
  header,
  loginDialog,
  emailNotifications,
});
