export const PAGE_TYPES_MAP = {
  home: 'Homepage',
  srp: 'ResultsSearch',
  vip: 'VIP',
  sVip: 'sVIP',
  vipWithFinancing: 'VIP',
  vipProcessing: 'VIP_PROCESSING',
  vipNotFound: 'eVIP',
  inbox: 'Inbox',
  drp: 'DRHub',
  // TODO:Cleanup after experiment is finished MOVE-26228
  drpSubmit: 'DRHub',
  notFound: 'Error_404',
  error: 'Error_500',
  savedItems: 'Watchlist',
  dealer: 'ResultsSeller',
  mip: 'MIP',
  syiMyAds: 'MyAds',
  savedSearches: 'SavedSearches',
  mrp: 'MRP',
  notifications: 'Notifications',
  myDeals: 'MyDeals',
};
