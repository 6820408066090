export const addTransactionIDToItems = <T extends { items?: I[] }, I>(
  data?: T,
  transactionId?: string | null
) =>
  data?.items?.map((item) => ({
    ...item,
    transactionId,
  })) ?? [];

export const addPageToItems = <T extends undefined | { items?: I[] }, I>(
  data: T,
  page: number
) =>
  data?.items?.map((item) => ({
    ...item,
    page,
  })) ?? [];
