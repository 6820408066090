import { createSelector } from 'reselect';
import { savedSearchItemPresenter } from './utils';
import { selectMakeModelTree } from '../../stores/referenceData';

const getSavedSearchesState = ({ features }: any) =>
  features?.savedSearches || {};
const getFilterConfigurationFromProps = (_, props) => props.filterConfiguration;
const getIntlFromProps = (_, props) => props.intl;

const getSavedSearches = createSelector(
  [
    selectMakeModelTree,
    getSavedSearchesState,
    getFilterConfigurationFromProps,
    getIntlFromProps,
  ],
  (makeModelTree, savedSearchesState, filterConfiguration, intl) =>
    savedSearchesState.items.map(
      savedSearchItemPresenter({
        filterConfiguration,
        makeModelTree,
        intl,
      })
    ) || []
);

export const getSavedSearchesProps = createSelector(
  [getSavedSearchesState, getSavedSearches],
  ({ isLoading, hasError, isEditMode }, items) => ({
    isLoading,
    isEditMode,
    hasError,
    items,
    hasCheckedItems: items.some((item) => item.isChecked),
  })
);

export const selectIsSearchSaved = createSelector(
  [getSavedSearchesState, (_, props) => props.queryParamsHash],
  (state, queryParamsHash) =>
    state.items.some(({ id }) => id === queryParamsHash)
);

export const selectIsSavedSearchListFetched = createSelector(
  getSavedSearchesState,
  ({ isFetched }) => isFetched
);
