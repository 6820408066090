import {
  HANDLE_RESPONSE_AD_CONFIG,
  HANDLE_RESPONSE_ERROR_AD_CONFIG,
  CONTACT,
} from './actionTypes';

const defaultState = {
  hasContactedDealer: false,
  configs: {},
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case CONTACT:
      return state.hasContactedDealer
        ? state
        : { ...state, hasContactedDealer: true };

    case HANDLE_RESPONSE_AD_CONFIG:
      const { page, adConfig } = action.payload;
      return {
        ...state,
        configs: {
          ...state.configs,
          [page]: adConfig,
        },
      };

    case HANDLE_RESPONSE_ERROR_AD_CONFIG:
      // TODO: handle errors https://jira.es.ecg.tools/browse/MOVE-8635
      return state;

    default:
      return state;
  }
};
