import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { EmptyContent } from '@move-ui/layout';
import { IMAGE_ERROR } from '../../../../shared/config';
import { isGoogleTagManagerEnabled } from '../../../utils/tracking/googleTagManager';
import { getLocalizedHomePath } from './selectors';
import useTracking from '../../../utils/tracking/useTracking';

interface ErrorRendererProps {
  trackingCategory: string;
  headline: string;
  subline: string;
}

const ErrorRenderer: FC<ErrorRendererProps> = ({
  trackingCategory,
  headline,
  subline,
}) => {
  const { formatMessage } = useIntl();
  const localizedHomePath = useSelector(getLocalizedHomePath);
  const { trackEvent } = useTracking();
  return (
    <EmptyContent
      title={headline}
      subtitle={subline}
      imageSrc={IMAGE_ERROR}
      imageAlt={headline}
      buttonProps={{
        'data-testid': 'ErrorBackButton',
        href: localizedHomePath,
        onClick: () => {
          if (isGoogleTagManagerEnabled()) {
            trackEvent({
              // @see https://jira.corp.ebay.com/browse/MOVE-4652
              eventAction: 'errorHome',
              getEventData: () => ({ eventCategory: trackingCategory }),
            });
          }
        },
      }}
      buttonText={formatMessage({ id: 'button_500_page' })}
    />
  );
};

ErrorRenderer.displayName = 'Error';

export default ErrorRenderer;
