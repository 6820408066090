export const handleSessionStorage = {
  get(key: string) {
    try {
      return window.sessionStorage.getItem(key);
    } catch {
      // ignore the error, can't be helped
      return undefined;
    }
  },
  set(key: string, value: string) {
    try {
      window.sessionStorage.setItem(key, value);
    } catch {
      // do nothing
    }
  },
  clear(key: string) {
    try {
      window.sessionStorage.removeItem(key);
    } catch {
      // do nothing
    }
  },
};
