import * as types from './actionTypes';
import * as api from './api';

export const fetchNotifications = () => async (dispatch) => {
  try {
    const response = await api.getNotifications();
    dispatch({
      type: types.HANDLE_RESPONSE_FETCH_NOTIFICATIONS,
      payload: {
        ...response,
      },
    });
  } catch (error) {
    dispatch({
      type: types.HANDLE_RESPONSE_ERROR_FETCH_NOTIFICATIONS,
      payload: error,
    });
  }
};

export const fetchNotificationsNewCount = () => async (dispatch) => {
  try {
    const { count } = await api.getNotificationsNewCount();
    dispatch({
      type: types.HANDLE_RESPONSE_FETCH_NOTIFICATIONS_NEW_COUNT,
      payload: {
        count,
      },
    });
  } catch {
    // ignore error
  }
};

export const removeNotificationsNewCount = () => async (dispatch) => {
  try {
    await api.deleteNotificationsNewCount();
    dispatch({
      type: types.HANDLE_RESPONSE_DELETE_NOTIFICATIONS_NEW_COUNT,
    });
  } catch {
    // ignore error
  }
};

export const markNotificationAsRead = (id) => () =>
  api.putNotificationMarkAsRead(id);
