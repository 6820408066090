import querystring from 'querystring';
import { createLocation } from '../entities';
import { Address } from '../../../../../lib/geoLocation/geoLocationTypes';

const ADDRESS_COMPONENT_TYPE = {
  STREET: 'route',
  STREET_NUMBER: 'street_number',
  CITY: 'locality',
  POSTAL_CODE: 'postal_code',
  PROVINCE: 'administrative_area_level_1',
  COUNTRY: 'country',
};

const findAddressComponentByType = (addressComponents, type) =>
  addressComponents.find((addressComponent) =>
    addressComponent.types.includes(type)
  );

/**
 * convert place data from google maps api to Area entity
 */
export const createLocationFromGooglePlaces = (place: Address) => {
  const {
    address_components: addressComponents,
    formatted_address: formattedAddress,
    geometry,
  } = place;
  const { lat, lng } = geometry?.location ?? {};

  const streetComponent = findAddressComponentByType(
    addressComponents,
    ADDRESS_COMPONENT_TYPE.STREET
  );
  const streetNumberComponent = findAddressComponentByType(
    addressComponents,
    ADDRESS_COMPONENT_TYPE.STREET_NUMBER
  );
  const cityComponent = findAddressComponentByType(
    addressComponents,
    ADDRESS_COMPONENT_TYPE.CITY
  );
  const postalCodeComponent = findAddressComponentByType(
    addressComponents,
    ADDRESS_COMPONENT_TYPE.POSTAL_CODE
  );
  const provinceComponent = findAddressComponentByType(
    addressComponents,
    ADDRESS_COMPONENT_TYPE.PROVINCE
  );
  const countryComponent = findAddressComponentByType(
    addressComponents,
    ADDRESS_COMPONENT_TYPE.COUNTRY
  );

  const { short_name: street } = streetComponent ?? {};
  const { short_name: streetNumber } = streetNumberComponent ?? {};
  const { long_name: city } = cityComponent ?? {};
  const { short_name: postalCode } = postalCodeComponent ?? {};
  const { short_name: provinceCode, long_name: provinceName } =
    provinceComponent ?? {};
  const { short_name: countryCode, long_name: countryName } =
    countryComponent ?? {};

  return createLocation({
    name: formattedAddress,
    address: formattedAddress,
    longitude: lng,
    latitude: lat,
    street,
    streetNumber,
    city,
    postalCode,
    provinceCode,
    provinceName,
    countryCode,
    countryName,
  });
};

export const createLocationFromSEOSuggestion = (filter, requestParams = '') => {
  const { city, province, country } = filter;

  const provinceCode = province;
  const countryName = country;
  const name = [city, provinceCode, countryName].filter(Boolean).join(', ');

  const { ll = '' } = querystring.parse(requestParams);
  const [latitude, longitude] = (ll as string).split(',');

  return createLocation({
    name,
    city,
    countryName,
    provinceCode,
    address: name,
    latitude: latitude ? Number(latitude) : null,
    longitude: longitude ? Number(longitude) : null,
  });
};

const COUNTRY_CODE_MAPPING = {
  DE: 'Germany',
  CA: 'Canada',
  AU: 'Australia',
};

export const createLocationFromGeoLocationHeader = (locationString) => {
  if (!locationString) {
    return undefined;
  }

  const locationComponent = locationString.split(';');
  if (locationComponent.length !== 4) {
    return undefined;
  }
  // eslint-disable-next-line prefer-const
  let [countryCode, provinceCode, city, latLong] = locationComponent;

  const [latitudeString, longitudeString] = latLong.split(',');
  const latitude = parseFloat(latitudeString);
  const longitude = parseFloat(longitudeString);
  const isValidLatLong = !Number.isNaN(latitude) && !Number.isNaN(longitude);

  city = city || undefined;
  provinceCode = provinceCode ? provinceCode.substring(2) : undefined;
  countryCode = countryCode || undefined;

  const countryName = COUNTRY_CODE_MAPPING[countryCode];

  let name: string | undefined = [city, provinceCode, countryName]
    .filter(Boolean)
    .join(', ');
  name = name || undefined;

  return createLocation({
    name,
    address: name,
    city,
    provinceCode,
    countryCode,
    countryName,
    latitude: isValidLatLong ? latitude : undefined,
    longitude: isValidLatLong ? longitude : undefined,
  });
};
