import { State } from './../stores/types/State';
import { ThunkDispatch } from 'redux-thunk';
import { AuthenticationAction } from '../stores/authentication/types/Actions';
import actionsMap from './afterLoginActionsMap';

export const AFTER_LOGIN_ACTION_STORAGE_KEY = 'move:LOGIN:ACTIONS';

type Item = {
  id: string;
  args: string[];
};

const serialize = JSON.stringify;
const defaultVal = {};

const deserialize = (strVal: string | null) => {
  if (!strVal) {
    return defaultVal;
  }
  let val: any = '';
  try {
    val = JSON.parse(strVal);
    if (!val || typeof val !== 'object') {
      return defaultVal;
    }
  } catch (e) {
    val = strVal;
  }

  return val !== undefined ? val : defaultVal;
};

const setItem = (obj: Item) => {
  try {
    sessionStorage.setItem(AFTER_LOGIN_ACTION_STORAGE_KEY, serialize(obj));
  } catch (error) {
    // we don't want to handle it
  }
};

const getItem = () => {
  try {
    const data = sessionStorage.getItem(AFTER_LOGIN_ACTION_STORAGE_KEY);
    return deserialize(data);
  } catch (error) {
    // we don't want to handle it
  }
  return defaultVal;
};

const removeItem = () => {
  try {
    sessionStorage.removeItem(AFTER_LOGIN_ACTION_STORAGE_KEY);
  } catch (error) {
    // we don't want to handle it
  }
};

export const saveActionForAfterLogin = (
  actionCreatorId: string,
  args: string[] = []
) => {
  setItem({
    id: actionCreatorId,
    args,
  });
};

export const triggerAfterLoginAction = (
  dispatch: ThunkDispatch<State, void, AuthenticationAction>
) => {
  const { id, args } = getItem();

  if (id && typeof actionsMap[id] === 'function') {
    dispatch(actionsMap[id].apply(null, args));
  }

  removeItem();
};

export const forgetAfterLoginAction = () => {
  removeItem();
};
