import { relevancySortingQueryParams } from '../../../shared/domains/sorting/specifications/sorting';
import { SortBy } from '../../../shared/domains/sorting/types/SortBy';
import { Sorting } from '../../../shared/domains/sorting/types/Sorting';
import { SortOrder } from '../../../shared/domains/sorting/types/SortOrder';

/**
 * query params for sorting
 */
const SORTING_PARAM_SORT_BY = 'sb';
const SORTING_PARAM_SORT_ORDER = 'od';

const VALUE_SORT_ORDER_ASC = 'up';
const VALUE_SORT_ORDER_DESC = 'down';

const SORTING_ORDER_MAP = {
  [VALUE_SORT_ORDER_ASC]: SortOrder.Ascending,
  [VALUE_SORT_ORDER_DESC]: SortOrder.Descending,
};

const SORTING_PARAM_TYPE_MAP = {
  p: SortBy.Price,
  ct: SortBy.DateCreated,
  rel: SortBy.Relevance,
  ml: SortBy.Mileage,
};

const SORTING_PARAM_NAME_MAP = {
  [SortBy.Relevance]: 'rel',
  [SortBy.Price]: 'p',
  [SortBy.DateCreated]: 'ct',
  [SortBy.Mileage]: 'ml',
  [SortBy.Year]: 'yc',
} as const;

const getSortingOrder = (sortOrder?: SortOrder) => {
  if (!sortOrder) {
    return undefined;
  }

  return sortOrder === SortOrder.Ascending
    ? VALUE_SORT_ORDER_ASC
    : VALUE_SORT_ORDER_DESC;
};

const getSortParamName = (sortBy?: SortBy) => {
  if (sortBy === SORTING_PARAM_TYPE_MAP.rel) {
    return relevancySortingQueryParams.sb;
  }
  return SORTING_PARAM_NAME_MAP[sortBy || ''];
};

export const createSortingParams = (sorting: Partial<Sorting> = {}) => {
  const { sortBy, sortOrder } = sorting;
  const sb = getSortParamName(sortBy);

  return {
    [SORTING_PARAM_SORT_BY]: sb,
    [SORTING_PARAM_SORT_ORDER]: sb && getSortingOrder(sortOrder),
  } as const;
};

export const mapSortParamToNameAndDirection = ({
  sb: sortingByParam,
  od: orderParam,
}: { sb?: string; od?: 'up' | 'down' } = {}): Sorting | undefined => {
  const sortBy: SortBy | undefined =
    SORTING_PARAM_TYPE_MAP[sortingByParam || ''];

  if (!sortBy) {
    return undefined;
  }

  const sortOrder: SortOrder | undefined = SORTING_ORDER_MAP[orderParam || ''];

  return {
    sortBy,
    ...(sortBy && sortOrder ? { sortOrder } : {}),
  };
};
