type Value = string | string[] | number | undefined | null;
type QueryObject = Record<string, Value>;

const propertiesToHideOnURL = ['ll', 'rd', 'aa', 'ps', 'psz', 'vc'];

const isQueryPropertyValid = (value: Value) =>
  (value || value === 0) && value !== ':';

const shouldShowPropertyOnURL = (
  queryProperty: string,
  queryObject: QueryObject
) =>
  isQueryPropertyValid(queryObject[queryProperty]) &&
  !propertiesToHideOnURL.includes(queryProperty);

export const pickValidPropertiesForURL = (queryObject: QueryObject) =>
  Object.keys(queryObject)
    .filter((queryProperty) =>
      shouldShowPropertyOnURL(queryProperty, queryObject)
    )
    .reduce(
      (result, key) => ({
        ...result,
        [key]: queryObject?.[key],
      }),
      {}
    );
