import { NOT_FOUND } from 'redux-first-router';
import lazyComponent from '../utils/lazyComponent';
import LoadingState from '../shared/components/LoadingState';
import isFeatureEnabled from '../../lib/env/isFeatureEnabled';
import {
  DEALER_ROUTE,
  ERROR_ROUTE,
  HOME_ROUTE,
  INBOX_ROUTE,
  MIP_ROUTE,
  MRP_ROUTE,
  NOTIFICATIONS_ROUTE,
  SAVED_ITEMS_ROUTE,
  SAVED_SEARCHES_ROUTE,
  SRP_ROUTE,
  LISTING_NOT_FOUND_ROUTE,
  VIP_PROCESSING_ROUTE,
  VIP_ROUTE,
  DRP_ROUTE,
  DRP_SUBMIT_ROUTE,
  MY_DEALS_ROUTE,
  pages,
} from '../stores/page';
import ErrorPage from './error';

const srpChunk = () => import('./srp');
const srpOrVipChunk = () => import('./srpOrVip');

const srp = isFeatureEnabled('hashed_vip') ? srpOrVipChunk : srpChunk;

const pageChunks = {
  [pages[DEALER_ROUTE]]: () => import('./dealer'),
  [pages[ERROR_ROUTE]]: () => import('./error'),
  [pages[HOME_ROUTE]]: () => import('./home'),
  [pages[INBOX_ROUTE]]: () => import('./inbox'),
  [pages[MIP_ROUTE]]: () => import('./mip'),
  [pages[MRP_ROUTE]]: () => import('./mrp'),
  [pages[NOT_FOUND]]: () => import('./notFound'),
  [pages[NOTIFICATIONS_ROUTE]]: () => import('./notifications'),
  [pages[SAVED_ITEMS_ROUTE]]: () => import('./savedItems'),
  [pages[SAVED_SEARCHES_ROUTE]]: () => import('./savedSearches'),
  [pages[MY_DEALS_ROUTE]]: () => import('./myDeals'),
  [pages[SRP_ROUTE]]: srp,
  [pages[VIP_ROUTE]]: () => import('./vip'),
  [pages[DRP_ROUTE]]: () => import('./drp'),
  [pages[DRP_SUBMIT_ROUTE]]: () => import('./drpSubmit'),
  [pages[LISTING_NOT_FOUND_ROUTE]]: () => import('./listingNotFound'),
  [pages[VIP_PROCESSING_ROUTE]]: () => import('./vipProcessing'),
};

type Props = { page: string; error: boolean };

const fetchComponent = ({ page }: Props) => pageChunks[page]();

const UniversalPageComponent = lazyComponent<Props>(fetchComponent, {
  minDelay: 300,
  loading: LoadingState,
  error: ErrorPage,
});

export default UniversalPageComponent;
