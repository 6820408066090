import { getEnv } from '@move-web-essentials-utils/env';
import apiClient from '../../../api/lib/apiClient';
import { MOVE_MRP_API_BASE_PATH } from '../../../shared/config';

const getBaseUrl = () => {
  if (IS_SERVER) {
    return `${getEnv(
      'move_consumer_api_gateway_url'
    )}${MOVE_MRP_API_BASE_PATH}/`;
  }
  return `${MOVE_MRP_API_BASE_PATH}/`;
};

export const fetchMrp = (options = {}) => {
  const doRequest = apiClient({
    metricsId: 'svc_getMrpData',
    ...options,
  });
  return doRequest({
    url: `${getBaseUrl()}`,
  });
};
