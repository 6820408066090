/*
 * The Event listener is used to notify the wrapper LazyComponent
 * that the UniversalComponent failed to load.
 * This is because the onError of the UniversalComponent is defined when
 * we create the class of UniversalComponent and we don't have access
 * of the LazyComponent instance inside onError.
 */
export class EventListener {
  events: Record<string, () => void> = {};

  retryEvents: Record<string, boolean> = {};

  on(name: string, callback: () => void) {
    if (this.retryEvents[name]) {
      callback();
    } else {
      this.events[name] = callback;
    }
  }

  dispatch(name: string) {
    const eventsToDispatch = this.events[name];

    if (eventsToDispatch) {
      eventsToDispatch();
    } else {
      this.retryEvents[name] = true;
    }
  }
}
