import { State } from './types/State';
import { Dispatch, Middleware } from 'redux';

type Context = {
  prevState: State;
  dispatch: Dispatch;
  state: State;
};

type Actor = (payload: any, context: Context) => void;

export const createActorRunnerMiddleware =
  (actors: Record<string, Actor[]>): Middleware<never, State> =>
  ({ getState, dispatch }) =>
  (next) =>
  (action) => {
    const prevState = getState();

    const result = next(action);

    const state = getState();

    if (actors && Array.isArray(actors[action.type])) {
      actors[action.type].forEach((actor) =>
        actor(action, { prevState, state, dispatch })
      );
    }

    return result;
  };

export const combineActors = (
  ...actorsList: Record<string, Actor | Actor[]>[]
) =>
  actorsList.reduce<Record<string, Actor[]>>((combinedActors, actors) => {
    Object.keys(actors).forEach((key) => {
      Object.assign(combinedActors, {
        [key]: (combinedActors[key] || []).concat(actors[key]),
      });
    });

    return combinedActors;
  }, {});
