import { createSelector } from 'reselect';
import { FIELD_MAPPING, PaymentType } from './constants';
import { DRP_ROUTE } from '../../stores/page';
import { getLocationType } from '../../stores/location';
import { OptionsData } from './storeTypes';
import { State } from '../../stores/types/State';

export const selectIsDigitalRetailPage = (state: State) =>
  getLocationType(state) === DRP_ROUTE;

export const selectFinancingCalculator = (state: State) =>
  state.features.financingCalculator;

const selectDrpPayment = (state: State) => state.pages.drp.payment;

const getType = (_: unknown, type: PaymentType) => type;

const selectData = (state: State) =>
  selectIsDigitalRetailPage(state)
    ? selectDrpPayment(state)
    : selectFinancingCalculator(state);

export const selectInput = (state: State) => selectData(state).input;

export const selectIsFinancingReady = (state: State) =>
  Boolean(state.features.financingCalculator.options.data);

export const selectIsOptionError = (state: State) =>
  state.features.financingCalculator.options.error;

export const selectIsOptionLoading = (state: State) =>
  state.features.financingCalculator.options.loading;

export const selectOptions = (state: State): OptionsData =>
  state.features.financingCalculator.options.data || {};

export const selectVehiclePrice = (state: State) =>
  (selectInput(state).price || '').toString();

export const selectTradeIn = (state: State) =>
  (selectInput(state).tradeIn || '').toString();

export const selectDownPayment = (state: State) =>
  (selectInput(state).downPayment || '').toString();

export const selectAllowance = (state: State) =>
  selectInput(state).allowance.toString();

export const selectFrequency = createSelector(
  [selectInput, getType],
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  (input, type) => input[FIELD_MAPPING.frequency[type]]!
);

export const selectTerm = createSelector(
  [selectInput, getType],
  (input, type) => input[FIELD_MAPPING.term[type]]
);

export const selectFrequencyOptions = createSelector(
  [selectOptions, getType],
  (options, type) =>
    options[type]?.frequencies.map((frequency) => ({
      isDefault: frequency.isDefault,
      value: frequency.frequency,
    }))
);

export const selectRate = (state: State, type: PaymentType) =>
  selectData(state)[type].apr;

export const selectTermOptions = createSelector(
  [selectOptions, getType],
  (options, type) =>
    options[type]?.terms.map((term) => ({
      ...term, // isDefault, rate, term
      value: `${term.term}${term.rate === undefined ? '' : `;${term.rate}`}`,
      allowanceOptions: term.allowance?.values.map((value) => ({ value })),
    }))
);

export const selectAllowanceOptions = createSelector(
  [selectTerm, selectTermOptions],
  (term, options) =>
    options?.find(({ value }) => value === term)?.allowanceOptions
);

export const selectFinancing = (type: PaymentType) => (state: State) =>
  selectData(state)[type];

export const selectPayment = (type: PaymentType) => (state: State) =>
  selectData(state)[type].payment;

export const selectBreakdown = (type: PaymentType) => (state: State) =>
  selectData(state)[type].breakdown;

export const selectIsLoading = (type: PaymentType) => (state: State) =>
  selectData(state)[type].loading;

export const selectError = (type: PaymentType) => (state: State) =>
  selectData(state)[type].error;

export const selectTotalProtectionProductsPrice =
  (type: PaymentType) => (state: State) =>
    selectData(state)[type].totalProtectionProductsPrice;

export const selectIsTaxIncluded = (state: State) =>
  Boolean(selectInput(state).isTaxIncluded);

export const selectIsError = (type: PaymentType) => (state: State) =>
  Boolean(selectData(state)[type].error);

export const selectListingId = (state: State) =>
  state.features.financingCalculator.listingId;

export const selectFinancingAvailability = createSelector(
  [selectOptions],
  ({ loan, lease }) => ({
    isLoanAvailable: Boolean(loan),
    isLeaseAvailable: Boolean(lease),
  })
);
