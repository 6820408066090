export const REQUEST_REPORT_STATES = {
  PENDING: 'pending',
  REQUESTED: 'requested',
  SUCCESS: 'success',
};

export const VARIANTS = {
  VIEW: 'VIEW',
  BUY: 'BUY',
  REQUEST: 'REQUEST',
};
