import qs from 'query-string';
import {
  getBrowserType,
  isAndroidDevice,
  isIOSDevice,
  isTabletDevice,
  isMobileDevice,
} from '@move-webapp/device';
import { getCookie } from 'cookies';
import { getEnv } from '@move-web-essentials-utils/env';
import { MediaQueries } from '@move-ui/theme';
import memoizeOne from 'memoize-one';

import { DEFAULT_LOCALE } from '../../../shared/config';
import getTrafficType from '../utils/getTrafficType';
import { isReturningUser } from '../../userSessionTracking/userSessionService';

const getEnvironment = memoizeOne(
  () => getEnv('optimizely_environment') || 'development'
);

const getLocale = () => getCookie('locale') || DEFAULT_LOCALE;

const getDeviceOS = () => {
  if (isAndroidDevice()) {
    return 'Android';
  }

  if (isIOSDevice()) {
    return 'IOS';
  }

  return 'Unknown/Other';
};

const getUserType = () =>
  isReturningUser() ? 'Returning Visitor' : 'New Visitor';

const getDeviceType = () => {
  if (isMobileDevice()) {
    return 'Mobile';
  }

  if (isTabletDevice()) {
    return 'Tablet';
  }

  return 'Desktop';
};

const getCampaignType = () =>
  typeof window === 'undefined'
    ? ''
    : qs.parse(window.location.search).utm_campaign || null;

const getScreenSize = () => {
  if (typeof window === 'undefined') {
    return '';
  }
  try {
    if (window.matchMedia(MediaQueries.XX_LARGE).matches) {
      return 'XX_LARGE';
    }

    if (window.matchMedia(MediaQueries.X_LARGE).matches) {
      return 'X_LARGE';
    }

    if (window.matchMedia(MediaQueries.LARGE).matches) {
      return 'LARGE';
    }

    if (window.matchMedia(MediaQueries.MEDIUM).matches) {
      return 'MEDIUM';
    }

    if (window.matchMedia(MediaQueries.SMALL).matches) {
      return 'SMALL';
    }
  } catch (e) {
    return '';
  }

  return 'X_SMALL';
};

export const getDefaultAttributes = () => ({
  environment: getEnvironment(),
  marketplace: 'CA',
  trafficType: getTrafficType(),
  language: getLocale(),
  browserType: getBrowserType(),
  campaignType: getCampaignType(),
  deviceOS: getDeviceOS(),
  deviceType: getDeviceType(),
  screenSize: getScreenSize(),
  userType: getUserType(),
});
