import { State as GlobalState } from '../../../app/stores/types/State';
import { createSelector } from 'reselect';
import { StructuredAddress } from './types/StructuredAddress';
import { Listing } from '../../shared/types/Listing';
import { Listings } from './types/Listings';

export const selectDealer = (
  state: NestedPick<GlobalState, 'pages', 'dealer'>
) => state.pages.dealer;

export const selectListings = createSelector(
  selectDealer,
  (dealer): Listings => {
    const listings = dealer?.listings;
    const items = listings?.items ?? [];
    const itemsWithoutContact: Listing[] = items.map((item) => {
      if (!item.contact) return item;
      return {
        ...item,
        // We don't want to display dealer names in the listings on dealer page. So
        // we override name here.
        contact: { ...item.contact, name: undefined },
      };
    });

    return {
      ...listings,
      items: itemsWithoutContact,
    };
  }
);

export const selectPageState = createSelector(
  selectDealer,
  (dealer) => dealer?.pageState ?? ''
);

export const selectMapUrl = (
  state: NestedPick<GlobalState, 'pages', 'dealer'>
) => {
  const dealer = selectDealer(state);
  return dealer?.mapsUrl;
};

export const selectSellerId = (
  state: NestedPick<GlobalState, 'pages', 'dealer'>
) => {
  const dealer = selectDealer(state);
  return dealer?.dealerDetails?.sellerId;
};

export const selectSellerForeignId = (
  state: NestedPick<GlobalState, 'pages', 'dealer'>
) => {
  const dealer = selectDealer(state);
  return dealer?.dealerDetails?.foreignId;
};

export const selectLogo = (
  state: NestedPick<GlobalState, 'pages', 'dealer'>
) => {
  const dealer = selectDealer(state);
  return dealer?.dealerDetails?.logoUrl;
};

export const selectPhone = (
  state: NestedPick<GlobalState, 'pages', 'dealer'>
) => {
  const dealer = selectDealer(state);
  return dealer?.dealerDetails?.phone ?? null;
};

export const selectName = (
  state: NestedPick<GlobalState, 'pages', 'dealer'>
) => {
  const dealer = selectDealer(state);
  return dealer?.dealerDetails?.name;
};

export const selectLocation = (
  state: NestedPick<GlobalState, 'pages', 'dealer'>
) => {
  const dealer = selectDealer(state);
  const locations = dealer?.dealerDetails?.locations ?? [];
  return locations.length > 0 ? locations[0] : {};
};

export const selectStructuredAddress = (
  state: NestedPick<GlobalState, 'pages', 'dealer'>
): StructuredAddress => {
  const { address1, zipCode, city, province, country } = selectLocation(state);
  return {
    streetAddress: address1,
    zipCode,
    city,
    province,
    country,
  };
};

export const selectLatLong = (
  state: NestedPick<GlobalState, 'pages', 'dealer'>
) => {
  const { latitude, longitude } = selectLocation(state);
  return latitude !== undefined && longitude !== undefined
    ? {
        lat: latitude,
        lon: longitude,
      }
    : undefined;
};

export const selectAddress = (
  state: NestedPick<GlobalState, 'pages', 'dealer'>
) => {
  const { address1, address2 } = selectLocation(state);
  return Boolean(address1) || Boolean(address2)
    ? [address1, address2].filter((address) => Boolean(address)).join(', ')
    : undefined;
};

export const selectDealerRating = (
  state: NestedPick<GlobalState, 'pages', 'dealer'>
) => {
  const dealer = selectDealer(state);
  return dealer?.dealerRating;
};

export const selectRating = (
  state: NestedPick<GlobalState, 'pages', 'dealer'>
) => {
  const dealerRating = selectDealerRating(state);
  return dealerRating?.rating;
};

export const selectHeroImages = createSelector(selectDealer, (dealer) => {
  const heroImageUrls = dealer?.dealerDetails?.heroImageUrls ?? [];
  return heroImageUrls.map((url) => ({
    uri: url.replace(/^https?:\/\//g, '').replace(/_1\.JPG/g, ''),
  }));
});

export const selectTitleLocation = (
  state: NestedPick<GlobalState, 'pages', 'dealer'>
) => {
  const { city, province } = selectLocation(state);
  return [city, province].filter((value) => Boolean(value)).join(', ');
};

export const selectUrl = (
  state: NestedPick<GlobalState, 'pages', 'dealer'>
) => {
  const dealer = selectDealer(state);
  return dealer?.dealerDetails?.url;
};

export const selectDirectionsUrl = (
  state: NestedPick<GlobalState, 'pages', 'dealer'>
) => {
  const rating = selectDealerRating(state);
  return rating?.directionsUrl;
};

export const selectCovid19 = (
  state: NestedPick<GlobalState, 'pages', 'dealer'>
) => {
  const dealer = selectDealer(state);
  return dealer?.dealerDetails?.covid19;
};
