import { useIntl } from 'react-intl';

export const useLabels = () => {
  const { formatMessage } = useIntl();

  return {
    header_buy: formatMessage({ id: 'header_buy' }),
    header_buy_all: formatMessage({ id: 'header_buy_all' }),
    header_buy_new: formatMessage({ id: 'header_buy_new' }),
    header_buy_used: formatMessage({ id: 'header_buy_used' }),
    header_logo_title: formatMessage({ id: 'header_logo_title' }),
    header_notifications: formatMessage({ id: 'header_notifications' }),
    header_research: formatMessage({ id: 'header_research' }),
    header_research_advice: formatMessage({
      id: 'header_research_advice',
    }),
    header_research_advice_url: formatMessage({
      id: 'header_research_advice_url',
    }),
    header_research_financing: formatMessage({
      id: 'header_research_financing',
    }),
    header_research_financing_url: formatMessage({
      id: 'header_research_financing_url',
    }),
    header_research_models: formatMessage({
      id: 'header_research_models',
    }),
    header_saved: formatMessage({ id: 'header_saved' }),
    header_saved_items: formatMessage({ id: 'header_saved_items' }),
    header_saved_searches: formatMessage({ id: 'header_saved_searches' }),
    home: formatMessage({ id: 'header_logo_title' }),
    post_ad: formatMessage({ id: 'post_ad' }),
    header_my_deals: formatMessage({ id: 'header_my_deals' }),
    login: formatMessage({ id: 'login' }),
    logout: formatMessage({ id: 'logout' }),
    web_my_autos_edit_profile: formatMessage({
      id: 'web_my_autos_edit_profile',
    }),
    messages: formatMessage({ id: 'inbox' }),
    register_now: formatMessage({ id: 'register_now' }),
    my_autos: formatMessage({ id: 'my_autos' }),
    my_autos_saved_items: formatMessage({ id: 'my_autos_saved_items' }),
    manage_ads: formatMessage({ id: 'manage_ads' }),
  };
};
