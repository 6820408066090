export enum Action {
  RecentlyChangedFilter = 'feature/filter/RECENTLY_CHANGED_FILTER',
  ResolveLocation = 'feature/filter/RESOLVE_LOCATION',
  SetFilterModal = 'feature/filter/SET_FILTER_MODAL',
  ResetFilterModal = 'feature/filter/RESET_FILTER_MODAL',
  OpenDSP = 'feature/filter/OPEN_DSP',
  CloseDSP = 'feature/filter/CLOSE_DSP',
  IncrementChangesCount = 'feature/filter/INCREMENT_CHANGES_COUNT',
  ResetChangesCount = 'feature/filter/RESET_CHANGES_COUNT',
}
