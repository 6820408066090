import { push } from './dataLayer';
import getGoogleTagManagerPageType from './getGoogleTagManagerPageType';
import { getReferrerDimensionValue } from './getReferrerDimensionValue';
import joinValues from './joinValues';
import {
  selectIsLoggedIn,
  selectUserIdEncrypted,
  selectUserEmailEncrypted,
} from '../../stores/authentication';
import {
  DIMENSION_PAGE_TYPE,
  DIMENSION_LOGGED_IN,
  DIMENSION_CLICK_SOURCE_DOMAIN,
  DIMENSION_USER_EMAIL,
  DIMENSION_USER_ID,
  DIMENSION_CLIENT_ID,
  DIMENSION_SELLER_ACCOUNT_TYPE,
  DIMENSION_ONSITE_SEARCH_KEYWORD,
  DIMENSION_EXPERIMENT_VARIATION,
  DIMENSION_SELECTED_CATEGORY_NAME,
  DIMENSION_L3_CATEGORY_NAME,
  DIMENSION_STAR_CARS_EXPERIMENT_VARIATION,
} from './dimensions';
import getExperimentsDimension from './getExperimentsDimension';
import { getActiveExperiments } from '../../../lib/optimizely';
import { getClientId } from '../../../lib/userSessionTracking/userSessionService';
import selectXPromotions from './selectXPromotions';

const dimensionsToClearOnPageView = [
  DIMENSION_SELLER_ACCOUNT_TYPE,
  DIMENSION_ONSITE_SEARCH_KEYWORD,
  DIMENSION_SELECTED_CATEGORY_NAME,
  DIMENSION_L3_CATEGORY_NAME,
];

const getDefaultConfig = (page, state, extendsData) => {
  const gaPageType = getGoogleTagManagerPageType(page, state);
  const activeExperiments = getActiveExperiments();

  return {
    eventCategory: gaPageType,
    eventAction: undefined,
    eventLabel: undefined,
    eventValue: undefined,
    [DIMENSION_PAGE_TYPE]: gaPageType,
    [DIMENSION_LOGGED_IN]: selectIsLoggedIn(state) ? '1' : '0',
    [DIMENSION_CLICK_SOURCE_DOMAIN]: getReferrerDimensionValue(),
    [DIMENSION_USER_ID]: selectUserIdEncrypted(state),
    [DIMENSION_USER_EMAIL]: selectUserEmailEncrypted(state),
    [DIMENSION_CLIENT_ID]: getClientId(),
    ...dimensionsToClearOnPageView.reduce((acc, dimension) => {
      acc[dimension] = undefined;
      return acc;
    }, {}),
    [DIMENSION_EXPERIMENT_VARIATION]: joinValues(
      [
        getExperimentsDimension(activeExperiments),
        extendsData[DIMENSION_EXPERIMENT_VARIATION],
      ].filter(Boolean)
    ),
    [DIMENSION_STAR_CARS_EXPERIMENT_VARIATION]: joinValues(selectXPromotions()),
  };
};

export const trackPageView =
  (page, dimensions, data, extendsData = {}) =>
  (_dispatch, getState) => {
    const state = getState();

    push({
      event: 'pageView',
      ...getDefaultConfig(page, state, extendsData),
      ...dimensions,
      ...data,
    });
  };
