import { get, set } from 'userContext';
import { getUserId } from '../user';
import { getDefaultAttributes } from './attributes';
import getInstance from './getInstance';

const cacheContainsExperiment = (cache, experiment) =>
  cache.map((x) => x.key).includes(experiment.key);

const getCachedExperimentVariation = (cache, experiment) => {
  const cachedExperiment = cache.find((x) => x.key === experiment.key);
  return cachedExperiment?.activeVariation;
};

/**
 * Add a experiment to the active experiments cache.
 *
 * @param {Object} experiment
 */
export const setActiveExperiment = (experiment, variation) => {
  const activeExperiments = get('optimizelyActiveExperiments') || [];
  if (!cacheContainsExperiment(activeExperiments, experiment)) {
    set(
      'optimizelyActiveExperiments',
      activeExperiments.concat({
        ...experiment,
        activeVariation: variation,
      })
    );
  }
};

/**
 * Get the list of active experiments.
 */
export const getActiveExperiments = () =>
  get('optimizelyActiveExperiments') || [];

/**
 * get variation of an experiment
 * side effect: cache experiment variation
 *
 * @param {Object} experiment the experiment to check
 */
export const getExperimentVariation = (experiment) => {
  const experimentVariation = getCachedExperimentVariation(
    getActiveExperiments(),
    experiment
  );

  if (experimentVariation) {
    return experimentVariation;
  }

  const optimizely = getInstance();
  const userId = getUserId();
  const defaultAttributes = getDefaultAttributes();
  const variation = optimizely?.activate(
    experiment.key,
    userId,
    defaultAttributes
  );

  setActiveExperiment(experiment, variation);
  return variation;
};
