import apiClient from '../../../api/lib/apiClient';
import { MOVE_DRP_PROTECTION_PRODUCTS } from '../../../shared/config';
import { PaymentType } from '../financingCalculator';
import {
  ProtectionProduct,
  ProtectionProductOption,
} from '../../pages/drp/stateTypes';
import { Frequency } from '../../shared/types/Frequency';

export const postProtectionProducts = async ({
  listingId,
  term,
  paymentFrequency,
  financeType,
}: {
  listingId: string;
  term: string;
  paymentFrequency: Frequency;
  financeType: PaymentType;
}): Promise<{ sessionId: string; products: ProtectionProduct[] }> => {
  const doRequest = apiClient({
    metricsId: 'svc_postProtectionProducts',
  });

  return await doRequest({
    method: 'POST',
    url: `${MOVE_DRP_PROTECTION_PRODUCTS}/${listingId}`,
    body: JSON.stringify({
      term,
      paymentFrequency,
      financeType: financeType.toUpperCase(),
    }),
  });
};

export const getProtectionProductDescription = async ({
  sessionId,
  productId,
}: {
  sessionId: string;
  productId: string;
}): Promise<Body> => {
  const doRequest = apiClient({
    metricsId: 'svc_getProtectionProductDescription',
    headers: { accept: 'text/html' },
  });

  return await doRequest({
    method: 'GET',
    url: `${MOVE_DRP_PROTECTION_PRODUCTS}/${sessionId}/${productId}/description.html`,
  });
};

export const getProtectionProductOptionInstallmentPrice = async ({
  sessionId,
  productId,
  optionId,
  locale,
}: {
  sessionId: string;
  productId: string;
  optionId: string;
  locale: string;
}): Promise<ProtectionProductOption & { installmentPrice: number }> => {
  const doRequest = apiClient({
    metricsId: 'svc_getProtectionProductOptionInstallmentPrice',
  });

  return await doRequest({
    method: 'GET',
    url: `${MOVE_DRP_PROTECTION_PRODUCTS}/${sessionId}/${productId}/${optionId}?locale=${locale}`,
  });
};
