import { Action } from './types/Action';
import { FinancingSpokeAction } from './actionCreators';
import { RESET_PAGE } from '../actionTypes';
import { AddressType, StreetType } from '../apiTypes';
import { FinancingState } from '../stateTypes';
import { ActionMap } from '../../../stores/types/ActionMap';

const emptyState = {
  addressType: AddressType.Street,
  previousAddressType: AddressType.Street,
  employerAddressType: AddressType.Street,
  previousEmployerAddressType: AddressType.Street,

  email: '',
  title: undefined,
  firstName: '',
  middleName: '',
  lastName: '',
  phone: '',
  mobilePhone: '',
  birthdateDay: '',
  birthdateMonth: undefined,
  birthdateYear: '',
  gender: undefined,
  maritalStatus: undefined,

  // residental
  street: '',
  streetNumber: '',
  suiteNumber: '',
  streetType: StreetType.Street,
  streetDirection: undefined,
  postalBoxNumber: '',
  civicAddress: '',
  ruralRouteNumber: '',
  concession: '',
  lotNumber: '',
  city: '',
  province: undefined,
  postalCode: '',
  monthsAtAddress: '',
  yearsAtAddress: '',

  homeAdditionInfo: '',
  homeMarketValue: '',
  homeMortgageAmount: '',
  homeMortgageOwnership: '',
  homeStatus: undefined,
  landlordName: '',
  monthlyPayment: '',
  // previous residental
  previousStreet: '',
  previousStreetNumber: '',
  previousSuiteNumber: '',
  previousStreetType: StreetType.Street,
  previousStreetDirection: undefined,
  previousPostalBoxNumber: '',
  previousCivicAddress: '',
  previousRuralRouteNumber: '',
  previousConcession: '',
  previousLotNumber: '',
  previousCity: '',
  previousProvince: undefined,
  previousPostalCode: '',
  previousMonthsAtAddress: '',
  previousYearsAtAddress: '',

  // employment
  employerName: '',
  occupation: '',
  yearsWithEmployer: '',
  monthsWithEmployer: '',
  employmentType: undefined,
  employmentStatus: undefined,
  annualIncome: '',
  workPhone: '',
  // employment address
  employerStreet: '',
  employerStreetNumber: '',
  employerSuiteNumber: '',
  employerStreetType: StreetType.Street,
  employerStreetDirection: undefined,
  employerPostalBoxNumber: '',
  employerCivicAddress: '',
  employerRuralRouteNumber: '',
  employerConcession: '',
  employerLotNumber: '',
  employerCity: '',
  employerProvince: undefined,
  employerPostalCode: '',

  // previous employment
  previousEmployerName: '',
  previousOccupation: '',
  previousYearsWithEmployer: '',
  previousMonthsWithEmployer: '',
  previousEmploymentType: undefined,
  previousEmploymentStatus: undefined,
  previousAnnualIncome: '',
  previousWorkPhone: '',
  // previous employment address
  previousEmployerStreet: '',
  previousEmployerStreetNumber: '',
  previousEmployerSuiteNumber: '',
  previousEmployerStreetType: StreetType.Street,
  previousEmployerStreetDirection: undefined,
  previousEmployerPostalBoxNumber: '',
  previousEmployerCivicAddress: '',
  previousEmployerRuralRouteNumber: '',
  previousEmployerConcession: '',
  previousEmployerLotNumber: '',
  previousEmployerCity: '',
  previousEmployerProvince: undefined,
  previousEmployerPostalCode: '',

  acceptTAC: false,
};
export const financingDefaultState: FinancingState = {
  input: emptyState,
  coApplicant: emptyState,
  isFinancingSpokeStarted: false,
};

const states: ActionMap<FinancingSpokeAction, FinancingState> = {
  [Action.SetFinancingValue]: (state, { payload }) => ({
    ...state,
    isFinancingSpokeStarted: !payload?.email, // when financing spoke is open, an automatic email update is triggered
    input: { ...state.input, ...payload },
  }),
  [Action.SetCoApplicantValue]: (state, { payload }) => ({
    ...state,
    coApplicant: { ...state.coApplicant, ...payload },
  }),
  [Action.ResetApplicant]: (state, { payload }) => ({
    ...state,
    input: { ...emptyState, ...payload },
  }),
  [Action.ResetCoApplicant]: (state, { payload }) => ({
    ...state,
    coApplicant: { ...emptyState, ...payload },
  }),
  [RESET_PAGE]: () => financingDefaultState,
};

export default (state = financingDefaultState, action: FinancingSpokeAction) =>
  states[action.type] ? states[action.type](state, action as never) : state;
