import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { ArrowDownIcon } from '@move-ui/icons-asset-catalog';
import Text, { TextSize } from '@move-ui/text';
import useDropdownMenu from 'react-accessible-dropdown-menu-hook';
import { LOGO } from '../config';
import styles from './AppBar.css';
import { LOGO_PLACEHOLDER } from '../constants';

type ButtonProps = ReturnType<typeof useDropdownMenu>['buttonProps'];

type Props = {
  buttonProps: ButtonProps;
  labelId?: string;
};

const AppBar: FC<Props> = ({ buttonProps, labelId }) => {
  const { formatMessage } = useIntl();
  const label = formatMessage(
    { id: 'ribbon_horizontal_navigation_copy' },
    { logo: LOGO_PLACEHOLDER }
  );
  const [labelStart, labelEnd] = label.split('{LOGO}');

  return (
    <div className={styles.component}>
      <button
        className={styles.button}
        data-testid="TopNavigationAppBarButton"
        {...buttonProps}
      >
        <Text id={labelId} className={styles.text} size={TextSize.XXS}>
          <span>{labelStart}</span>
          <img
            alt={formatMessage({
              id: 'ribbon_horizontal_navigation_kijiji_alt',
            })}
            className={styles.logo}
            src={LOGO.small.source}
            width={LOGO.small.width}
            height={LOGO.small.height}
          />
          <span>{labelEnd}</span>
          <ArrowDownIcon className={styles.icon} />
        </Text>
      </button>
    </div>
  );
};

export default AppBar;
