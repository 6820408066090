import {
  HANDLE_REMOVED_AREA_SUCCESS,
  HANDLE_SAVE_AREA_SUCCESS,
  RECEIVE_RESTORED_AREA,
  REQUEST_SAVE_AREA,
} from './actionTypes';
import {
  handleRemoveAreaSuccessAction,
  handleSaveAreaSuccessAction,
  receiveRestoredAreaAction,
  requestSaveAreaAction,
} from './actionCreators';
import { GeoLocationState } from './types/State';

export type GeoLocationAction =
  | ReturnType<typeof requestSaveAreaAction>
  | ReturnType<typeof handleSaveAreaSuccessAction>
  | ReturnType<typeof receiveRestoredAreaAction>
  | ReturnType<typeof handleRemoveAreaSuccessAction>;

export const initialState: GeoLocationState = {
  area: undefined,
  isResolving: false,
  resolvedLocation: null,
  isError: false,
};

const geoLocationReducer = (
  state = initialState,
  action: GeoLocationAction
) => {
  switch (action.type) {
    case REQUEST_SAVE_AREA:
      return {
        ...state,
        area: action.area,
      };

    case HANDLE_SAVE_AREA_SUCCESS:
      const { payload } = action;

      return {
        ...state,
        area: payload,
      };

    case RECEIVE_RESTORED_AREA:
      return {
        ...state,
        area: action.area,
      };

    case HANDLE_REMOVED_AREA_SUCCESS:
      return {
        ...state,
        area: null,
      };

    default:
      return state;
  }
};

export default geoLocationReducer;
