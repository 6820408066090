import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { EVENT_KEYS, trackOptimizelyEvent } from '../../../lib/optimizely';
import { State } from '../../stores/types/State';
import { PAGE_TYPES_MAP } from '../../utils/tracking/pageTypesMap';
import selectTrackingPageName from '../../utils/tracking/selectTrackingPageName';
import track from '../../utils/tracking/track';
import useTracking from '../../utils/tracking/useTracking';

const useMenuItemTracking = (
  action: string,
  label?: string,
  options = { nonInteraction: false }
) => {
  const { trackEvent } = useTracking();

  return useCallback(() => {
    trackEvent({
      eventAction: action,
      getEventData: () => ({
        ...(label && { eventLabel: label }),
        ...options,
      }),
    });
  }, [label, action, options, trackEvent]);
};

const trackSellYourItem = (eventLabel: string, state: State) => {
  track(
    {
      eventAction: 'SellMyCar',
      getEventData: () => ({
        eventCategory: selectTrackingPageName(state),
        nonInteraction: 'false',
      }),
    },
    state
  );

  track(
    {
      eventAction: 'PostAdBegin',
      getEventData: () => ({
        eventCategory: PAGE_TYPES_MAP.syiMyAds,
        eventLabel,
        nonInteraction: 'true',
      }),
    },
    state
  );

  trackOptimizelyEvent(EVENT_KEYS.SELL_MY_CAR_CLICK);
};

export const useHeaderTracking = () => {
  const state = useSelector((appState: State) => appState);

  return {
    trackLogo: useMenuItemTracking('HeaderLogoClick'),
    trackBuyAll: useMenuItemTracking('Buy', 'AllCars'),
    trackBuyNew: useMenuItemTracking('Buy', 'NewCars'),
    trackBuyUsed: useMenuItemTracking('Buy', 'UsedCars'),

    trackAdvice: useMenuItemTracking('Research', 'advice'),
    trackMrp: useMenuItemTracking('Research', 'MRP'),
    trackFinance: useMenuItemTracking('Research', 'Financing'),

    trackSavedItems: useMenuItemTracking('Saved', 'SavedItems'),
    trackSavedSearches: useMenuItemTracking('Saved', 'SavedSearches'),
    trackMyDeals: useMenuItemTracking('mydealsclick', 'position=top_menu'),

    trackSellYourItem: (eventLabel: string) =>
      trackSellYourItem(eventLabel, state),
  };
};
