export default {
  R2S_TEXT_BEGIN: 'R2STextBegin',
  RESULTS_AD_CLICK: 'ResultsAdClick',
  RESULTS_AD_CLICK_DR: 'ResultsAdClickDR',
  R2S_CHAT_SUCCESS: 'R2SChatSuccess',
  MIP_CLICK: 'MIPClick',
  MIP_SEARCH: 'MIPSearch',
  MIP_RELATED_AD_CLICK: 'MIPRelatedAdClick',
  VIP_SAVE_CLICK: 'vipSaveClick',
  LOAD_MORE_CLICK: 'LoadMoreClick',
  PHONE_CALL_CLICK: 'PhoneCallClick',
  SIGN_IN: 'SignInClick',
  REGISTER: 'RegisterClick',
  HOME_SEARCH: 'HomeSearch',
  LOGIN_SUCCESS: 'LoginSuccess',
  R2S_CHAT_FAILURE: 'R2SChatFailure',
  RESULTS_SEARCH: 'ResultsSearch',
  SEARCH_PERFORMED: 'SearchPerformed',
  RECENT_SEARCH_PERFORMED: 'RecentSearchPerformed',
  VIP_VIEW: 'VIPView',
  GROWTH_ERROR: 'GrowthExperimentError',
  R2SCHAT_BEGIN_SRP: 'R2SChatBeginSRP',
  FILTER_CLICK: 'FilterClick',
  DOWNLOAD_CLICK: 'DownloadClick',
  CLOSE_DOWNLOAD_MODAL: 'CloseDownloadModal',
  RELATED_AD_CLICK: 'RelatedAdClick',
  RECENT_AD_CLICK: 'RecentAdClick',
  SAVE_SEARCH: 'SaveSearch',
  SAVE_SEARCH_TOOLTIP_SHOWN: 'SaveSearchTooltipShown',
  SAVED_SEARCHES: 'SavedSearches',
  SAVED_SEARCH_SUCCESS: 'SavedSearchSuccess',
  SAVE_ITEM_CLICK: 'WatchlistAdd',
  SAVE_LISTING_TOOLTIP_SHOWN: 'SaveListingTooltipShown',
  SAVE_LISTING_BANNER_SHOWN: 'SaveListingBannerShown',
  SORT_ATTEMPT: 'SortAttempt',
  SORT_SELECTED: 'SortSelected',
  WATCHLIST: 'Watchlist',
  ADD_FILTER: 'AddFilter',
  REMOVE_FILTER: 'RemoveFilter',
  DR_FILTER_PROMO: 'DRFilterPromo',
  DR_FILTER: 'DRFilter',
  DR_FILTER_CHIP_CLICK: 'DRFilterchipClick',
  DR_FILTER_CHIP_RESULTS: 'DRFilterchipResults',
  DR_PROMO: 'DRPromo',
  SUBMIT_SEARCH: 'SubmitSearch',
  LAST_SEARCH_AD_CLICK: 'LastSearchAdClick',
  BACK_SRP: 'BackSRP',
  SRP_VISITS: 'SRPVisits',
  CARFAX_ATTRIBUTES_CHANGE: 'CARFAXAttributesChange',
  NUMBER_OF_CYLINDERS_CHANGE: 'NumberOfCylindersChange',
  DRIVETRAIN_CHANGE: 'DriveTrainChange',
  FUEL_TYPE_CHANGE: 'FuelTypeChange',
  TRANSMISSION_CHANGE: 'TransmissionChange',
  TOTAL_FILTER_CHANGE: 'TotalFilterChange',
  TRIM_CHANGE: 'TrimChange',
  CONSTRUCTION_YEAR_CHANGE: 'ConstructionYearChange',
  PRICE_CHANGE: 'PriceChange',
  MILEAGE_CHANGE: 'MileageChange',
  SEATS_CHANGE: 'SeatsChange',
  MAKE_MODEL_QS_CHANGE: 'MakeModelQSChange',
  PRICE_QS_CHANGE: 'PriceQSChange',
  MILEAGE_QS_CHANGE: 'MileageQSChange',
  MAKE_MODEL_ADVANCED_CHANGE: 'MakeModelAdvancedChange',
  ADVANCED_SEARCH_CLICK: 'AdvancedSearchClick',
  COLOUR_CHANGE: 'ColourChange',
  BODY_TYPE_CHANGE: 'BodyTypeChange',
  VEHICLE_REPORT_CHANGE: 'VehicleReportChange',
  DIGITAL_RETAIL_CHANGE: 'DigitalRetailChange',
  EXTRAS_CHANGE: 'ExtrasChange',
  TRIM_FILTER_CHANGE: 'TrimFilterChange',
  SELL_MY_CAR_CLICK: 'SellMyCarClick',
  R2S_CHAT_SUCCESS_DEALER: 'R2SChatSuccessDealer',
  RECOMMENDATION_IMPRESSION: 'RecommendationImpression',
  RECOMMENDATION_CLICK: 'RecommendationClick',
  WATCHLIST_ADD_ATTEMPT: 'WatchlistAddAttempt',
  WATCHLIST_ADD_RELATED_AD: 'WatchlistAddRelatedAd',
  WATCHLIST_ADD_RELATED_AD_ATTEMPT: 'WatchlistAddRelatedAdAttempt',
  MESSAGE_SEND_BEGIN: 'MessageSendBegin',
  WATCHLIST_ADD_AD_ATTEMPT_SRP: 'WatchlistAddAttemptSRP',
  WATCHLIST_ADD_AD_SRP: 'WatchlistAddSRP',
  WATCHLIST_ADD_CONTACTFORM: 'WatchlistAddContactForm',
  WATCHLIST_REMOVE_CONTACTFORM: 'WatchlistRemoveContactForm',
  RELATED_AD_CLICK_CONTACTFORM: 'RelatedAdClickContactForm',
  WATCHLIST_ADD_ATTEMPT_CONTACTFORM: 'WatchlistAddAttemptContactForm',
  CARD_VIEW_TYPE: 'CardViewType',
  LIST_VIEW_TYPE: 'ListViewType',
  R2S_PHONE_BEGIN: 'R2SPhoneBegin',
  R2S_PHONE_BEGIN_DEALER: 'R2SPhoneBeginDealer',
  R2S_CHAT_SUCCESS_GALLERY: 'R2SChatSuccessGallery',
  CONTACT_FORM_REQUEST_BEGIN: 'DigitalRetailRequestBegin',
  CONTACT_FORM_REQUEST_SUCCESS: 'DigitalRetailRequestSuccess',
  CONTACT_FORM_LEARN_MORE: 'DRLearnMore',
  BUY_CARFAX_REPORT: 'BuyCarfaxReport',
};
