import {
  REQUEST_DISCOVER,
  HANDLE_RESPONSE_DISCOVER,
  HANDLE_RESPONSE_ERROR_DISCOVER,
} from './actionTypes';
import { REQUEST_STATE } from './types';

const defaultState = {
  categories: [],
  campaign: {},
  requestState: REQUEST_STATE.PENDING,
};

const browseBy = {
  [REQUEST_DISCOVER]: (state) => ({
    ...state,
    requestState: REQUEST_STATE.PENDING,
  }),
  [HANDLE_RESPONSE_DISCOVER]: (state, payload) => ({
    ...state,
    categories: payload.categories,
    campaign: payload.campaign,
    requestState: REQUEST_STATE.SUCCESS,
  }),
  [HANDLE_RESPONSE_ERROR_DISCOVER]: (state) => ({
    ...state,
    requestState: REQUEST_STATE.ERROR,
  }),
};

export default (state = defaultState, { type, payload } = {}) =>
  browseBy[type] ? browseBy[type](state, payload) : state;
