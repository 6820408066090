import React, { FC } from 'react';
import { Link, Target } from '@move-ui/link';
import Text, { TextSize } from '@move-ui/text';
import { MenuItem } from '../types';
import styles from './TopMenuItem.css';

type Props = {
  item: MenuItem;
  itemProp: any;
  onItemClick: (category: string) => void;
};

const TopMenuItem: FC<Props> = ({
  item: { key, label, url },
  children = (
    <Text className={styles.text} size={TextSize.XS}>
      {label}
    </Text>
  ),
  itemProp: { onClick, ...itemPropRest },
  onItemClick,
}) => {
  const handleClick = (event) => {
    onItemClick(key);
    onClick?.(event);
  };

  return (
    <Link
      aria-label={label}
      className={styles.component}
      href={url}
      masked
      target={Target.Blank}
      onClick={handleClick}
      {...itemPropRest}
    >
      {children}
    </Link>
  );
};

export default TopMenuItem;
