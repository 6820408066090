import { redirect } from 'redux-first-router';

import { getCurrentLocale } from '../../stores/i18n';
import { SRP_BASE_PATHS } from '../../../shared/config';
import isFeatureEnabled from '../../../lib/env/isFeatureEnabled';
import { addLocalePrefixToUrl } from '../../../lib/locale-utils';

import {
  getUrlDecodedSrpSeoSegments,
  isPopState,
  isPageChanged,
  isPageTypeChanged,
  getUrlDecodedPathName,
  cameFromRealVip,
  isSrpSeoSegmentChanged,
} from '../../stores/location';

import {
  srpHvipRouteActionsForLocale,
  srpRouteActionsForLocale,
  showErrorPageAction,
  showNotFoundPageAction,
  selectPageId,
} from '../../stores/page';

import {
  loadingStarted as loadingSrpStarted,
  loadingFinished as loadingSrpFinished,
  getSrpByUrl,
  getSrpByParams,
  resetSortingAction,
  requestSearchResults,
  requestSortedSearchResultsAction,
  setSkipPageRequest,
  setSkipByUrlRequest,
  fetchListing,
  lazyLoadFinished,
  resetContentAction,
} from './actionCreators';
import {
  getSearch,
  hasItems,
  shouldSkipByUrlRequest,
  getSorting,
  shouldSkipPageRequest,
  selectLazyLoadingType,
} from './selectors';
import getSearchActionReturnCondition from './utils/getSearchActionReturnCondition';
import {
  createSrpRouteAction,
  createRootSrpRouteAction,
} from './utils/createSrpRouteAction';
import getDefaultSearchQueryParams from '../../shared/utils/getDefaultSearchQueryParams';
import {
  replaceHistoryWithQueryURL,
  decodeQueryURL,
} from './domain/services/queryURL';
import { createQueryObjectFromURL } from './domain/factories/createQueryObjectFromURL';
import {
  selectQuery,
  applyQuery,
  setPreparatoryQuery,
  Query,
} from '../../stores/searchQuery';
import {
  createSortingParams,
  mapSortParamToNameAndDirection,
} from './utils/sorting';
import getLocationSearchQueryParams from '../../shared/utils/getLocationSearchQueryParams';
import { SEARCH_PERFORMED } from './tracking';

import { selectIsLoggedIn } from '../../stores/authentication';
import { fetchSavedSearchList } from '../../features/savedSearches/actionCreators';
import { selectIsSavedSearchListFetched } from '../../features/savedSearches/selectors';
import {
  getHashedVipParamsFromUrl,
  shouldShowRealVip,
} from '../../pageSegments/hashedVip/shouldShowRealVip';

import { LAZY_LOADING_TYPE } from './types';
import { getDefaultSortParams } from './utils/getDefaultSortParams';
import track from '../../utils/tracking/track';
import normalizeUrl from '../../../shared/utils/normalizeUrl';
import { ThunkAction } from 'redux-thunk';
import { State } from '../../stores/types/State';
import { Action } from 'redux';
import { UrlStatus } from './types/UrlStatus';
import { FEATURE_DIGITAL_RETAIL } from '../../../shared/featureFlags';
import {
  getLocalizedDrSrpRedirect,
  isDrSrpRoute,
} from '../../../server/renderApp/drSrpHelpers';

const escapeStringForRegex = (value: string) =>
  value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

export const srpThunk: ThunkAction<
  Promise<Action | void>,
  State,
  { action: { payload?: { historyState?: { realVip?: boolean } } } },
  Action
> = async (dispatch, getState, bag) => {
  const state = getState();
  const pathname = getUrlDecodedPathName(state);
  const seoSegments = getUrlDecodedSrpSeoSegments(state);
  const locale = getCurrentLocale(state);
  const seoPath = seoSegments ? `/${seoSegments}` : '/';
  const basePath = pathname.replace(
    new RegExp(`${escapeStringForRegex(seoPath)}/?$`),
    ''
  );

  if (!FEATURE_DIGITAL_RETAIL && isDrSrpRoute(state.location)) {
    const localizedDrSrpUrl = getLocalizedDrSrpRedirect(state.i18n);
    window.location.replace(localizedDrSrpUrl);
    return;
  }

  // if route is not matching locale, go to 404 NOT_FOUND
  if (basePath !== SRP_BASE_PATHS[locale]) {
    dispatch(loadingSrpFinished());
    return dispatch(showNotFoundPageAction());
  }

  // load Saved Searches once
  const isLoggedIn = selectIsLoggedIn(state);
  const isListFetched = selectIsSavedSearchListFetched(state);
  if (isLoggedIn && !isListFetched && !IS_SERVER) {
    dispatch(fetchSavedSearchList({}));
  }

  // don't request the page and keep on the loading state
  // this happens when user comes from the homepage and does not have
  // the correct url yet, so it loads the main srp first
  if (shouldSkipPageRequest(state)) {
    dispatch(setSkipPageRequest(false));
    return undefined;
  }

  /*
   * Data is already loaded, don't request again.
   * This is mainly the case when:
   * - goToSRP was used
   * - browser back button was used
   * -- Start --
   */

  // To avoid requests to byUrl twice when hashed vip feature is enabled
  // and browser back button is used.
  if (
    isFeatureEnabled('hashed_vip') &&
    cameFromRealVip(state) &&
    hasItems(state)
  ) {
    dispatch(loadingSrpFinished());

    return undefined;
  }

  if (isPopState(state) && !isSrpSeoSegmentChanged(state) && hasItems(state)) {
    dispatch(setPreparatoryQuery(selectQuery(state)));

    dispatch(loadingSrpFinished());

    return undefined;
  }

  if (!isPageChanged(state) && hasItems(state)) {
    dispatch(loadingSrpFinished());

    return undefined;
  }

  if (
    shouldSkipByUrlRequest(state) ||
    (isPopState(state) &&
      isPageChanged(state) &&
      hasItems(state) &&
      !isSrpSeoSegmentChanged(state))
  ) {
    // In order for dimension tracking to work when the user is using the
    // browser back button, we need to populate preparatoryQuery from query
    // here.
    if (isPopState(state)) {
      dispatch(setPreparatoryQuery(selectQuery(state)));
    }

    dispatch(loadingSrpFinished());

    return undefined;
  }

  /* -- End --*/

  const requestUrl =
    normalizeUrl(addLocalePrefixToUrl(locale, `${basePath}${seoPath}`)) || '';
  dispatch(loadingSrpStarted());

  const lazyLoadingType = selectLazyLoadingType(state);
  if (
    !isPopState(state) &&
    isPageTypeChanged(state) &&
    lazyLoadingType !== LAZY_LOADING_TYPE.HASHED_VIP
  ) {
    dispatch(resetContentAction());
  }

  // search by seo url resets the sorting
  dispatch(resetSortingAction());

  const queryFromURL = IS_SERVER
    ? {}
    : createQueryObjectFromURL(window.location.hash.replace('#', ''));

  const requestPromises: Promise<void>[] = [];

  if (
    queryFromURL?.vip &&
    (lazyLoadingType === LAZY_LOADING_TYPE.NONE ||
      lazyLoadingType === LAZY_LOADING_TYPE.HASHED_VIP ||
      lazyLoadingType === LAZY_LOADING_TYPE.HASHED_SRP_VIP)
  ) {
    if (shouldShowRealVip(bag.action.payload)) {
      document.body.classList.remove('hashedVipLoading');
      return undefined;
    }
    const { id } = getHashedVipParamsFromUrl();
    if (id) {
      requestPromises.push(fetchListing(id)(dispatch));
    }
  }

  if (
    !lazyLoadingType ||
    lazyLoadingType === LAZY_LOADING_TYPE.NONE ||
    lazyLoadingType === LAZY_LOADING_TYPE.HASHED_SRP ||
    lazyLoadingType === LAZY_LOADING_TYPE.HASHED_SRP_VIP
  ) {
    requestPromises.push(
      (async () => {
        const action = await getSrpByUrl(requestUrl, {
          locale,
          queryParams: queryFromURL,
        })(dispatch, getState);

        const updatedState = getState();
        // Decode the query from the URL in case it was
        // encoded by the authentication
        if (queryFromURL) {
          decodeQueryURL();
        }

        // handle different SRP redirect scenarios indicated by the backend response
        const isPermanentRedirectedSrp =
          action?.payload?.url?.status === UrlStatus.Redirected;
        const isTemporaryRedirectedSrp =
          action?.payload?.url?.status === UrlStatus.RedirectedTemporary;
        const shouldRedirect =
          isPermanentRedirectedSrp || isTemporaryRedirectedSrp;

        if (shouldRedirect) {
          const redirectStatus = isPermanentRedirectedSrp ? 301 : 302;
          const srpRouteAction = createSrpRouteAction(updatedState);
          const redirectAction = {
            ...srpRouteAction,
            payload: {
              ...srpRouteAction?.payload,
              redirectStatus,
            },
          };

          dispatch(redirect(redirectAction));
        }

        if (!isPopState(state) && !shouldRedirect) {
          /* Track after the request is resolved to get the correct dimensions */
          track(SEARCH_PERFORMED, getState());
        }
      })()
    );
  }

  try {
    await Promise.all(requestPromises);
    dispatch(lazyLoadFinished());
    dispatch(loadingSrpFinished());
  } catch (err) {
    dispatch(showErrorPageAction(err as Error));
  }

  return undefined;
};

export const routes: Record<string, { path: string; thunk?: typeof srpThunk }> =
  Object.assign(
    {},
    ...Object.entries(SRP_BASE_PATHS).map(([locale, basePath]) => {
      const path = normalizeUrl(`${basePath}/:srpSeoSegments*/`);
      return {
        [srpRouteActionsForLocale[locale]]: {
          path,
          thunk: srpThunk,
        },
        // a workaround to produce URLs with fragment https://github.com/faceyspacey/redux-first-router/issues/212
        [srpHvipRouteActionsForLocale[locale]]: {
          path: `${path}#vip=:listingId&termLoan=:termLoan?&frequencyLoan=:frequencyLoan?`,
          // does not include thunk because the router would trigger a different action anyway
        },
      };
    })
  );

// this actionCreator is also triggered from pages/srp/actors
// to avoid cross page dependency violations, see MOVE-11446
export const goToSRP =
  (query: Query = {}): ThunkAction<Promise<Action | void>, State, never, any> =>
  async (dispatch, getState) => {
    const state = getState();
    const locale = getCurrentLocale(state);
    const previousQuery = selectQuery(state);
    const sorting = getSorting(state);

    // TODO: MOVE-9120 - make sure the E2E test breaks
    //  whenever the sorting behavior changes
    if (
      getSearchActionReturnCondition(getSearch(state), query, previousQuery)
    ) {
      dispatch(setSkipByUrlRequest(true));
      dispatch(setPreparatoryQuery(query));

      const srpAction = dispatch(createSrpRouteAction(state));

      replaceHistoryWithQueryURL(query, locale);

      return srpAction;
    }

    const sortParams = createSortingParams(sorting);

    if (query.sb) {
      dispatch(
        requestSortedSearchResultsAction(
          mapSortParamToNameAndDirection({
            ...sortParams,
            ...query,
          })
        )
      );
    }

    if (
      !isPopState(state) &&
      isPageTypeChanged(state) &&
      selectPageId(state) !== 'srp'
    ) {
      dispatch(resetContentAction());
    }

    dispatch(loadingSrpStarted());

    // We want to show the page first in a loading state,
    // then update it with the correct url and content.
    // Before loading SRP data, we already route to the root SRP
    // to render the skeleton loading UI.
    //
    // `createRootSrpRouteAction(state)` triggers the root SRP route.
    // Because of `setSkipPageRequest(true)` it will not fetch data,
    // but only show the loading UI.
    dispatch(setSkipPageRequest(true));
    dispatch(createRootSrpRouteAction(state));

    dispatch(requestSearchResults());

    const locationSearchQueryParams = getLocationSearchQueryParams(getState());
    const defaultSorting = getDefaultSortParams();

    // always request page 1
    const requestQuery = {
      ...defaultSorting,
      ...sortParams,
      ...query,
      ...getDefaultSearchQueryParams(),
      ...locationSearchQueryParams,
    };

    dispatch(applyQuery(requestQuery));
    replaceHistoryWithQueryURL(requestQuery, locale);

    try {
      await getSrpByParams(requestQuery, { locale })(dispatch, getState);

      const updatedState = getState();
      const srpRouteAction = createSrpRouteAction(updatedState);

      dispatch(setSkipPageRequest(false));
      dispatch(setSkipByUrlRequest(true));

      dispatch(redirect(srpRouteAction));

      replaceHistoryWithQueryURL(requestQuery, locale);

      /* Track after the request is resolved to get the correct dimensions */
      track(SEARCH_PERFORMED, getState());
    } catch (error) {
      dispatch(showErrorPageAction(error as Error));
    }
    return undefined;
  };
