export const INFO_BANNER_IMPRESSION = {
  eventAction: 'InfoBannerImpressions',
  getEventData: ({ id }) => ({
    eventLabel: id,
    nonInteraction: true,
  }),
};

export const INFO_BANNER_CLICK = {
  eventAction: 'InfoBannerClick',
  getEventData: ({ id }) => ({
    eventLabel: id,
    nonInteraction: false,
  }),
};
