import { FinancingType } from './constants';
import {
  Action,
  setTradeInError,
  saveTradeInInput,
  saveTradeInResult,
  setCarDetailsByVinError,
  handleTradeInResult,
  setColorOptionsByVehicleIdError,
  handleColorOptionsByVehicleIdResult,
  handleCarDetailsByVinResult,
} from './actionCreators';
import {
  REQUEST_TRADE_IN_RESULT,
  HANDLE_TRADE_IN_RESULT,
  SET_TRADE_IN_ERROR,
  REMOVE_TRADE_IN_RESULT,
  SAVE_TRADE_IN_RESULT,
  SAVE_TRADE_IN_INPUT,
  REQUEST_CAR_DETAILS_BY_VIN_RESULT,
  HANDLE_CAR_DETAILS_BY_VIN_RESULT,
  SET_CAR_DETAILS_BY_VIN_ERROR,
  REQUEST_COLOR_OPTIONS_BY_VEHICLE_ID_RESULT,
  SET_COLOR_OPTIONS_BY_VEHICLE_ID_ERROR,
  HANDLE_COLOR_OPTIONS_BY_VEHICLE_ID_RESULT,
  REMOVE_BY_VIN_RESULTS,
  TRADE_IN_DATA_LOADED,
} from './actionTypes';
import {
  ColorOption,
  MakeOptions,
  ModelOptions,
  VersionOptions,
} from './components/useKBBTradeInOptions';
import {
  CarDetailsByVin,
  CarDetailsInputMode,
  TradeInColorOptions,
} from './types';

export type State = {
  tradeInValue: number | null;
  input: Input;
  isTradeInDataLoaded: boolean;
  isLoadingTradeInResult: boolean;
  isTradeInError: boolean;
  isLoadingCarDetailsByVinResult: boolean;
  carDetailsByVinErrorCode: number;
  carDetailsByVin?: CarDetailsByVin;
  isLoadingColorOptionsByVinResult: boolean;
  colorOptionsByVinErrorCode: number;
  colorOptionsByVin?: TradeInColorOptions;
  tradeInValueFromAPI: number;
};

export type Input = {
  mode: CarDetailsInputMode;
  makeId: string;
  modelId: string;
  year: string;
  vehicleId: string;
  colorId: string;
  gradeId: string;
  mileage: string;
  makeOptions: MakeOptions;
  modelOptions: ModelOptions;
  versionOptions: VersionOptions;
  colorOptions: ColorOption[];
  isMakingPayment?: boolean;
  financingType?: FinancingType;
  remainingPayment: string;
  lienHolderName: string;
};

const DEFAULT_INPUT: Input = {
  mode: CarDetailsInputMode.Manual,
  makeId: '',
  modelId: '',
  year: '',
  vehicleId: '',
  colorId: '',
  gradeId: '',
  mileage: '',
  makeOptions: [],
  modelOptions: [],
  versionOptions: [],
  colorOptions: [],
  isMakingPayment: undefined,
  financingType: undefined,
  remainingPayment: '',
  lienHolderName: '',
};

export const defaultState: State = {
  tradeInValue: null,
  input: DEFAULT_INPUT,
  isTradeInDataLoaded: false,
  isLoadingTradeInResult: false,
  isTradeInError: false,
  isLoadingCarDetailsByVinResult: false,
  carDetailsByVinErrorCode: 0,
  isLoadingColorOptionsByVinResult: false,
  colorOptionsByVinErrorCode: 0,
  tradeInValueFromAPI: 0,
};

const states: {
  [key in Action['type']]: (state: State, action: any) => State;
} = {
  [REQUEST_TRADE_IN_RESULT]: (state) => ({
    ...state,
    isLoadingTradeInResult: true,
  }),
  [TRADE_IN_DATA_LOADED]: (state) => ({
    ...state,
    isTradeInDataLoaded: true,
  }),
  [HANDLE_TRADE_IN_RESULT]: (
    state,
    { payload }: ReturnType<typeof handleTradeInResult>
  ) => ({
    ...state,
    isLoadingTradeInResult: false,
    tradeInValueFromAPI: payload.tradeInValueFromAPI,
  }),
  [SET_TRADE_IN_ERROR]: (
    state,
    { payload }: ReturnType<typeof setTradeInError>
  ) => ({
    ...state,
    isTradeInError: payload.isError,
    isLoadingTradeInResult: false,
  }),
  [REMOVE_TRADE_IN_RESULT]: (state) => ({
    ...state,
    tradeInValue: null,
    input: DEFAULT_INPUT,
  }),
  [SAVE_TRADE_IN_INPUT]: (
    state,
    { payload }: ReturnType<typeof saveTradeInInput>
  ) => ({
    ...state,
    input: payload,
  }),
  [SAVE_TRADE_IN_RESULT]: (
    state,
    { payload }: ReturnType<typeof saveTradeInResult>
  ) => ({
    ...state,
    tradeInValue: payload.tradeInValue,
  }),
  [REQUEST_CAR_DETAILS_BY_VIN_RESULT]: (state) => ({
    ...state,
    carDetailsByVinErrorCode: 0,
    isLoadingCarDetailsByVinResult: true,
  }),
  [SET_CAR_DETAILS_BY_VIN_ERROR]: (
    state,
    { payload }: ReturnType<typeof setCarDetailsByVinError>
  ) => ({
    ...state,
    carDetailsByVinErrorCode: payload.code,
    isLoadingCarDetailsByVinResult: false,
  }),
  [HANDLE_CAR_DETAILS_BY_VIN_RESULT]: (
    state,
    { payload }: ReturnType<typeof handleCarDetailsByVinResult>
  ) => ({
    ...state,
    isLoadingCarDetailsByVinResult: false,
    carDetailsByVin: payload.carDetailsByVin,
  }),
  [REQUEST_COLOR_OPTIONS_BY_VEHICLE_ID_RESULT]: (state) => ({
    ...state,
    colorOptionsByVinErrorCode: 0,
    isLoadingColorOptionsByVinResult: true,
  }),
  [SET_COLOR_OPTIONS_BY_VEHICLE_ID_ERROR]: (
    state,
    { payload }: ReturnType<typeof setColorOptionsByVehicleIdError>
  ) => ({
    ...state,
    colorOptionsByVinErrorCode: payload.code,
    isLoadingColorOptionsByVinResult: false,
  }),
  [HANDLE_COLOR_OPTIONS_BY_VEHICLE_ID_RESULT]: (
    state,
    { payload }: ReturnType<typeof handleColorOptionsByVehicleIdResult>
  ) => ({
    ...state,
    isLoadingColorOptionsByVinResult: false,
    colorOptionsByVin: payload.colorOptionsByVin,
  }),
  [REMOVE_BY_VIN_RESULTS]: (state) => ({
    ...state,
    carDetailsByVin: undefined,
    colorOptionsByVin: undefined,
    colorOptionsByVinErrorCode: 0,
    carDetailsByVinErrorCode: 0,
  }),
};

const tradeInStateReducer = (state: State = defaultState, action?: Action) => {
  if (!action) {
    return state;
  }

  return states[action.type] ? states[action.type](state, action) : state;
};

export default tradeInStateReducer;
