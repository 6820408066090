import { ActionType } from './types/ActionType';
import { Action } from './types/Action';
import { State } from './types/State';

const reducer = (state: State = {}, action: Action) => {
  switch (action.type) {
    case ActionType.Open:
      return { ...action.payload };
    case ActionType.Close:
      return {};
    default:
      return state;
  }
};

export default reducer;
