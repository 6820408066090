import {
  checkLoginStatus,
  login,
  logout,
  register,
} from '@move-auth/web-client';
import { ThunkDispatch } from 'redux-thunk';
import { State } from '../types/State';
import {
  pushDimensions,
  saveDimensions,
} from '../../utils/tracking/persistDimensions';
import isServer from '../../../lib/isServer';
import { getCurrentURLHashFragment } from '../../utils/hashUrl';
import getLinkerParam from '../../utils/tracking/getLinkerParam';
import selectTrackingPageName from '../../utils/tracking/selectTrackingPageName';
import { EVENT_KEYS } from '../../../lib/optimizely/constants';
import { trackOptimizelyEvent } from '../../../lib/optimizely';

import {
  HANDLE_LOGIN_FAILURE,
  HANDLE_LOGIN_STATUS_FAILURE,
  HANDLE_LOGIN_STATUS_SUCCESS,
  HANDLE_AUTO_LOGIN_STATUS_SUCCESS,
  HANDLE_LOGIN_SUCCESS,
  HANDLE_LOGOUT_FAILURE,
  HANDLE_LOGOUT_SUCCESS,
  HANDLE_REGISTER_FAILURE,
  HANDLE_REGISTER_SUCCESS,
  REQUEST_LOGIN,
  REQUEST_LOGIN_STATUS,
  REQUEST_LOGOUT,
  REQUEST_REGISTER,
} from './actionTypes';
import {
  AuthenticationAction,
  HandleLoginStatusFailureAction,
  HandleLoginStatusSuccessAction,
  FailurePayload,
} from './types/Actions';

export const checkLoginStatusSuccessfulAction = (
  payload: Parameters<typeof checkLoginStatusSuccessfulAction>[0]
) =>
  ({
    type: HANDLE_LOGIN_STATUS_SUCCESS,
    payload,
  } as HandleLoginStatusSuccessAction);

export const autoLoginNotificationAction = (
  payload: Parameters<typeof checkLoginStatusSuccessfulAction>[0]
) => ({
  type: HANDLE_AUTO_LOGIN_STATUS_SUCCESS,
  payload,
});

export const checkLoginStatusFailureAction = (err: FailurePayload) =>
  ({
    type: HANDLE_LOGIN_STATUS_FAILURE,
    payload: err,
  } as HandleLoginStatusFailureAction);

export const checkLoginStatusAction =
  () => async (dispatch: ThunkDispatch<State, void, AuthenticationAction>) => {
    dispatch({ type: REQUEST_LOGIN_STATUS });
    pushDimensions();

    try {
      const status = await checkLoginStatus();
      dispatch(checkLoginStatusSuccessfulAction(status));
    } catch (err) {
      dispatch(checkLoginStatusFailureAction(err as Error));
    }
  };

export const loginAction =
  (flow?: string, contextLabel?: string, sourceUrl?: string) =>
  async (
    dispatch: ThunkDispatch<State, void, AuthenticationAction>,
    getState: () => State
  ) => {
    dispatch({ type: REQUEST_LOGIN, payload: { flow, contextLabel } });
    saveDimensions();

    const linkerParam = await getLinkerParam();
    try {
      await login(
        {
          ...linkerParam,
          referrer: selectTrackingPageName(getState()),
        },
        sourceUrl
      );
      dispatch({ type: HANDLE_LOGIN_SUCCESS });
    } catch (err) {
      dispatch({ type: HANDLE_LOGIN_FAILURE, payload: err as Error });
    }
  };

export const logoutAction =
  () => async (dispatch: ThunkDispatch<State, void, AuthenticationAction>) => {
    dispatch({ type: REQUEST_LOGOUT });
    saveDimensions();
    const currentURLHash = !isServer()
      ? getCurrentURLHashFragment()
      : undefined;

    try {
      await logout(currentURLHash);
      dispatch({ type: HANDLE_LOGOUT_SUCCESS });
    } catch (err) {
      dispatch({ type: HANDLE_LOGOUT_FAILURE, payload: err as Error });
    }
  };

export const registerAction =
  (flow?: string, contextLabel?: string, sourceUrl?: string) =>
  async (
    dispatch: ThunkDispatch<State, void, AuthenticationAction>,
    getState: () => State
  ) => {
    dispatch({ type: REQUEST_REGISTER, payload: { flow, contextLabel } });
    trackOptimizelyEvent(EVENT_KEYS.REGISTER);

    const linkerParam = await getLinkerParam();
    try {
      await register(
        {
          ...linkerParam,
          referrer: selectTrackingPageName(getState()),
        },
        sourceUrl
      );
      dispatch({ type: HANDLE_REGISTER_SUCCESS });
    } catch (err) {
      dispatch({ type: HANDLE_REGISTER_FAILURE, payload: err as Error });
    }
  };
