import { selectIsLoggedIn } from '../../stores/authentication';
import { PAGE_TYPES_MAP } from './pageTypesMap';

export const isSVIP = (pageType, state) => {
  const listing = state?.pages?.vip?.listing;

  const sellerForeignId = listing?.sellerForeignId;
  const userId = state?.authentication?.userId;
  const isOwner =
    typeof userId !== 'undefined' &&
    typeof sellerForeignId !== 'undefined' &&
    userId === sellerForeignId;
  const isExpiredVip =
    listing?.listingStatus?.statusId === 'deleted' ||
    listing?.listingStatus?.statusId === 'paused';
  const isSeller =
    pageType === PAGE_TYPES_MAP.vip &&
    isOwner &&
    selectIsLoggedIn(state) &&
    !isExpiredVip;

  return isSeller;
};
