import { appendQueryString } from '@move-web-essentials-utils/url';
import apiClient, { REQUEST_HEADER } from '../../../api/lib/apiClient';
import { urls } from '../../../api/urls';

export const createParentTransactionIDHeader = (parentTransactionId) =>
  parentTransactionId
    ? {
        [REQUEST_HEADER.PARENT_TRANSACTION_ID]: parentTransactionId,
      }
    : {};

export const getListing = (listingId, query, options = {}) => {
  const doRequest = apiClient({
    metricsId: 'svc_getListing',
    ...options,
  });

  return doRequest({
    url: appendQueryString(`${urls.listing}/${listingId}`, query),
  });
};
