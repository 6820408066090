import { useRef, useLayoutEffect, useEffect } from 'react';

import { useSelector } from 'react-redux';

import { getPathName } from '../../../stores/location';
import { getClientId } from '../../../../lib/userSessionTracking/userSessionService';

const GETFEEDBACK_EVENT_VPV_NAME = 'virtualPageView';
const GETFEEDBACK_EVENT_CALLBACK_NAME = 'setEventCallback';
const GETFEEDBACK_EVENT_DATA_NAME = 'data';

const GETFEEDBACK_ACTIONS = {
  close: 'Campaign:Close',
  success: 'Campaign:Success',
};

const TIMEOUT_INTERVAL = 5 * 1000;
const INITIALISATION_INTERVAL = 500;
const INITIALISATION_INTERVAL_CANCELATION = 60 * 1000;
const queue = [];

const isSDKInitialized = () =>
  IS_SERVER === false && typeof window.usabilla_live !== 'undefined';

const runGetFeedbackCommand = (...args) => {
  try {
    if (isSDKInitialized()) {
      window.usabilla_live?.(...args);
    } else {
      queue.push(args);
    }
  } catch (_error) {
    // ignore me
  }
};

export const setCustomDataWithDefaults = (customData = {}) => {
  const clientId = getClientId();

  runGetFeedbackCommand(GETFEEDBACK_EVENT_DATA_NAME, {
    custom: {
      ...customData,
      client_id: clientId,
    },
  });
};

export const GetFeedback = IS_SERVER
  ? () => null
  : () => {
      const pathName = useSelector(getPathName);
      const hasSetEventCallback = useRef(false);
      const clientId = getClientId();

      useLayoutEffect(() => {
        if (hasSetEventCallback.current) {
          return;
        }
        // GetFeedback callback function called after events dispatched.
        runGetFeedbackCommand(
          GETFEEDBACK_EVENT_CALLBACK_NAME,
          // eslint-disable-next-line no-unused-vars
          ([_category, action, _label, _value, _userData]) => {
            // For SPA application , users can see one or more campaigns within same page.
            // Sending a VPV event simulates a new page view on Usabilla side
            if (
              action === GETFEEDBACK_ACTIONS.close ||
              action === GETFEEDBACK_ACTIONS.success
            ) {
              setTimeout(
                () => runGetFeedbackCommand(GETFEEDBACK_EVENT_VPV_NAME),
                TIMEOUT_INTERVAL
              );
              hasSetEventCallback.current = true;
            }
          }
        );
      }, [pathName]);

      useEffect(() => {
        setCustomDataWithDefaults();
      }, [clientId]);

      // retry to send commands to GetFeedback that might
      // got lost due to the SDK not yet being loaded
      const sdkInitialisationInterval = setInterval(() => {
        if (isSDKInitialized()) {
          queue.forEach((args) => {
            runGetFeedbackCommand(...args);
          });
          queue.length = 0;
          clearInterval(sdkInitialisationInterval);
        }
      }, INITIALISATION_INTERVAL);

      // give up after some time (a browser extension might block the SDK)
      setTimeout(() => {
        if (sdkInitialisationInterval) {
          clearInterval(sdkInitialisationInterval);
        }
      }, INITIALISATION_INTERVAL_CANCELATION);

      return null;
    };
