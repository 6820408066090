/* globals ga */
const MAX_MILLISECONDS_TO_WAIT = 200;

const getLinkerParam = () =>
  new Promise((resolve) => {
    if (typeof ga === 'undefined') {
      resolve({});
    }

    ga('require', 'linker');
    ga(() => {
      try {
        const trackers = ga.getAll();
        if (trackers) {
          const linkerParam = trackers[0].get('linkerParam');
          if (linkerParam) {
            const [name, value] = linkerParam.split('=');
            resolve(name && value ? { [name]: value } : {});
          } else {
            resolve({});
          }
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        resolve({});
      }
    });
  });

// if a adblocker blocks the async requested ga script than we resolve after a short time
const stopWaiting = () =>
  new Promise((resolve) => {
    setTimeout(resolve, MAX_MILLISECONDS_TO_WAIT, {
      warning: `Couldn't resolve linkerParam after ${MAX_MILLISECONDS_TO_WAIT}`,
    });
  });

export default async () => {
  const result = await Promise.race([getLinkerParam(), stopWaiting()]);
  if (result.warning) {
    // eslint-disable-next-line no-console
    console.warn(result.warning);
    return {};
  }
  return result;
};
