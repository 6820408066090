import { Component, ReactNode } from 'react';
import logger from '@move-web-essentials-utils/logger';

type Props = {
  onError: (error: Error) => void;
  renderErrorPage: () => ReactNode;
};

export class AppErrorBoundary extends Component<Props> {
  state = { hasError: false };

  componentDidCatch(error: Error) {
    const { onError } = this.props;
    logger.error(error);
    onError(error);
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    const { hasError } = this.state;
    const { renderErrorPage, children } = this.props;

    if (hasError) {
      return renderErrorPage();
    }

    return children;
  }
}
