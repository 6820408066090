import { REQUEST_HEADER } from '../../../api/lib/apiClient';

import { DEALER_ROUTE, showErrorPageAction } from '../../stores/page';
import { handleRequestDealer, handleResponseDealer } from './actionCreators';
import { getDealer } from './api';

export const DEALER_PAGE_ID = 'dealer';
export const routes = {
  [DEALER_ROUTE]: {
    path: `/${DEALER_PAGE_ID}/:dealerId/`,
    thunk: async (dispatch, getState) => {
      const state = getState();

      const existingDealerId = state?.pages?.dealer?.dealerDetails?.foreignId;
      const dealerId = state?.location?.payload?.dealerId;

      // Check if we have the dealer already in store. If so, do nothing.
      if (existingDealerId && existingDealerId === dealerId) {
        return;
      }

      const locale = state?.i18n?.currentLocale;

      dispatch(handleRequestDealer());

      try {
        const { body, headers = {} } = await getDealer(
          { dealerId },
          { locale, resolveWithFullResponse: true }
        );
        dispatch(
          handleResponseDealer({
            ...body,
            // enrich listing with transactionId from header
            listings: {
              ...body.listings,
              items: body.listings.items.map((item) => ({
                ...item,
                transactionId: headers.get(REQUEST_HEADER.TRANSACTION_ID),
              })),
            },
          })
        );
      } catch (error) {
        dispatch(showErrorPageAction(error as Error));
      }
    },
  },
};
