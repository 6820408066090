import { addSavedSearchAndNotify } from '../pages/savedSearches';
import { addSavedItemAndTrack } from '../features/savedItems';
import {
  toggleChat,
  sendMessageAndToggleChatWindow,
  sendOpenFormMessage,
  showRetailModal,
} from '../features/contactForm';
import {
  displayContactFormModal,
  displayRequestImagesModal,
} from '../pages/vip';
import { goToDigitalRetailPage } from '../stores/page';
import { restoreChat } from '../pages/srp/actionCreators';
import {
  ACTION_CREATOR_ADD_SAVED_ITEM,
  ACTION_CREATOR_ADD_SAVED_SEARCH,
  ACTION_CREATOR_DISPLAY_CONTACT_FORM_MODAL,
  ACTION_CREATOR_DISPLAY_REQUEST_IMAGES_MODAL,
  ACTION_CREATOR_OPEN_DIGITAL_RETAIL_PAGE,
  ACTION_CREATOR_RESTORE_SRP_CHAT,
  ACTION_CREATOR_SEND_MESSAGE_OPEN_CHAT_WINDOW,
  ACTION_CREATOR_SEND_OPEN_FORM_MESSAGE,
  ACTION_CREATOR_SHOW_RETAIL_MODAL,
  ACTION_CREATOR_TOGGLE_CHAT,
} from './actionTypes';

const actionsMap = {
  [ACTION_CREATOR_TOGGLE_CHAT]: toggleChat,
  [ACTION_CREATOR_RESTORE_SRP_CHAT]: restoreChat,
  [ACTION_CREATOR_ADD_SAVED_SEARCH]: addSavedSearchAndNotify,
  [ACTION_CREATOR_ADD_SAVED_ITEM]: addSavedItemAndTrack,
  [ACTION_CREATOR_SEND_MESSAGE_OPEN_CHAT_WINDOW]:
    sendMessageAndToggleChatWindow,
  [ACTION_CREATOR_SEND_OPEN_FORM_MESSAGE]: sendOpenFormMessage,
  [ACTION_CREATOR_DISPLAY_CONTACT_FORM_MODAL]: displayContactFormModal,
  [ACTION_CREATOR_DISPLAY_REQUEST_IMAGES_MODAL]: displayRequestImagesModal,
  [ACTION_CREATOR_SHOW_RETAIL_MODAL]: showRetailModal,
  [ACTION_CREATOR_OPEN_DIGITAL_RETAIL_PAGE]: goToDigitalRetailPage,
};

export default actionsMap;
