import queryString from 'querystring';

import apiClient from './lib/apiClient';
import { urls } from './urls';

import getApiVersion from '../lib/env/getApiVersion';
import { omitFalsyValues } from './omitFalsyValues';
import { Query } from '../app/stores/searchQuery';

export const getListings = (
  query: Query = {},
  options: { headers?: Record<string, string | boolean> } = {}
) => {
  const qs = queryString.stringify(omitFalsyValues(query));
  const doRequest = apiClient({
    metricsId: 'svc_getListings',
    version: getApiVersion(),
    ...options,
  });

  return doRequest({ url: `${urls.search}?${qs}` });
};

export const getListingsByParams = (
  query: Query = {},
  options: {
    locale?: string;
    additionalParam?: string;
    resolveWithFullResponse?: boolean;
  } = {}
) => {
  const { additionalParam, ...restOptions } = options;
  const qs = queryString.stringify(omitFalsyValues(query));
  const doRequest = apiClient({
    metricsId: 'svc_getListings',
    version: getApiVersion(),
    ...restOptions,
  });

  return doRequest({
    url: `${urls.srp}?listingsOnly=true&${qs}${
      additionalParam ? `&${additionalParam}` : ''
    }`,
  });
};
