import uuidv4 from 'uuid/v4';

import { get, set } from 'userContext';

const CLIENT_ID_CONTEXT_PROP = 'clientId';
const CLIENT_IP_CONTEXT_PROP = 'CLIENT_IP';
const CLIENT_LOCALE_CONTEXT_PROP = 'CLIENT_LOCALE';
const CLIENT_IS_RETURNING_USER = 'CLIENT_RETURNING';

const generateClientId = () => uuidv4();
const getClientId = () => get(CLIENT_ID_CONTEXT_PROP);
const setClientId = (clientId: string) => set(CLIENT_ID_CONTEXT_PROP, clientId);

const getClientIp = () => get(CLIENT_IP_CONTEXT_PROP);
const setClientIp = (clientIp: string) => set(CLIENT_IP_CONTEXT_PROP, clientIp);

const getClientLocale = () => get(CLIENT_LOCALE_CONTEXT_PROP);
const setClientLocale = (locale: string) =>
  set(CLIENT_LOCALE_CONTEXT_PROP, locale);

const isReturningUser = () => get<boolean>(CLIENT_IS_RETURNING_USER);
const setReturningUser = (returningUser: boolean) =>
  set(CLIENT_IS_RETURNING_USER, returningUser);

export {
  generateClientId,
  getClientId,
  setClientId,
  getClientIp,
  setClientIp,
  getClientLocale,
  setClientLocale,
  isReturningUser,
  setReturningUser,
};
