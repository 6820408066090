import {
  SellerType,
  sellerTypeTrackingMap,
} from './../../constants/sellerTypes';
/**
 * tracking definition for all saved items (aka Watchlist) events
 *
 * @see https://jira.corp.ebay.com/browse/MOVE-2070
 */

import {
  DIMENSION_LISTING_ID,
  DIMENSION_SELLER_ACCOUNT_TYPE,
} from '../../utils/tracking/dimensions';
import { EVENT_KEYS } from '../../../lib/optimizely';

export const ADD_CLICK = {
  eventAction: 'WatchlistAdd',
  getEventData: ({
    adId,
    sellerType,
    dimensions,
  }: {
    adId: string;
    sellerType: SellerType;
    dimensions?: number[];
  }) => ({
    eventLabel: 'na',
    nonInteraction: false,
    [DIMENSION_LISTING_ID]: adId,
    [DIMENSION_SELLER_ACCOUNT_TYPE]: sellerTypeTrackingMap[sellerType],
    ...dimensions,
  }),
  optimizelyEvents: [EVENT_KEYS.SAVE_ITEM_CLICK],
};

export const REMOVE_CLICK = {
  eventAction: 'WatchlistRemove',
  getEventData: ({ adId, dimensions }) => ({
    eventLabel: 'na',
    nonInteraction: false,
    [DIMENSION_LISTING_ID]: adId,
    ...dimensions,
  }),
};

export const ADD_CLICK_ATTEMPT = {
  eventAction: 'WatchlistAddAttempt',
  getEventData: ({ adId, dimensions }) => ({
    eventLabel: 'na',
    nonInteraction: false,
    [DIMENSION_LISTING_ID]: adId,
    ...dimensions,
  }),
  optimizelyEvents: [EVENT_KEYS.WATCHLIST_ADD_ATTEMPT],
};

export const ADD_CLICK_RELATED_AD = {
  eventAction: 'WatchlistAddRelatedAd',
  getEventData: ({ adId, sellerType }) => ({
    eventLabel: 'na',
    noInteraction: false,
    [DIMENSION_LISTING_ID]: adId,
    [DIMENSION_SELLER_ACCOUNT_TYPE]: sellerTypeTrackingMap[sellerType],
  }),
  optimizelyEvents: [EVENT_KEYS.WATCHLIST_ADD_RELATED_AD],
};
