import { getEnv } from '@move-web-essentials-utils/env';
import { getCssVars } from '@move-ui/theme';
import isFeatureEnabled from '../lib/env/isFeatureEnabled';

const ONE_YEAR_MILLISECONDS = 365 * 24 * 60 * 60 * 1000;

export const BASE_URL = 'https://www.kijijiautos.ca';
export const MOVE_CONSUMER_API_GATEWAY_PATH = '/consumer';
export const MOVE_ACCOUNTS_SERVICE_PATH = '/user';
export const MOVE_CONSUMER_API_GATEWAY_CONTEXT_PATH =
  MOVE_CONSUMER_API_GATEWAY_PATH;

export const MOVE_SYI_APP_PATH = '/sell-my-car';
export const MOVE_SYI_APP_PATH_FR = '/fr/vendre-mon-auto';
export const MOVE_SYI_BUYER_PATHS_REGEXP = new RegExp(
  `(${MOVE_SYI_APP_PATH}|${MOVE_SYI_APP_PATH_FR}|/static/move-syi-buyer)`
);

export const MOVE_API_BASE_PATH = `${MOVE_CONSUMER_API_GATEWAY_CONTEXT_PATH}/svc`;
export const MOVE_SRP_API_BASE_PATH = `${MOVE_CONSUMER_API_GATEWAY_CONTEXT_PATH}/srp`;
export const MOVE_CHAT_API_BASE_PATH = `${MOVE_CONSUMER_API_GATEWAY_CONTEXT_PATH}/chat`;
export const MOVE_FREESPEE_API_BASE_PATH = `${MOVE_CONSUMER_API_GATEWAY_CONTEXT_PATH}/phone-reveal`;
export const MOVE_EPS_API_BASE_PATH = isFeatureEnabled('yams')
  ? `${MOVE_CONSUMER_API_GATEWAY_CONTEXT_PATH}/yams`
  : `${MOVE_CONSUMER_API_GATEWAY_CONTEXT_PATH}/eps/upload`;
export const MOVE_CMS_API_BASE_PATH = `${MOVE_CONSUMER_API_GATEWAY_CONTEXT_PATH}/cms`;
export const MOVE_GOT_V2_API_BASE_PATH = `${MOVE_CONSUMER_API_GATEWAY_CONTEXT_PATH}/got/v2`;
export const MOVE_GEOCODE_API_BASE_PATH = `${MOVE_CONSUMER_API_GATEWAY_CONTEXT_PATH}/geocode`;
export const MOVE_SAVED_ITEMS_API_BASE_PATH = `${MOVE_CONSUMER_API_GATEWAY_CONTEXT_PATH}/saved-items-api`;
export const MOVE_SIMILAR_ITEMS_API_CONTEXT_PATH = `${MOVE_CONSUMER_API_GATEWAY_CONTEXT_PATH}/similar-items-api`;
export const MOVE_REPORT_LISTING_BASE_PATH = `${MOVE_CONSUMER_API_GATEWAY_CONTEXT_PATH}/report-listing`;
export const MOVE_USER_SETTINGS_API_BASE_PATH = `${MOVE_CONSUMER_API_GATEWAY_CONTEXT_PATH}/settings`;
export const MOVE_DEALERS_API_BASE_PATH = `${MOVE_CONSUMER_API_GATEWAY_CONTEXT_PATH}/dealers`;
export const MOVE_MIP_API_BASE_PATH = `${MOVE_CONSUMER_API_GATEWAY_CONTEXT_PATH}/mip`;
export const MOVE_MRP_API_BASE_PATH = `${MOVE_CONSUMER_API_GATEWAY_CONTEXT_PATH}/mrp`;
export const MOVE_ODIN_API_BASE_PATH = `${MOVE_CONSUMER_API_GATEWAY_CONTEXT_PATH}/odin-proxy`;
export const MOVE_SITEMAP_API_BASE_PATH = `${MOVE_CONSUMER_API_GATEWAY_CONTEXT_PATH}/sitemap`;
export const MOVE_OPTIMIZELY_DATAFILE_API_BASE_PATH = `${MOVE_CONSUMER_API_GATEWAY_CONTEXT_PATH}/optimizely-datafile`;
export const MOVE_PERSONALIZED_RECOMMENDATIONS_API_BASE_PATH = `${MOVE_CONSUMER_API_GATEWAY_CONTEXT_PATH}/personalized-recommendations`;
export const MOVE_FINANCING_PATH = `${MOVE_CONSUMER_API_GATEWAY_CONTEXT_PATH}/digital-retail/financing`;
export const MOVE_DRP_PROTECTION_PRODUCTS = `${MOVE_CONSUMER_API_GATEWAY_CONTEXT_PATH}/digital-retail/protection/products`;
export const MOVE_PLACE_SUGGESTIONS = `${MOVE_CONSUMER_API_GATEWAY_CONTEXT_PATH}/place-suggestions`;
export const MOVE_TRADE_IN_PATH = `${MOVE_CONSUMER_API_GATEWAY_CONTEXT_PATH}/trade-in/v2`;
export const MOVE_SEO_MAPPING_API_BASE_PATH = `${MOVE_CONSUMER_API_GATEWAY_CONTEXT_PATH}/seo-mapping`;
export const MOVE_DIGITAL_RETAIL_API_BASE_PATH = `${MOVE_CONSUMER_API_GATEWAY_CONTEXT_PATH}/digital-retail`;
export const LOCALE_COOKIE_NAME = 'locale';
export const LOCALE_COOKIE_MAX_AGE = ONE_YEAR_MILLISECONDS;
export const CLIENT_ID_COOKIE_NAME = 'mvcid';
export const CLIENT_ID_COOKIE_MAX_AGE = ONE_YEAR_MILLISECONDS;
export const COMMON_HEADERS = {
  'content-type': 'text/html; charset=utf-8',
  'content-security-policy': 'upgrade-insecure-requests',
};

export const firebaseConfig = {
  apiKey: getEnv('firebase_api_key'),
  authDomain: getEnv('firebase_auth_domain'),
  databaseURL: getEnv('firebase_database_url'),
  projectId: getEnv('firebase_project_id'),
  storageBucket: getEnv('firebase_storage_bucket'),
  messagingSenderId: getEnv('firebase_messaging_sender_id'),
  appId: getEnv('firebase_app_id'),
};

export const THEME_COLOR = getCssVars()['--color-brand-300'];
export const VEHICLE_CATEGORY = 'Car';
export const MOVE_API_CLIENT_ID = 'ca.move.web.app';
export const LOCALES = ['en-CA', 'fr-CA'];
export const DEFAULT_LOCALE = 'en-CA';
export const DEFAULT_COUNTRY = 'CA';
export const CURRENCY = 'CAD';
export const CURRENCY_FORMATS = {
  number: {
    currency: {
      style: 'currency',
      currency: CURRENCY,
      // needed for "fr-CA", otherwise "$ CA" is displayed instead of "$"
      currencyDisplay: 'narrowSymbol',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    currency_two_fraction: {
      style: 'currency',
      currency: CURRENCY,
      // needed for "fr-CA", otherwise "$ CA" is displayed instead of "$"
      currencyDisplay: 'narrowSymbol',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
  },
};
export const SRP_BASE_PATHS = {
  'en-CA': '/cars',
  'fr-CA': '/voitures',
};
export const MIP_BASE_PATHS = {
  'en-CA': '/info',
  'fr-CA': '/info',
};

export const TRAILING_SLASH_URLS = true;

// illustrations
export { default as IMAGE_EMPTY_CHAT_PANEL } from '@move-core/asset-catalog/images/ca/web/img-error-illustration-8.svg';
export { default as IMAGE_EMPTY_INBOX } from '@move-core/asset-catalog/images/ca/web/img-error-illustration-5.svg';
export { default as IMAGE_EMPTY_INBOX_ERROR } from '@move-core/asset-catalog/images/ca/web/img-error-illustration-3.svg';
export { default as IMAGE_EMPTY_SAVED_SEARCH } from '@move-core/asset-catalog/images/ca/web/img-no-search-alert.svg';
export { default as IMAGE_EMPTY_DEALS } from '@move-core/asset-catalog/images/ca/web/img-digital-retail.svg';
export { default as IMAGE_EMPTY_SAVED_ITEMS } from '@move-core/asset-catalog/images/ca/web/img-error-illustration-7.svg';
export { default as IMAGE_ERROR } from '@move-core/asset-catalog/images/ca/web/img-error-illustration-4.svg';
export { default as IMAGE_LOGIN } from '@move-core/asset-catalog/images/ca/web/img-error-illustration-6.svg';
export { default as IMAGE_PROCESSING } from '@move-core/asset-catalog/images/ca/web/img-error-illustration-9.svg';
export { default as COMBINED_BRAND_LOGO } from '@move-core/asset-catalog/images/ca/web/img-horizontal-vertical.svg';
export { default as BITMAP_VIN_DISCOVERABILITY } from '@move-core/asset-catalog/images/ca/web/bmp-vin-discoverability.webp';

export const PUBLIC_DOMAIN_URL =
  getEnv('move_webapp_public_domain') || 'https://www.kijijiautos.ca';

export const APP_STORE_ID = '1327751487';
export const PLAY_STORE_ID = 'ecg.move.ca';
export const META_TWITTER_SITE = true;

export const ADOBE_AUDIENCE_MANAGER_PARTNER = 'kijiji';
export const WHITELISTED_DOMAINS_FOR_TRACKJS = [
  'optimizely.com',
  'kijiji.ca',
  'trackjs.com',
  'googletagmanager.com',
  'facebook.net',
  'cdnjs.cloudflare.com',
];
export const DEFAULT_LOCATION_RADIUS = 150;
