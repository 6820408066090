import { SortBy } from '../types/SortBy';
import { SortOrder } from '../types/SortOrder';

export const relevancySortingState = {
  sortBy: SortBy.Relevance,
  sortOrder: SortOrder.Descending,
};

export const relevancySortingQueryParams = {
  sb: 'rel',
  od: 'down',
} as const;
