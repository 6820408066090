import { showNotFoundPageAction, showErrorPageAction } from '../../stores/page';
import {
  REQUEST_MRP,
  HANDLE_RESPONSE_SUCCESS_MRP,
  HANDLE_RESPONSE_ERROR_MRP,
  SET_MAKE_FILTER,
  RESET_MAKE_FILTER,
} from './actionTypes';
import { fetchMrp } from './api';

const requestMrp = () => ({
  type: REQUEST_MRP,
});

const handleResponseError = () => ({
  type: HANDLE_RESPONSE_ERROR_MRP,
});

export const handleResponseSuccess = (payload) => ({
  type: HANDLE_RESPONSE_SUCCESS_MRP,
  payload,
});

export const setMakeFilter = (make) => ({
  type: SET_MAKE_FILTER,
  make,
});

export const resetMakeFilter = (make) => ({
  type: RESET_MAKE_FILTER,
  make,
});

export const getMrp =
  (options = {}) =>
  async (dispatch) => {
    dispatch(requestMrp());
    try {
      const payload = await fetchMrp(options);
      dispatch(handleResponseSuccess(payload));
    } catch (error) {
      dispatch(handleResponseError());
      dispatch(
        error?.statusCode === 404
          ? showNotFoundPageAction()
          : showErrorPageAction(error)
      );
    }
  };
