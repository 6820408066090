import React from 'react';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';
import { getLinkForLocales } from '../../../../lib/locale-utils';

import { DEFAULT_LOCALE } from '../../../config';

const getLinkForLocale = (links, locale) =>
  links.find((link) => link.locale.toLowerCase() === locale.toLowerCase());

const SelfReferencingCanonicalLink = ({
  pathname = '',
  currentLocale,
  pageId = '',
  seoUrls,
}) => {
  const linksForAllLocales = getLinkForLocales(pathname, pageId, seoUrls);

  const linkForCurrentLocale =
    getLinkForLocale(linksForAllLocales, currentLocale) ||
    getLinkForLocale(linksForAllLocales, DEFAULT_LOCALE);

  return (
    <Helmet>
      <link rel="canonical" href={linkForCurrentLocale.href} />
    </Helmet>
  );
};

SelfReferencingCanonicalLink.propTypes = {
  pathname: PropTypes.string,
  currentLocale: PropTypes.string,
  pageId: PropTypes.string,
  seoUrls: PropTypes.objectOf(PropTypes.objectOf(PropTypes.string)),
};

export default SelfReferencingCanonicalLink;
