import { RADIUS_OPTIONS } from '../config';
import { DEFAULT_LOCATION_RADIUS } from '../../../../../shared/config';

/**
 * convert radius to string
 * @param {number} radius
 * @returns {string}
 */
export const convertRadiusToString = (radius) =>
  radius ? radius.toString() : '';

/**
 * get radius from area
 * @param {Area} area
 * @return {String}     the area radius or default radius if the radius setting is invalid or not set
 */
export const getRadiusValue = (area) =>
  convertRadiusToString(
    RADIUS_OPTIONS.includes(area?.radius)
      ? area.radius
      : DEFAULT_LOCATION_RADIUS
  );
