import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { selectIsLoggedIn } from '../authentication';
import { redirectToHome } from './actionCreators';

export const handlePrivateRoute =
  (
    routeName: string
  ): ThunkAction<
    void,
    Parameters<typeof selectIsLoggedIn>[0],
    { action: AnyAction },
    AnyAction
  > =>
  (dispatch, getState, { action }) => {
    if (action.type === routeName && !selectIsLoggedIn(getState())) {
      dispatch(redirectToHome());
    }
  };
