import { getEnv } from '@move-web-essentials-utils/env';
import apiClient from '../../../api/lib/apiClient';
import { MOVE_CONSUMER_API_GATEWAY_PATH } from '../../../shared/config';

export const subscribeToPushNotifications = async () => {
  const messaging = window.firebase?.messaging();
  const deviceToken = await messaging?.getToken();

  await apiClient()({
    method: 'PUT',
    url: `${MOVE_CONSUMER_API_GATEWAY_PATH}/notifications/subscriptions/tokens/${deviceToken}`,
    body: JSON.stringify({
      fcmProjectId: getEnv('firebase_project_id'),
      topics: ['CHAT'],
      ttl: 0,
      ttlTimeUnit: 'NANOSECONDS',
      userAgent: window.navigator.userAgent,
    }),
  });
};
