export const ACTION_CREATOR_TOGGLE_CHAT = 'ACTION_CREATOR_TOGGLE_CHAT';
export const ACTION_CREATOR_RESTORE_SRP_CHAT =
  'ACTION_CREATOR_RESTORE_SRP_CHAT';
export const ACTION_CREATOR_ADD_SAVED_SEARCH =
  'ACTION_CREATOR_ADD_SAVED_SEARCH';
export const ACTION_CREATOR_ADD_SAVED_ITEM = 'ACTION_CREATOR_ADD_SAVED_ITEM';
export const ACTION_CREATOR_SEND_MESSAGE_OPEN_CHAT_WINDOW =
  'ACTION_CREATOR_SEND_MESSAGE_OPEN_CHAT_WINDOW';
export const ACTION_CREATOR_DISPLAY_CONTACT_FORM_MODAL =
  'ACTION_CREATOR_DISPLAY_CONTACT_FORM_MODAL';
export const ACTION_CREATOR_DISPLAY_REQUEST_IMAGES_MODAL =
  'ACTION_CREATOR_DISPLAY_REQUEST_IMAGES_MODAL';
export const ACTION_CREATOR_SEND_OPEN_FORM_MESSAGE =
  'ACTION_CREATOR_SEND_OPEN_FORM_MESSAGE';
export const ACTION_CREATOR_SHOW_RETAIL_MODAL =
  'ACTION_CREATOR_SHOW_RETAIL_MODAL';
export const ACTION_CREATOR_OPEN_DIGITAL_RETAIL_PAGE =
  'ACTION_CREATOR_OPEN_DIGITAL_RETAIL_PAGE';
