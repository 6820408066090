import * as types from './actionTypes';
import { BackendNotificationItemType } from './types/NotificationItem';

export type State = {
  isLoading: boolean;
  newCount: number;
  items: BackendNotificationItemType[];
};

type Action = {
  type: string;
  payload?: any;
};

export const defaultState = {
  isLoading: true,
  newCount: 0,
  items: [],
};

const states = {
  [types.HANDLE_RESPONSE_FETCH_NOTIFICATIONS]: (state, { payload }) => ({
    isLoading: false,
    newCount: payload.newCount,
    items: payload.notifications,
  }),
  [types.HANDLE_RESPONSE_ERROR_FETCH_NOTIFICATIONS]: (state) => ({
    ...state,
    isLoading: false,
  }),
  [types.HANDLE_RESPONSE_DELETE_NOTIFICATIONS_NEW_COUNT]: (state) => ({
    ...state,
    newCount: 0,
  }),
  [types.HANDLE_RESPONSE_FETCH_NOTIFICATIONS_NEW_COUNT]: (
    state,
    { payload }
  ) => ({
    ...state,
    newCount: payload.count,
  }),
};

const reducer = (state: State = defaultState, action: Action = { type: '' }) =>
  states[action.type] ? states[action.type](state, action) : state;

export default reducer;
