import { get } from 'lodash';

import { selectHasLocationPreparatoryQuery } from '../../stores/searchQuery';
import { isValidBrowseByCategoryType } from '../../features/browseBy';
import { getPreviousRoute } from '../../stores/location';
import { getGoogleTagManagerPageType } from '../../utils/tracking/googleTagManager';
import { selectPageId } from '../../stores/page';

import { SRP_BASE_PATHS, DEFAULT_LOCALE } from '../../../shared/config';

import { relevancySortingState } from '../../shared/domains/sorting/specifications/sorting';
import { hasAbundance } from './domain/specifications/seo';
import { State as FullState } from '../../stores/types/State';

type State = NestedPick<FullState, 'pages', 'srp'>;

export const getSearch = (state: State) => state.pages.srp || {};
export const getSeoUrls = (state: State) => getSearch(state).url?.seoUrls || {};
export const getContent = (state: State) => getSearch(state).content || {};
export const getItems = (state: State) => getSearch(state).items || [];
export const hasItems = (state: State) => Boolean(getItems(state).length);
export const getRelatedUrls = (state: State) =>
  getSearch(state).related || null;
export const getNumResultsTotal = (state: State) =>
  getSearch(state).numResultsTotal;
export const shouldSkipByUrlRequest = (state: State) =>
  getSearch(state).skipByUrlRequest;
export const shouldSkipPageRequest = (state: State) =>
  getSearch(state).skipPageRequest;

export const getResolvedLocation = (state: State) =>
  getSearch(state).resolvedLocation || null;

const getResolvedCity = (state: State) => getResolvedLocation(state)?.city;
const getResolvedLocationProvince = (state: State) =>
  getResolvedLocation(state)?.province;

export const getLocationFilter = (state: State) =>
  state.pages.srp.url?.locationFilter || null;

export const getSorting = (state: State) =>
  getSearch(state).sorting || relevancySortingState;

export const isLifestyleSrp = (state: State) => {
  const reverseLookupAllowed = Boolean(
    getSearch(state).url?.reverseLookupAllowed ?? true
  );

  return !reverseLookupAllowed;
};

export const isDigitalRetailSrp = (state: State) =>
  !!state.pages.srp?.url?.requestParams?.includes('digital_retail_eligible') &&
  isLifestyleSrp(state);

export const selectDefaultLocalePath = (state: State) =>
  get(getSeoUrls(state), [DEFAULT_LOCALE]);

// Takes path after "/cars/" (in english). E.g. extract "pickup-truck" from
// "/cars/pickup-truck/".
const getPathAfterBasePath = (state: State) => {
  const seoUrls = get(state, 'pages.srp.url.seoUrls', {});
  const seoUrlForCurrentLocale = seoUrls[DEFAULT_LOCALE];

  const basePath = SRP_BASE_PATHS[DEFAULT_LOCALE];
  const pathAfterBasePath =
    seoUrlForCurrentLocale &&
    seoUrlForCurrentLocale.replace(`${basePath}`, '').replace(/[/]/g, '');

  return pathAfterBasePath;
};

export const getLifestyleName = (state: State) =>
  isLifestyleSrp(state) ? getPathAfterBasePath(state) : undefined;

export const getPageType = (
  state: State &
    Parameters<typeof getPreviousRoute>[0] &
    Parameters<typeof selectPageId>[0]
) => {
  const previousRoute = getPreviousRoute(state);
  return previousRoute.pathname
    ? getGoogleTagManagerPageType(selectPageId(state))
    : 'ResultsBrowse';
};

export const getProvince = (
  state: State & Parameters<typeof selectHasLocationPreparatoryQuery>[0]
) => {
  const locationFilter = getLocationFilter(state);
  if (locationFilter) {
    return locationFilter.province;
  }

  return (
    (selectHasLocationPreparatoryQuery(state) &&
      getResolvedLocationProvince(state)) ||
    undefined
  );
};

export const getCity = (
  state: State & Parameters<typeof selectHasLocationPreparatoryQuery>[0]
) => {
  const locationFilter = getLocationFilter(state);
  if (locationFilter) {
    return locationFilter.city;
  }

  return (
    (selectHasLocationPreparatoryQuery(state) && getResolvedCity(state)) ||
    undefined
  );
};

export const getSelectedItem = (state: State) => state.pages.srp?.selectedItem;

export const isTopCarsSliderDataValid = (state: State) =>
  Boolean(
    getContent(state).topSlider &&
      isValidBrowseByCategoryType(
        get(getContent(state), 'topSlider.type', null)
      )
  );

export const selectQueryParamsHash = (state: State) =>
  state.pages.srp?.queryParamsHash;

export const selectHashedVip = (state: State) => state.pages.srp?.hashedVip;

export const selectHashedVipId = (state: State) => selectHashedVip(state)?.id;

export const selectLazyLoadingType = (state: State) =>
  state.pages.srp?.lazyLoading;

export const selectFirstAdvertisingTrack = (state: State) =>
  state.pages.srp?.firstAdvertisingTrack || {};

export const selectIsInfiniteScroll = (state: State) =>
  state.pages.srp?.isInfiniteScroll ?? true;

export const selectHasAbundance = (state: State) =>
  hasAbundance(getNumResultsTotal(state) || 0);

export const selectChatId = (state: State) => state.pages.srp?.chatId;
