import { isArray, uniq } from 'lodash';
import { FilterValue } from '../../../../stores/referenceData';
import { Query } from '../../../../stores/searchQuery';

export const sanitizeBodyType = ({
  filter: { values = [], selectedValues },
}: {
  filter: {
    values: Pick<FilterValue, 'value'>[];
    selectedValues: NonNullable<Query[keyof Query]>;
  };
}) => {
  if (isArray(selectedValues)) {
    return uniq(selectedValues).filter((filterValue) =>
      values.some(({ value }) => value === filterValue)
    );
  }

  return values.some(({ value }) => value === selectedValues)
    ? selectedValues
    : '';
};
