import { ConvertedAdConfig } from '../../types/ConvertedAdConfig';

type AmazonTamConfig = Pick<ConvertedAdConfig, 'aps' | 'useAPS'>;

const getAmazonTamConfig = (): AmazonTamConfig => ({
  aps: {
    pubID: '5110',
    bidTimeout: 2e3,
    deals: true,
  },
  useAPS: true,
});

export default getAmazonTamConfig;
