export const LOADING_STARTED = 'page/srp/LOADING_STARTED';
export const LOADING_FINISHED = 'page/srp/LOADING_FINISHED';
export const LAZY_LOADING_STARTED = 'page/srp/LAZY_LOADING_STARTED';
export const LAZY_LOADING_FINISHED = 'page/srp/LAZY_LOADING_FINISHED';
export const REQUEST_SEARCH_RESULTS = 'page/srp/REQUEST_SEARCH_RESULTS';
export const HANDLE_RESPONSE_SEARCH_RESULTS =
  'page/srp/HANDLE_RESPONSE_SEARCH_RESULTS';
export const REQUEST_MORE_SEARCH_RESULTS =
  'page/srp/REQUEST_MORE_SEARCH_RESULTS';
export const HANDLE_RESPONSE_MORE_SEARCH_RESULTS =
  'page/srp/HANDLE_RESPONSE_MORE_SEARCH_RESULTS';
export const HANDLE_RESPONSE_ERROR_MORE_SEARCH_RESULTS =
  'page/srp/HANDLE_RESPONSE_ERROR_MORE_SEARCH_RESULTS';
export const HANDLE_RESPONSE_HASHED_VIP = 'page/srp/HANDLE_RESPONSE_HASHED_VIP';
export const HANDLE_RESPONSE_MISSING_HASHED_VIP =
  'page/srp/HANDLE_RESPONSE_MISSING_HASHED_VIP';
export const PAGE_UNMOUNTED = 'page/srp/PAGE_UNMOUNTED';

export const SET_SORTING = 'page/srp/SET_SORTING';
export const RESET_SORTING = 'page/srp/RESET_SORTING';
export const RESET_CONTENT = 'page/srp/RESET_CONTENT';
export const REQUEST_SORTED_SEARCH_RESULTS =
  'page/srp/REQUEST_SORTED_SEARCH_RESULTS';
export const HANDLE_RESPONSE_SORTED_SEARCH_RESULTS =
  'page/srp/HANDLE_RESPONSE_SORTED_SEARCH_RESULTS';
export const HANDLE_RESPONSE_ERROR_SORTED_SEARCH_RESULTS =
  'page/srp/HANDLE_RESPONSE_ERROR_SORTED_SEARCH_RESULTS';
export const SELECT_ITEM = 'page/srp/SELECT_ITEM';

export const SET_SKIP_BY_URL_REQUEST = 'page/srp/SET_SKIP_BY_URL_REQUEST';
export const SET_SKIP_PAGE_REQUEST = 'page/srp/SET_SKIP_PAGE_REQUEST';

export const FIRST_ADVERTISING_TRACK = 'page/srp/FIRST_ADVERTISING_TRACK';

export const SET_IS_INFINITE_SCROLL = 'page/srp/SET_IS_INFINITE_SCROLL';

export const RESTORE_CHAT = 'page/srp/RESTORE_CHAT';
export const CLEAR_CHAT = 'page/srp/CLEAR_CHAT';
