import { pickBy, some } from 'lodash';
import { Query } from '../../../../stores/searchQuery';

const ADDITIONAL_QUERY_PARAMS = [
  'll',
  'rd',
  'aa',
  'sb',
  'od',
  'vc',
  'ps',
  'psz',
];

export const filterQueryParamsToConsider = (queryParams: (keyof Query)[]) =>
  queryParams.filter(
    (queryParam) => !ADDITIONAL_QUERY_PARAMS.includes(queryParam)
  );

export const isAnyFilterSet = (query: Query) => {
  const values = pickBy(
    query,
    (_item, key) => !ADDITIONAL_QUERY_PARAMS.includes(key)
  );

  return some(values, (value) => {
    if (Array.isArray(value)) {
      return Boolean(value.length);
    }

    return Boolean(value);
  });
};

export const filterQueryParamsToCount = (
  queryParams: (keyof Query)[],
  exclude: string[] = []
) => {
  const paramsToIgnore = [...exclude, 'll', 'rd', 'aa', 'ps', 'psz', 'vc'];
  return queryParams.filter(
    (queryParam) => !paramsToIgnore.includes(queryParam)
  );
};
