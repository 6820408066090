import {
  useState,
  useRef,
  useEffect,
  ComponentClass,
  ComponentType,
} from 'react';
import {
  createIdleValue,
  cancelIdle,
  getUrgentValue,
  IdleValue,
} from './IdleValue';

export default function usePreloadComponentOnIdle<P = any>(
  UniversalComponent: ComponentClass<P> & {
    preload: (props?: P) => Promise<void>;
  },
  predicate = () => false,
  Fallback: ComponentType<P> = () => null,
  props?: P
) {
  const idleValue = useRef<IdleValue<typeof UniversalComponent>>();
  const [lazyComponent, setLazyComponent] = useState(() => Fallback);
  const [isComponentLoaded, setIsComponentLoaded] = useState(false);

  useEffect(() => {
    idleValue.current = createIdleValue(() => {
      UniversalComponent.preload(props).then(() => {
        setIsComponentLoaded(true);
      });

      return UniversalComponent;
    });

    return () => {
      if (idleValue.current) {
        cancelIdle(idleValue.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (idleValue.current && (predicate() || isComponentLoaded)) {
      const urgentValue = getUrgentValue(idleValue.current);

      if (urgentValue !== lazyComponent) {
        setLazyComponent(() => urgentValue);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idleValue.current, isComponentLoaded, predicate()]);

  return lazyComponent;
}
