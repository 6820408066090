import { getUserId } from '../user';

/**
 * Forces a user into a variation for a given experiment for the lifetime of the Optimizely client.
 * See also https://docs.developers.optimizely.com/full-stack/docs/set-forced-variation
 *
 * @param {string} experimentKey The key of the experiment to set with the forced variation.
 * @param {string} variationKey The key of the forced variation. Set the value to null to clear the existing experiment-to-variation mapping
 * @param {object} optimizely The optimizely instance
 *
 * @return boolean `true` if the user was successfully forced into a variation, `false` if the `experimentKey` isn't in the project file or the `variationKey` isn't in the experiment.
 *
 */
export const setForcedVariation = (experimentKey, variationKey, optimizely) => {
  const userId = getUserId();
  return optimizely?.setForcedVariation(experimentKey, userId, variationKey);
};

/**
 * Returns the forced variation set by `setForcedVariation`, or null if no variation was forced.
 * See also https://docs.developers.optimizely.com/full-stack/docs/get-forced-variation
 *
 * @param {string} experimentKey The key of the experiment to retrieve the forced variation
 * @param {object} optimizely The optimizely instance
 *
 * @return {string|null} The variation the user was bucketed into, or `null` if `setForcedVariation` failed to force the user into the variation.
 *
 */
export const getForcedVariation = (experimentKey, optimizely) => {
  const userId = getUserId();
  return optimizely?.getForcedVariation(experimentKey, userId);
};
