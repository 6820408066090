import isFeatureEnabled from '../../lib/env/isFeatureEnabled';

const globalObjectName = '__exposed_items__';

// This helper exposes certain items to the global scope which would be otherwise inaccessible to give certain
// helper tools a comfortable entry point.
export const expose = (itemsToExpose: Record<string, any>) => {
  if (isFeatureEnabled('exposed_apis') && typeof window === 'object') {
    window[globalObjectName] = {
      ...window?.[globalObjectName],
      ...itemsToExpose,
    };
  }
};
