import {
  getItems,
  getLifestyleName,
  getNumResultsTotal,
  getPageType,
  getSorting,
  selectHashedVip,
} from './selectors';
import {
  selectCategoryName,
  selectConditionQuery,
  selectDrivetrainFromPreparatoryQuery,
  selectExteriorColorFromPreparatoryQuery,
  selectFuelTypeFromPreparatoryQuery,
  selectMakeModelTrim,
  selectQuery,
  selectSearchMaxPrice,
  selectSearchMinPrice,
  selectTransmissionFromPreparatoryQuery,
} from '../../stores/searchQuery';

import { EVENT_KEYS as OPTIMIZELY_EVENTS } from '../../../lib/optimizely';
import { getTrackingConditions } from '../../shared/components/DetailSearchModal';
import {
  selectCity,
  selectPostalCode,
  selectProvinceCode,
} from '../../stores/geoLocation';
import {
  DIMENSION_CONDITION_FILTER_TYPE,
  DIMENSION_L1_CATEGORY_NAME,
  DIMENSION_L1_LOCATION_NAME,
  DIMENSION_L2_CATEGORY_NAME,
  DIMENSION_L2_LOCATION_NAME,
  DIMENSION_LIFESTYLE,
  DIMENSION_LISTING_ID,
  DIMENSION_LOCATION_POSTAL_CODE,
  DIMENSION_ONSITE_SEARCH_MAX_PRICE,
  DIMENSION_ONSITE_SEARCH_MIN_PRICE,
  DIMENSION_ONSITE_SEARCH_RESULTS_VIEW_TYPE,
  DIMENSION_ONSITE_SEARCH_TOTAL_RESULTS,
  DIMENSION_PAGE_TYPE,
  DIMENSION_SEARCH_CONDITION,
  DIMENSION_SELECTED_CATEGORY_NAME,
  DIMENSION_SELLER_ACCOUNT_TYPE,
  DIMENSION_SORT_ORDER,
} from '../../utils/tracking/dimensions';
import joinValues from '../../utils/tracking/joinValues';

import { sellerTypeTrackingMap } from '../../constants/sellerTypes';
import { getSortingForTracking } from '../../features/search/components/SearchResult.tracking';
import { getHashedVipTrackingDimensions } from '../../pageSegments/hashedVip';
import { getMakeModelTrimDimensions } from '../../../shared/utils/getMakeModelTrimDimensions';
import { State } from '../../stores/types/State';
import { getViewType } from '../../features/search/utils/getView';
import {
  selectSearchMaxPayment,
  selectSearchMinPayment,
} from '../../stores/searchQuery/selectors';

export const getDimensions = (
  state: Pick<
    State,
    'searchQuery' | 'page' | 'geoLocation' | 'location' | 'referenceData'
  > &
    NestedPick<State, 'pages', 'srp'>
) => {
  const numResultsTotal = getNumResultsTotal(state);
  const query = selectQuery(state);
  const conditions = getTrackingConditions(query);
  const hashedVip = selectHashedVip(state);
  const hashedVipDimensions = hashedVip
    ? getHashedVipTrackingDimensions(hashedVip)
    : {};

  const makeModelTrims = selectMakeModelTrim(state);
  return {
    [DIMENSION_PAGE_TYPE]: getPageType(state),
    [DIMENSION_LIFESTYLE]: getLifestyleName(state),
    [DIMENSION_L1_LOCATION_NAME]: selectProvinceCode(state),
    [DIMENSION_L2_LOCATION_NAME]: selectCity(state),
    [DIMENSION_LOCATION_POSTAL_CODE]: selectPostalCode(state),
    [DIMENSION_SELECTED_CATEGORY_NAME]: selectCategoryName(state),
    [DIMENSION_L1_CATEGORY_NAME]: 'Motors',
    [DIMENSION_L2_CATEGORY_NAME]: 'Cars',
    ...getMakeModelTrimDimensions(makeModelTrims),
    [DIMENSION_ONSITE_SEARCH_MIN_PRICE]:
      selectSearchMinPrice(state) || selectSearchMinPayment(state),
    [DIMENSION_ONSITE_SEARCH_MAX_PRICE]:
      selectSearchMaxPrice(state) || selectSearchMaxPayment(state),
    [DIMENSION_ONSITE_SEARCH_TOTAL_RESULTS]: Number.isInteger(numResultsTotal)
      ? String(numResultsTotal)
      : undefined,
    [DIMENSION_ONSITE_SEARCH_RESULTS_VIEW_TYPE]: getViewType(),
    [DIMENSION_CONDITION_FILTER_TYPE]: joinValues(selectConditionQuery(state)),
    [DIMENSION_SEARCH_CONDITION]:
      conditions && conditions.length > 0
        ? conditions.join(';').replace('digital_retail_eligible', 'dr')
        : undefined,

    // additional #VIP dimensions
    ...hashedVipDimensions,
    // additional parameters used on AU
    exteriorColor: joinValues(selectExteriorColorFromPreparatoryQuery(state)),
    transmission: joinValues(selectTransmissionFromPreparatoryQuery(state)),
    fuelType: joinValues(selectFuelTypeFromPreparatoryQuery(state)),
    driveTrain: joinValues(selectDrivetrainFromPreparatoryQuery(state)),
  };
};

const mapAdIds = (items: { id: string }[]) =>
  items.map((item, index) => ({
    id: item.id,
    pos: index + 1,
  }));

export const getData = (state: Parameters<typeof getItems>[0]) => ({
  adIds: mapAdIds(getItems(state)),
});

type SliderData = { pagePath: string; tileId: string; tileIndex: number };

export const SLIDER_CLICK = {
  eventAction: 'sliderClick',
  getEventData: ({ pagePath, tileId, tileIndex }: SliderData) => ({
    eventLabel: `topSlider=${pagePath};tile=${tileId};slot=${tileIndex}`,
  }),
};

export const SLIDER_MIP_CLICK = {
  eventAction: 'MIPClick',
  getEventData: ({ pagePath, tileId, tileIndex }: SliderData) => ({
    eventLabel: `location=topSlider;${pagePath};tile=${tileId};slot=${tileIndex}`,
  }),
};

export const SEARCH_PERFORMED = {
  eventAction: 'SearchPerformed',
  getEventData: (_: unknown, state: State) => {
    const query = selectQuery(state);
    const conditions = getTrackingConditions(query);
    return {
      eventLabel: `${conditions.length}`,
      [DIMENSION_SORT_ORDER]: getSortingForTracking(getSorting(state)),
      [DIMENSION_ONSITE_SEARCH_RESULTS_VIEW_TYPE]: getViewType(),
    };
  },
  optimizelyEvents: [OPTIMIZELY_EVENTS.SEARCH_PERFORMED],
};

type ItemData = { id: string; sellerType: string };

export const SAVE_ITEM_CLICK_ATTEMPT = {
  eventAction: 'WatchlistAddAttempt',
  getEventData: ({ id, sellerType }: ItemData) => ({
    eventLabel: 'na',
    nonInteraction: false,
    [DIMENSION_LISTING_ID]: id,
    [DIMENSION_SELLER_ACCOUNT_TYPE]: sellerTypeTrackingMap[sellerType],
  }),
  optimizelyEvents: [
    OPTIMIZELY_EVENTS.WATCHLIST_ADD_ATTEMPT,
    OPTIMIZELY_EVENTS.WATCHLIST_ADD_AD_ATTEMPT_SRP,
  ],
};

export const SAVE_ITEM_CLICK = {
  eventAction: 'WatchlistAdd',
  getEventData: ({ id, sellerType }: ItemData) => ({
    eventLabel: 'na',
    nonInteraction: false,
    [DIMENSION_LISTING_ID]: id,
    [DIMENSION_SELLER_ACCOUNT_TYPE]: sellerTypeTrackingMap[sellerType],
  }),
  optimizelyEvents: [
    OPTIMIZELY_EVENTS.SAVE_ITEM_CLICK,
    OPTIMIZELY_EVENTS.WATCHLIST_ADD_AD_SRP,
  ],
};

export const UNSAVE_ITEM_CLICK = {
  eventAction: 'WatchlistRemove',
  getEventData: ({ id, sellerType }: ItemData) => ({
    eventLabel: 'na',
    nonInteraction: false,
    [DIMENSION_LISTING_ID]: id,
    [DIMENSION_SELLER_ACCOUNT_TYPE]: sellerTypeTrackingMap[sellerType],
  }),
};

export const MESSAGE_SEND_CANCEL = {
  eventAction: 'MessageSendCancel',
  getEventData: ({ position }: { position: number }) => ({
    eventLabel: `SRP;position=${position}`,
  }),
};

export const DISPLAY_SAVE_SEARCH_TOOLTIP = {
  eventAction: 'SaveSearchTooltipShown',
  getEventData: () => ({ eventLabel: 'na' }),
  optimizelyEvents: [OPTIMIZELY_EVENTS.SAVE_SEARCH_TOOLTIP_SHOWN],
};

export const DISPLAY_SAVE_LISTING_TOOLTIP = {
  eventAction: 'SaveListingTooltipShown',
  getEventData: () => ({ eventLabel: 'na' }),
  optimizelyEvents: [OPTIMIZELY_EVENTS.SAVE_LISTING_TOOLTIP_SHOWN],
};
