const isSafari = Boolean(typeof safari === 'object' && safari.pushNotification);

const runOnTabBlur = (task: () => void) => {
  window.addEventListener(
    'visibilitychange',
    () => {
      if (document.visibilityState === 'hidden') {
        task();
      }
    },
    true
  );

  // Safari does not reliably fire the `pagehide` or `visibilitychange`
  // events when closing a tab, so we have to use `beforeunload` with a
  // timeout to check whether the default action was prevented.
  if (isSafari) {
    window.addEventListener('beforeunload', task, true);
  }
};

/**
 * runs task on:
 *  - tab close
 *  - tab switch
 *  - reload
 *  - unload
 *
 * @param {function} task - callback function
 */
export default function onTabBlur(task: () => void) {
  if (!IS_SERVER) {
    runOnTabBlur(task);
  }
}
