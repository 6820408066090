import { useSelector } from 'react-redux';
import { selectIsHome, selectIsSRP, selectIsVIP } from '../../../stores/page';
import { WidthBehaviour } from '@move-ui/layout';

const useFooterWidthBehavior = () => {
  const isHome = useSelector(selectIsHome);
  const isVIP = useSelector(selectIsVIP);
  const isSRP = useSelector(selectIsSRP);

  const isFullContent = isHome || isVIP || isSRP;

  return isFullContent
    ? WidthBehaviour.FullContent
    : WidthBehaviour.LimitedContent;
};

export default useFooterWidthBehavior;
