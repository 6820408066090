import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { CloseIcon } from '@move-ui/icons-asset-catalog';
import useDropdownMenu from 'react-accessible-dropdown-menu-hook';
import { GhostInverseButton } from '@move-ui/button';
import { LOGO } from '../config';
import { MenuItem } from '../types';
import TopMenuItem from './TopMenuItem';
import styles from './TopMenu.css';

type ItemProps = ReturnType<typeof useDropdownMenu>['itemProps'];

type Props = {
  isOpen?: boolean;
  itemProps: ItemProps;
  items: MenuItem[];
  onClose: () => void;
  onLogoClick: () => void;
  onMenuItemClick: (category: string) => void;
};

const TopMenu: FC<Props> = ({
  isOpen,
  itemProps,
  items,
  onClose,
  onLogoClick,
  onMenuItemClick,
}) => {
  const { formatMessage } = useIntl();

  const handleClose = () => onClose();
  const handleKey = (event) => {
    if (event.keyCode === 13 || event.keyCode === 32) {
      event.preventDefault();
      onClose();
    }
  };

  return (
    <div className={styles.component}>
      <GhostInverseButton
        className={styles.closeButton}
        icon={<CloseIcon className={styles.icon} />}
        iconOnly
        label={formatMessage({ id: 'close' })}
        tabIndex={isOpen ? 0 : -1}
        onKeyDown={handleKey}
        onClick={handleClose}
      />
      <ul className={styles.menu} role="menu">
        {items.map((item, index) => (
          <li key={item.key} className={styles.item}>
            {item.key === 'Homepage' ? (
              <TopMenuItem
                item={item}
                itemProp={itemProps[index]}
                onItemClick={onLogoClick}
              >
                <img
                  alt={formatMessage({
                    id: 'ribbon_horizontal_navigation_kijiji_alt',
                  })}
                  src={LOGO.medium.source}
                  width={LOGO.medium.width}
                  height={LOGO.medium.height}
                />
              </TopMenuItem>
            ) : (
              <TopMenuItem
                item={item}
                itemProp={itemProps[index]}
                onItemClick={onMenuItemClick}
              />
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TopMenu;
