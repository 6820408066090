import { shape, string } from 'prop-types';

import { push } from '../../utils/tracking/dataLayer';
import { sellerTypeTrackingMap } from '../../constants/sellerTypes';
import { priceRatingTrackingMap } from '../../constants/priceRating';
import { EVENT_KEYS as OPTIMIZELY_EVENTS } from '../../../lib/optimizely';
import {
  DIMENSION_ADVERT_CLICK_SOURCE,
  DIMENSION_CONSTRUCTION_YEAR,
  DIMENSION_HAS_CERTIFIED_PRE_OWNED,
  DIMENSION_ITEM_CONDITION,
  DIMENSION_L1_LOCATION_NAME,
  DIMENSION_L2_LOCATION_NAME,
  DIMENSION_L3_CATEGORY_NAME,
  DIMENSION_L4_CATEGORY_NAME,
  DIMENSION_LISTING_ID,
  DIMENSION_LISTING_IMAGE_COUNT,
  DIMENSION_LISTING_PRICE,
  DIMENSION_PRICE_LABEL,
  DIMENSION_SELLER_ACCOUNT_TYPE,
  DIMENSION_TRIM_FILTER,
  DIMENSION_LOCATION_POSTAL_CODE,
  DIMENSION_DR_IN_VIP,
} from '../../utils/tracking/dimensions';

import {
  selectCertifiedPreOwned,
  selectContactTypeForTracking,
  selectDriveTrain,
  selectExteriorColor,
  selectFuelType,
  selectHasDigitalRetailing,
  selectId,
  selectIsConditionNew,
  selectLocation,
  selectMake,
  selectModel,
  selectNumberOfImages,
  selectPriceRating,
  selectPrices,
  selectTransmission,
  selectTrim,
  selectYear,
} from './selectors';
import { PromotionType } from '../../features/search';

export const DEFAULT_ADVERT_TYPE = 'organic';
const ADVERT_SOURCE = {
  [PromotionType.Top]: 'topad',
  [PromotionType.Inline]: 'inlinead',
  [DEFAULT_ADVERT_TYPE]: 'organic',
};

const selectRatingLabel = (state) =>
  selectPriceRating(state)?.rating ?? 'NO_BADGE';
const getPrice = (state) => {
  const price = selectPrices(state)?.consumerPrice?.amount;
  return (price && Math.round(price)) ?? 'not_provided';
};

export const getDimensions = (state) => {
  const numberOfImages = selectNumberOfImages(state);
  const price = getPrice(state);
  return {
    [DIMENSION_LISTING_ID]: selectId(state),
    [DIMENSION_SELLER_ACCOUNT_TYPE]: selectContactTypeForTracking(state),
    [DIMENSION_ITEM_CONDITION]: selectIsConditionNew(state) ? 'New' : 'Used',
    [DIMENSION_LISTING_IMAGE_COUNT]: Number.isInteger(numberOfImages)
      ? String(numberOfImages)
      : undefined,
    [DIMENSION_PRICE_LABEL]: priceRatingTrackingMap[selectRatingLabel(state)],
    [DIMENSION_LISTING_PRICE]: price ? String(price) : undefined,
    [DIMENSION_HAS_CERTIFIED_PRE_OWNED]: selectCertifiedPreOwned(state)
      ? 'CPO'
      : '',
    [DIMENSION_L3_CATEGORY_NAME]: selectMake(state),
    [DIMENSION_L4_CATEGORY_NAME]: selectModel(state),
    [DIMENSION_TRIM_FILTER]: selectTrim(state),
    [DIMENSION_CONSTRUCTION_YEAR]: selectYear(state),
    [DIMENSION_DR_IN_VIP]: selectHasDigitalRetailing(state) ? '1' : '0',
  };
};

export const setAdvertSourceDimension = (promotionType) => {
  const advertSource = ADVERT_SOURCE[promotionType || DEFAULT_ADVERT_TYPE];

  if (advertSource) {
    push({
      [DIMENSION_ADVERT_CLICK_SOURCE]: advertSource,
    });
  }
};

export const clearAdvertSourceDimension = () =>
  push({
    [DIMENSION_ADVERT_CLICK_SOURCE]: undefined,
  });

export const SAVE_RECENT_AD_CLICK_ATTEMPT = {
  eventAction: 'WatchlistAddAttempt',
  getEventData: ({ id, sellerType, position }) => ({
    eventLabel: `feature=recentAds; position=${position}`,
    nonInteraction: false,
    [DIMENSION_LISTING_ID]: id,
    [DIMENSION_SELLER_ACCOUNT_TYPE]: sellerTypeTrackingMap[sellerType],
  }),
  optimizelyEvents: [OPTIMIZELY_EVENTS.WATCHLIST_ADD_RELATED_AD_ATTEMPT],
};

export const SAVE_RECENT_AD_CLICK = {
  eventAction: 'WatchlistAdd',
  getEventData: ({ id, sellerType, position }) => ({
    eventLabel: `feature=recentAds; position=${position}`,
    nonInteraction: false,
    [DIMENSION_LISTING_ID]: id,
    [DIMENSION_SELLER_ACCOUNT_TYPE]: sellerTypeTrackingMap[sellerType],
  }),
  optimizelyEvents: [OPTIMIZELY_EVENTS.WATCHLIST_ADD_RELATED_AD],
};

export const UNSAVE_RECENT_AD_CLICK = {
  eventAction: 'WatchlistRemove',
  getEventData: ({ id, sellerType, position }) => ({
    eventLabel: `feature=recentAds; position=${position}`,
    nonInteraction: false,
    [DIMENSION_LISTING_ID]: id,
    [DIMENSION_SELLER_ACCOUNT_TYPE]: sellerTypeTrackingMap[sellerType],
  }),
};

export const CONTACT_FORM_SAVE_AD_CLICK_ATTEMPT = {
  eventAction: 'WatchlistAddAttempt',
  getEventData: ({ id, sellerType, position }) => ({
    eventLabel: `position=${position}; ContactForm`,
    nonInteraction: false,
    [DIMENSION_LISTING_ID]: id,
    [DIMENSION_SELLER_ACCOUNT_TYPE]: sellerTypeTrackingMap[sellerType],
  }),
  optimizelyEvents: [OPTIMIZELY_EVENTS.WATCHLIST_ADD_ATTEMPT_CONTACTFORM],
};

export const CONTACT_FORM_SAVE_AD_CLICK = {
  eventAction: 'WatchlistAdd',
  getEventData: ({ id, sellerType, position }) => ({
    eventLabel: `position=${position}; ContactForm`,
    nonInteraction: false,
    [DIMENSION_LISTING_ID]: id,
    [DIMENSION_SELLER_ACCOUNT_TYPE]: sellerTypeTrackingMap[sellerType],
  }),
  optimizelyEvents: [OPTIMIZELY_EVENTS.WATCHLIST_ADD_CONTACTFORM],
};

export const CONTACT_FORM_UNSAVE_AD_CLICK = {
  eventAction: 'WatchlistRemove',
  getEventData: ({ id, sellerType, position }) => ({
    eventLabel: `position=${position}; ContactForm`,
    nonInteraction: false,
    [DIMENSION_LISTING_ID]: id,
    [DIMENSION_SELLER_ACCOUNT_TYPE]: sellerTypeTrackingMap[sellerType],
  }),
  optimizelyEvents: [OPTIMIZELY_EVENTS.WATCHLIST_REMOVE_CONTACTFORM],
};

export const SAVE_RELATED_AD_CLICK_ATTEMPT = {
  eventAction: 'WatchlistAddAttempt',
  getEventData: ({ id, sellerType, position }) => ({
    eventLabel: `feature=relatedAds; position=${position}`,
    nonInteraction: false,
    [DIMENSION_LISTING_ID]: id,
    [DIMENSION_SELLER_ACCOUNT_TYPE]: sellerTypeTrackingMap[sellerType],
  }),
  optimizelyEvents: [OPTIMIZELY_EVENTS.WATCHLIST_ADD_RELATED_AD_ATTEMPT],
};
export const SAVE_RELATED_AD_CLICK = {
  eventAction: 'WatchlistAdd',
  getEventData: ({ id, sellerType, position }) => ({
    eventLabel: `feature=relatedAds; position=${position}`,
    nonInteraction: false,
    [DIMENSION_LISTING_ID]: id,
    [DIMENSION_SELLER_ACCOUNT_TYPE]: sellerTypeTrackingMap[sellerType],
  }),
  optimizelyEvents: [OPTIMIZELY_EVENTS.WATCHLIST_ADD_RELATED_AD],
};

export const UNSAVE_RELATED_AD_CLICK = {
  eventAction: 'WatchlistRemove',
  getEventData: ({ id, sellerType, position }) => ({
    eventLabel: `feature=relatedAds; position=${position}`,
    nonInteraction: false,
    [DIMENSION_LISTING_ID]: id,
    [DIMENSION_SELLER_ACCOUNT_TYPE]: sellerTypeTrackingMap[sellerType],
  }),
};

export const SAVE_ITEM_CLICK_ATTEMPT = {
  eventAction: 'WatchlistAddAttempt',
  getEventData: ({ id, sellerType }) => ({
    eventLabel: 'na',
    nonInteraction: false,
    [DIMENSION_LISTING_ID]: id,
    [DIMENSION_SELLER_ACCOUNT_TYPE]: sellerType,
  }),
  optimizelyEvents: [OPTIMIZELY_EVENTS.WATCHLIST_ADD_ATTEMPT],
};

export const SAVE_ITEM_CLICK = {
  eventAction: 'WatchlistAdd',
  getEventData: ({ id, sellerType }) => ({
    eventLabel: 'na',
    nonInteraction: false,
    [DIMENSION_LISTING_ID]: id,
    [DIMENSION_SELLER_ACCOUNT_TYPE]: sellerType,
  }),
  optimizelyEvents: [OPTIMIZELY_EVENTS.SAVE_ITEM_CLICK],
};

export const UNSAVE_ITEM_CLICK = {
  eventAction: 'WatchlistRemove',
  getEventData: ({ id, sellerType }) => ({
    eventLabel: 'na',
    nonInteraction: false,
    [DIMENSION_LISTING_ID]: id,
    [DIMENSION_SELLER_ACCOUNT_TYPE]: sellerType,
  }),
};

export const BANNER_SAVE_ITEM_CLICK_ATTEMPT = {
  eventAction: 'WatchlistAddAttempt',
  getEventData: ({ id, sellerType }) => ({
    eventLabel: 'location=banner',
    nonInteraction: false,
    [DIMENSION_LISTING_ID]: id,
    [DIMENSION_SELLER_ACCOUNT_TYPE]: sellerType,
  }),
  optimizelyEvents: [OPTIMIZELY_EVENTS.WATCHLIST_ADD_ATTEMPT],
};

export const BANNER_SAVE_ITEM_CLICK = {
  eventAction: 'WatchlistAdd',
  getEventData: ({ id, sellerType }) => ({
    eventLabel: 'location=banner',
    nonInteraction: false,
    [DIMENSION_LISTING_ID]: id,
    [DIMENSION_SELLER_ACCOUNT_TYPE]: sellerType,
  }),
  optimizelyEvents: [OPTIMIZELY_EVENTS.SAVE_ITEM_CLICK],
};

export const BANNER_UNSAVE_ITEM_CLICK = {
  eventAction: 'WatchlistRemove',
  getEventData: ({ id, sellerType }) => ({
    eventLabel: 'location=banner',
    nonInteraction: false,
    [DIMENSION_LISTING_ID]: id,
    [DIMENSION_SELLER_ACCOUNT_TYPE]: sellerType,
  }),
};

const FACEBOOK_TRACKING_PIXEL_DATA = {
  exteriorColor: 'exteriorColor',
  transmission: 'transmission',
  fuelType: 'fuelType',
  driveTrain: 'driveTrain',
};

export const facebookTrackingPixelDataShape = shape({
  [DIMENSION_LOCATION_POSTAL_CODE]: string,
  [DIMENSION_L1_LOCATION_NAME]: string,
  [DIMENSION_L2_LOCATION_NAME]: string,
  [FACEBOOK_TRACKING_PIXEL_DATA.exteriorColor]: string,
  [FACEBOOK_TRACKING_PIXEL_DATA.transmission]: string,
  [FACEBOOK_TRACKING_PIXEL_DATA.fuelType]: string,
  [FACEBOOK_TRACKING_PIXEL_DATA.driveTrain]: string,
});

export const selectFacebookTrackingPixelData = (state) => {
  const { province, city, postalCode } = selectLocation(state) || {};
  return {
    [DIMENSION_LOCATION_POSTAL_CODE]: postalCode,
    [DIMENSION_L1_LOCATION_NAME]: province,
    [DIMENSION_L2_LOCATION_NAME]: city,
    [FACEBOOK_TRACKING_PIXEL_DATA.exteriorColor]: selectExteriorColor(state),
    [FACEBOOK_TRACKING_PIXEL_DATA.transmission]: selectTransmission(state),
    [FACEBOOK_TRACKING_PIXEL_DATA.fuelType]: selectFuelType(state),
    [FACEBOOK_TRACKING_PIXEL_DATA.driveTrain]: selectDriveTrain(state),
  };
};

export const setFacebookTrackingPixelData = (trackingPixelData) =>
  push(trackingPixelData);

export const clearFacebookTrackingPixelData = () =>
  push({
    [DIMENSION_LOCATION_POSTAL_CODE]: undefined,
    [DIMENSION_L1_LOCATION_NAME]: undefined,
    [DIMENSION_L2_LOCATION_NAME]: undefined,
    [FACEBOOK_TRACKING_PIXEL_DATA.exteriorColor]: undefined,
    [FACEBOOK_TRACKING_PIXEL_DATA.transmission]: undefined,
    [FACEBOOK_TRACKING_PIXEL_DATA.fuelType]: undefined,
    [FACEBOOK_TRACKING_PIXEL_DATA.driveTrain]: undefined,
  });

export const CLICK_BADGE_PRICE_RATING = {
  eventAction: 'PriceLabelClick',
  getEventData: ({ nonInteraction }) => ({
    nonInteraction,
  }),
};

export const CLICK_GALLERY_HEADER_LINK = {
  eventAction: 'VehicleReportBuySuccess',
  getEventData: () => ({
    eventLabel: 'flow=gallery',
  }),
  optimizelyEvents: [OPTIMIZELY_EVENTS.BUY_CARFAX_REPORT],
};

export const CLICK_INCLUDE_TAXES_DR_PAYMENT = {
  eventAction: 'DRPaymentTaxes',
  getEventData: () => ({
    eventCategory: 'DRCustomizePayment',
  }),
};
