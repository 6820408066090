import { getBasename } from '../../../lib/locale-utils';
import {
  MOVE_SYI_APP_PATH_FR,
  MOVE_SYI_APP_PATH,
} from '../../../shared/config';
import normalizeUrl from '../../../shared/utils/normalizeUrl';

export const getSyiAppPath = (currentLocale: string) =>
  getBasename({ currentLocale }) === '/fr'
    ? MOVE_SYI_APP_PATH_FR
    : MOVE_SYI_APP_PATH;

export const getSyiAppMyAdsPath = (currentLocale: string) =>
  normalizeUrl(`${getSyiAppPath(currentLocale)}/syi/`) || '';

export const getSyiAppLandingPageListingPath = (currentLocale: string) =>
  normalizeUrl(getSyiAppPath(currentLocale)) || '';
