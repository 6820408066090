import { getEnv } from '@move-web-essentials-utils/env';
import { authorizedApiClient } from '../../../api/lib/apiClient';
import { MOVE_SAVED_ITEMS_API_BASE_PATH } from '../../../shared/config';

export const GET_METRICS_ID = 'svc_getSavedItemsIds';
export const POST_METRICS_ID = 'svc_postSavedItems';
export const DELETE_METRICS_ID = 'svc_deleteSavedItems';

const ENV_API = 'move_consumer_api_gateway_url';

const apiBaseUrl = IS_SERVER
  ? `${getEnv(ENV_API)}${MOVE_SAVED_ITEMS_API_BASE_PATH}`
  : MOVE_SAVED_ITEMS_API_BASE_PATH;

export const fetchSavedItems = () => {
  const doRequest = authorizedApiClient({ metricsId: GET_METRICS_ID });

  return doRequest({
    method: 'GET',
    url: `${apiBaseUrl}/saved-items-ad-ids`,
  });
};

export const postSavedItem = (adId: string) => {
  const doRequest = authorizedApiClient({ metricsId: POST_METRICS_ID });

  return doRequest({
    method: 'POST',
    url: `${apiBaseUrl}/saved-items/${adId}`,
  });
};

export const deleteSavedItem = (adId: string) => {
  const doRequest = authorizedApiClient({ metricsId: DELETE_METRICS_ID });

  return doRequest({
    method: 'DELETE',
    url: `${apiBaseUrl}/saved-items?adIds=${adId}`,
  });
};
