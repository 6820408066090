import logger from '@move-web-essentials-utils/logger';
import { allDimensions } from './dimensions';
import { push, get } from './dataLayer';

const storageId = 'move-persisted-dimensions';

export const saveDimensions = () => {
  try {
    const dimensionsObject = allDimensions.reduce((memo, dimension) => {
      const value = get(dimension);
      if (value) {
        return {
          ...memo,
          [dimension]: value,
        };
      }
      return memo;
    }, {});

    sessionStorage.setItem(storageId, JSON.stringify(dimensionsObject));
  } catch (error) {
    logger.info('failed to save dimensions', error.message);
  }
};

export const pushDimensions = () => {
  try {
    push(JSON.parse(sessionStorage.getItem(storageId)) || {});
    sessionStorage.removeItem(storageId);
  } catch (error) {
    logger.info('failed to push dimensions', error.message);
  }
};
