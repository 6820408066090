import { handleSessionStorage } from '../../../../shared/utils/handleSessionStorage';

export const getConversationHistory = (key) => {
  const conversationHistory = handleSessionStorage.get(key)
    ? handleSessionStorage.get(key).split(',')
    : [];
  return conversationHistory;
};

export const isFirstGuestMessage = (listingId) => {
  const conversationHistory = getConversationHistory('guest_message_history');
  if (!conversationHistory.includes(listingId)) {
    conversationHistory.push(listingId);
    handleSessionStorage.set(
      'guest_message_history',
      conversationHistory.join()
    );
    return true;
  }
  return false;
};

export const isFirstMessage = (listingId) => {
  const conversationHistory = getConversationHistory(
    'open_contact_form_history'
  );
  const _isFirsMessage = !conversationHistory.includes(listingId);
  return _isFirsMessage;
};

export const updateConversationHistory = (listingId) => {
  const conversationHistory = getConversationHistory(
    'open_contact_form_history'
  );

  if (!conversationHistory.includes(listingId)) {
    conversationHistory.push(listingId);
    handleSessionStorage.set(
      'open_contact_form_history',
      conversationHistory.join()
    );
  }
};
