import React, { FC } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import loadHintFonts from '@move-ui/base/loadHintFonts';
import { getBasename } from '../lib/locale-utils';
import {
  setClientId,
  setClientLocale,
  setReturningUser,
} from '../lib/userSessionTracking/userSessionService';
import { initDatafile } from '../lib/optimizely';
import App from './components/App';
import configureStore from './stores';
import { checkLoginStatusAction } from './stores/authentication';
import { initFocusVisible } from '../lib/focusVisible';
import { triggerLazyLoad as createTriggerLazyLoad } from './pages/srp';
import initAuthAdapter from '../lib/initAuthAdapter';
import { endHydration, startHydration } from '../lib/lazyHydration';

// The "Symbol" pollyfil overwrites the "Object.defineProperty"
// method and besides only defining the property on the object
// it also defines the same property in the Object.prototype
// getting his descriptor before, deleting it and set it again.
// But sometimes this descriptor is undefined, throwing an error on IE and
// causing an issue later on webpack that uses Symbol.toStringTag
// when it lazy load the components.
// Here we are forcing the property Symbol.toStringTag on
// Object.prototype so it sets the descriptor again.
// The error is happening only on development mode
// Related issue: https://github.com/Financial-Times/polyfill-library/issues/115
// To prevent the error happen, following fix should execute as soon as possible.
if (DEBUG) {
  Object.prototype[Symbol.toStringTag] = Object.prototype[Symbol.toStringTag];
}

initDatafile();
initFocusVisible();

window.addEventListener('load', () => loadHintFonts().catch(() => {}));

const { currentLocale } = window.INITIAL_STATE.i18n;

const { clientId, isReturningUser, optimizelyDatafile, ...initialState } =
  window.INITIAL_STATE;
const basename = getBasename({ currentLocale });
setClientId(clientId);
setReturningUser(isReturningUser);
setClientLocale(currentLocale);

const { store, runRouteThunks } = configureStore({
  ...(basename ? { basename } : ({} as any)),
  initialState,
});

const wrapComponentWithProvider = (Component: FC) => (
  <Provider store={store}>
    <HelmetProvider>
      <Component />
    </HelmetProvider>
  </Provider>
);

const getRootElement = () => document.getElementById('root');

const hydrate = (Component: FC) =>
  ReactDOM.hydrate(wrapComponentWithProvider(Component), getRootElement());

const render = (Component: FC) =>
  ReactDOM.render(wrapComponentWithProvider(Component), getRootElement());

if (DEBUG) {
  window.store = store;
}

initAuthAdapter(store);

const shouldLazyLoad = store.dispatch(createTriggerLazyLoad());

store
  .dispatch(checkLoginStatusAction())
  .then((mayBeResolvedLoginPromise) => {
    if (shouldLazyLoad) {
      return runRouteThunks();
    }

    return mayBeResolvedLoginPromise;
  })
  .then(() => {
    // We need to wait for the router thunk to be resolved before
    // re-rendering the application, since it could cause a double
    // tracking of the pageview if the router redirects or reload
    // the page.
    if (shouldLazyLoad) {
      render(App);
    }
  });

if (!shouldLazyLoad) {
  startHydration();
  hydrate(App);
  endHydration();
}
