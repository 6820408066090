import uuid from 'uuid/v4';

import {
  PUSH_SNACKBAR_MESSAGE,
  PUSH_SNACKBAR_TRANSLATION_KEY,
  REMOVE_FROM_SNACKBAR,
  REMOVE_FROM_SNACKBAR_BY_TEXT,
} from './actionTypes';
import { SnackbarState, SnackBarEntry } from './types/State';

export const defaultState = {
  snackbarList: [],
};

const states = {
  [PUSH_SNACKBAR_MESSAGE]: (
    state,
    {
      payload: {
        message,
        appearance,
        closeable,
        displayDuration,
        pauseOnHover,
      },
    }
  ) => ({
    ...state,
    snackbarList: [
      ...state.snackbarList,
      {
        id: uuid(),
        text: message,
        createdAt: new Date(),
        appearance,
        closeable,
        displayDuration,
        pauseOnHover,
      },
    ],
  }),
  [PUSH_SNACKBAR_TRANSLATION_KEY]: (
    state,
    { translationKey, translationValues }
  ) => ({
    ...state,
    snackbarList: [
      ...state.snackbarList,
      {
        id: uuid(),
        translationKey,
        translationValues,
        createdAt: new Date(),
      },
    ],
  }),
  [REMOVE_FROM_SNACKBAR]: (state, { id }) => ({
    ...state,
    snackbarList: state.snackbarList.filter((message) => message.id !== id),
  }),
  [REMOVE_FROM_SNACKBAR_BY_TEXT]: (state, { text }) => ({
    ...state,
    snackbarList: state.snackbarList.filter((message) => message.text !== text),
  }),
};

const snackbarsReducer = (
  state: SnackbarState = defaultState,
  action: { type: string; payload?: SnackBarEntry } = { type: '' }
) => (states[action.type] ? states[action.type](state, action) : state);

export default snackbarsReducer;
