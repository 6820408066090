export const SET_MIP_REFERRER = 'page/mip/SET_MIP_REFERRER';
export const REQUEST_MIP = 'page/mip/REQUEST_MIP';
export const INITIAL_REQUEST_MIP = 'page/mip/INITIAL_REQUEST_MIP';
export const EXIT_MIP = 'page/mip/EXIT_MIP';
export const HANDLE_RESPONSE_MIP = 'page/mip/HANDLE_RESPONSE_MIP';
export const HANDLE_RESPONSE_ERROR_MIP = 'page/mip/HANDLE_RESPONSE_ERROR_MIP';
export const UPDATE_NAVIGATION = 'page/mip/UPDATE_NAVIGATION';
export const MIP_LOADED = 'page/mit/MIP_LOADED';
export const REQUEST_VIP_LISTINGS = 'page/mip/REQUEST_VIP_LISTINGS';
export const RECEIVE_VIP_LISTINGS = 'page/mip/RECEIVE_VIP_LISTINGS';
export const ERROR_VIP_LISTINGS = 'page/mip/ERROR_VIP_LISTINGS';
