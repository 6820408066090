export const REQUEST_SAVE_AREA = 'location/REQUEST_SAVE_AREA';
export const HANDLE_SAVE_AREA_SUCCESS = 'location/HANDLE_SAVE_AREA_SUCCESS';
export const HANDLE_SAVE_AREA_ERROR = 'location/HANDLE_SAVE_AREA_ERROR';

export const REQUEST_RESTORE_AREA = 'location/REQUEST_RESTORE_AREA';
export const RECEIVE_RESTORED_AREA = 'location/RECEIVE_RESTORED_AREA';
export const HANDLE_RESTORE_AREA_ERROR = 'location/HANDLE_RESTORE_AREA_ERROR';

export const REQUEST_REMOVE_AREA = 'location/REMOVE_AREA';
export const HANDLE_REMOVED_AREA_SUCCESS =
  'location/HANDLE_REMOVED_AREA_SUCCESS';
export const HANDLE_REMOVE_AREA_ERROR = 'location/HANDLE_REMOVE_AREA_ERROR';
