export const isBot = (userAgent: string) => {
  const robots = new RegExp(
    [
      /bot/,
      /spider/,
      /crawl/,
      /APIs-Google/,
      /mediapartners/,
      /Google Favicon/,
      /FeedFetcher/,
      /Google-Read-Aloud/,
      /DuplexWeb-Google/,
      /googleweblight/,
      /bing/,
      /yandex/,
      /baidu/,
      /duckduck/,
      /yahoo/,
      /ecosia/,
      /ia_archiver/,
      /facebook/,
      /instagram/,
      /pinterest/,
      /reddit/,
      /slack/,
      /twitter/,
      /whatsapp/,
      /youtube/,
      /semrush/,
    ]
      .map((r) => r.source)
      .join('|'),
    'i'
  );

  return robots.test(userAgent);
};
