import getInstance from './getInstance';
import { getUserId } from '../user';
import { getDefaultAttributes } from './attributes';

/**
 * Tracks a conversion event. Logs an error message if the specified event key doesn't match any existing events.
 * See also https://docs.developers.optimizely.com/full-stack/docs/track
 *
 * @param {string} eventKey The key of the event to be tracked. This key must match the event key provided when the event was created in the Optimizely app.
 *
 */
export const trackOptimizelyEvent = (eventKey) => {
  const optimizely = getInstance();
  const userId = getUserId();
  const defaultAttributes = getDefaultAttributes();
  return optimizely?.track(eventKey, userId, defaultAttributes);
};
