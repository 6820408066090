export const normalizePriceFilter = (price) => {
  if (!price) {
    return undefined;
  }

  const [lowerBound, upperBound] = price.split(':');
  if (lowerBound === '') {
    return `${upperBound}_or_less`;
  }
  if (upperBound === '') {
    return `${lowerBound}_or_more`;
  }
  return `${lowerBound}_to_${upperBound}`;
};

export const normalizePrice = (price) =>
  typeof price !== 'number' || price === 0
    ? 'other'
    : Math.min(Math.floor(price / 10000), 10);

// The mapping between normalized values and localed values is hardcoded
// In the future, the normalized values should be directly sent from the server
// https://jira.corp.ebay.com/browse/MOVE-9208
export const normalizeFuelType = (fuelType) => {
  if (fuelType === 'Gas' || fuelType === 'Essence') {
    return 'petrol';
  }
  if (fuelType === 'Diesel') {
    return 'diesel';
  }
  if (fuelType === 'Electric' || fuelType === 'Électrique') {
    return 'electricity';
  }
  if (fuelType === 'Hybrid' || fuelType === 'Hybride') {
    return 'hybrid';
  }
  if (fuelType === 'Other' || fuelType === 'Autres') {
    return 'other';
  }
  return undefined;
};

export const normalizeDrivetrain = (drivetrain) => {
  if (
    drivetrain === 'Four-wheel drive' ||
    drivetrain === 'À quatre roues motrices'
  ) {
    return 'four_by_four';
  }
  if (
    drivetrain === 'All-wheel drive (AWD)' ||
    drivetrain === 'Traction intégrale (TI)'
  ) {
    return 'awd';
  }
  if (
    drivetrain === 'Front-wheel drive (FWD)' ||
    drivetrain === 'Traction avant (TA)'
  ) {
    return 'fwd';
  }
  if (
    drivetrain === 'Rear-wheel drive (RWD)' ||
    drivetrain === 'Propulsion arrière (PA)'
  ) {
    return 'rwd';
  }
  if (drivetrain === 'Other' || drivetrain === 'Autres') {
    return 'other';
  }

  return undefined;
};

export const normalizeBody = (body) => {
  if (body === 'Sedan' || body === 'Berline') {
    return 'limousine';
  }
  if (body === 'Wagon' || body === 'Familiale') {
    return 'estatecar';
  }
  if (body === 'Van' || body === 'Fourgonnette') {
    return 'van';
  }
  if (body === 'Convertible' || body === 'Décapotable') {
    return 'cabrio';
  }
  if (body === 'Sports car/coupe' || body === 'Voitures sport et coupés') {
    return 'sportscar';
  }
  if (body === 'Pickup truck' || body === 'Camionnette') {
    return 'pickup';
  }
  if (body === 'SUV' || body === 'VUS') {
    return 'suv';
  }
  if (body === 'Hatchback' || body === 'À hayon') {
    return 'hatchback';
  }
  if (body === 'Other' || body === 'Autres') {
    return 'othercar';
  }

  return undefined;
};

// remove any match that is not \w+ (equal to [a-zA-Z0-9_]).
export const normalizeSpecialCharacters = (value) =>
  value?.toLowerCase()?.replace(/[^\w]/g, '_');
