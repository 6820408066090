import { useCallback } from 'react';
import { useStore } from 'react-redux';
import track from './track';

export default function useTracking() {
  const store = useStore();

  const trackEvent = useCallback((gtmEvent, data = {}, event) => {
    event?.preventDefault?.();
    track(gtmEvent, store.getState(), data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // will eventually become { trackEvent, trackPage }
  return { trackEvent };
}
