export const getLocalStorageObject = <T = undefined>(
  key: string,
  fallback?: T
): T => {
  try {
    const values = localStorage.getItem(key);
    return values ? JSON.parse(values) : fallback;
  } catch {
    return fallback as T;
  }
};

export const setLocalStorageObject = <T>(key: string, value: T) => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch {
    // access to local storage denied
  }
};

export const removeLocalStorageItem = (key: string) =>
  localStorage.removeItem(key);
