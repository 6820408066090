export const CURRENCY_FORMAT = {
  style: 'currency',
  currency: 'CAD',
  // needed for "fr-CA", otherwise "$ CA" is displayed instead of "$"
  currencyDisplay: 'narrowSymbol',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
};

export enum FinancingType {
  Financing = 'financing',
  Leasing = 'leasing',
}

export const TRADE_IN_INPUT_STORAGE_KEY = 'trade-in-input';
export const TRADE_IN_VIN_STORAGE_KEY = 'car-details-by-vin';
export const FINANCING_STORAGE_KEY = 'financing-calculations';
