import qs from 'query-string';

export const getReferrerDimensionValue = () => {
  const currentLocationWithoutQueryParams = document.location.href.replace(
    document.location.search,
    ''
  );

  if (
    document.referrer &&
    document.referrer !== currentLocationWithoutQueryParams
  ) {
    return document.referrer;
  }

  const parsedQuery = qs.parse(document.location.search);
  const utmSource = parsedQuery && parsedQuery.utm_source;

  if (!document.referrer && utmSource) {
    return utmSource;
  }

  return '(NULL)';
};
