import pageVisibility from '@move-web-essentials-utils/page-visibility';
import { getUnreadCount } from './api';

const {
  hiddenProp = '',
  isSupported: isPageVisibilityAvailable,
  visibilityChangeEventName = '',
} = pageVisibility;

const TIMEOUT_INTERVAL = 20000;
const TOKEN_REFRESH_DELAY = 5000;
const TYPE_UNREAD_COUNT = 'typeUnreadCount';
const TYPE_CONVERSATION = 'typeConversation';
const MAX_RETRY_COUNT = 2;

type PollingType = 'typeUnreadCount' | 'typeConversation';

type UnreadCountCallbackData = {
  unreadMessages: number;
  unreadConversations: number;
};

export type UnreadCountCallback = (data: UnreadCountCallbackData) => void;

export type ConversationCallback = () => void;

type RegisteredCallback = UnreadCountCallback | ConversationCallback;

type CallbackEntry = {
  callback: RegisteredCallback;
  type: PollingType;
};

class PollingTicker {
  private registeredCallbacks: Map<number, CallbackEntry>;

  private locale?: string;

  private timeoutId?: ReturnType<typeof setTimeout>;

  private tokenRefreshDelayId?: ReturnType<typeof setTimeout>;

  private unreadMessages: number;

  private unreadConversations: number;

  private retryCount: number;

  private onPollingError?: () => void;

  constructor() {
    this.registeredCallbacks = new Map();
    this.unreadMessages = 0;
    this.unreadConversations = 0;
    this.retryCount = 0;
  }

  private triggerUnreadCallbacks = (data: UnreadCountCallbackData) =>
    this.registeredCallbacks.forEach(({ callback, type }) => {
      if (type === TYPE_UNREAD_COUNT) {
        (callback as UnreadCountCallback)(data);
      }
    });

  private triggerConversationCallbacks = () =>
    this.registeredCallbacks.forEach(({ callback, type }) => {
      if (type === TYPE_CONVERSATION) {
        (callback as ConversationCallback)();
      }
    });

  private handleError = () => {
    this.stopCheckVisibility();
    this.onPollingError?.();
  };

  private loop = async () => {
    try {
      const { unreadMessagesCount, conversationsWithUnreadMessagesCount } =
        await getUnreadCount({ locale: this.locale });
      if (
        unreadMessagesCount !== this.unreadMessages ||
        conversationsWithUnreadMessagesCount !== this.unreadConversations
      ) {
        this.unreadMessages = unreadMessagesCount;
        this.unreadConversations = conversationsWithUnreadMessagesCount;
        this.triggerUnreadCallbacks({
          unreadMessages: this.unreadMessages,
          unreadConversations: this.unreadConversations,
        });
      }

      if (this.timeoutId) {
        this.retryCount = 0;
        this.timeoutId = setTimeout(this.loop, TIMEOUT_INTERVAL);
      }
    } catch {
      if (this.retryCount >= MAX_RETRY_COUNT) {
        this.handleError();
      }

      if (this.timeoutId && this.retryCount < MAX_RETRY_COUNT) {
        this.retryCount += 1;
        this.timeoutId = setTimeout(this.loop, TIMEOUT_INTERVAL);
      }
    }

    this.triggerConversationCallbacks();
  };

  private stopTick = () => {
    if (this.timeoutId) clearTimeout(this.timeoutId);
    if (this.tokenRefreshDelayId) clearTimeout(this.tokenRefreshDelayId);
    this.timeoutId = undefined;
  };

  private startTick = () => {
    this.timeoutId = setTimeout(this.loop, 0);
  };

  private visibilityCallback = () => {
    if (document[hiddenProp]) {
      this.stopTick();
    } else {
      // give some time for auth token to refresh
      this.tokenRefreshDelayId = setTimeout(
        this.startTick,
        TOKEN_REFRESH_DELAY
      );
    }
  };

  private startCheckVisibility = () => {
    if (!isPageVisibilityAvailable) {
      return;
    }

    document.addEventListener(
      visibilityChangeEventName,
      this.visibilityCallback
    );
  };

  private stopCheckVisibility = () => {
    if (!isPageVisibilityAvailable) {
      return;
    }

    document.removeEventListener(
      visibilityChangeEventName,
      this.visibilityCallback
    );
  };

  private registerPollingCallback = (
    uid: number,
    callback: RegisteredCallback,
    type: PollingType
  ) => {
    const previousSize = this.registeredCallbacks.size;

    this.registeredCallbacks.set(uid, { callback, type });

    if (previousSize === 0) {
      this.startTick();
      this.startCheckVisibility();
    }

    return this.registeredCallbacks.size;
  };

  public registerUnreadPollingCallback = (
    uid: number,
    callback: UnreadCountCallback
  ) => this.registerPollingCallback(uid, callback, TYPE_UNREAD_COUNT);

  public registerConversationPollingCallback = (
    uid: number,
    callback: ConversationCallback
  ) => this.registerPollingCallback(uid, callback, TYPE_CONVERSATION);

  public unregisterPollingCallback = (uid: number) => {
    this.registeredCallbacks.delete(uid);

    if (this.registeredCallbacks.size === 0) {
      this.stopTick();
      this.stopCheckVisibility();
    }

    return this.registeredCallbacks.size;
  };

  public setPollingLocale = (locale: string) => {
    this.locale = locale;
  };

  public registerErrorCallback = (callback: () => void) => {
    this.onPollingError = callback;
  };
}

const pollingTicker = new PollingTicker();

export const {
  setPollingLocale,
  registerUnreadPollingCallback,
  registerConversationPollingCallback,
  unregisterPollingCallback,
  registerErrorCallback,
} = pollingTicker;
