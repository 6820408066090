import { isNaN } from 'lodash';
import { createSelector } from 'reselect';
import { findMake, findModel } from '../../utils/filters/makeModel';
import { selectMakeModelTree } from './../referenceData';
import { State as FullState } from '../types/State';
import { MakeModelTrim } from '../../shared/types/MakeModelTrim';

type State = Pick<FullState, 'searchQuery'>;

// No fallback value is needed to run the app, but we do this
// so tests dont break (would need to be cleaned up eventually)
const selectSearchQuery = (state: State) =>
  state.searchQuery || { query: {}, preparatoryQuery: {} };

export const selectQuery = createSelector(
  selectSearchQuery,
  ({ query }) => query
);

export const selectPreparatoryQuery = createSelector(
  selectSearchQuery,
  ({ preparatoryQuery }) => preparatoryQuery
);

export const selectHasLocationPreparatoryQuery = createSelector(
  selectPreparatoryQuery,
  (query) => !!query.ll
);

export const selectConditionQuery = createSelector(
  selectPreparatoryQuery,
  (query) => query.con
);

export const selectExteriorColorFromPreparatoryQuery = createSelector(
  selectPreparatoryQuery,
  (query) => query.ecol
);

export const selectTransmissionFromPreparatoryQuery = createSelector(
  selectPreparatoryQuery,
  (query) => query.tr
);

export const selectFuelTypeFromPreparatoryQuery = createSelector(
  selectPreparatoryQuery,
  (query) => query.ft
);

export const selectDrivetrainFromPreparatoryQuery = createSelector(
  selectPreparatoryQuery,
  (query) => query.dt
);

export const selectSellerType = createSelector(
  selectPreparatoryQuery,
  (query) => query.st
);

export const selectMakeModelTrim = (
  state: Pick<FullState, 'searchQuery' | 'referenceData'>
) => {
  const query = selectPreparatoryQuery(state);

  if (!query.ms) {
    return [];
  }
  const makeModelTree = selectMakeModelTree(state);
  return query.ms
    .map((ms) => {
      const [makeIdPart, modelIdPart, trimPart] = ms.split(';');
      const makeId = Number(makeIdPart);

      if (!makeId) {
        return null;
      }

      const modelId = Number(modelIdPart);
      const findMakeModel =
        modelIdPart === '' || isNaN(modelId) ? findMake : findModel;

      return {
        ...makeModelTree.find(findMakeModel(makeId, modelId)),
        ...(trimPart ? { trm: trimPart } : {}),
      };
    })
    .filter((ms) => ms && Object.keys(ms).length > 0) as MakeModelTrim[];
};

export const selectCategoryName = (state: State) => {
  const makeModelTrims = selectMakeModelTrim(state);
  const result = makeModelTrims
    .map(({ makeName, modelName }) =>
      [makeName, modelName].filter(Boolean).join('/')
    )
    .join(';');
  return result.length > 0 ? result : undefined;
};

export const selectSearchMinPrice = (state: State) => {
  const [min] = selectPreparatoryQuery(state).p?.split(':') || [];
  return min || undefined;
};

export const selectSearchMaxPrice = (state: State) => {
  const [, max] = selectPreparatoryQuery(state).p?.split(':') || [];
  return max || undefined;
};

export const selectSearchMinPayment = (state: State) => {
  const [min] = selectPreparatoryQuery(state).payment?.split(':') || [];
  return min || undefined;
};

export const selectSearchMaxPayment = (state: State) => {
  const [, max] = selectPreparatoryQuery(state).payment?.split(':') || [];
  return max || undefined;
};

export const selectLocationCoordinates = (state: State) => {
  const coordinates = selectPreparatoryQuery(state)?.ll;

  if (!coordinates) {
    return null;
  }

  const [latitude, longitude] = coordinates.split(',').map(Number);

  return { latitude, longitude };
};

const BODY_TYPE_QUERY_KEYS = { c: true, vc: true, psz: true };
export const selectIsBodyTypeSrp = (state: State) => {
  const query = selectQuery(state);
  const { c } = query;
  const hasSingleBodyType =
    (c !== undefined && !Array.isArray(c)) ||
    (Array.isArray(c) && c.length === 1);

  const onlyBodyType = Object.keys(query).every(
    (key) => BODY_TYPE_QUERY_KEYS[key]
  );
  return onlyBodyType && hasSingleBodyType;
};
