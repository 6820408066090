/*
 * selectors for location state provided by redux-first-router
 */

import { Location } from 'redux-first-router';
import normalizeUrl from '../../shared/utils/normalizeUrl';
import { LocationKind } from './types/LocationKind';
import { State as GlobalState } from './types/State';

type State = Pick<GlobalState, 'location'>;

export const getQuery = (
  state: State
): NonNullable<State['location']['query']> => state.location?.query ?? {};
export const getQueryString = (state: State) => state.location?.search ?? '';
export const getPathName = (state: State) => state.location?.pathname ?? '';
export const getUrlDecodedPathName = (state: State) =>
  decodeURIComponent(getPathName(state));
export const getPayload = (state: State): Record<string, any> =>
  state.location?.payload ?? {};
export const getPreviousRoute = (state: State) =>
  state.location?.prev ?? ({} as Location);
export const getRoutesMap = (
  state: State
): NonNullable<State['location']['routesMap']> =>
  state.location?.routesMap ?? {};
export const getUrlDecodedSrpSeoSegments = (state: State) =>
  decodeURIComponent(getPayload(state).srpSeoSegments || '');
export const getMipSeoSegments = (state: State) =>
  getPayload(state).mipSeoSegments;

export const getLocationType = (state: State) => state.location?.type;
export const getPrevLocationType = (state: State) => state.location?.prev?.type;

export const isPopState = (state: State) =>
  state.location?.kind === LocationKind.Pop;
export const isPageTypeChanged = (state: State) =>
  getLocationType(state) !== getPrevLocationType(state);
export const isPageChanged = (state: State) => {
  const locationTypeChanged = isPageTypeChanged(state);

  if (locationTypeChanged) {
    return true;
  }
  return (
    normalizeUrl(state.location?.pathname) !==
    normalizeUrl(state.location?.prev?.pathname)
  );
};

export const isLoadState = (state: State) =>
  state.location?.kind === LocationKind.Load;
export const cameFromRealVip = (state: State) =>
  state.location?.prev?.payload?.historyState?.realVip;

export const isSrpSeoSegmentChanged = (state: State) =>
  state.location?.payload?.srpSeoSegments !==
  state.location?.prev?.payload?.srpSeoSegments;
