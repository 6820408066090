import {
  HANDLE_RESPONSE_DEALER,
  HANDLE_RESPONSE_ERROR_MORE_SEARCH_RESULTS,
  HANDLE_RESPONSE_MORE_LISTINGS,
  REQUEST_MORE_LISTINGS,
  REQUEST_DEALER,
} from './actionTypes';
import { selectListings, selectSellerId } from './selectors';
import { REQUEST_HEADER } from '../../../api/lib/apiClient';
import { getCurrentLocale } from '../../stores/i18n';
import { getMoreListings } from './api';
import { State } from './types/State';
import { Listings } from './types/Listings';

export const handleRequestDealer = () =>
  ({
    type: REQUEST_DEALER,
  } as const);

export const handleResponseDealer = (payload: State) =>
  ({
    type: HANDLE_RESPONSE_DEALER,
    payload,
  } as const);

export const handleResponseMoreListings = (payload: Listings) =>
  ({
    type: HANDLE_RESPONSE_MORE_LISTINGS,
    payload,
  } as const);

export const handleResponseErrorMoreSearchResults = () =>
  ({
    type: HANDLE_RESPONSE_ERROR_MORE_SEARCH_RESULTS,
  } as const);

export const handleRequestMoreListings = () =>
  ({
    type: REQUEST_MORE_LISTINGS,
  } as const);

export const fetchMoreListings = () => async (dispatch, getState) => {
  dispatch(handleRequestMoreListings());

  const state = getState();
  const locale = getCurrentLocale(state);
  const { nextPageToken } = selectListings(state);
  const sellerId = selectSellerId(state);

  if (!sellerId) return;

  try {
    const { body, headers = {} } = await getMoreListings(
      { sellerId, nextPageToken },
      { locale, resolveWithFullResponse: true }
    );
    dispatch(
      handleResponseMoreListings({
        ...body,
        items: body.items.map((item) => ({
          ...item,
          transactionId: headers.get(REQUEST_HEADER.TRANSACTION_ID),
        })),
      })
    );
  } catch {
    dispatch(handleResponseErrorMoreSearchResults());
  }
};

export type DealerAction =
  | ReturnType<typeof handleResponseDealer>
  | ReturnType<typeof handleRequestDealer>
  | ReturnType<typeof handleResponseMoreListings>
  | ReturnType<typeof handleResponseErrorMoreSearchResults>
  | ReturnType<typeof handleRequestMoreListings>;
