import qs from 'query-string';
import { AUTH_EVENT_PARAM, AUTH_EVENTS } from '@move-auth/web-client';
import { defineMessages } from 'react-intl';
import { ThunkDispatch } from 'redux-thunk';

import {
  triggerAfterLoginAction,
  forgetAfterLoginAction,
} from '../../utils/afterLoginActions';
import { pushSnackbarTranslationKey } from '../snackbars';
import { fetchNotificationsNewCount } from '../../features/notifications';

import {
  selectUserIdEncrypted,
  selectUserEmailEncrypted,
  selectUserExternalAuthProvider,
} from './selectors';
import {
  HANDLE_LOGIN_STATUS_FAILURE,
  HANDLE_LOGIN_STATUS_SUCCESS,
  HANDLE_AUTO_LOGIN_STATUS_SUCCESS,
  REQUEST_LOGIN,
  REQUEST_REGISTER,
} from './actionTypes';
import {
  LOGIN_BEGIN,
  LOGIN_SUCCESS,
  USER_REGISTRATION_BEGIN,
  USER_REGISTRATION_SUCCESS,
} from './tracking';
import { State } from '../types/State';
import {
  AuthenticationAction,
  HandleAutoLoginStatusSuccessAction,
  HandleLoginStatusFailureAction,
  HandleLoginStatusSuccessAction,
  RequestLoginAction,
  RequestRegisterAction,
} from './types/Actions';
import track from './../../utils/tracking/track';

const messages = defineMessages({
  loginStatusFailed: {
    id: 'error_message_user_logged_out',
  },
  autoLoginConfirmation: {
    id: 'login_SSO_confirmation_text',
  },
  loginConfirmation: {
    id: 'login_confirmation_text',
  },
  registrationConfirmation: {
    id: 'registration_confirmation_text',
  },
  leaveConfirmation: {
    id: 'identity_profile_delete_account_review',
  },
});

const doTracking = ({
  isLoggedIn,
  prevState,
}: {
  isLoggedIn: boolean;
  prevState: State;
}) => {
  const parsedQuery = qs.parse(window.location.search);
  const event = parsedQuery[AUTH_EVENT_PARAM];

  if (isLoggedIn && event) {
    const userId = selectUserIdEncrypted(prevState);
    const externalAuthProvider = selectUserExternalAuthProvider(prevState);
    const userEmail = selectUserEmailEncrypted(prevState);
    if (event === AUTH_EVENTS.USER_LOGIN) {
      track(LOGIN_SUCCESS, prevState, {
        userId,
        userEmail,
        externalAuthProvider,
      });
    } else if (event === AUTH_EVENTS.USER_REGISTRATION) {
      track(USER_REGISTRATION_SUCCESS, prevState, { externalAuthProvider });
      // when the registration is completed sucessfully,
      // the user is logged in automatically
      // which should trigger another LoginSuccess event
      track(LOGIN_SUCCESS, prevState, {
        userId,
        userEmail,
        externalAuthProvider,
      });
    }
  }
};

const showNotification = ({
  isLoggedIn,
  dispatch,
}: {
  isLoggedIn: boolean;
  dispatch: ThunkDispatch<State, void, AuthenticationAction>;
}) => {
  const parsedQuery = qs.parse(window.location.search);
  const event = parsedQuery[AUTH_EVENT_PARAM];
  const messageKeys = {
    [AUTH_EVENTS.USER_LOGIN]: isLoggedIn ? messages.loginConfirmation.id : null,
    [AUTH_EVENTS.USER_REGISTRATION]: isLoggedIn
      ? messages.registrationConfirmation.id
      : null,
    [AUTH_EVENTS.USER_LEAVE]: messages.leaveConfirmation.id,
  };
  const messageKey = messageKeys[event];

  if (messageKey) {
    dispatch(pushSnackbarTranslationKey(messageKey));
  }
};

// we need to remove tracking query string params
// to avoid sending events multiple times when the
// user or the OS reloads the site
const removeTrackingQueryParam = () => {
  const { pathname, search, hash } = window.location;
  const title = document.title;
  const parsedQuery = qs.parse(search);
  delete parsedQuery[String(AUTH_EVENT_PARAM)];
  const queryString = Object.keys(parsedQuery).length
    ? `?${qs.stringify(parsedQuery)}`
    : '';

  window.history.replaceState(
    window.history.state,
    title,
    `${pathname}${queryString}${hash}`
  );
};

type Context = {
  dispatch: ThunkDispatch<State, void, AuthenticationAction>;
  prevState: State;
};

export default {
  [REQUEST_LOGIN]: (
    { payload }: Pick<RequestLoginAction, 'payload'>,
    { prevState }: Context
  ) => track(LOGIN_BEGIN, prevState, payload),

  [HANDLE_LOGIN_STATUS_SUCCESS]: (
    {
      payload: { isLoggedIn },
    }: Pick<HandleLoginStatusSuccessAction, 'payload'>,
    { dispatch, prevState }: Context
  ) => {
    doTracking({ isLoggedIn, prevState });
    showNotification({ dispatch, isLoggedIn });
    removeTrackingQueryParam();
    if (isLoggedIn) {
      triggerAfterLoginAction(dispatch);
    } else {
      forgetAfterLoginAction();
    }

    if (isLoggedIn) {
      dispatch(fetchNotificationsNewCount());
    }
  },

  [REQUEST_REGISTER]: (
    { payload }: Pick<RequestRegisterAction, 'payload'>,
    { prevState }: Context
  ) => track(USER_REGISTRATION_BEGIN, prevState, payload),

  [HANDLE_LOGIN_STATUS_FAILURE]: (
    _: Pick<HandleLoginStatusFailureAction, 'payload'>,
    { dispatch }: Context
  ) => {
    forgetAfterLoginAction();
    dispatch(pushSnackbarTranslationKey(messages.loginStatusFailed.id));
  },

  [HANDLE_AUTO_LOGIN_STATUS_SUCCESS]: (
    { payload }: Pick<HandleAutoLoginStatusSuccessAction, 'payload'>,
    { dispatch }: Context
  ) => {
    dispatch(
      pushSnackbarTranslationKey(messages.autoLoginConfirmation.id, payload)
    );
  },
};
