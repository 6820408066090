import {
  updatePreparatoryQuery,
  setPreparatoryQuery,
  applyQuery,
  resetPreparatoryQuery,
} from './actionCreators';
import cloneDeep from 'lodash/cloneDeep';
import { DEFAULT_LOCATION_RADIUS } from '../../../shared/config';
import { State } from './types/State';
import { Action } from './types/Action';
import { Province } from '../../shared/types/Province';
import { Coordinates } from '../../shared/types/Coordinates';

const initialState: State = {
  query: {},
  preparatoryQuery: {},
};

type Area = {
  location?: {
    position?: Coordinates;
    province?: Province;
  };
  radius?: number;
  isLimitedToProvince?: boolean;
};

export const buildInitialState = (area?: Area): State => {
  const { location, radius, isLimitedToProvince } = area || {};
  const { position, province } = location || {};
  const { latitude, longitude } = position || {};

  if (!latitude || !longitude) {
    return initialState;
  }

  return {
    query: {},
    preparatoryQuery: {
      ll: `${latitude},${longitude}`,
      rd: `${radius || DEFAULT_LOCATION_RADIUS}`,
      aa: isLimitedToProvince && province?.code ? province?.code : undefined,
    },
  };
};

const states = {
  [Action.UpdatePreparatoryQuery]: (
    state: State,
    { query }: Pick<State, 'query'>
  ) => ({
    ...state,
    preparatoryQuery: {
      ...state.preparatoryQuery,
      ...query,
    },
  }),
  [Action.SetPreparatoryQuery]: (
    state: State,
    { query }: Pick<State, 'query'>
  ) => ({
    ...state,
    preparatoryQuery: query,
  }),
  [Action.ApplyQuery]: (state: State, { query }: Pick<State, 'query'>) => {
    const clone = cloneDeep(query);
    return { ...state, query: clone, preparatoryQuery: clone };
  },
  [Action.ResetPreparatoryQuery]: (state: State) => ({
    ...state,
    preparatoryQuery: {},
  }),
};

const reducer = (
  state = initialState,
  {
    type,
    ...rest
  }: ReturnType<
    | typeof updatePreparatoryQuery
    | typeof setPreparatoryQuery
    | typeof applyQuery
    | typeof resetPreparatoryQuery
  >
) => states[type]?.(state, rest as any) || state;

export default reducer;
