import React, { FC } from 'react';
import cx from 'classnames';
import { useIntl } from 'react-intl';
import { CloseIcon } from '@move-ui/icons-asset-catalog';
import { PrimaryButton, GhostInverseButton, ButtonSize } from '@move-ui/button';
import Text, { TextSize } from '@move-ui/text';
import RatingStars from '@move-ui/rating';
import { Link } from '@move-ui/link';
import { APP_DOWNLOAD_BEGIN } from './SmartBanner.tracking';
import { trackOptimizelyEvent, EVENT_KEYS } from '../../../../lib/optimizely';
import { APP_STORE_ID } from '../../../../shared/config';
import styles from './SmartBanner.css';
import useTracking from '../../../utils/tracking/useTracking';

const APP_RATING = 4.6;

type Props = {
  className?: string;
  close?: () => void;
  category: string;
};

export const IOSSmartBanner: FC<Props> = ({ close, className, category }) => {
  const { formatMessage } = useIntl();
  const { trackEvent } = useTracking();

  const onClickDownlad = () => {
    trackEvent(APP_DOWNLOAD_BEGIN);
    trackOptimizelyEvent(EVENT_KEYS.DOWNLOAD_CLICK);
  };

  return (
    <div className={cx(styles.iosComponent, className)}>
      <GhostInverseButton
        className={styles.closeButton}
        onClick={close}
        data-testid="smartBannerClose"
        iconOnly
        icon={<CloseIcon className={cx(styles.closeButtonIcon)} />}
      />
      <img
        className={styles.appLogo}
        alt={formatMessage({ id: 'smartbanner_app_name' })}
        src="/static/ca/appicon_120.png"
        width="50"
        height="50"
      />
      <div className={styles.message}>
        <Text color="neutral-100" size={TextSize.XS}>
          {formatMessage({ id: 'smartbanner_app_name' })}
        </Text>
        <div>
          <RatingStars
            value={APP_RATING}
            iconClassName={styles.starIcon}
            activeClassName={styles.activeStarIos}
          />
          <Text color="neutral-200" size={TextSize.XXS}>
            {APP_RATING} (4,766)
          </Text>
        </div>
        <Text color="neutral-200" size={TextSize.XXS}>
          {formatMessage({ id: 'smartbanner_free_app_store' })}
        </Text>
      </div>
      <Link
        href={`itms-apps://apps.apple.com/app/apple-store/id${APP_STORE_ID}?pt=251500&ct=custom_smartbanner_${category}&mt=8`}
      >
        <PrimaryButton
          size={ButtonSize.Size200}
          onClick={onClickDownlad}
          className={styles.downloadBtnIos}
          label={formatMessage({ id: 'smartbanner_get_app' })}
        />
      </Link>
    </div>
  );
};
