import { useIntl } from 'react-intl';

enum TranslationKey {
  // key of menu labels, must match CATEGORIES, @see ../config
  Homepage = 'Homepage',
  BuySell = 'BuySell',
  CarsVehicles = 'CarsVehicles',
  RealEstate = 'RealEstate',
  Jobs = 'Jobs',
  Services = 'Services',
  Pets = 'Pets',
  Community = 'Community',
  VacationRentals = 'VacationRentals',
}

const useTopNavigationTranslations = () => {
  const intl = useIntl();

  return {
    [TranslationKey.Homepage]: intl.formatMessage({
      id: 'ribbon_horizontal_navigation_kijiji_alt_text',
    }),
    [TranslationKey.BuySell]: intl.formatMessage({
      id: 'ribbon_horizontal_navigation_buysell',
    }),
    [TranslationKey.CarsVehicles]: intl.formatMessage({
      id: 'ribbon_horizontal_navigation_carsvehicles',
    }),
    [TranslationKey.RealEstate]: intl.formatMessage({
      id: 'ribbon_horizontal_navigation_realestate',
    }),
    [TranslationKey.Jobs]: intl.formatMessage({
      id: 'ribbon_horizontal_navigation_jobs',
    }),
    [TranslationKey.Services]: intl.formatMessage({
      id: 'ribbon_horizontal_navigation_services',
    }),
    [TranslationKey.Pets]: intl.formatMessage({
      id: 'ribbon_horizontal_navigation_pets',
    }),
    [TranslationKey.Community]: intl.formatMessage({
      id: 'ribbon_horizontal_navigation_community',
    }),
    [TranslationKey.VacationRentals]: intl.formatMessage({
      id: 'ribbon_horizontal_navigation_vacationrentals',
    }),
  };
};

export default useTopNavigationTranslations;
export { TranslationKey };
