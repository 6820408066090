import { isNil } from 'lodash';
import getDefaultSearchQueryParams from '../../../shared/utils/getDefaultSearchQueryParams';
import { Query } from '../../../stores/searchQuery';

export const getQueryWithDefaults = (query: Query = {}) => {
  const { psz, ...rest } = query;
  return {
    ...rest,
    ...getDefaultSearchQueryParams(),
    ...(isNil(psz) ? {} : { psz }),
  };
};
