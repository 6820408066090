import { useSelector } from 'react-redux';
import {
  selectUsername,
  selectUserAvatar,
  selectIsLoggedIn,
} from '../../stores/authentication';
import { selectHasUnreadMessages } from './selectors';
import { selectHasUnreadNotifications } from '../../features/notifications';

export const useUser = () => {
  const username = useSelector(selectUsername);
  const avatar = useSelector(selectUserAvatar);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const hasUnreadMessages = useSelector(selectHasUnreadMessages);
  const hasUnreadNotifications = useSelector(selectHasUnreadNotifications);

  return {
    username,
    avatar,
    isLoggedIn,
    hasUnreadMessages,
    hasUnreadNotifications,
  };
};
