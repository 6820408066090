import { push } from './dataLayer';
import {
  DIMENSION_PAGE_TYPE,
  DIMENSION_EXPERIMENT_VARIATION,
  resetUnusedCDs,
  DIMENSION_STAR_CARS_EXPERIMENT_VARIATION,
} from './dimensions';
import getExperimentsDimension from './getExperimentsDimension';
import selectTrackingPageName from './selectTrackingPageName';
import {
  getActiveExperiments,
  trackOptimizelyEvent,
} from '../../../lib/optimizely';
import joinValues from './joinValues';
import selectXPromotions from './selectXPromotions';
import { State } from '../../stores/types/State';

const EVENT_LABEL_MAX_LENGTH = 100;

type Data = Record<string, any>;

type EventData = {
  eventLabel?: string;
  eventValue?: string;
  nonInteraction?: string | boolean;
  eventCategory?: string;
};

type GtmEvent<T extends Data = Data> = {
  eventAction?: string;
  optimizelyEvents?: string[];
  getEventData?: (data: T, state: State) => EventData;
};

/**
 * Create the tracking object and send it to GTM via dataList
 */
const track = <T extends Data = Data>(
  { eventAction, optimizelyEvents, getEventData }: GtmEvent<T>,
  state: State,
  data: T = {} as T
) => {
  // Event Action is required, otherwise tracking should be ignored
  if (eventAction) {
    try {
      const {
        eventLabel,
        eventValue,
        eventCategory: overridingCategory,
        ...rest
      } = getEventData?.(data, state) || {};
      const eventCategory = overridingCategory ?? selectTrackingPageName(state);

      push({
        event: 'gaEvent',
        // eventCategory and [DIMENSION_PAGE_TYPE] are duplicated
        eventCategory,
        // explicitly set the fields below
        eventLabel: eventLabel?.substr(0, EVENT_LABEL_MAX_LENGTH),
        eventAction,
        eventValue: eventLabel && eventValue ? eventValue : undefined,
        // reset all unused CDs
        ...resetUnusedCDs,
        // set CDs
        [DIMENSION_PAGE_TYPE]: eventCategory,
        [DIMENSION_EXPERIMENT_VARIATION]: getExperimentsDimension(
          getActiveExperiments()
        ),
        [DIMENSION_STAR_CARS_EXPERIMENT_VARIATION]: joinValues(
          selectXPromotions()
        ),
        ...rest,
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(
        `[track] Failed to track '${eventAction}': ${(e as Error).message}`
      );
    }
  }

  optimizelyEvents?.forEach((event) => trackOptimizelyEvent(event));
};

export default track;
