import { getPayload } from '../location';
import { shouldShowRealVip } from '../../pageSegments/hashedVip/shouldShowRealVip';

import {
  DEALER_ROUTE,
  pages,
  SRP_ROUTE,
  LISTING_NOT_FOUND_ROUTE,
  HOME_ROUTE,
  DRP_ROUTE,
} from './constants';
import { State } from './types/State';

export const selectPageId = (state: State) => state.page;

export const selectIsVIP = (
  state: State & Parameters<typeof getPayload>[0]
) => {
  const payload = getPayload(state);
  return shouldShowRealVip(payload);
};

export const selectIsHome = ({ page }: State) =>
  page === pages[String(HOME_ROUTE)];

export const selectIsSRP = ({ page }: State) => page === pages[SRP_ROUTE];

export const selectIsDealerPage = ({ page }: State) =>
  page === pages[DEALER_ROUTE];

export const selectIsListingNotFoundPage = ({ page }: State) =>
  page === pages[LISTING_NOT_FOUND_ROUTE];

export const selectIsDRP = ({ page }: State) => page === pages[DRP_ROUTE];
