import { createSelector } from 'reselect';
import { groupBy, uniqBy } from 'lodash';
import normalizeUrl from '../../../shared/utils/normalizeUrl';

const selectMrp = ({ pages: { mrp } }) => mrp;

export const selectTextJson = createSelector(selectMrp, (mrp) => mrp?.textJson);

export const selectMetadata = createSelector(selectMrp, (mrp) => mrp?.meta);

export const selectPageState = createSelector(
  selectMrp,
  (mrp) => mrp?.pageState
);

export const selectImage = createSelector(selectMrp, (mrp) => mrp?.image);

export const selectSubtitle = createSelector(selectMrp, (mrp) => mrp?.subtitle);

export const selectMips = createSelector(selectMrp, (mrp) =>
  mrp?.mips.map((mip) => ({
    ...mip,
    url: normalizeUrl(mip.url),
  }))
);

export const selectMakes = createSelector(selectMips, (mips) =>
  uniqBy(mips, 'make').map((mip) => mip.make)
);

export const selectFilters = createSelector(selectMrp, (mrp) => mrp?.filters);

export const selectCurrentMake = createSelector(
  selectFilters,
  (filters) => filters?.make
);

const isMipVisible = (filters, mip) =>
  !filters?.make || mip.make === filters?.make;

export const selectMipsWithVisibility = createSelector(
  selectMips,
  selectFilters,
  (mips, filters) =>
    mips?.map((mip) => ({
      ...mip,
      url: normalizeUrl(mip.url),
      isVisible: isMipVisible(filters, mip),
    }))
);

export const selectGroupedMips = createSelector(
  selectMipsWithVisibility,
  (mips) => groupBy(mips, 'make')
);
