import { createInstance, enums, logging } from '@optimizely/optimizely-sdk';
import logger from '@move-web-essentials-utils/logger';
import { getEnv } from '@move-web-essentials-utils/env';
import { getDatafile } from 'optimizelyDatafile';
import { setActiveExperiment } from './activeExperiments';
import * as ALL_FEATURES from '../constants/feature-tests';
import initForcedVariations from '../utils/initForcedVariationsBrowser';

let optimizelyInstance = null;
const optimizelyEnvironment = 'optimizely_environment';

export const init = () => {
  throw Error('should not call init() in browser environment');
};

// when a feature decision was made (feature enabled/disabled)
// and this feature is currently in a feature-test
// the feature-test will be added to the active experiments cache
// (which is used for GA custom dimensions)
//
// when a feature decision was made b/c of a rollout
// nothing will happen
const onDecision = (decision) => {
  if (
    decision.type === enums.DECISION_NOTIFICATION_TYPES.FEATURE &&
    decision.decisionInfo.source ===
      enums.DECISION_NOTIFICATION_TYPES.FEATURE_TEST
  ) {
    const { featureKey, sourceInfo } = decision.decisionInfo;
    const ticketCode = Object.values(ALL_FEATURES).find(
      (x) => x.key === featureKey
    )?.ticketCode;

    if (!ticketCode) {
      logger.error(
        `OPTIMIZELY: No ticketCode found for ${featureKey}. Make sure to set it in lib/optimizely/constants/features.`
      );
      return;
    }

    setActiveExperiment(
      {
        key: sourceInfo.experimentKey,
        ticketCode,
      },
      sourceInfo.variationKey
    );
  }
};

const internalInit = () => {
  const datafile = getDatafile();
  const environment = getEnv(optimizelyEnvironment);

  if (datafile) {
    optimizelyInstance = createInstance({
      datafile,
      logger: logging.createLogger({
        logLevel:
          environment !== 'production'
            ? enums.LOG_LEVEL.INFO
            : enums.LOG_LEVEL.WARNING,
      }),
    });

    // will be called by optimizely SDK once a decision is made (e.g. feature enabled, experiment started etc.)
    optimizelyInstance.notificationCenter.addNotificationListener(
      enums.NOTIFICATION_TYPES.DECISION,
      onDecision
    );

    initForcedVariations(optimizelyInstance);
  }
};

const getInstance = () => {
  if (!optimizelyInstance) {
    internalInit();
  }
  return optimizelyInstance;
};

export default getInstance;
