import { State } from '../../stores/types/State';
import { createSelector } from 'reselect';
import { selectIsFinancingSpokeReady } from './financingSpoke/selectors';
import { selectIsPaymentSpokeReady } from './paymentSpoke/selectors';

/*
  If a listing fails to load, a user is not able to go enter any DRP.
  Therefore we can assume that we will always be able to select a listing since we must not import a page's selectors
  from anywhere else.
 */
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const selectListing = (state: State) => state.pages.drp.listing!;

export const selectProvince = (state: State) =>
  selectListing(state).location?.province;

export const selectSpoke = (state: State) => state.location.payload.spoke;

export const selectPaymentType = (state: State) => state.pages.drp.paymentType;

export const selectIsPaymentSpokeFilledOut = (state: State) =>
  state.pages.drp.isPaymentSpokeFilledOut;

export const selectIsFinancingSpokeFilledOut = (state: State) =>
  state.pages.drp.isFinancingSpokeFilledOut;

export const selectIsFinancingSpokeStarted = (state: State) =>
  state.pages.drp.financing.isFinancingSpokeStarted;

export const selectReferenceId = (state: State) => state.pages.drp.referenceId;

export const selectIsDealLoading = (state: State) =>
  state.pages.drp.isDealLoading;

export const selectDealErrorMessages = (state: State) =>
  state.pages.drp.errorMessages;

export const selectDealHasError = (state: State) =>
  Boolean(state.pages.drp.errorMessages.length);

export const selectDealerRating = (state: State) =>
  selectListing(state).dealerRating;

export const selectListingLocation = (state: State) =>
  selectListing(state).location;

export const selectContactName = (state: State) =>
  selectListing(state).contact?.name;

export const selectDRDisplayPrice = (state: State) =>
  selectListing(state).prices.consumerPrice?.localized;

export const selectCurrentStep = (state: State) => state.pages.drp.currentStep;

export const selectIsStepActive = (step: number) => (state: State) =>
  step <= selectCurrentStep(state);

export const selectIsSendButtonDisabled = createSelector(
  selectIsPaymentSpokeReady,
  selectIsFinancingSpokeReady,
  selectIsDealLoading,
  (isPaymentSpokeReady, isFinancingSpokeReady, isDealLoading) =>
    !isPaymentSpokeReady || !isFinancingSpokeReady || isDealLoading
);

export const selectProtectionProductsOptIn = (state: State) =>
  selectListing(state).protectionProductsOptIn;
