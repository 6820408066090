import { State as GlobalState } from '../types/State';

type State = Pick<GlobalState, 'authentication'>;

export const selectIsLoggedIn = (state: State) =>
  state.authentication?.isLoggedIn;

export const selectUserId = (state: State) => state.authentication?.userId;
export const selectUsername = (state: State) => state.authentication?.username;

export const selectUserIdEncrypted = (state: State) =>
  state.authentication?.userIdEncrypted;
export const selectUserEmailEncrypted = (state: State) =>
  state.authentication?.emailEncrypted;
export const selectUserAvatar = (state: State) => state.authentication?.avatar;
export const selectUserEmail = (state: State) => state.authentication?.email;
export const selectUserExternalAuthProvider = (state: State) =>
  state.authentication?.externalAuthProvider;
