import { FilterValue } from '../../../../stores/referenceData';

export const isFilterValueSelected = <
  T extends string | string[] | Record<string, string | string[]>
>(
  filterValue: Pick<FilterValue, 'value' | 'param'>,
  currentValue?: T
) => {
  const { value, param = '' } = filterValue;
  if (typeof currentValue === 'undefined') {
    return false;
  }

  // single value: compare value
  if (typeof currentValue === 'string') {
    return currentValue === value;
  }

  // multiple values: compare value
  if (typeof currentValue[param] === 'undefined') {
    return false;
  }
  if (typeof currentValue[param] === 'string') {
    return currentValue[param] === value;
  }
  // multiple values: check if value is in the list
  if (Array.isArray(currentValue[param])) {
    return currentValue[param].includes(value);
  }

  return false;
};

export const findFilterValue = <T extends { value: string }>(
  filterValues: T[],
  value?: string
) => filterValues.find((filterValue) => filterValue.value === value);
