import { SET_HAS_UNREAD_MESSAGES } from './actionTypes';
import { setMakeModel } from '../../stores/searchQuery';
import { goToSrp } from '../../stores/page';

export const setHasUnreadMessages = (hasUnreadMessages) => ({
  type: SET_HAS_UNREAD_MESSAGES,
  payload: { hasUnreadMessages },
});

export const searchMakeModel = (makeModelSelection) => async (dispatch) => {
  const query = await dispatch(setMakeModel(makeModelSelection));
  return dispatch(goToSrp(query));
};
