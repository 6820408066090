import { isEmpty } from 'lodash';
import { EVENT_KEYS } from '../../../../lib/optimizely';
import joinValues from '../../../utils/tracking/joinValues';
import {
  DIMENSION_L1_LOCATION_NAME,
  DIMENSION_L2_LOCATION_NAME,
  DIMENSION_ONSITE_SEARCH_MIN_PRICE,
  DIMENSION_ONSITE_SEARCH_MAX_PRICE,
  DIMENSION_ONSITE_SEARCH_KEYWORD,
  DIMENSION_SELECTED_CATEGORY_NAME,
  DIMENSION_CONDITION_FILTER_TYPE,
} from '../../../utils/tracking/dimensions';

import { filterQueryParamsToConsider } from '../../domains/filters/specifications/query';
import { getMakeModelTrimDimensions } from '../../../../shared/utils/getMakeModelTrimDimensions';
import { LocationArea } from '../../../features/location/types/LocationArea';
import { Query } from '../../../stores/searchQuery';

const EXTRAS_PARAM_GROUP = ['fe', 'clim', 'pa', 'spc', 'tct'];
const EXTRAS_PARAM_KEY = 'extra';

export const getTrackingConditions = (filterInputValues: Query) => {
  const conditions = filterQueryParamsToConsider(
    Object.keys(filterInputValues) as (keyof Query)[]
  )
    // remove empty ones
    .filter(
      (item) => filterInputValues[item] && !isEmpty(filterInputValues[item])
    )
    // group all extras params as one
    .reduce<string[]>((acc, item) => {
      if (EXTRAS_PARAM_GROUP.includes(item)) {
        acc.push(EXTRAS_PARAM_KEY);
      } else {
        acc.push(item);
      }
      return acc;
    }, [])
    // remove duplicates
    .filter((item, index, self) => self.indexOf(item) === index)
    .sort();

  const value = conditions
    .map((condition) =>
      condition
        .replace('digital_retail_eligible', 'dr')
        .replace('frequency', 'PAYMENT_frequency')
        .replace('term', 'PAYMENT_term')
    )
    .flatMap((condition) => {
      if (condition !== 'payment') {
        return condition;
      }
      const [paymin, paymax] = filterInputValues?.payment?.split(':') || '';
      return [paymin && 'PAYMENT_min', paymax && 'PAYMENT_max'].filter(Boolean);
    });

  if (filterInputValues?.term || filterInputValues?.frequency) {
    value.unshift('PAYMENT');
  }

  return value;
};

/**
 * tracking definition for DSP events
 * @see https://jira.corp.ebay.com/browse/MOVE-4521
 */
export const SEARCH_RESULTS = {
  eventAction: 'SubmitSearch',
  getEventData: ({
    location,
    minPrice,
    maxPrice,
    minPayment,
    maxPayment,
    conditions,
    keyword,
    condition,
    categoryName,
    makeModelTrims = [],
  }: Pick<LocationArea, 'location'> & {
    minPrice: string;
    maxPrice: string;
    minPayment: string;
    maxPayment: string;
    keyword: string;
    condition: string | string[];
    conditions: string[];
    categoryName: string;
    makeModelTrims: Parameters<typeof getMakeModelTrimDimensions>[0];
  }) => ({
    [DIMENSION_L1_LOCATION_NAME]: location?.province?.code ?? undefined,
    [DIMENSION_L2_LOCATION_NAME]: location?.city ?? undefined,
    [DIMENSION_ONSITE_SEARCH_MIN_PRICE]: minPrice || minPayment || undefined,
    [DIMENSION_ONSITE_SEARCH_MAX_PRICE]: maxPrice || maxPayment || undefined,
    [DIMENSION_ONSITE_SEARCH_KEYWORD]: keyword || undefined,
    [DIMENSION_CONDITION_FILTER_TYPE]: joinValues(condition),
    [DIMENSION_SELECTED_CATEGORY_NAME]: categoryName || undefined,
    ...getMakeModelTrimDimensions(makeModelTrims),
    eventLabel:
      conditions && conditions.length > 0 ? conditions.join(';') : 'none',
  }),
  optimizelyEvents: [EVENT_KEYS.SUBMIT_SEARCH],
};

export const SEARCH_RESULTS_WITH_DR = {
  ...SEARCH_RESULTS,
  optimizelyEvents: [
    ...SEARCH_RESULTS.optimizelyEvents,
    EVENT_KEYS.DR_FILTER_PROMO,
    EVENT_KEYS.DR_FILTER,
  ],
};

export const SEARCH_CANCEL = {
  eventAction: 'SubmitCancel',
};

export const SEARCH_RESET = {
  eventAction: 'SubmitReset',
};
