import { combineReducers } from 'redux';
import dealer from './dealer/reducer';
import home from './home/reducer';
import mip from './mip/reducer';
import srp from './srp/reducer';
import vip from './vip/reducer';
import drp from './drp/reducer';
import mrp from './mrp/reducer';
import deals from './myDeals/reducer';

export default combineReducers({
  dealer,
  home,
  srp,
  vip,
  drp,
  deals,
  mrp,
  mip,
});
