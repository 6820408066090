export const REQUEST_CONVERSATION_LIST =
  'feature/inbox/REQUEST_CONVERSATION_LIST';
export const HANDLE_RESPONSE_CONVERSATION_LIST =
  'feature/inbox/HANDLE_RESPONSE_CONVERSATION_LIST';
export const HANDLE_RESPONSE_ERROR_CONVERSATION_LIST =
  'feature/inbox/HANDLE_RESPONSE_ERROR_CONVERSATION_LIST';

export const SELECT_CONVERSATION = 'feature/inbox/SELECT_CONVERSATION';

export const CLOSE_CONVERSATION = 'feature/inbox/CLOSE_CONVERSATION';

export const RESET_UNREAD_MESSAGES_COUNT =
  'feature/inbox/RESET_UNREAD_MESSAGES_COUNT';

export const DELETE_CONVERSATION_LIST =
  'feature/inbox/DELETE_CONVERSATION_LIST';
export const HANDLE_DELETE_CONVERSATION_LIST =
  'feature/inbox/HANDLE_DELETE_CONVERSATION_LIST';
export const HANDLE_ERROR_DELETE_CONVERSATION_LIST =
  'feature/inbox/HANDLE_ERROR_DELETE_CONVERSATION_LIST';

export const TOGGLE_EDIT_MODE = 'feature/inbox/TOGGLE_EDIT_MODE';
export const CHECK_EDIT_CONVERSATION = 'feature/inbox/CHECK_EDIT_CONVERSATION';
