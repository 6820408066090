import { getPayload } from '../../stores/location';
import { pages, VIP_ROUTE } from '../../stores/page';
import { shouldShowRealVip } from '../../pageSegments/hashedVip/shouldShowRealVip';
import { PAGE_TYPES_MAP } from './pageTypesMap';
import { isSVIP } from './isSVip';

const selectTrackingPageName = (state) => {
  const pageType = PAGE_TYPES_MAP[state.page];

  const payload = getPayload(state);

  const isVip = shouldShowRealVip(payload);
  if (isVip) {
    if (isSVIP(PAGE_TYPES_MAP.vip, state)) {
      return PAGE_TYPES_MAP.sVip;
    }
    return PAGE_TYPES_MAP[pages[VIP_ROUTE]];
  }

  return pageType;
};

export default selectTrackingPageName;
