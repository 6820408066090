import {
  HANDLE_LOGIN_FAILURE,
  HANDLE_LOGIN_STATUS_FAILURE,
  HANDLE_LOGIN_STATUS_SUCCESS,
  HANDLE_LOGIN_SUCCESS,
  HANDLE_LOGOUT_FAILURE,
  HANDLE_LOGOUT_SUCCESS,
  HANDLE_REGISTER_FAILURE,
  HANDLE_REGISTER_SUCCESS,
  REQUEST_LOGIN,
  REQUEST_LOGIN_STATUS,
  REQUEST_LOGOUT,
  REQUEST_REGISTER,
} from './actionTypes';
import { AuthenticationState } from './types/State';
import { AuthenticationAction, FailurePayload } from './types/Actions';

export const initialState: AuthenticationState = {
  isRequesting: false,
  isLoggedIn: false,
  userId: null,
  errorMessage: null,
  userIdEncrypted: undefined,
  emailEncrypted: undefined,
  avatar: undefined,
};

const setErrorMessage = (payload: FailurePayload) => {
  const defaultErrorMessage = 'Failure during the authentication check'; // TODO: set a proper error message
  if (!payload) {
    return defaultErrorMessage;
  }
  if (typeof payload === 'string') {
    return payload;
  }
  return payload.message || defaultErrorMessage;
};

export default function authenticationReducer(
  state: AuthenticationState = initialState,
  action: AuthenticationAction
) {
  switch (action.type) {
    case REQUEST_LOGIN_STATUS:
      return {
        ...state,
        isRequesting: true,
        errorMessage: null,
      };
    case HANDLE_LOGIN_STATUS_SUCCESS: {
      const payload = action.payload;
      return {
        ...state,
        isRequesting: false,
        isLoggedIn: payload.isLoggedIn,
        userId: payload.userId,
        username: payload.username,
        email: payload.email,
        userIdEncrypted: payload.userIdEncrypted,
        emailEncrypted: payload.emailEncrypted,
        errorMessage: null,
        avatar: payload.picture,
      };
    }
    case HANDLE_LOGIN_STATUS_FAILURE:
      return {
        ...state,
        isLoggedIn: false,
        userId: null,
        isRequesting: false,
        errorMessage: setErrorMessage(action.payload),
        userIdEncrypted: undefined,
        emailEncrypted: undefined,
      };
    case REQUEST_LOGIN:
      return {
        ...state,
        isRequesting: true,
        errorMessage: null,
      };
    case HANDLE_LOGIN_SUCCESS:
      // NOTE: if successful than go to the shared login page from the marketplace (e.g. CIS)
      return { ...state, isRequesting: false, errorMessage: null };
    case HANDLE_LOGIN_FAILURE:
      return {
        ...state,
        isRequesting: false,
        errorMessage: setErrorMessage(action.payload),
      };
    case REQUEST_LOGOUT:
      return {
        ...state,
        isRequesting: true,
        errorMessage: null,
      };
    case HANDLE_LOGOUT_SUCCESS:
      return {
        ...state,
        isLoggedIn: false,
        userId: null,
        isRequesting: false,
        errorMessage: null,
      };
    case HANDLE_LOGOUT_FAILURE:
      return {
        ...state,
        isRequesting: false,
        errorMessage: setErrorMessage(action.payload),
      };
    case REQUEST_REGISTER:
      return {
        ...state,
        isRequesting: true,
        errorMessage: null,
      };
    case HANDLE_REGISTER_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        errorMessage: null,
      };
    case HANDLE_REGISTER_FAILURE:
      return {
        ...state,
        isRequesting: false,
        errorMessage: setErrorMessage(action.payload),
      };
    default:
      return state;
  }
}
