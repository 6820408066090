import isEnvFeatureFlagEnabled from '../lib/env/isFeatureEnabled';

export const FEATURE_HEADS_UP = false;
export const FEATURE_GEO_LOCATION_HEADER = isEnvFeatureFlagEnabled(
  'geo_location_header'
);
export const FEATURE_FREESPEE_CALL_TRACKING = isEnvFeatureFlagEnabled(
  'freespee_call_tracking'
);
export const FEATURE_SMART_BANNER_ANDROID = isEnvFeatureFlagEnabled(
  'smart_banner_android'
);
export const FEATURE_SMART_BANNER_IOS =
  isEnvFeatureFlagEnabled('smart_banner_ios');
export const FEATURE_ADVERTISING_REPEATING_SRP_ADS = isEnvFeatureFlagEnabled(
  'advertising_repeating_srp_ads'
);
export const FEATURE_SRP_NATIVE_ADS = isEnvFeatureFlagEnabled('srp_native_ads');
export const FEATURE_TOP_NAVIGATION = isEnvFeatureFlagEnabled('top_navigation');
export const FEATURE_DIGITAL_RETAIL = false;
