import {
  getLocalStorageObject,
  setLocalStorageObject,
} from '../../../shared/utils/localStorage';

const STORAGE_KEY = 'enable_notifications';

export const savePushNotificationsPreferences = (shouldShow: boolean) =>
  setLocalStorageObject(STORAGE_KEY, shouldShow);

export const isNotificationAccepted = () =>
  getLocalStorageObject(STORAGE_KEY, false);
