import { State as GlobalState } from './types/State';

type State = Pick<GlobalState, 'i18n'>;

export const getTranslations = (state: State) => state.i18n.translations;

export const getDefaultLocale = (state: State) =>
  state.i18n.defaultLocale || state.i18n.DEFAULT_LOCALE;

export const getCurrentLocale = (state: State) =>
  state.i18n.currentLocale || getDefaultLocale(state);

const i18nReducer = (
  i18n: State['i18n'] = {
    translations: {},
    currentLocale: '',
    DEFAULT_LOCALE: '',
  }
) => i18n;

export default i18nReducer;
