import {
  ERROR_ROUTE,
  srpHvipRouteActionsForLocale,
  srpRouteActionsForLocale,
  VIP_ROUTE,
  VIP_ROUTE_WITH_FINANCING,
} from '../../stores/page';
import {
  HANDLE_RESPONSE_LISTING,
  SET_PROVISIONAL_BASE64,
  DISPLAY_CONTACT_FORM_MODAL,
  CLOSE_CONTACT_FORM_MODAL,
  SET_CLICK_SCROLL_SECTION,
  RESET_CLICK_SCROLL_SECTION,
  DISPLAY_REQUEST_IMAGES_MODAL,
  CLOSE_REQUEST_IMAGES_MODAL,
  DISPLAY_DEALER_UPDATES_MODAL,
  CLOSE_DEALER_UPDATES_MODAL,
  DISPLAY_DESCRIPTION_MODAL,
  CLOSE_DESCRIPTION_MODAL,
  DISPLAY_VEHICLE_FEATURES_MODAL,
  CLOSE_VEHICLE_FEATURES_MODAL,
  SET_FINANCING_DEFAULTS,
} from './actionTypes';

const defaultState = {
  listing: null,
  provisionalBase64Image: null,
  isContactFormModalOpen: false,
  isDealerUpdatesModalOpen: false,
  isDescriptionModalOpen: false,
};

// The actions from srpRouteActionsForLocale may be used to switch between listings
// with different paths.
// The actions from srpHvipRouteActionsForLocale are used to switch between listings
// with identical paths but different IDs.
const srpRouteActions = [
  ...Object.values(srpRouteActionsForLocale),
  ...Object.values(srpHvipRouteActionsForLocale),
];

export default function listingReducer(
  state = defaultState,
  { type, payload }
) {
  const navigatingToAnotherListing =
    [VIP_ROUTE, VIP_ROUTE_WITH_FINANCING].includes(type) ||
    srpRouteActions.includes(type);

  if (navigatingToAnotherListing) {
    return {
      ...state,
      listing: payload.item,
    };
  }

  switch (type) {
    case HANDLE_RESPONSE_LISTING:
      return {
        ...state,
        listing: payload,
        promotion: state.promotion,
      };
    case ERROR_ROUTE:
      return {
        ...state,
      };
    case SET_PROVISIONAL_BASE64:
      return {
        ...state,
        provisionalBase64Image: payload,
      };
    case DISPLAY_CONTACT_FORM_MODAL:
      return {
        ...state,
        isContactFormModalOpen: true,
      };
    case CLOSE_CONTACT_FORM_MODAL:
      return {
        ...state,
        isContactFormModalOpen: false,
      };
    case DISPLAY_REQUEST_IMAGES_MODAL:
      return {
        ...state,
        isRequestImagesModalOpen: true,
      };
    case CLOSE_REQUEST_IMAGES_MODAL:
      return {
        ...state,
        isRequestImagesModalOpen: false,
      };
    case SET_CLICK_SCROLL_SECTION:
      return {
        ...state,
        clickScrollSection: payload,
      };
    case RESET_CLICK_SCROLL_SECTION:
      return {
        ...state,
        clickScrollSection: undefined,
      };
    case DISPLAY_DEALER_UPDATES_MODAL:
      return {
        ...state,
        isDealerUpdatesModalOpen: true,
      };
    case CLOSE_DEALER_UPDATES_MODAL:
      return {
        ...state,
        isDealerUpdatesModalOpen: false,
      };
    case DISPLAY_DESCRIPTION_MODAL:
      return {
        ...state,
        isDescriptionModalOpen: true,
      };
    case CLOSE_DESCRIPTION_MODAL:
      return {
        ...state,
        isDescriptionModalOpen: false,
      };
    case DISPLAY_VEHICLE_FEATURES_MODAL:
      return {
        ...state,
        isVehicleFeaturesModalOpen: true,
      };
    case CLOSE_VEHICLE_FEATURES_MODAL:
      return {
        ...state,
        isVehicleFeaturesModalOpen: false,
      };
    case SET_FINANCING_DEFAULTS:
      return {
        ...state,
        financingDefaults: payload,
      };
    default:
      return state;
  }
}
