import { useIntl } from 'react-intl';

export const useLabels = () => {
  const { formatMessage } = useIntl();

  return {
    // Brand Section
    brand_title: formatMessage({
      id: 'config_footer_brand_title',
    }),
    brand_about_title: formatMessage({
      id: 'config_footer_brand_about_title',
    }),
    brand_about_url: formatMessage({
      id: 'config_footer_brand_about_url',
    }),
    brand_mrp_title: formatMessage({
      id: 'config_footer_mrp_title',
    }),
    brand_mrp_alt: formatMessage({
      id: 'config_footer_mrp_alt',
    }),
    brand_cpo_title: formatMessage({
      id: 'config_footer_cpo_title',
    }),
    brand_cpo_alt: formatMessage({
      id: 'config_footer_cpo_alt',
    }),
    brand_cpp_title: formatMessage({
      id: 'config_footer_cpp_title',
    }),
    brand_cpp_alt: formatMessage({
      id: 'config_footer_cpp_alt',
    }),
    brand_careers_title: formatMessage({
      id: 'config_footer_brand_careers_title',
    }),
    brand_careers_url: formatMessage({
      id: 'config_footer_brand_careers_url',
    }),
    brand_memberbenefits_title: formatMessage({
      id: 'config_footer_brand_memberbenefits_title',
    }),
    brand_memberbenefits_url: formatMessage({
      id: 'config_footer_brand_memberbenefits_url',
    }),
    brand_advertiseonbrand_title: formatMessage({
      id: 'config_footer_brand_advertiseonbrand_title',
    }),
    brand_advertiseonbrand_url: formatMessage({
      id: 'config_footer_brand_advertiseonbrand_url',
    }),
    brand_dealer_sitemap_title: formatMessage({
      id: 'footer_dealer_sitemap',
    }),

    // Explore section
    explore_title: formatMessage({
      id: 'config_footer_explore_title',
    }),
    explore_blog_title: formatMessage({
      id: 'config_footer_explore_blog_title',
    }),
    explore_blog_url: formatMessage({
      id: 'config_footer_explore_blog_url',
    }),
    explore_toolstopromoteads_title: formatMessage({
      id: 'config_footer_explore_toolstopromoteads_title',
    }),
    explore_toolstopromoteads_url: formatMessage({
      id: 'config_footer_explore_toolstopromoteads_url',
    }),
    explore_adevinta_sites_title: formatMessage({
      id: 'config_footer_explore_adevinta_sites_title',
    }),
    explore_adevinta_sites_url: formatMessage({
      id: 'config_footer_explore_adevinta_sites_url',
    }),
    explore_link_to_horizontal_title: formatMessage({
      id: 'config_link_to_horizontal_title',
    }),
    explore_link_to_horizontal_url: formatMessage({
      id: 'config_link_to_horizontal_url',
    }),

    // Info section
    info_title: formatMessage({
      id: 'config_footer_info_title',
    }),
    info_termsofuse_title: formatMessage({
      id: 'config_footer_info_termsofuse_title',
    }),
    info_termsofuse_url: formatMessage({
      id: 'config_footer_info_termsofuse_url',
    }),
    info_privacypolicy_title: formatMessage({
      id: 'config_footer_info_privacypolicy_title',
    }),
    info_privacypolicy_url: formatMessage({
      id: 'config_footer_info_privacypolicy_url',
    }),
    info_cookiepolicy_title: formatMessage({
      id: 'config_footer_info_cookiepolicy_title',
    }),
    info_cookiepolicy_url: formatMessage({
      id: 'config_footer_info_cookiepolicy_url',
    }),
    info_postingpolicy_title: formatMessage({
      id: 'config_footer_info_postingpolicy_title',
    }),
    info_postingpolicy_url: formatMessage({
      id: 'config_footer_info_postingpolicy_url',
    }),
    info_adchoice_title: formatMessage({
      id: 'config_footer_info_adchoice_title',
    }),
    info_adchoice_url: formatMessage({
      id: 'config_footer_info_adchoice_url',
    }),
    info_feedback_title: formatMessage({
      id: 'config__footer_feedback_title',
    }),
    info_feedback_url: formatMessage({
      id: 'config_footer_feedback_url',
    }),

    // Help section
    help_title: formatMessage({
      id: 'config_footer_help_title',
    }),
    help_support_title: formatMessage({
      id: 'config_footer_help_support_title',
    }),
    help_support_url: formatMessage({
      id: 'config_footer_help_support_url',
    }),
    help_a11y_services_title: formatMessage({
      id: 'config_footer_help_a11y_services_title',
    }),
    help_a11y_services_url: formatMessage({
      id: 'config_footer_help_a11y_services_url',
    }),

    // Organization Schema
    organization_schema_address_country: formatMessage({
      id: 'organization_schema_address_country',
    }),
    organization_schema_address_locality: formatMessage({
      id: 'organization_schema_address_locality',
    }),
    organization_schema_address_region: formatMessage({
      id: 'organization_schema_address_region',
    }),
    organization_schema_brand: formatMessage({
      id: 'organization_schema_brand',
    }),
    organization_schema_contact_point_url: formatMessage({
      id: 'organization_schema_contact_point_url',
    }),
    organization_schema_contact_type: formatMessage({
      id: 'organization_schema_contact_type',
    }),
    organization_schema_description: formatMessage({
      id: 'organization_schema_description',
    }),
    organization_schema_knows_about: formatMessage({
      id: 'organization_schema_knows_about',
    }),
    organization_schema_legal_name: formatMessage({
      id: 'organization_schema_legal_name',
    }),
    organization_schema_postal_code: formatMessage({
      id: 'organization_schema_postal_code',
    }),
    organization_schema_street_address: formatMessage({
      id: 'organization_schema_street_address',
    }),

    // Copyright  / Credit
    copyright: formatMessage({
      id: 'web_footer_copyright',
    }),
    adevinta_alt_text: formatMessage({
      id: 'web_footer_adevinta_alt_text',
    }),

    // App stores
    appstore_alt: formatMessage({
      id: 'config_footer_vertical_appstore_alt',
    }),
    appstore_link: formatMessage({
      id: 'config_vertical_appstore_link',
    }),
    playstore_alt: formatMessage({
      id: 'config_footer_vertical_playstore_alt',
    }),
    playstore_link: formatMessage({
      id: 'config_vertical_playstore_link',
    }),

    // Social media
    facebook_alt: formatMessage({
      id: 'config_footer_vertical_facebook_alt',
    }),
    facebook_link: formatMessage({
      id: 'config_footer_vertical_facebook_link',
    }),
    twitter_alt: formatMessage({
      id: 'config_footer_vertical_twitter_alt',
    }),
    twitter_link: formatMessage({
      id: 'config_footer_vertical_twitter_link',
    }),
    central_alt: formatMessage({
      id: 'config_footer_horizontal_central_alt',
    }),
    central_link: formatMessage({
      id: 'config_footer_horizontal_central_link',
    }),
    youtube_alt: formatMessage({
      id: 'config_footer_vertical_youtube_alt',
    }),
    youtube_link: formatMessage({
      id: 'config_footer_vertical_youtube_link',
    }),
    instagram_alt: formatMessage({
      id: 'config_footer_vertical_instagram_alt',
    }),
    instagram_link: formatMessage({
      id: 'config_footer_vertical_instagram_link',
    }),

    // Language switcher
    language_switch_title: formatMessage({
      id: 'language_switch_title',
    }),
    language_switch_flag_icon_alt: formatMessage({
      id: 'language_switcher_flag_icon_alt',
    }),
    language_switch_en_ca: formatMessage({
      id: 'language_switch_en_ca',
    }),
    language_switch_fr_ca: formatMessage({
      id: 'language_switch_fr_ca',
    }),
  };
};

export default useLabels;
