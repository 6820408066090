import React, { FC, Fragment, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';

import { AppErrorBoundary } from './AppErrorBoundary';
import TranslationProvider from '../TranslationProvider';
import {
  FEATURE_HEADS_UP,
  FEATURE_TOP_NAVIGATION,
} from '../../../shared/featureFlags';
import Page from '../../pages';
import Header from '../../pageSegments/header';
import Footer from '../../pageSegments/footer';
import { Snackbars } from '../../pageSegments/snackbars';
import {
  SkipToAccessibilityLink,
  SkipToContentLink,
  SkipToContentTarget,
} from './SkipToContent';
import ErrorPage from '../../pages/error';
import ScrollBehaviour from '../../../shared/components/scrollBehaviour';
import { SmartBanner } from '../../features/smartBanner';
import { HeadsUp } from '../../features/headsUp';
import { OutdatedBrowserBanner } from '../../features/outdatedBrowserBanner';
import { PushNotificationSubscriber } from '../../pageSegments/pushNotifications';
import { LoginDialog } from '../../pageSegments/loginDialog';

import { LoginPrompt } from '../../pageSegments/loginPrompt';
import { TopNavigation } from '../../features/topNavigation';
import usePreloadComponentOnIdle from '../../shared/hooks/usePreloadComponentOnIdle';

import { selectPhone } from '../../pages/dealer';
import styles from './App.css';
import {
  selectIsDealerPage,
  selectIsVIP,
  showErrorPageAction,
} from '../../stores/page';
import { NPSPerformanceSurvey } from '../../shared/components/NPSPerformanceSurvey';
import { NPSAfterMessageSurvey } from '../../shared/components/NPSAfterMessageSurvey';
import { GetFeedback } from '../../shared/components/GetFeedback';
import { State } from '../../stores/types/State';

const renderErrorPage = () => <ErrorPage page="error" />;

const App: FC = () => {
  const dispatch = useDispatch();
  const addPaddingToFooter = useSelector(
    (state: State) =>
      selectIsVIP(state) || (selectIsDealerPage(state) && !!selectPhone(state))
  );

  const SnackbarsWhenIdle = usePreloadComponentOnIdle(Snackbars);
  const LoginDialogWhenIdle = usePreloadComponentOnIdle(LoginDialog);
  const LoginPromptWhenIdle = usePreloadComponentOnIdle(LoginPrompt);

  const handleError = useCallback(
    (error: Error) => dispatch(showErrorPageAction(error)),
    [dispatch]
  );

  return (
    <TranslationProvider>
      <AppErrorBoundary renderErrorPage={renderErrorPage} onError={handleError}>
        <ScrollBehaviour>
          <Fragment>
            <NPSPerformanceSurvey />
            <NPSAfterMessageSurvey />
            <PushNotificationSubscriber />
            <SkipToContentLink />
            <SkipToAccessibilityLink />
            <SmartBanner className={styles.banner} />
            <OutdatedBrowserBanner className={styles.outdatedBrowserBanner} />
            {FEATURE_HEADS_UP && <HeadsUp className={styles.headsUp} />}
            {FEATURE_TOP_NAVIGATION && (
              <TopNavigation className={styles.headsUp} />
            )}
            <Header className={styles.header} />
            <SkipToContentTarget />
            <Page className={styles.wrapperContent} />
            <SnackbarsWhenIdle />
            <LoginDialogWhenIdle />
            <LoginPromptWhenIdle />
            <Footer
              className={cx(styles.footer, {
                [styles.addPaddingToFooter]: addPaddingToFooter,
              })}
            />
            <GetFeedback />
          </Fragment>
        </ScrollBehaviour>
      </AppErrorBoundary>
    </TranslationProvider>
  );
};

export default App;
