import { pages, SRP_ROUTE, VIP_ROUTE } from '../../stores/page';

export const selectUserInitial = ({
  authentication: { email = '', username = undefined } = {},
}) => (username || email).charAt(0) || null;

export const selectIsSearchResultPage = ({ page }) =>
  page === pages[String(SRP_ROUTE)];

export const selectIsVehicleItemPage = ({ page }) =>
  page === pages[String(VIP_ROUTE)];

const selectHeader = (state) => state?.pageSegments?.header;

export const selectHasUnreadMessages = (state) =>
  selectHeader(state)?.hasUnreadMessages || false;

export const selectMenuOpen = (state) => selectHeader(state)?.menuOpen || false;
