import {
  selectTradeInInput,
  selectTradeInValue,
} from '../../../features/tradeIn';
import { State } from '../../../stores/types/State';
import { TradeIn } from '../apiTypes';

const findOption = <T extends { value: string }>(
  theValue: string,
  options: T[]
): T | undefined =>
  theValue ? options.find(({ value }) => theValue === value) : undefined;

export const selectTradeInForDeal = (state: State): TradeIn | undefined => {
  const tradeInValue = selectTradeInValue(state);

  const {
    makeId,
    makeOptions,
    modelId,
    modelOptions,
    year,
    vehicleId,
    versionOptions,
    mileage,
    remainingPayment,
    lienHolderName,
  } = selectTradeInInput(state);

  const make = findOption(makeId, makeOptions)?.label;
  const model = findOption(modelId, modelOptions)?.label;
  const bodyStyle = findOption(vehicleId, versionOptions)?.bodyType;

  if (!make || !model || !bodyStyle || tradeInValue === null) {
    return undefined;
  }

  return {
    year: parseInt(year),
    make,
    model,
    mileage: parseInt(mileage),
    trim: findOption(vehicleId, versionOptions)?.label,
    tradeInAllowanceAmount: tradeInValue || 0,
    lienAmount: parseFloat(remainingPayment),
    lienHolderName,
    bodyStyle,
  };
};
