import {
  HANDLE_ERROR_PROTECTION_PRODUCTS,
  HANDLE_LOADING_PROTECTION_PRODUCTS,
  HANDLE_RESPONSE_PROTECTION_PRODUCTS,
  RESET_PROTECTION_PRODUCTS,
  SET_FORM_STATE,
} from './actionTypes';
import { postProtectionProducts } from './api';
import {
  selectTerm,
  selectFrequency,
  PaymentType,
} from '../financingCalculator';
import { State } from '../../stores/types/State';
import { Dispatch } from 'redux';
import { selectListing, selectPaymentType } from '../../pages/drp/selectors';
import ApiError from '../../../api/lib/apiClient/ApiError';
import {
  ProtectionProduct,
  ProtectionProductFormState,
} from '../../pages/drp/stateTypes';

export const setFormState = (payload: ProtectionProductFormState) =>
  ({
    type: SET_FORM_STATE,
    payload,
  } as const);

export const handleResponseProtectionProducts = (payload: {
  sessionId: string;
  products: ProtectionProduct[];
}) =>
  ({
    type: HANDLE_RESPONSE_PROTECTION_PRODUCTS,
    payload,
  } as const);

export const handleLoadingProtectionProducts = () =>
  ({
    type: HANDLE_LOADING_PROTECTION_PRODUCTS,
  } as const);

export const handleErrorProtectionProducts = (error: ApiError) =>
  ({
    type: HANDLE_ERROR_PROTECTION_PRODUCTS,
    payload: error,
  } as const);

export const fetchProtectionProducts =
  () => async (dispatch: Dispatch, getState: () => State) => {
    const state = getState();

    const listingId = String(selectListing(state)?.id);

    const financeType =
      selectPaymentType(state) === 'loan'
        ? PaymentType.Loan
        : PaymentType.Lease;

    dispatch(handleLoadingProtectionProducts());

    try {
      const response = await postProtectionProducts({
        listingId,
        financeType,
        term: selectTerm(state, financeType),
        paymentFrequency: selectFrequency(state, financeType),
      });

      dispatch(handleResponseProtectionProducts(response));
    } catch (e: any) {
      dispatch(handleErrorProtectionProducts(e));
    }
  };

export const resetProtectionProducts = () =>
  ({
    type: RESET_PROTECTION_PRODUCTS,
  } as const);

export type ProtectionPlansAction =
  | ReturnType<typeof setFormState>
  | ReturnType<typeof handleResponseProtectionProducts>
  | ReturnType<typeof handleErrorProtectionProducts>
  | ReturnType<typeof resetProtectionProducts>
  | ReturnType<typeof handleLoadingProtectionProducts>;
