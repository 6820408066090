import { SET_HAS_UNREAD_MESSAGES } from './actionTypes';

const defaultState = {
  hasUnreadMessages: false,
};

const header = {
  [SET_HAS_UNREAD_MESSAGES]: (state, { hasUnreadMessages }) => ({
    ...state,
    hasUnreadMessages,
  }),
};

export default (state = defaultState, { type, payload } = {}) =>
  header[type] ? header[type](state, payload) : state;
