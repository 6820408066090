import { NOT_FOUND, redirect } from 'redux-first-router';

import {
  DRP_ROUTE,
  ERROR_ROUTE,
  GO_TO_SRP,
  HOME_ROUTE,
  INBOX_ROUTE,
  LISTING_NOT_FOUND_ROUTE,
  MIP_ROUTE,
  MRP_ROUTE,
  MY_DEALS_ROUTE,
  NOTIFICATIONS_ROUTE,
  SAVED_ITEMS_ROUTE,
  SAVED_SEARCHES_ROUTE,
  srpHvipRouteActionsForLocale,
  VIP_ROUTE,
  VIP_ROUTE_WITH_FINANCING,
} from './constants';
import { removeLocalePrefixFromUrl } from '../../../lib/locale-utils';
import { MIP_BASE_PATHS } from '../../../shared/config';
import { Listing } from '../../shared/types/Listing';
import { PaymentType } from '../../features/financingCalculator';
import { Query } from '../searchQuery';
import { Frequency } from '../../shared/types/Frequency';

export const goToVip = (
  item: Partial<Pick<Listing, 'id'>>,
  parentTransactionId?: Listing['transactionId'],
  financing?: { termLoan?: string; frequencyLoan?: Frequency }
) => ({
  type:
    financing?.termLoan && financing?.frequencyLoan
      ? VIP_ROUTE_WITH_FINANCING
      : VIP_ROUTE,
  payload: {
    listingId: item.id,
    item: { ...item, provisional: true },
    parentTransactionId,
    ...financing,
  },
});

export const goToHashedVip = ({
  listingId,
  srpSeoSegments,
  locale,
  historyState,
  item,
  parentTransactionId,
  termLoan,
  frequencyLoan,
}: {
  listingId: Listing['id'];
  srpSeoSegments?: unknown;
  locale: string;
  historyState: { realVip: boolean };
  item?: Listing;
  parentTransactionId?: Listing['transactionId'];
  termLoan?: string;
  frequencyLoan?: Frequency;
}) =>
  ({
    type: srpHvipRouteActionsForLocale[locale],
    payload: {
      srpSeoSegments,
      listingId,
      historyState,
      timestamp: new Date().getTime(),
      item,
      parentTransactionId,
      termLoan,
      frequencyLoan,
    },
  } as const);

export const goToDigitalRetailPage = ({
  listingId,
  isPaymentSpokeFilledOut,
  financingType,
  spokeId = '',
}: {
  listingId: Listing['id'];
  isPaymentSpokeFilledOut?: boolean;
  financingType?: PaymentType;
  spokeId?: string;
}) =>
  ({
    type: DRP_ROUTE,
    payload: {
      listingId,
      isPaymentSpokeFilledOut,
      financingType,
      spoke: spokeId || undefined,
    },
  } as const);

export const goToHome = () => ({ type: HOME_ROUTE } as const);

export const redirectToHome = () => redirect(goToHome());

export const showErrorPageAction = (error: Error) =>
  ({ type: ERROR_ROUTE, payload: error } as const);

export const showNotFoundPageAction = () => ({ type: NOT_FOUND } as const);

export const showListingNotFoundPageAction = () =>
  ({ type: LISTING_NOT_FOUND_ROUTE } as const);

export const goToSrp = (query?: Query) =>
  ({ type: GO_TO_SRP, payload: { query } } as const);

export const goToMrp = (locale: string) =>
  ({ type: `${MRP_ROUTE}_${locale}` } as const);

export const goToSavedItems = () => ({ type: SAVED_ITEMS_ROUTE } as const);

export const goToSavedSearches = () =>
  ({ type: SAVED_SEARCHES_ROUTE } as const);

export const goToInbox = () => ({ type: INBOX_ROUTE } as const);

export const goToNotifications = () => ({ type: NOTIFICATIONS_ROUTE } as const);

export const goToMyDeals = () => ({ type: MY_DEALS_ROUTE } as const);

/**
 * The sole purpose of this action is to capture which page refers to the MIP,
 * we could have just used URI otherwise.
 * Unfortunately we get ready URIs as input and have to parse them here
 * to pass proper payload to the router.
 */
export const goToMip = ({
  url,
  locale,
  referrer,
}: {
  url: string;
  locale: string;
  referrer: string;
}) =>
  ({
    type: `${MIP_ROUTE}_${locale}`,
    payload: {
      referrer,
      mipSeoSegments: removeLocalePrefixFromUrl(locale, url)
        .replace(MIP_BASE_PATHS[locale], '')
        .replace(/(^\/)|(\/$)/g, ''),
    },
  } as const);
