export const REQUEST_TRADE_IN_RESULT =
  'feature/tradeIn/REQUEST_TRADE_IN_RESULT' as const;
export const TRADE_IN_DATA_LOADED =
  'feature/tradeIn/TRADE_IN_DATA_LOADED' as const;
export const HANDLE_TRADE_IN_RESULT =
  'feature/tradeIn/HANDLE_TRADE_IN_RESULT' as const;
export const REMOVE_TRADE_IN_RESULT =
  'feature/tradeIn/REMOVE_TRADE_IN_RESULT' as const;
export const SET_TRADE_IN_ERROR = 'feature/tradeIn/SET_TRADE_IN_ERROR' as const;
export const SAVE_TRADE_IN_INPUT =
  'feature/tradeIn/SAVE_TRADE_IN_INPUT' as const;
export const SAVE_TRADE_IN_RESULT =
  'feature/tradeIn/SAVE_TRADE_IN_RESULT' as const;
export const REQUEST_CAR_DETAILS_BY_VIN_RESULT =
  'feature/tradeIn/REQUEST_CAR_DETAILS_BY_VIN_RESULT' as const;
export const SET_CAR_DETAILS_BY_VIN_ERROR =
  'feature/tradeIn/SET_CAR_DETAILS_BY_VIN_ERROR' as const;
export const HANDLE_CAR_DETAILS_BY_VIN_RESULT =
  'feature/tradeIn/HANDLE_CAR_DETAILS_BY_VIN_RESULT' as const;
export const REQUEST_COLOR_OPTIONS_BY_VEHICLE_ID_RESULT =
  'feature/tradeIn/REQUEST_COLOR_OPTIONS_BY_VEHICLE_ID_RESULT' as const;
export const SET_COLOR_OPTIONS_BY_VEHICLE_ID_ERROR =
  'feature/tradeIn/SET_COLOR_OPTIONS_BY_VEHICLE_ID_ERROR' as const;
export const HANDLE_COLOR_OPTIONS_BY_VEHICLE_ID_RESULT =
  'feature/tradeIn/HANDLE_COLOR_OPTIONS_BY_VEHICLE_ID_RESULT' as const;
export const REMOVE_BY_VIN_RESULTS =
  'feature/tradeIn/REMOVE_BY_VIN_RESULTS' as const;
