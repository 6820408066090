import { SRP_BASE_PATHS } from '../../../shared/config';
import { getPreviousRoute } from '../../stores/location';
import { DEALER_ROUTE, SRP_ROUTE } from '../../stores/page';

export const isReferredBySrp = (state) => {
  const fullNames = Object.keys(SRP_BASE_PATHS).map(
    (local) => `${SRP_ROUTE}_${local}`
  );
  const previousRoute = getPreviousRoute(state);
  return fullNames.find((n) => n === previousRoute.type) !== undefined;
};

export const isLifeStyleSrp = (state) => {
  const { reverseLookupAllowed = true } = state.pages.srp.url ?? {};
  return !reverseLookupAllowed;
};

export const isReferredByDealerPage = (state) => {
  const previousRoute = getPreviousRoute(state);
  return previousRoute.type === DEALER_ROUTE;
};
