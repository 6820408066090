export const HANDLE_RESPONSE_LISTING = 'page/drp/HANDLE_RESPONSE_LISTING';
export const SET_TRADE_IN_INPUT_VALUE = 'page/drp/SET_TRADE_IN_INPUT_VALUE';
export const SET_IS_PAYMENT_SPOKE_FILLED_OUT =
  'page/drp/SET_IS_PAYMENT_SPOKE_FILLED_OUT';
export const SET_IS_TRADE_IN_SPOKE_FILLED_OUT =
  'page/drp/SET_IS_TRADE_IN_SPOKE_FILLED_OUT';
export const SET_IS_FINANCING_SPOKE_FILLED_OUT =
  'page/drp/SET_IS_FINANCING_SPOKE_FILLED_OUT';
export const SET_PAYMENT_TYPE = 'page/drp/SET_PAYMENT_TYPE';
export const DEAL_SUBMITTED = 'page/drp/DEAL_SUBMITTED';
export const DEAL_SAVED = 'page/drp/DEAL_SAVED';
export const DEAL_ABORTED = 'page/drp/DEAL_ABORTED';
export const DEAL_ERROR = 'page/drp/DEAL_ERROR';
export const DEAL_REQUEST = 'page/drp/DEAL_REQUEST';
export const RESET_PAGE = 'page/drp/RESET_PAGE';
