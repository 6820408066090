import { Coordinates } from '../types/Coordinates';

type Location = {
  country?: { longName: string };
  province?: { shortName: string; longName: string };
};

export const isGeoPositionValid = (position: Partial<Coordinates>) =>
  typeof position.longitude !== 'undefined' &&
  typeof position.latitude !== 'undefined';

export const areGeoPositionsEqual = (
  position1: Partial<Coordinates>,
  position2: Partial<Coordinates>
) =>
  position1.longitude === position2.longitude &&
  position1.latitude === position2.latitude;

export const getCountry = ({ country }: { country?: { longName: string } }) =>
  country?.longName;

export const getProvince = ({
  province,
}: {
  province?: { shortName: string; longName: string };
}) => ({
  code: province?.shortName,
  name: province?.longName,
});

export const getAddressField = (location: Location, key: string) => {
  if (key === 'country') {
    return getCountry(location);
  }
  if (key === 'province') {
    return getProvince(location);
  }

  return location[key]?.longName || location[key]?.shortName || location[key];
};

export const getFormattedAddress = (location: Location = {}) =>
  getAddressField(location, 'address');

export const locationToClientFormat = (location: Location = {}) => ({
  value: getFormattedAddress(location),
  ...Object.keys(location).reduce(
    (acc, key) => ({
      ...acc,
      [key]: getAddressField(location, key),
    }),
    {
      province: getProvince(location),
    }
  ),
});
