import { omitBy } from 'lodash';
import { createSelector } from 'reselect';
import { State } from '../../../stores/types/State';
import { isEmpty } from '../api';
import { FinancingInput } from '../stateTypes';
import { DealRequest, AddressType, Relationship } from './../apiTypes';

const formatDate = (day: string, month: string, year: string) =>
  `${('0' + month).slice(-2)}/${('0' + day).slice(-2)}/${year}`;

const requiresPreviousEmployer = (
  yearsWithEmployer: FinancingInput['yearsWithEmployer']
) => yearsWithEmployer !== '' && parseInt(yearsWithEmployer) < 2;

const requiresPreviousResidence = (
  yearsAtAddress: FinancingInput['yearsAtAddress']
) => yearsAtAddress !== '' && parseInt(yearsAtAddress) < 2;

export const selectFinancingSpokeInput = (state: State) =>
  state.pages.drp.financing.input;

export const selectRequiresPreviousEmployer = (state: State) => {
  const { yearsWithEmployer } = selectFinancingSpokeInput(state);
  return requiresPreviousEmployer(yearsWithEmployer);
};

export const selectRequiresPreviousResidence = (state: State) => {
  const { yearsAtAddress } = selectFinancingSpokeInput(state);
  return requiresPreviousResidence(yearsAtAddress);
};

export const selectFinancingSpokeCoApplicant = (state: State) =>
  state.pages.drp.financing.coApplicant;

export const selectCoApplicantRequiresPreviousEmployer = (state: State) => {
  const { yearsWithEmployer } = selectFinancingSpokeCoApplicant(state);
  return requiresPreviousEmployer(yearsWithEmployer);
};

export const selectCoApplicantRequiresPreviousResidence = (state: State) => {
  const { yearsAtAddress } = selectFinancingSpokeCoApplicant(state);
  return requiresPreviousResidence(yearsAtAddress);
};

export const selectCoApplicantRelationship = (state: State) =>
  selectFinancingSpokeCoApplicant(state).relationship;

const digitsFrom = (input?: string) =>
  (input ?? '').match(/[0-9]+/g)?.join('') || '';

const selectFinancingDealData = (
  input: FinancingInput,
  // eslint-disable-next-line @typescript-eslint/no-shadow
  requiresPreviousResidence: boolean,
  // eslint-disable-next-line @typescript-eslint/no-shadow
  requiresPreviousEmployer: boolean,
  relation?: Relationship
) =>
  omitBy(
    {
      relation,
      email: input.email,
      personal: omitBy(
        {
          honorificPrefix: input.title,
          firstName: input.firstName,
          middleName: input.middleName,
          lastName: input.lastName,
          phone: digitsFrom(input.phone),
          mobilePhone: digitsFrom(input.mobilePhone),
          birthDate: formatDate(
            input.birthdateDay,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            input.birthdateMonth!,
            input.birthdateYear
          ),
          gender: input.gender,
          maritalStatus: input.maritalStatus,
        },
        isEmpty
      ),
      residence: omitBy(
        {
          address: omitBy(
            {
              addressType: input.addressType,
              streetNumber: input.streetNumber,
              streetName: input.street,
              suiteNumber: input.suiteNumber,
              streetType: input.streetType,
              streetDirection: input.streetDirection,
              postBoxNumber: parseInt(input.postalBoxNumber),
              civicAddress: input.civicAddress,
              ruralRouteNumber: parseInt(input.ruralRouteNumber),
              concession: input.concession,
              lotNumber: parseInt(input.lotNumber),
              city: input.city,
              province: input.province,
              postalCode: input.postalCode,
            },
            isEmpty
          ),

          yearsAtAddress: parseInt(input.yearsAtAddress),
          monthsAtAddress: parseInt(input.monthsAtAddress),
          homeMonthlyPayment: parseFloat(input.monthlyPayment),
          homeOwnershipType: input.homeStatus,
          homeMortgageAmount: parseInt(input.homeMortgageAmount),
          homeMarketValue: parseInt(input.homeMarketValue),
          homeMortgageOwnership: input.homeMortgageOwnership,
          additionalInfo: input.homeAdditionInfo,
          landlordName: input.landlordName,
        },
        isEmpty
      ),
      previousResidence: requiresPreviousResidence && {
        address: omitBy(
          {
            addressType: input.previousAddressType,
            streetNumber: input.previousStreetNumber,
            streetName: input.previousStreet,
            suiteNumber: input.previousSuiteNumber,
            streetType: input.previousStreetType,
            streetDirection: input.previousStreetDirection,
            postBoxNumber: parseInt(input.previousPostalBoxNumber),
            civicAddress: input.previousCivicAddress,
            ruralRouteNumber: parseInt(input.previousRuralRouteNumber),
            concession: input.previousConcession,
            lotNumber: parseInt(input.previousLotNumber),
            city: input.previousCity,
            province: input.previousProvince,
            postalCode: input.previousPostalCode,
          },
          isEmpty
        ),
        yearsAtAddress: parseInt(input.previousYearsAtAddress),
        monthsAtAddress: parseInt(input.previousMonthsAtAddress),
      },
      employment: omitBy(
        {
          employmentType: input.employmentType,
          employmentStatus: input.employmentStatus,
          annualGrossIncome: parseFloat(input.annualIncome),
          address: omitBy(
            {
              addressType: input.employerAddressType,
              streetNumber: input.employerStreetNumber,
              streetName: input.employerStreet,
              suiteNumber: input.employerSuiteNumber,
              streetType: input.employerStreetType,
              streetDirection: input.employerStreetDirection,
              postBoxNumber: parseInt(input.employerPostalBoxNumber),
              civicAddress: input.employerCivicAddress,
              ruralRouteNumber: parseInt(input.employerRuralRouteNumber),
              concession: input.employerConcession,
              lotNumber: parseInt(input.employerLotNumber),
              city: input.employerCity,
              province: input.employerProvince,
              postalCode: input.employerPostalCode,
            },
            isEmpty
          ),
          employerName: input.employerName,
          occupation: input.occupation,
          employerPhone: digitsFrom(input.workPhone),
          employerDurationYears: parseInt(input.yearsWithEmployer),
          employerDurationMonths: parseInt(input.monthsWithEmployer),
        },
        isEmpty
      ),
      previousEmployment:
        requiresPreviousEmployer &&
        omitBy(
          {
            employmentType: input.previousEmploymentType,
            employmentStatus: input.previousEmploymentStatus,
            annualGrossIncome: parseFloat(input.previousAnnualIncome),
            address: omitBy(
              {
                addressType: input.previousEmployerAddressType,
                streetNumber: input.previousEmployerStreetNumber,
                streetName: input.previousEmployerStreet,
                suiteNumber: input.previousEmployerSuiteNumber,
                streetType: input.previousEmployerStreetType,
                streetDirection: input.previousEmployerStreetDirection,
                postBoxNumber: parseInt(input.previousEmployerPostalBoxNumber),
                civicAddress: input.previousEmployerCivicAddress,
                ruralRouteNumber: parseInt(
                  input.previousEmployerRuralRouteNumber
                ),
                concession: input.previousEmployerConcession,
                lotNumber: parseInt(input.previousEmployerLotNumber),
                city: input.previousEmployerCity,
                province: input.previousEmployerProvince,
                postalCode: input.previousEmployerPostalCode,
              },
              isEmpty
            ),
            employerName: input.previousEmployerName,
            occupation: input.previousOccupation,
            employerPhone: digitsFrom(input.previousWorkPhone),
            employerDurationYears: parseInt(input.previousYearsWithEmployer),
            employerDurationMonths: parseInt(input.previousMonthsWithEmployer),
          },
          isEmpty
        ),
    },
    (value) => !value
  ) as DealRequest['applicant'];

export const selectFinancingForDeal = createSelector(
  selectFinancingSpokeInput,
  selectRequiresPreviousResidence,
  selectRequiresPreviousEmployer,
  selectFinancingDealData
);

export const selectFinancingCoApplicantForDeal = createSelector(
  selectFinancingSpokeCoApplicant,
  selectCoApplicantRequiresPreviousResidence,
  selectCoApplicantRequiresPreviousEmployer,
  selectCoApplicantRelationship,
  selectFinancingDealData
);

export const selectFinancingAddressType = (state: State) =>
  selectFinancingSpokeInput(state).addressType;

export const selectCoApplicantAddressType = (state: State) =>
  selectFinancingSpokeCoApplicant(state).addressType;

const areAllFieldsSet = (object: FinancingInput, ...keys: string[]) =>
  keys.every(
    (key) => typeof object[key] === 'string' && object[key].length > 0
  );

const commonAddressFields = [
  'firstName',
  'lastName',
  'city',
  'province',
  'postalCode',
] as const;

const isAddressFilled = (addressType: AddressType, address: FinancingInput) => {
  switch (addressType) {
    case AddressType.Street:
      return areAllFieldsSet(
        address,
        ...commonAddressFields,
        'street',
        'streetNumber'
      );
    case AddressType.RuralRoute:
      return areAllFieldsSet(
        address,
        ...commonAddressFields,
        'ruralRouteNumber',
        'concession',
        'lotNumber'
      );
    case AddressType.PostalBox:
      return areAllFieldsSet(
        address,
        ...commonAddressFields,
        'postalBoxNumber',
        'civicAddress'
      );
    default:
      return false;
  }
};

export const selectIsFinancingAddressAvailable = createSelector(
  selectFinancingAddressType,
  selectFinancingSpokeInput,
  isAddressFilled
);

export const selectIsCoApplicantAddressAvailable = createSelector(
  selectCoApplicantAddressType,
  selectFinancingSpokeCoApplicant,
  isAddressFilled
);

export const selectIsFinancingSpokeReady = (state) =>
  state.pages.drp.isFinancingSpokeFilledOut;
