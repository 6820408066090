import {
  DIMENSION_USER_EMAIL,
  DIMENSION_USER_ID,
} from '../../utils/tracking/dimensions';
import { EVENT_KEYS } from '../../../lib/optimizely';

export const LOGIN_BEGIN = {
  eventAction: 'LoginBegin',
  getEventData: ({
    flow,
    contextLabel,
  }: {
    flow?: string;
    contextLabel?: string;
  }) => ({
    eventLabel: flow
      ? `flow=${flow}${contextLabel ? `; ${contextLabel}` : ''}`
      : 'NA',
  }),
  optimizelyEvents: [EVENT_KEYS.SIGN_IN],
};

export const LOGIN_SUCCESS = {
  eventAction: 'LoginSuccess',
  getEventData: ({
    externalAuthProvider,
    userId,
    userEmail,
  }: {
    externalAuthProvider: string;
    userId?: string;
    userEmail?: string;
  }) => ({
    eventLabel: externalAuthProvider,
    [DIMENSION_USER_ID]: userId,
    [DIMENSION_USER_EMAIL]: userEmail,
  }),
  optimizelyEvents: [EVENT_KEYS.LOGIN_SUCCESS],
};

export const USER_REGISTRATION_BEGIN = {
  eventAction: 'UserRegistrationBegin',
  getEventData: ({
    flow,
    contextLabel,
  }: {
    flow?: string;
    contextLabel?: string;
  }) =>
    flow
      ? { eventLabel: `flow=${flow}${contextLabel ? `; ${contextLabel}` : ''}` }
      : {},
};

export const USER_REGISTRATION_SUCCESS = {
  eventAction: 'UserRegistrationSuccess',
  getEventData: ({
    externalAuthProvider,
  }: {
    externalAuthProvider: string;
  }) => ({ eventLabel: externalAuthProvider }),
};
