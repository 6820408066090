import React, { FC, Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { updateScroll } from 'redux-first-router';
import { getPathName } from '../../../app/stores/location';
import usePrevious from '../../../app/shared/hooks/usePrevious';

type Props = {
  onUpdate?: () => void;
};

const ScrollBehaviour: FC<Props> = ({ children, onUpdate }) => {
  const pathName = useSelector(getPathName);
  const previousPathName = usePrevious(pathName);

  useEffect(() => {
    if (
      typeof previousPathName !== 'undefined' &&
      pathName !== previousPathName
    ) {
      updateScroll();
      onUpdate?.();
    }
  }, [pathName, previousPathName, onUpdate]);

  return <Fragment>{children}</Fragment>;
};

export default ScrollBehaviour;
