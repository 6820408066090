import { HIDE_LOGIN_DIALOG, SHOW_LOGIN_DIALOG } from './actionTypes';

export const defaultState = {
  isLoginDialogOpen: false,
};

const states = {
  [SHOW_LOGIN_DIALOG]: (state, { payload }) => ({
    ...state,
    isLoginDialogOpen: true,
    type: payload.type,
    source: payload.source,
    payload: payload.data,
    category: payload.category,
    eventName: payload.eventName,
    contextLabel: payload.contextLabel,
  }),
  [HIDE_LOGIN_DIALOG]: (state) => ({
    ...state,
    isLoginDialogOpen: false,
  }),
};

const reducer = (state = defaultState, action = {}) =>
  states[action.type] ? states[action.type](state, action) : state;

export default reducer;
